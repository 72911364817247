import { LSP } from './types';

let lastRequestId: string | null = null;

export function getLastRequestId() {
  return lastRequestId;
}

export function getRequestId() {
  lastRequestId = `${Math.random()}`;
  return lastRequestId;
}

export function isLSPError(e: unknown): e is LSP.Error {
  return typeof e === 'object' && e !== null && 'message' in e;
}
