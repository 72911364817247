import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { toFormattedTime } from '@prophecy/utils/date';
import { CommentProvider, SCOPE_ACTIVE_COMMENT, useFloatingCommentsContentState, useFloatingCommentsState, CommentsPlugin, 
// useCommentLeaf,
useCommentLeafState } from '@udecode/plate-comments/react';
import { PortalBody } from '@udecode/plate-common/react';
import { createContext, createRef, useContext } from 'react';
import { Avatar } from '../Avatar';
import { Stack, StackItem } from '../Layout';
import { theme } from '../theme';
import { Text } from '../Typography';
import { StyledCommentedText, StyledCommentPopup, StyledCommentsPositioner } from './styled';
export const CommentsContext = createContext({
    comments: [],
    renderCommentActions: () => null,
    markdownContainer: createRef()
});
export function CommentsPopoverContent() {
    const { activeCommentId, ref } = useFloatingCommentsContentState();
    const { comments, renderCommentActions } = useContext(CommentsContext);
    const comment = comments.find((c) => c.id === activeCommentId);
    if (!comment)
        return null;
    return (_jsx(CommentProvider, { id: activeCommentId, scope: SCOPE_ACTIVE_COMMENT, children: _jsxs(StyledCommentPopup, { ref: ref, gap: theme.spaces.x16, children: [_jsxs(Stack, { direction: 'horizontal', alignY: 'center', gap: theme.spaces.x12, children: [_jsx(StackItem, { shrink: '0', children: _jsx(Avatar, { size: 'md', src: comment.avatarUrl, icon: comment.avatar }) }), _jsxs(Stack, { children: [_jsx(Text, { level: 'sm', weight: theme.fontWeight.medium, children: comment.author }), _jsx(Text, { level: 'xs', children: toFormattedTime(Date.now() - comment.createdAt) })] })] }), _jsx(Text, { level: 'sm', children: comment.value }), renderCommentActions && renderCommentActions(comment)] }) }, activeCommentId));
}
export function CommentsPopover() {
    const { activeCommentId, loaded } = useFloatingCommentsState();
    const { markdownContainer } = useContext(CommentsContext);
    if (!loaded || !activeCommentId)
        return null;
    return (_jsx(PortalBody, { element: markdownContainer?.current, children: _jsx(StyledCommentsPositioner, { children: _jsx(CommentsPopoverContent, {}) }) }));
}
function extractCommentClassNamesFromLeaf(leaf) {
    return Object.keys(leaf).filter((key) => key.startsWith('comment_'));
}
export function CommentedText(props) {
    const { children, leaf, nodeProps } = props;
    const state = useCommentLeafState({ leaf });
    // const { props: rootProps } = useCommentLeaf(state);
    if (!state.commentCount)
        return _jsx(_Fragment, { children: children });
    const commentClassNames = extractCommentClassNamesFromLeaf(leaf);
    return (_jsx(StyledCommentedText, { ...props, isActive: state.isActive, className: [...commentClassNames, props.className].join(' '), nodeProps: {
            // ...rootProps,
            ...nodeProps
        }, children: children }));
}
export const CustomCommentPlugin = CommentsPlugin.configure({
    render: { afterEditable: () => _jsx(CommentsPopover, {}) }
});
