import { theme } from '../theme/index';
export const tokens = {
    TabsRoot: {
        gap: theme.spaces.x4,
        borderColor: theme.colors.gray200,
        borderRadius: theme.radius.x12,
        padding: theme.spaces.x12
    },
    TabsList: {
        fontSize: theme.fontSizes.x14,
        fontWeight: theme.fontWeight.semiBold,
        padding: theme.spaces.x12,
        fontFamily: theme.fontFamily.text,
        white: {
            borderRadius: theme.radius.x10,
            borderColor: theme.colors.gray200,
            backgroundColor: theme.colors.gray50,
            padding: theme.spaces.x4,
            gap: theme.spaces.x4
        },
        minimal: {
            borderRadius: theme.radius.x10,
            borderColor: theme.colors.gray100,
            backgroundColor: theme.colors.gray50,
            padding: theme.spaces.none,
            gap: theme.spaces.x2
        }
    },
    TabsTrigger: {
        fontSize: theme.fontSizes.x14,
        fontWeight: theme.fontWeight.semiBold,
        color: theme.colors.gray500,
        borderRadius: theme.radius.x6,
        transition: theme.transition.m,
        brand: {
            backgroundColor: theme.colors.brand50,
            color: theme.colors.brand700,
            borderColor: theme.colors.brand300,
            boxShadow: 'none'
        },
        gray: {
            backgroundColor: theme.colors.gray50,
            color: theme.colors.gray700,
            borderColor: theme.colors.gray300,
            boxShadow: 'none'
        },
        underline: {
            backgroundColor: theme.colors.transparent,
            color: theme.colors.brand700,
            borderColor: theme.colors.transparent,
            boxShadow: 'none'
        },
        white: {
            backgroundColor: theme.colors.white,
            color: theme.colors.gray700,
            borderColor: theme.colors.transparent,
            boxShadow: theme.shadows.sm
        },
        minimal: {
            backgroundColor: theme.colors.white,
            color: theme.colors.gray700,
            borderColor: theme.colors.gray300,
            boxShadow: theme.shadows.sm
        },
        disabled: {
            color: theme.colors.gray300,
            borderColor: theme.colors.gray200,
            backgroundColor: theme.colors.transparent
        },
        active: {
            backgroundColor: theme.colors.transparent
        },
        size: {
            s: {
                height: theme.sizes.x36,
                padding: theme.sizes.x12,
                margin: theme.spaces.x2,
                fontSize: theme.text.sm.fontSize
            },
            m: {
                height: theme.sizes.x44,
                padding: theme.spaces.x14,
                margin: theme.spaces.x4,
                fontSize: theme.text.md.fontSize
            }
        }
    },
    TabsTriggerGroup: {
        backgroundColor: theme.colors.brand50,
        TabsTrigger: {
            brand: {
                color: theme.colors.brand500,
                active: {
                    backgroundColor: theme.colors.brand100,
                    color: theme.colors.brand700
                }
            },
            gray: {
                color: theme.colors.gray500,
                active: {
                    backgroundColor: theme.colors.gray100,
                    color: theme.colors.gray700
                }
            },
            underline: {
                color: theme.colors.gray500,
                active: {
                    backgroundColor: theme.colors.gray100,
                    color: theme.colors.gray700
                }
            },
            white: {
                color: theme.colors.gray500,
                active: {
                    backgroundColor: theme.colors.gray100,
                    color: theme.colors.gray700
                }
            },
            minimal: {
                color: theme.colors.gray500,
                active: {
                    backgroundColor: theme.colors.gray100,
                    color: theme.colors.gray700
                }
            }
        }
    },
    SwitchTabs: {
        TabsList: {
            background: theme.colors.gray100,
            borderRadius: theme.radius.x6,
            fontWeight: theme.fontWeight.medium,
            color: theme.colors.gray500
        },
        TabsTrigger: {
            color: theme.colors.gray500,
            brand: {
                active: {
                    borderColor: theme.colors.purple300,
                    color: theme.colors.purple700,
                    iconColor: theme.colors.purple600
                }
            },
            active: {
                backgroundColor: theme.colors.white,
                borderColor: theme.colors.gray300,
                borderRadius: theme.radius.x6,
                color: theme.colors.gray700,
                boxShadow: theme.shadows.sm
            }
        }
    }
};
