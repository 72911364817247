import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import { Stack } from '../Layout';
import { Checkbox } from './Checkbox';
import { tokens } from './tokens';
export function CheckboxGroup(_a) {
    var { options, name, value, defaultValue, orientation = 'vertical', gap = tokens.CheckboxGroup.gap, onChange } = _a, restProps = __rest(_a, ["options", "name", "value", "defaultValue", "orientation", "gap", "onChange"]);
    const [checkedValues = [], setCheckedValues] = useControlledState({
        value,
        defaultValue,
        onChange
    });
    const checkedValuesMap = Object.fromEntries(checkedValues.map((value) => [value, true]));
    const _onChange = (value, checked) => {
        const newCheckedValues = checked
            ? [...checkedValues, value]
            : checkedValues.filter((checkedValue) => checkedValue !== value);
        setCheckedValues(newCheckedValues);
    };
    return (_jsx(Stack, Object.assign({ direction: orientation, gap: gap }, restProps, { children: options.map(({ key, label, value, disabled, hint, helpText }, index) => (_jsx(Checkbox, { name: name, value: value, disabled: disabled, checked: Boolean(checkedValuesMap[value]), hint: hint, helpText: helpText, onChange: (checked) => _onChange(value, checked), children: label }, key !== null && key !== void 0 ? key : index))) })));
}
