import '@fontsource/inter';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/roboto-mono';
import '@fontsource/roboto-mono/500.css';
import '@fontsource/roboto-mono/600.css';
import '@fontsource/roboto-mono/700.css';
import './theme.css';
export const theme = {
    outlineWidth: 'var(--var-outlineWidth)',
    newBreakpoints: {
        m: 'var(--var-newBreakpoints-m)',
        l: 'var(--var-newBreakpoints-l)',
        xl: 'var(--var-newBreakpoints-xl)'
    },
    fontFamily: {
        text: 'var(--var-fontFamily-text)',
        code: 'var(--var-fontFamily-code)'
    },
    fontWeight: {
        normal: 'var(--var-fontWeight-normal)',
        medium: 'var(--var-fontWeight-medium)',
        semiBold: 'var(--var-fontWeight-semiBold)',
        bold: 'var(--var-fontWeight-bold)'
    },
    display: {
        xxl: {
            fontSize: 'var(--var-display-xxl-fontSize)',
            lineHeight: 'var(--var-display-xxl-lineHeight)'
        },
        xl: {
            fontSize: 'var(--var-display-xl-fontSize)',
            lineHeight: 'var(--var-display-xl-lineHeight)'
        },
        lg: {
            fontSize: 'var(--var-display-lg-fontSize)',
            lineHeight: 'var(--var-display-lg-lineHeight)'
        },
        md: {
            fontSize: 'var(--var-display-md-fontSize)',
            lineHeight: 'var(--var-display-md-lineHeight)'
        },
        sm: {
            fontSize: 'var(--var-display-sm-fontSize)',
            lineHeight: 'var(--var-display-sm-lineHeight)'
        },
        xs: {
            fontSize: 'var(--var-display-xs-fontSize)',
            lineHeight: 'var(--var-display-xs-lineHeight)'
        }
    },
    text: {
        xl: {
            fontSize: 'var(--var-text-xl-fontSize)',
            lineHeight: 'var(--var-text-xl-lineHeight)'
        },
        lg: {
            fontSize: 'var(--var-text-lg-fontSize)',
            lineHeight: 'var(--var-text-lg-lineHeight)'
        },
        md: {
            fontSize: 'var(--var-text-md-fontSize)',
            lineHeight: 'var(--var-text-md-lineHeight)'
        },
        sm: {
            fontSize: 'var(--var-text-sm-fontSize)',
            lineHeight: 'var(--var-text-sm-lineHeight)'
        },
        xs: {
            fontSize: 'var(--var-text-xs-fontSize)',
            lineHeight: 'var(--var-text-xs-lineHeight)'
        },
        xxs: {
            fontSize: 'var(--var-text-xxs-fontSize)',
            lineHeight: 'var(--var-text-xxs-lineHeight)'
        }
    },
    code: {
        sm: {
            fontSize: 'var(--var-code-sm-fontSize)',
            lineHeight: 'var(--var-code-sm-lineHeight)'
        },
        xs: {
            fontSize: 'var(--var-code-xs-fontSize)',
            lineHeight: 'var(--var-code-xs-lineHeight)'
        }
    },
    shadows: {
        xs: 'var(--var-shadows-xs)',
        sm: 'var(--var-shadows-sm)',
        md: 'var(--var-shadows-md)',
        lg: 'var(--var-shadows-lg)',
        xl: 'var(--var-shadows-xl)',
        xxl: 'var(--var-shadows-xxl)',
        xxxl: 'var(--var-shadows-xxxl)',
        skeumorphic: 'var(--var-shadows-skeumorphic)',
        skeumorphicXs: 'var(--var-shadows-skeumorphicXs)',
        primaryButtonHover: 'var(--var-shadows-primaryButtonHover)'
    },
    effects: {
        primaryButtonBG: {
            default: 'var(--var-effects-primaryButtonBG-default)',
            defaultHover: 'var(--var-effects-primaryButtonBG-defaultHover)',
            error: 'var(--var-effects-primaryButtonBG-error)',
            errorHover: 'var(--var-effects-primaryButtonBG-errorHover)'
        }
    },
    focusRing: {
        color: 'var(--var-focusRing-color)',
        shadow: {
            xs: 'var(--var-focusRing-shadow-xs)',
            sm: 'var(--var-focusRing-shadow-sm)',
            skeumorphic: 'var(--var-focusRing-shadow-skeumorphic)'
        },
        error: {
            color: 'var(--var-focusRing-error-color)',
            shadow: {
                xs: 'var(--var-focusRing-error-shadow-xs)',
                skeumorphic: 'var(--var-focusRing-error-shadow-skeumorphic)'
            }
        }
    },
    backgroundBlur: {
        xs: 'var(--var-backgroundBlur-xs)',
        sm: 'var(--var-backgroundBlur-sm)',
        md: 'var(--var-backgroundBlur-md)',
        lg: 'var(--var-backgroundBlur-lg)',
        xl: 'var(--var-backgroundBlur-xl)'
    },
    colorShades: {
        x0: 'var(--var-colorShades-x0)',
        x25: 'var(--var-colorShades-x25)',
        x50: 'var(--var-colorShades-x50)',
        x100: 'var(--var-colorShades-x100)',
        x200: 'var(--var-colorShades-x200)',
        x300: 'var(--var-colorShades-x300)',
        x400: 'var(--var-colorShades-x400)',
        x500: 'var(--var-colorShades-x500)',
        x600: 'var(--var-colorShades-x600)',
        x700: 'var(--var-colorShades-x700)',
        x800: 'var(--var-colorShades-x800)',
        x900: 'var(--var-colorShades-x900)',
        x950: 'var(--var-colorShades-x950)'
    },
    spaces: {
        none: 'var(--var-spaces-none)',
        x1: 'var(--var-spaces-x1)',
        x2: 'var(--var-spaces-x2)',
        x3: 'var(--var-spaces-x3)',
        x4: 'var(--var-spaces-x4)',
        x6: 'var(--var-spaces-x6)',
        x8: 'var(--var-spaces-x8)',
        x10: 'var(--var-spaces-x10)',
        x12: 'var(--var-spaces-x12)',
        x14: 'var(--var-spaces-x14)',
        x16: 'var(--var-spaces-x16)',
        x20: 'var(--var-spaces-x20)',
        x24: 'var(--var-spaces-x24)',
        x28: 'var(--var-spaces-x28)',
        x32: 'var(--var-spaces-x32)',
        x36: 'var(--var-spaces-x36)',
        x40: 'var(--var-spaces-x40)',
        x44: 'var(--var-spaces-x44)',
        x48: 'var(--var-spaces-x48)',
        x56: 'var(--var-spaces-x56)',
        x64: 'var(--var-spaces-x64)',
        x80: 'var(--var-spaces-x80)',
        x96: 'var(--var-spaces-x96)',
        x128: 'var(--var-spaces-x128)',
        x160: 'var(--var-spaces-x160)'
    },
    fontSizes: {
        x10: 'var(--var-fontSizes-x10)',
        x12: 'var(--var-fontSizes-x12)',
        x13: 'var(--var-fontSizes-x13)',
        x14: 'var(--var-fontSizes-x14)',
        x15: 'var(--var-fontSizes-x15)',
        x16: 'var(--var-fontSizes-x16)',
        x18: 'var(--var-fontSizes-x18)',
        x20: 'var(--var-fontSizes-x20)',
        x22: 'var(--var-fontSizes-x22)',
        x24: 'var(--var-fontSizes-x24)',
        x28: 'var(--var-fontSizes-x28)',
        x30: 'var(--var-fontSizes-x30)',
        x36: 'var(--var-fontSizes-x36)',
        x48: 'var(--var-fontSizes-x48)',
        x60: 'var(--var-fontSizes-x60)',
        x72: 'var(--var-fontSizes-x72)'
    },
    lineHeight: {
        auto: 'var(--var-lineHeight-auto)',
        x16: 'var(--var-lineHeight-x16)',
        x18: 'var(--var-lineHeight-x18)',
        x19: 'var(--var-lineHeight-x19)',
        x20: 'var(--var-lineHeight-x20)',
        x21: 'var(--var-lineHeight-x21)',
        x22: 'var(--var-lineHeight-x22)',
        x24: 'var(--var-lineHeight-x24)',
        x28: 'var(--var-lineHeight-x28)',
        x30: 'var(--var-lineHeight-x30)',
        x32: 'var(--var-lineHeight-x32)',
        x38: 'var(--var-lineHeight-x38)',
        x44: 'var(--var-lineHeight-x44)',
        x60: 'var(--var-lineHeight-x60)',
        x72: 'var(--var-lineHeight-x72)',
        x90: 'var(--var-lineHeight-x90)'
    },
    sizes: {
        x4: 'var(--var-sizes-x4)',
        x6: 'var(--var-sizes-x6)',
        x8: 'var(--var-sizes-x8)',
        x10: 'var(--var-sizes-x10)',
        x12: 'var(--var-sizes-x12)',
        x14: 'var(--var-sizes-x14)',
        x16: 'var(--var-sizes-x16)',
        x18: 'var(--var-sizes-x18)',
        x20: 'var(--var-sizes-x20)',
        x22: 'var(--var-sizes-x22)',
        x24: 'var(--var-sizes-x24)',
        x28: 'var(--var-sizes-x28)',
        x30: 'var(--var-sizes-x30)',
        x32: 'var(--var-sizes-x32)',
        x36: 'var(--var-sizes-x36)',
        x40: 'var(--var-sizes-x40)',
        x44: 'var(--var-sizes-x44)',
        x45: 'var(--var-sizes-x45)',
        x48: 'var(--var-sizes-x48)',
        x56: 'var(--var-sizes-x56)',
        x64: 'var(--var-sizes-x64)'
    },
    radius: {
        none: 'var(--var-radius-none)',
        x2: 'var(--var-radius-x2)',
        x4: 'var(--var-radius-x4)',
        x6: 'var(--var-radius-x6)',
        x8: 'var(--var-radius-x8)',
        x10: 'var(--var-radius-x10)',
        x12: 'var(--var-radius-x12)',
        x16: 'var(--var-radius-x16)',
        x20: 'var(--var-radius-x20)',
        x24: 'var(--var-radius-x24)',
        x32: 'var(--var-radius-x32)',
        full: 'var(--var-radius-full)'
    },
    width: {
        x320: 'var(--var-width-x320)',
        x384: 'var(--var-width-x384)',
        x480: 'var(--var-width-x480)',
        x560: 'var(--var-width-x560)',
        x640: 'var(--var-width-x640)',
        x768: 'var(--var-width-x768)',
        x1024: 'var(--var-width-x1024)',
        x1280: 'var(--var-width-x1280)',
        x1440: 'var(--var-width-x1440)',
        x1600: 'var(--var-width-x1600)',
        x1920: 'var(--var-width-x1920)'
    },
    paragraphMaxWidth: 'var(--var-paragraphMaxWidth)',
    grid: {
        desktop: {
            containerWidth: 'var(--var-grid-desktop-containerWidth)',
            contentWidth: 'var(--var-grid-desktop-contentWidth)',
            gutter: 'var(--var-grid-desktop-gutter)'
        },
        tablet: {
            containerWidth: 'var(--var-grid-tablet-containerWidth)',
            contentWidth: 'var(--var-grid-tablet-contentWidth)',
            gutter: 'var(--var-grid-tablet-gutter)'
        },
        mobile: {
            containerWidth: 'var(--var-grid-mobile-containerWidth)',
            contentWidth: 'var(--var-grid-mobile-contentWidth)',
            gutter: 'var(--var-grid-mobile-gutter)'
        }
    },
    colors: {
        white: 'var(--var-colors-white)',
        black: 'var(--var-colors-black)',
        transparent: 'var(--var-colors-transparent)',
        gray25: 'var(--var-colors-gray25)',
        gray50: 'var(--var-colors-gray50)',
        gray100: 'var(--var-colors-gray100)',
        gray200: 'var(--var-colors-gray200)',
        gray300: 'var(--var-colors-gray300)',
        gray400: 'var(--var-colors-gray400)',
        gray500: 'var(--var-colors-gray500)',
        gray600: 'var(--var-colors-gray600)',
        gray700: 'var(--var-colors-gray700)',
        gray800: 'var(--var-colors-gray800)',
        gray900: 'var(--var-colors-gray900)',
        gray950: 'var(--var-colors-gray950)',
        brand25: 'var(--var-colors-brand25)',
        brand50: 'var(--var-colors-brand50)',
        brand100: 'var(--var-colors-brand100)',
        brand200: 'var(--var-colors-brand200)',
        brand300: 'var(--var-colors-brand300)',
        brand400: 'var(--var-colors-brand400)',
        brand500: 'var(--var-colors-brand500)',
        brand600: 'var(--var-colors-brand600)',
        brand700: 'var(--var-colors-brand700)',
        brand800: 'var(--var-colors-brand800)',
        brand900: 'var(--var-colors-brand900)',
        brand950: 'var(--var-colors-brand950)',
        error25: 'var(--var-colors-error25)',
        error50: 'var(--var-colors-error50)',
        error100: 'var(--var-colors-error100)',
        error200: 'var(--var-colors-error200)',
        error300: 'var(--var-colors-error300)',
        error400: 'var(--var-colors-error400)',
        error500: 'var(--var-colors-error500)',
        error600: 'var(--var-colors-error600)',
        error700: 'var(--var-colors-error700)',
        error800: 'var(--var-colors-error800)',
        error900: 'var(--var-colors-error900)',
        error950: 'var(--var-colors-error950)',
        warning25: 'var(--var-colors-warning25)',
        warning50: 'var(--var-colors-warning50)',
        warning100: 'var(--var-colors-warning100)',
        warning200: 'var(--var-colors-warning200)',
        warning300: 'var(--var-colors-warning300)',
        warning400: 'var(--var-colors-warning400)',
        warning500: 'var(--var-colors-warning500)',
        warning600: 'var(--var-colors-warning600)',
        warning700: 'var(--var-colors-warning700)',
        warning800: 'var(--var-colors-warning800)',
        warning900: 'var(--var-colors-warning900)',
        warning950: 'var(--var-colors-warning950)',
        success25: 'var(--var-colors-success25)',
        success50: 'var(--var-colors-success50)',
        success100: 'var(--var-colors-success100)',
        success200: 'var(--var-colors-success200)',
        success300: 'var(--var-colors-success300)',
        success400: 'var(--var-colors-success400)',
        success500: 'var(--var-colors-success500)',
        success600: 'var(--var-colors-success600)',
        success700: 'var(--var-colors-success700)',
        success800: 'var(--var-colors-success800)',
        success900: 'var(--var-colors-success900)',
        success950: 'var(--var-colors-success950)',
        grayBlue25: 'var(--var-colors-grayBlue25)',
        grayBlue50: 'var(--var-colors-grayBlue50)',
        grayBlue100: 'var(--var-colors-grayBlue100)',
        grayBlue200: 'var(--var-colors-grayBlue200)',
        grayBlue300: 'var(--var-colors-grayBlue300)',
        grayBlue400: 'var(--var-colors-grayBlue400)',
        grayBlue500: 'var(--var-colors-grayBlue500)',
        grayBlue600: 'var(--var-colors-grayBlue600)',
        grayBlue700: 'var(--var-colors-grayBlue700)',
        grayBlue800: 'var(--var-colors-grayBlue800)',
        grayBlue900: 'var(--var-colors-grayBlue900)',
        grayBlue950: 'var(--var-colors-grayBlue950)',
        moss25: 'var(--var-colors-moss25)',
        moss50: 'var(--var-colors-moss50)',
        moss100: 'var(--var-colors-moss100)',
        moss200: 'var(--var-colors-moss200)',
        moss300: 'var(--var-colors-moss300)',
        moss400: 'var(--var-colors-moss400)',
        moss500: 'var(--var-colors-moss500)',
        moss600: 'var(--var-colors-moss600)',
        moss700: 'var(--var-colors-moss700)',
        moss800: 'var(--var-colors-moss800)',
        moss900: 'var(--var-colors-moss900)',
        moss950: 'var(--var-colors-moss950)',
        greenLight25: 'var(--var-colors-greenLight25)',
        greenLight50: 'var(--var-colors-greenLight50)',
        greenLight100: 'var(--var-colors-greenLight100)',
        greenLight200: 'var(--var-colors-greenLight200)',
        greenLight300: 'var(--var-colors-greenLight300)',
        greenLight400: 'var(--var-colors-greenLight400)',
        greenLight500: 'var(--var-colors-greenLight500)',
        greenLight600: 'var(--var-colors-greenLight600)',
        greenLight700: 'var(--var-colors-greenLight700)',
        greenLight800: 'var(--var-colors-greenLight800)',
        greenLight900: 'var(--var-colors-greenLight900)',
        greenLight950: 'var(--var-colors-greenLight950)',
        green25: 'var(--var-colors-green25)',
        green50: 'var(--var-colors-green50)',
        green100: 'var(--var-colors-green100)',
        green200: 'var(--var-colors-green200)',
        green300: 'var(--var-colors-green300)',
        green400: 'var(--var-colors-green400)',
        green500: 'var(--var-colors-green500)',
        green600: 'var(--var-colors-green600)',
        green700: 'var(--var-colors-green700)',
        green800: 'var(--var-colors-green800)',
        green900: 'var(--var-colors-green900)',
        green950: 'var(--var-colors-green950)',
        teal25: 'var(--var-colors-teal25)',
        teal50: 'var(--var-colors-teal50)',
        teal100: 'var(--var-colors-teal100)',
        teal200: 'var(--var-colors-teal200)',
        teal300: 'var(--var-colors-teal300)',
        teal400: 'var(--var-colors-teal400)',
        teal500: 'var(--var-colors-teal500)',
        teal600: 'var(--var-colors-teal600)',
        teal700: 'var(--var-colors-teal700)',
        teal800: 'var(--var-colors-teal800)',
        teal900: 'var(--var-colors-teal900)',
        teal950: 'var(--var-colors-teal950)',
        cyan25: 'var(--var-colors-cyan25)',
        cyan50: 'var(--var-colors-cyan50)',
        cyan100: 'var(--var-colors-cyan100)',
        cyan200: 'var(--var-colors-cyan200)',
        cyan300: 'var(--var-colors-cyan300)',
        cyan400: 'var(--var-colors-cyan400)',
        cyan500: 'var(--var-colors-cyan500)',
        cyan600: 'var(--var-colors-cyan600)',
        cyan700: 'var(--var-colors-cyan700)',
        cyan800: 'var(--var-colors-cyan800)',
        cyan900: 'var(--var-colors-cyan900)',
        cyan950: 'var(--var-colors-cyan950)',
        blueLight25: 'var(--var-colors-blueLight25)',
        blueLight50: 'var(--var-colors-blueLight50)',
        blueLight100: 'var(--var-colors-blueLight100)',
        blueLight200: 'var(--var-colors-blueLight200)',
        blueLight300: 'var(--var-colors-blueLight300)',
        blueLight400: 'var(--var-colors-blueLight400)',
        blueLight500: 'var(--var-colors-blueLight500)',
        blueLight600: 'var(--var-colors-blueLight600)',
        blueLight700: 'var(--var-colors-blueLight700)',
        blueLight800: 'var(--var-colors-blueLight800)',
        blueLight900: 'var(--var-colors-blueLight900)',
        blueLight950: 'var(--var-colors-blueLight950)',
        blue25: 'var(--var-colors-blue25)',
        blue50: 'var(--var-colors-blue50)',
        blue100: 'var(--var-colors-blue100)',
        blue200: 'var(--var-colors-blue200)',
        blue300: 'var(--var-colors-blue300)',
        blue400: 'var(--var-colors-blue400)',
        blue500: 'var(--var-colors-blue500)',
        blue600: 'var(--var-colors-blue600)',
        blue700: 'var(--var-colors-blue700)',
        blue800: 'var(--var-colors-blue800)',
        blue900: 'var(--var-colors-blue900)',
        blue950: 'var(--var-colors-blue950)',
        blueDark25: 'var(--var-colors-blueDark25)',
        blueDark50: 'var(--var-colors-blueDark50)',
        blueDark100: 'var(--var-colors-blueDark100)',
        blueDark200: 'var(--var-colors-blueDark200)',
        blueDark300: 'var(--var-colors-blueDark300)',
        blueDark400: 'var(--var-colors-blueDark400)',
        blueDark500: 'var(--var-colors-blueDark500)',
        blueDark600: 'var(--var-colors-blueDark600)',
        blueDark700: 'var(--var-colors-blueDark700)',
        blueDark800: 'var(--var-colors-blueDark800)',
        blueDark900: 'var(--var-colors-blueDark900)',
        blueDark950: 'var(--var-colors-blueDark950)',
        indigo25: 'var(--var-colors-indigo25)',
        indigo50: 'var(--var-colors-indigo50)',
        indigo100: 'var(--var-colors-indigo100)',
        indigo200: 'var(--var-colors-indigo200)',
        indigo300: 'var(--var-colors-indigo300)',
        indigo400: 'var(--var-colors-indigo400)',
        indigo500: 'var(--var-colors-indigo500)',
        indigo600: 'var(--var-colors-indigo600)',
        indigo700: 'var(--var-colors-indigo700)',
        indigo800: 'var(--var-colors-indigo800)',
        indigo900: 'var(--var-colors-indigo900)',
        indigo950: 'var(--var-colors-indigo950)',
        violet25: 'var(--var-colors-violet25)',
        violet50: 'var(--var-colors-violet50)',
        violet100: 'var(--var-colors-violet100)',
        violet200: 'var(--var-colors-violet200)',
        violet300: 'var(--var-colors-violet300)',
        violet400: 'var(--var-colors-violet400)',
        violet500: 'var(--var-colors-violet500)',
        violet600: 'var(--var-colors-violet600)',
        violet700: 'var(--var-colors-violet700)',
        violet800: 'var(--var-colors-violet800)',
        violet900: 'var(--var-colors-violet900)',
        violet950: 'var(--var-colors-violet950)',
        purple25: 'var(--var-colors-purple25)',
        purple50: 'var(--var-colors-purple50)',
        purple100: 'var(--var-colors-purple100)',
        purple200: 'var(--var-colors-purple200)',
        purple300: 'var(--var-colors-purple300)',
        purple400: 'var(--var-colors-purple400)',
        purple500: 'var(--var-colors-purple500)',
        purple600: 'var(--var-colors-purple600)',
        purple700: 'var(--var-colors-purple700)',
        purple800: 'var(--var-colors-purple800)',
        purple900: 'var(--var-colors-purple900)',
        purple950: 'var(--var-colors-purple950)',
        fuchsia25: 'var(--var-colors-fuchsia25)',
        fuchsia50: 'var(--var-colors-fuchsia50)',
        fuchsia100: 'var(--var-colors-fuchsia100)',
        fuchsia200: 'var(--var-colors-fuchsia200)',
        fuchsia300: 'var(--var-colors-fuchsia300)',
        fuchsia400: 'var(--var-colors-fuchsia400)',
        fuchsia500: 'var(--var-colors-fuchsia500)',
        fuchsia600: 'var(--var-colors-fuchsia600)',
        fuchsia700: 'var(--var-colors-fuchsia700)',
        fuchsia800: 'var(--var-colors-fuchsia800)',
        fuchsia900: 'var(--var-colors-fuchsia900)',
        fuchsia950: 'var(--var-colors-fuchsia950)',
        pink25: 'var(--var-colors-pink25)',
        pink50: 'var(--var-colors-pink50)',
        pink100: 'var(--var-colors-pink100)',
        pink200: 'var(--var-colors-pink200)',
        pink300: 'var(--var-colors-pink300)',
        pink400: 'var(--var-colors-pink400)',
        pink500: 'var(--var-colors-pink500)',
        pink600: 'var(--var-colors-pink600)',
        pink700: 'var(--var-colors-pink700)',
        pink800: 'var(--var-colors-pink800)',
        pink900: 'var(--var-colors-pink900)',
        pink950: 'var(--var-colors-pink950)',
        rose25: 'var(--var-colors-rose25)',
        rose50: 'var(--var-colors-rose50)',
        rose100: 'var(--var-colors-rose100)',
        rose200: 'var(--var-colors-rose200)',
        rose300: 'var(--var-colors-rose300)',
        rose400: 'var(--var-colors-rose400)',
        rose500: 'var(--var-colors-rose500)',
        rose600: 'var(--var-colors-rose600)',
        rose700: 'var(--var-colors-rose700)',
        rose800: 'var(--var-colors-rose800)',
        rose900: 'var(--var-colors-rose900)',
        rose950: 'var(--var-colors-rose950)',
        orangeDark25: 'var(--var-colors-orangeDark25)',
        orangeDark50: 'var(--var-colors-orangeDark50)',
        orangeDark100: 'var(--var-colors-orangeDark100)',
        orangeDark200: 'var(--var-colors-orangeDark200)',
        orangeDark300: 'var(--var-colors-orangeDark300)',
        orangeDark400: 'var(--var-colors-orangeDark400)',
        orangeDark500: 'var(--var-colors-orangeDark500)',
        orangeDark600: 'var(--var-colors-orangeDark600)',
        orangeDark700: 'var(--var-colors-orangeDark700)',
        orangeDark800: 'var(--var-colors-orangeDark800)',
        orangeDark900: 'var(--var-colors-orangeDark900)',
        orangeDark950: 'var(--var-colors-orangeDark950)',
        orange25: 'var(--var-colors-orange25)',
        orange50: 'var(--var-colors-orange50)',
        orange100: 'var(--var-colors-orange100)',
        orange200: 'var(--var-colors-orange200)',
        orange300: 'var(--var-colors-orange300)',
        orange400: 'var(--var-colors-orange400)',
        orange500: 'var(--var-colors-orange500)',
        orange600: 'var(--var-colors-orange600)',
        orange700: 'var(--var-colors-orange700)',
        orange800: 'var(--var-colors-orange800)',
        orange900: 'var(--var-colors-orange900)',
        orange950: 'var(--var-colors-orange950)',
        yellow25: 'var(--var-colors-yellow25)',
        yellow50: 'var(--var-colors-yellow50)',
        yellow100: 'var(--var-colors-yellow100)',
        yellow200: 'var(--var-colors-yellow200)',
        yellow300: 'var(--var-colors-yellow300)',
        yellow400: 'var(--var-colors-yellow400)',
        yellow500: 'var(--var-colors-yellow500)',
        yellow600: 'var(--var-colors-yellow600)',
        yellow700: 'var(--var-colors-yellow700)',
        yellow800: 'var(--var-colors-yellow800)',
        yellow900: 'var(--var-colors-yellow900)',
        yellow950: 'var(--var-colors-yellow950)'
    },
    alphaColor: {
        whiteAlpha10: 'var(--var-alphaColor-whiteAlpha10)',
        whiteAlpha20: 'var(--var-alphaColor-whiteAlpha20)',
        whiteAlpha30: 'var(--var-alphaColor-whiteAlpha30)',
        whiteAlpha40: 'var(--var-alphaColor-whiteAlpha40)',
        whiteAlpha50: 'var(--var-alphaColor-whiteAlpha50)',
        whiteAlpha60: 'var(--var-alphaColor-whiteAlpha60)',
        whiteAlpha70: 'var(--var-alphaColor-whiteAlpha70)',
        whiteAlpha80: 'var(--var-alphaColor-whiteAlpha80)',
        whiteAlpha90: 'var(--var-alphaColor-whiteAlpha90)',
        whiteAlpha100: 'var(--var-alphaColor-whiteAlpha100)',
        blackAlpha10: 'var(--var-alphaColor-blackAlpha10)',
        blackAlpha20: 'var(--var-alphaColor-blackAlpha20)',
        blackAlpha30: 'var(--var-alphaColor-blackAlpha30)',
        blackAlpha40: 'var(--var-alphaColor-blackAlpha40)',
        blackAlpha50: 'var(--var-alphaColor-blackAlpha50)',
        blackAlpha60: 'var(--var-alphaColor-blackAlpha60)',
        blackAlpha70: 'var(--var-alphaColor-blackAlpha70)',
        blackAlpha80: 'var(--var-alphaColor-blackAlpha80)',
        blackAlpha90: 'var(--var-alphaColor-blackAlpha90)',
        blackAlpha100: 'var(--var-alphaColor-blackAlpha100)'
    },
    gradient: {
        gray600To500: 'var(--var-gradient-gray600To500)',
        gray700To600: 'var(--var-gradient-gray700To600)',
        gray800To600: 'var(--var-gradient-gray800To600)',
        gray800To700: 'var(--var-gradient-gray800To700)',
        gray900To600: 'var(--var-gradient-gray900To600)',
        gray900To700: 'var(--var-gradient-gray900To700)',
        brand600To500: 'var(--var-gradient-brand600To500)',
        brand700To600: 'var(--var-gradient-brand700To600)',
        brand800To600: 'var(--var-gradient-brand800To600)',
        brand800To700: 'var(--var-gradient-brand800To700)',
        brand900To600: 'var(--var-gradient-brand900To600)',
        brand900To700: 'var(--var-gradient-brand900To700)',
        neutral01: 'var(--var-gradient-neutral01)',
        neutral02: 'var(--var-gradient-neutral02)',
        neutral03: 'var(--var-gradient-neutral03)',
        neutral04: 'var(--var-gradient-neutral04)',
        neutral05: 'var(--var-gradient-neutral05)',
        neutral06: 'var(--var-gradient-neutral06)',
        neutral07: 'var(--var-gradient-neutral07)',
        gradient01: 'var(--var-gradient-gradient01)',
        gradient02: 'var(--var-gradient-gradient02)',
        gradient03: 'var(--var-gradient-gradient03)',
        gradient04: 'var(--var-gradient-gradient04)',
        gradient05: 'var(--var-gradient-gradient05)',
        gradient06: 'var(--var-gradient-gradient06)',
        gradient07: 'var(--var-gradient-gradient07)',
        gradient08: 'var(--var-gradient-gradient08)',
        gradient09: 'var(--var-gradient-gradient09)',
        gradient10: 'var(--var-gradient-gradient10)',
        gradient11: 'var(--var-gradient-gradient11)',
        gradient12: 'var(--var-gradient-gradient12)',
        gradient13: 'var(--var-gradient-gradient13)',
        gradient14: 'var(--var-gradient-gradient14)',
        gradient15: 'var(--var-gradient-gradient15)',
        gradient16: 'var(--var-gradient-gradient16)',
        gradient17: 'var(--var-gradient-gradient17)',
        gradient18: 'var(--var-gradient-gradient18)',
        gradient19: 'var(--var-gradient-gradient19)',
        gradient20: 'var(--var-gradient-gradient20)',
        gradient21: 'var(--var-gradient-gradient21)',
        gradient22: 'var(--var-gradient-gradient22)',
        gradient23: 'var(--var-gradient-gradient23)',
        gradient24: 'var(--var-gradient-gradient24)',
        gradient25: 'var(--var-gradient-gradient25)',
        gradient26: 'var(--var-gradient-gradient26)',
        gradient27: 'var(--var-gradient-gradient27)',
        gradient28: 'var(--var-gradient-gradient28)',
        gradient29: 'var(--var-gradient-gradient29)',
        gradient30: 'var(--var-gradient-gradient30)',
        gradient31: 'var(--var-gradient-gradient31)',
        gradient32: 'var(--var-gradient-gradient32)',
        gradient33: 'var(--var-gradient-gradient33)',
        gradient34: 'var(--var-gradient-gradient34)',
        gradient35: 'var(--var-gradient-gradient35)',
        gradient36: 'var(--var-gradient-gradient36)',
        gradient37: 'var(--var-gradient-gradient37)',
        gradient38: 'var(--var-gradient-gradient38)',
        gradient39: 'var(--var-gradient-gradient39)',
        gradient40: 'var(--var-gradient-gradient40)',
        gradient41: 'var(--var-gradient-gradient41)',
        gradient42: 'var(--var-gradient-gradient42)',
        gradient43: 'var(--var-gradient-gradient43)',
        gradient44: 'var(--var-gradient-gradient44)',
        gradient45: 'var(--var-gradient-gradient45)',
        gradient46: 'var(--var-gradient-gradient46)',
        gradient47: 'var(--var-gradient-gradient47)',
        gradient48: 'var(--var-gradient-gradient48)',
        gradient49: 'var(--var-gradient-gradient49)',
        gradient50: 'var(--var-gradient-gradient50)',
        gradient51: 'var(--var-gradient-gradient51)',
        gradient52: 'var(--var-gradient-gradient52)',
        gradient53: 'var(--var-gradient-gradient53)',
        gradient54: 'var(--var-gradient-gradient54)',
        gradient55: 'var(--var-gradient-gradient55)',
        gradient56: 'var(--var-gradient-gradient56)',
        gradient57: 'var(--var-gradient-gradient57)',
        gradient58: 'var(--var-gradient-gradient58)',
        gradient59: 'var(--var-gradient-gradient59)',
        gradient60: 'var(--var-gradient-gradient60)',
        gradient61: 'var(--var-gradient-gradient61)',
        gradient62: 'var(--var-gradient-gradient62)',
        gradient63: 'var(--var-gradient-gradient63)',
        gradient64: 'var(--var-gradient-gradient64)',
        gradient65: 'var(--var-gradient-gradient65)',
        gradient66: 'var(--var-gradient-gradient66)',
        gradient67: 'var(--var-gradient-gradient67)',
        gradient68: 'var(--var-gradient-gradient68)',
        gradient69: 'var(--var-gradient-gradient69)',
        gradient70: 'var(--var-gradient-gradient70)',
        gradient71: 'var(--var-gradient-gradient71)',
        gradient72: 'var(--var-gradient-gradient72)',
        gradient73: 'var(--var-gradient-gradient73)',
        gradient74: 'var(--var-gradient-gradient74)',
        gradient75: 'var(--var-gradient-gradient75)',
        gradient76: 'var(--var-gradient-gradient76)',
        gradient77: 'var(--var-gradient-gradient77)',
        gradient78: 'var(--var-gradient-gradient78)',
        gradient79: 'var(--var-gradient-gradient79)',
        gradient80: 'var(--var-gradient-gradient80)',
        gradient81: 'var(--var-gradient-gradient81)',
        gradient82: 'var(--var-gradient-gradient82)',
        gradient83: 'var(--var-gradient-gradient83)',
        gradient84: 'var(--var-gradient-gradient84)',
        gradient85: 'var(--var-gradient-gradient85)',
        gradient86: 'var(--var-gradient-gradient86)',
        gradient87: 'var(--var-gradient-gradient87)',
        gradient88: 'var(--var-gradient-gradient88)',
        gradient89: 'var(--var-gradient-gradient89)',
        gradient90: 'var(--var-gradient-gradient90)',
        gradient91: 'var(--var-gradient-gradient91)',
        bgMenu: 'var(--var-gradient-bgMenu)',
        listItemHover: 'var(--var-gradient-listItemHover)'
    },
    textColor: {
        primary: 'var(--var-textColor-primary)',
        primaryOnBrand: 'var(--var-textColor-primaryOnBrand)',
        secondary: 'var(--var-textColor-secondary)',
        secondaryHover: 'var(--var-textColor-secondaryHover)',
        secondaryOnBrand: 'var(--var-textColor-secondaryOnBrand)',
        tertiary: 'var(--var-textColor-tertiary)',
        tertiaryHover: 'var(--var-textColor-tertiaryHover)',
        tertiaryOnBrand: 'var(--var-textColor-tertiaryOnBrand)',
        quaternary: 'var(--var-textColor-quaternary)',
        quaternaryOnBrand: 'var(--var-textColor-quaternaryOnBrand)',
        white: 'var(--var-textColor-white)',
        disabled: 'var(--var-textColor-disabled)',
        placeholder: 'var(--var-textColor-placeholder)',
        placeholderSubtle: 'var(--var-textColor-placeholderSubtle)',
        brandPrimary: 'var(--var-textColor-brandPrimary)',
        brandSecondary: 'var(--var-textColor-brandSecondary)',
        brandTertiary: 'var(--var-textColor-brandTertiary)',
        errorPrimary: 'var(--var-textColor-errorPrimary)',
        warningPrimary: 'var(--var-textColor-warningPrimary)',
        successPrimary: 'var(--var-textColor-successPrimary)'
    },
    borderColor: {
        primary: 'var(--var-borderColor-primary)',
        secondary: 'var(--var-borderColor-secondary)',
        tertiary: 'var(--var-borderColor-tertiary)',
        disabled: 'var(--var-borderColor-disabled)',
        disabledSubtle: 'var(--var-borderColor-disabledSubtle)',
        brand: 'var(--var-borderColor-brand)',
        brandAlt: 'var(--var-borderColor-brandAlt)',
        error: 'var(--var-borderColor-error)',
        errorSubtle: 'var(--var-borderColor-errorSubtle)'
    },
    foregroundColor: {
        primary: 'var(--var-foregroundColor-primary)',
        secondary: 'var(--var-foregroundColor-secondary)',
        secondaryHover: 'var(--var-foregroundColor-secondaryHover)',
        tertiary: 'var(--var-foregroundColor-tertiary)',
        tertiaryHover: 'var(--var-foregroundColor-tertiaryHover)',
        quaternary: 'var(--var-foregroundColor-quaternary)',
        quaternaryHover: 'var(--var-foregroundColor-quaternaryHover)',
        quinary: 'var(--var-foregroundColor-quinary)',
        quinaryHover: 'var(--var-foregroundColor-quinaryHover)',
        senary: 'var(--var-foregroundColor-senary)',
        white: 'var(--var-foregroundColor-white)',
        disabled: 'var(--var-foregroundColor-disabled)',
        disabledSubtle: 'var(--var-foregroundColor-disabledSubtle)',
        brandPrimary: 'var(--var-foregroundColor-brandPrimary)',
        brandSecondary: 'var(--var-foregroundColor-brandSecondary)',
        errorPrimary: 'var(--var-foregroundColor-errorPrimary)',
        errorSecondary: 'var(--var-foregroundColor-errorSecondary)',
        warningPrimary: 'var(--var-foregroundColor-warningPrimary)',
        warningSecondary: 'var(--var-foregroundColor-warningSecondary)',
        successPrimary: 'var(--var-foregroundColor-successPrimary)',
        successSecondary: 'var(--var-foregroundColor-successSecondary)'
    },
    backgroundColor: {
        primary: 'var(--var-backgroundColor-primary)',
        primaryAlt: 'var(--var-backgroundColor-primaryAlt)',
        primaryHover: 'var(--var-backgroundColor-primaryHover)',
        primarySolid: 'var(--var-backgroundColor-primarySolid)',
        secondary: 'var(--var-backgroundColor-secondary)',
        secondaryAlt: 'var(--var-backgroundColor-secondaryAlt)',
        secondaryHover: 'var(--var-backgroundColor-secondaryHover)',
        secondarySubtle: 'var(--var-backgroundColor-secondarySubtle)',
        secondarySolid: 'var(--var-backgroundColor-secondarySolid)',
        tertiary: 'var(--var-backgroundColor-tertiary)',
        quaternary: 'var(--var-backgroundColor-quaternary)',
        active: 'var(--var-backgroundColor-active)',
        disabled: 'var(--var-backgroundColor-disabled)',
        disabledSubtle: 'var(--var-backgroundColor-disabledSubtle)',
        overlay: 'var(--var-backgroundColor-overlay)',
        brandPrimary: 'var(--var-backgroundColor-brandPrimary)',
        brandSecondary: 'var(--var-backgroundColor-brandSecondary)',
        brandSolid: 'var(--var-backgroundColor-brandSolid)',
        brandSolidHover: 'var(--var-backgroundColor-brandSolidHover)',
        brandSection: 'var(--var-backgroundColor-brandSection)',
        brandSectionSubtle: 'var(--var-backgroundColor-brandSectionSubtle)',
        errorPrimary: 'var(--var-backgroundColor-errorPrimary)',
        errorSecondary: 'var(--var-backgroundColor-errorSecondary)',
        errorSolid: 'var(--var-backgroundColor-errorSolid)',
        warningPrimary: 'var(--var-backgroundColor-warningPrimary)',
        warningSecondary: 'var(--var-backgroundColor-warningSecondary)',
        warningSolid: 'var(--var-backgroundColor-warningSolid)',
        successPrimary: 'var(--var-backgroundColor-successPrimary)',
        successSecondary: 'var(--var-backgroundColor-successSecondary)',
        successSolid: 'var(--var-backgroundColor-successSolid)'
    },
    zLayer: {
        xs: 'var(--var-zLayer-xs)',
        s: 'var(--var-zLayer-s)',
        m: 'var(--var-zLayer-m)',
        l: 'var(--var-zLayer-l)',
        xl: 'var(--var-zLayer-xl)',
        xxl: 'var(--var-zLayer-xxl)',
        xxxl: 'var(--var-zLayer-xxxl)'
    },
    transition: {
        s: 'var(--var-transition-s)',
        m: 'var(--var-transition-m)',
        l: 'var(--var-transition-l)'
    }
};
export var BaseColor;
(function (BaseColor) {
    BaseColor["primary"] = "brand";
    BaseColor["secondary"] = "cyan";
    BaseColor["gray"] = "gray";
    BaseColor["error"] = "error";
    BaseColor["warning"] = "warning";
    BaseColor["success"] = "success";
    BaseColor["blue"] = "blue";
    BaseColor["blueLight"] = "blueLight";
    BaseColor["blueDark"] = "blueDark";
    BaseColor["grayBlue"] = "grayBlue";
    BaseColor["pink"] = "pink";
    BaseColor["rose"] = "rose";
    BaseColor["orange"] = "orange";
    BaseColor["orangeDark"] = "orangeDark";
    BaseColor["moss"] = "moss";
    BaseColor["green"] = "green";
    BaseColor["greenLight"] = "greenLight";
    BaseColor["teal"] = "teal";
    BaseColor["cyan"] = "cyan";
    BaseColor["indigo"] = "indigo";
    BaseColor["violet"] = "violet";
    BaseColor["purple"] = "purple";
    BaseColor["fuchsia"] = "fuchsia";
    BaseColor["yellow"] = "yellow";
    BaseColor["transparent"] = "transparent";
})(BaseColor || (BaseColor = {}));
