import styled from 'styled-components';
import { Button } from '../Button';
import { ChevronDownIcon, ChevronRightIcon, EditBIcon } from '../Icons';
import { ICON_CLASS } from '../Icons/tokens';
import { InlineInput } from '../Input';
import { Stack } from '../Layout';
import { getInterFontWeightCSS, theme } from '../theme';
import { tokens } from './tokens';
import { Variant } from './types';
export const StyledBreadcrumbContainer = styled(Stack) `
  font-family: ${tokens.Crumb.fontFamily};
  ${getInterFontWeightCSS(tokens.Crumb.fontWeight)};
  color: ${tokens.Crumb.color};
  font-size: ${tokens.ArrowIcon.fontSize};
  min-width: 0;
  width: 100%;
`;
export const StyledChevronRightIcon = styled(ChevronRightIcon) `
  color: ${tokens.ArrowIcon.color};
  font-size: ${tokens.ArrowIcon.fontSize};
  margin: 0 ${tokens.ArrowIcon.margin};
  width: auto;
  flex-shrink: 0;
`;
export const StyledChevronDownIcon = styled(ChevronDownIcon) `
  color: ${tokens.ArrowIcon.color};
  font-size: ${tokens.ArrowIcon.fontSize};
  margin: 0 ${tokens.ArrowIcon.margin};
  width: auto;
  flex-shrink: 0;
`;
export const StyledEditIcon = styled(EditBIcon) `
  cursor: pointer;
  width: 0;
  opacity: 0;
  transition: ${theme.transition.s};
  fill: ${({ $variant }) => tokens.EditIcon.color[$variant === Variant.brand ? 'brand' : 'gray'] ?? tokens.Crumb.color};
`;
export const StyledLabel = styled.label `
  max-width: 180px;
  font-size: ${tokens.label.fontSize};
  cursor: pointer;
`;
export const StyledInput = styled(InlineInput) `
  max-width: 200px;
  border-radius: ${tokens.Input.radius};
  display: unset;
  > div {
    border: none;
    &&&,
    &&&:hover,
    &&&:focus-within {
      background: ${tokens.Input.background};
    }
  }
  .${ICON_CLASS} {
    width: ${tokens.Input.iconSize};
    height: ${tokens.Input.iconSize};
  }
`;
export const StyledButton = styled(Button) `
  background: ${({ $variant }) => tokens[$variant].background ?? tokens.Crumb.background};
  color: ${tokens.Crumb.color};
  display: flex;
  gap: ${tokens.Crumb.gap};
  align-items: center;
  cursor: pointer;
  pointer-events: all;
  border-radius: ${({ $variant }) => tokens[$variant].borderRadius ?? tokens.Input.radius};
  .${ICON_CLASS} {
    color: inherit;
    font-size: inherit;
  }

  &:hover:not(:focus),
  &&:focus-visible {
    ${StyledEditIcon} {
      opacity: 1;
      margin-left: ${tokens.Crumb.gap};
      width: ${tokens.EditIcon.width};
    }
  }

  &,
  &:hover,
  &&:focus,
  &&:focus-visible {
    box-shadow: none;
    border: none;
  }

  &&:focus-visible {
    box-shadow: ${tokens.Crumb.focused.boxShadow};
  }

  &:hover {
    &:not([disabled]) {
      background: ${({ $variant }) => tokens[$variant].active.background};
      color: ${({ $variant }) => tokens[$variant].active.color};
    }
    .${ICON_CLASS} {
      visibility: visible;
    }
  }

  &:focus,
  &[data-active='true'] {
    &:not([disabled]) {
      background: ${({ $variant, active }) => active ? (tokens[$variant].active.background ?? tokens.Crumb.background) : tokens[$variant].background};
      color: ${({ $variant, active }) => active ? (tokens[$variant].active.color ?? tokens.Crumb.active.color) : tokens.Crumb.hover.color};
    }
    .${ICON_CLASS} {
      visibility: visible;
    }
  }

  ${({ active, $variant }) => active &&
    `
    ${StyledLabel} {
        cursor: default;
    }
    cursor: default;
    background: ${tokens[$variant].active.background ?? tokens.Crumb.background};
    color: ${tokens[$variant].active.color ?? tokens.Crumb.active.color};
  `}
`;
export const DropdownOverlayStyle = {
    borderRadius: `${theme.radius.x6}`
};
export const StyledCrumbWrapper = styled(Stack).attrs({ alignY: 'center', direction: 'horizontal' }) `
  flex-shrink: 0;
  &[data-state='open'] {
    button {
      box-shadow: ${tokens.Crumb.focused.boxShadow};
    }
  }
`;
