export const FROZEN_EMPTY_ARRAY = Object.freeze([]) as unknown as unknown[];
export const FROZEN_EMPTY_OBJECT = Object.freeze({}) as unknown;
export const SIDEBAR_ALLOWED_OVERLAY_CLS = `sidebar-allowed-overlay`;
export const ABOVE_ALL_CLS = `above-all`;
export const FS_MASK_CLS = 'fs-mask';
export const EXECUTION_TOAST_ATTR = {
  name: 'data-component-name',
  value: 'job_status_toast'
};
export const DIALOG_OVERLAY_COMPONENT_NAME_ATTR = {
  name: 'data-component-name',
  value: 'dialog_overlay'
};
export const COPILOT_BUTTON_LABEL_ATTR = {
  name: 'aria-label',
  value: 'copilot'
};
export const FX_BUTTON_LABEL_ATTR = {
  name: 'aria-label',
  value: 'fx'
};
export const COPILOT_BUTTON_ATTR = {
  [COPILOT_BUTTON_LABEL_ATTR.name]: COPILOT_BUTTON_LABEL_ATTR.value
};
export const FX_BUTTON_ATTR = {
  [FX_BUTTON_LABEL_ATTR.name]: FX_BUTTON_LABEL_ATTR.value
};
export const DIALOG_OVERLAY_ATTR = {
  [DIALOG_OVERLAY_COMPONENT_NAME_ATTR.name]: DIALOG_OVERLAY_COMPONENT_NAME_ATTR.value
};
export const DATA_DRAWER_BUTTON_LABEL_ATTR = {
  name: 'aria-label',
  value: 'data table toggle'
};
export const DATA_DRAWER_BUTTON_ATTR = {
  [DATA_DRAWER_BUTTON_LABEL_ATTR.name]: DATA_DRAWER_BUTTON_LABEL_ATTR.value
};
export const RUN_BUTTON_ATTR_NAME = 'data-run-type';
export enum RUN_BUTTON_ATTR_VALUE {
  Pipeline = 'Pipeline',
  GemDialog = 'GemDialog'
}
export const DEPLOYMENT_DOCS_URL = 'https://docs.prophecy.io/architecture/deployment/installation-guide/';
export const CONTACT_SUPPORT_URL = 'https://www.prophecy.io/contact-us';

export const SUB_GRAPH_NODE_TYPES = {
  subGraphInNode: 'subGraphInNode',
  subGraphOutNode: 'subGraphOutNode'
};
export const PATH_ROOTS = {
  COMPONENT: 'component',
  RECORD: 'record',
  WORKFLOW: '$.workflow',
  JOB: '$.job',
  SQL: '$.sql',
  EXPECTATION: '$.expectation',
  LINEAGE: '$.lineageGraph',
  METADATA: '$.metadata',
  REUSABLE_SUBGRAPHS: '$.subgraphSpec'
};
export const SQL_PATH_ROOTS = {
  currentEntityGraph: `${PATH_ROOTS.SQL}.currentEntityGraph`,
  currentPipelineGraph: `${PATH_ROOTS.SQL}.currentPipelineGraph`
};
export enum SubgraphType {
  ForEachLoop = 'ForEachLoop',
  TaskGroup = 'TaskGroup'
}
export const REQUIREMENTS_FOLDER_NAME = 'requirements';
export const REQUIREMENTS_FILE_NAME = 'Requirement.md';
export const defaultUnixCronValue = '0 0/1 * * *';
export const defaultQuartzCronValue = '0 0 0 1/1 * ? *';

export const GRAPH_DEFAULT_ZOOM_FACTOR = 1 / 1.2;
