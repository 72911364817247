import { jsx as _jsx } from "react/jsx-runtime";
import { useOnce } from '@prophecy/utils/react/hooks';
import { isEmpty } from 'lodash-es';
import { createContext, useContext, useMemo } from 'react';
import { PluginType } from './types';
export const MarkdownContext = createContext({});
export const useMarkdownProps = () => {
    return useContext(MarkdownContext);
};
export const withMarkdownContext = (WrappedComponent) => (props) => {
    const { supportedPluginTypes: _supportedPluginTypes } = props;
    const supportedPluginTypes = useOnce(() => new Set(isEmpty(_supportedPluginTypes) ? Object.values(PluginType) : _supportedPluginTypes));
    const contextValue = useMemo(() => ({ ...props, supportedPluginTypes }), [props, supportedPluginTypes]);
    return (_jsx(MarkdownContext.Provider, { value: contextValue, children: _jsx(WrappedComponent, { ...props }) }));
};
