import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import { Ellipsis } from '../Ellipsis';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { renderWithTooltip } from '../Tooltip';
import { Text } from '../Typography';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { StyledRoot, StyledRadioItem, StyledRadio, StyledOptionsWrap, StyledRadioLabel, StyledGroupIconSection, StyledCaption } from './styled';
import { tokens } from './tokens';
import { Size, Placement } from './types';
function Radio({ checked, key, value, disabled, children, size = Size.s, hint, tooltip, disableBorder = true, alignY, checkboxPlacement = Placement.left, caption, ...restProps }) {
    //radioItem class used in Radio box to apply the hover effect
    let radioUI = (_jsx(StyledRadioLabel, { size: size, disabled: disabled, level: 'sm', children: children }));
    if (tooltip) {
        radioUI = _jsx(_Fragment, { children: renderWithTooltip(_jsx(Stack, { width: '100%', children: radioUI }), tooltip) });
    }
    if (hint) {
        radioUI = _jsx(LabelWithHint, { hint: hint, children: radioUI });
    }
    return (_jsxs(Stack, { gap: theme.spaces.x2, children: [_jsxs(StyledRadioItem, { ...restProps, checkboxPlacement: checkboxPlacement, disableBorder: disableBorder, value: value, alignY: alignY, id: key || value, disabled: disabled, children: [checked ? (_jsx(StyledRadio, { className: 'radioItem', checkboxPlacement: checkboxPlacement, size: size, checked: checked, disabled: disabled })) : (_jsx(StyledRadio, { className: 'radioItem', checkboxPlacement: checkboxPlacement, size: size, checked: checked, disabled: disabled })), radioUI] }), caption && _jsx(StyledCaption, { children: caption })] }));
}
RadioGroup.Radio = Radio;
function Item({ key, value, asChild, label, headingWithBorder, icon, helpText, disabled, isCard, hint, tooltip, children, ...restProps }) {
    const getChildrenUI = () => {
        if (isCard) {
            let childUi = (_jsxs(Stack, { align: 'center', gap: theme.spaces.x4, width: '100%', height: '100%', children: [icon && (_jsx(StyledGroupIconSection, { align: 'center', alignY: 'center', children: icon })), _jsxs(Stack, { gap: theme.spaces.x4, align: 'center', alignY: 'center', padding: `0 0 ${theme.spaces.x8} 0`, width: '100%', children: [_jsx(Stack, { direction: 'horizontal', width: '100%', align: 'center', padding: `0 ${theme.spaces.x10}`, children: _jsx(Ellipsis, { tooltip: !tooltip, title: label, children: _jsx(Text, { level: 'sm', weight: theme.fontWeight.medium, tone: disabled ? tokens.RadioItem.disabled.color : theme.colors.gray900, children: label }) }) }), helpText && (_jsx(Stack, { direction: 'horizontal', gap: theme.spaces.x2, align: 'center', alignY: 'center', width: '100%', children: _jsx(Text, { level: 'xs', tone: disabled ? tokens.RadioItem.disabled.color : tokens.RadioItem.subTextColor, children: helpText }) }))] })] }));
            if (tooltip) {
                childUi = _jsx(_Fragment, { children: renderWithTooltip(childUi, tooltip) });
            }
            return childUi;
        }
        else {
            return children;
        }
    };
    let itemUI = (_jsx(StyledRadioItem, { ...restProps, headingWithBorder: headingWithBorder, isCard: isCard, value: value, id: key || value, disabled: disabled, children: getChildrenUI() }));
    if (tooltip && !isCard) {
        itemUI = _jsx(_Fragment, { children: renderWithTooltip(_jsx(Stack, { width: '100%', children: itemUI }), tooltip) });
    }
    if (hint) {
        itemUI = (_jsx(LabelWithHint, { hint: hint, children: _jsx(Stack, { width: '100%', children: itemUI }) }));
    }
    return itemUI;
}
RadioGroup.Item = Item;
const renderOptions = (options, selection, size = Size.s, disabled) => {
    if (!options || (options && options.length < 1)) {
        return null;
    }
    let _options = options;
    if (typeof _options[0] === 'string' || typeof _options[0] === 'number') {
        _options = _options.map((option) => {
            return {
                label: String(option),
                value: String(option),
                disabled
            };
        });
    }
    return _options.map((_option) => {
        const option = _option;
        return (_jsx(Radio, { disableBorder: true, checked: selection === option.value, value: option.value, size: size, hint: option.hint, caption: option.caption, disabled: option.disabled || disabled, children: option.label }, option.key || option.value));
    });
};
export function RadioGroup({ name, defaultValue, disabled, value, options, onChange, orientation, wrap = true, gap = tokens.RadioGroup.gap, children, width = 'auto', size = Size.s, ...restProps }) {
    const [selection, setSelection] = useControlledState({
        value: value,
        defaultValue: defaultValue,
        onChange: (value) => onChange?.(value)
    });
    return (_jsx(StyledRoot, { name: name, defaultValue: selection, value: selection, onValueChange: setSelection, orientation: orientation, ...restProps, dir: 'ltr', children: _jsx(StyledOptionsWrap, { direction: orientation ? orientation : 'horizontal', gap: gap, wrap: wrap, width: width, alignY: orientation === 'vertical' ? 'stretch' : 'center', children: options && options.length > 0 ? renderOptions(options, selection, size, disabled) : children }) }));
}
