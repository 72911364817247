import { jsx as _jsx } from "react/jsx-runtime";
import { withProps } from '@prophecy/utils/react/hoc';
import { BoldPlugin, CodePlugin, ItalicPlugin, StrikethroughPlugin, SubscriptPlugin, SuperscriptPlugin, UnderlinePlugin } from '@udecode/plate-basic-marks/react';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { CodeBlockPlugin, CodeLinePlugin, CodeSyntaxPlugin } from '@udecode/plate-code-block/react';
import { ParagraphPlugin, Plate, PlateLeaf, usePlateEditor } from '@udecode/plate-common/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { HeadingPlugin } from '@udecode/plate-heading/react';
import { HighlightPlugin } from '@udecode/plate-highlight/react';
import { HorizontalRulePlugin } from '@udecode/plate-horizontal-rule/react';
import { BulletedListPlugin, ListItemPlugin, ListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';
import { MarkdownPlugin } from '@udecode/plate-markdown';
import { ImagePlugin } from '@udecode/plate-media/react';
import { TableCellHeaderPlugin, TableCellPlugin, TablePlugin, TableRowPlugin } from '@udecode/plate-table/react';
import { useEffect, useMemo, useRef } from 'react';
import { CodeBlockElement, CodeLineElement, CodeSyntaxLeaf } from './CodeElement/CodeBlockElement';
import Prism from './CodeElement/prismjs';
import { CommentedText, CommentsContext, CustomCommentPlugin } from './Comments';
import { withMarkdownContext } from './context';
import { FixedToolbarPlugin } from './FixedToolbarPlugin';
import { FloatingToolbarPlugin } from './FloatingToolbarPlugin';
import { HrElement } from './HRElement';
import { ImageElement } from './ImageElement';
import { CustomLinkPlugin } from './LinkPlugin';
import { deserializeMdWithComments } from './markdownDeserialize';
import { serializeToMd } from './markdownSerialize';
import { StyledMarkdownContainer, StyledMarkdownEditor, StyledPlateContent } from './styled';
const CodeBlockPluginWithPrism = CodeBlockPlugin.configure({
    options: {
        prism: Prism
    }
});
export const MarkdownEditor = withMarkdownContext(({ className, value, onChange, placeholder, comments, readOnly, renderCommentActions, showFixedToolbar = true, showFloatingToolbar = true }) => {
    const internalValueRef = useRef(value);
    const editor = usePlateEditor({
        value: (editor) => {
            return deserializeMdWithComments(editor, value || '', comments);
        },
        override: {
            components: {
                [BlockquotePlugin.key]: withProps(PlateLeaf, { as: 'blockquote' }),
                [BoldPlugin.key]: withProps(PlateLeaf, { as: 'strong' }),
                [CodeBlockPlugin.key]: CodeBlockElement,
                [CodeLinePlugin.key]: CodeLineElement,
                [CodePlugin.key]: withProps(PlateLeaf, { as: 'code' }),
                [CodeSyntaxPlugin.key]: CodeSyntaxLeaf,
                [HEADING_KEYS.h1]: withProps(PlateLeaf, { as: 'h1' }),
                [HEADING_KEYS.h2]: withProps(PlateLeaf, { as: 'h2' }),
                [HEADING_KEYS.h3]: withProps(PlateLeaf, { as: 'h3' }),
                [HEADING_KEYS.h4]: withProps(PlateLeaf, { as: 'h4' }),
                [HEADING_KEYS.h5]: withProps(PlateLeaf, { as: 'h5' }),
                [HEADING_KEYS.h6]: withProps(PlateLeaf, { as: 'h6' }),
                [HighlightPlugin.key]: withProps(PlateLeaf, { as: 'mark' }),
                [HorizontalRulePlugin.key]: HrElement,
                [ImagePlugin.key]: ImageElement,
                [ItalicPlugin.key]: withProps(PlateLeaf, { as: 'i' }),
                [CustomLinkPlugin.key]: withProps(PlateLeaf, { as: 'a' }),
                [ParagraphPlugin.key]: withProps(PlateLeaf, { as: 'p' }),
                [StrikethroughPlugin.key]: withProps(PlateLeaf, { as: 's' }),
                [SubscriptPlugin.key]: withProps(PlateLeaf, { as: 'sub' }),
                [SuperscriptPlugin.key]: withProps(PlateLeaf, { as: 'sup' }),
                [TableCellHeaderPlugin.key]: withProps(PlateLeaf, { as: 'th' }),
                [TableCellPlugin.key]: withProps(PlateLeaf, { as: 'td' }),
                [TablePlugin.key]: withProps(PlateLeaf, { as: 'table' }),
                [TableRowPlugin.key]: withProps(PlateLeaf, { as: 'tr' }),
                [UnderlinePlugin.key]: withProps(PlateLeaf, { as: 'u' }),
                [BulletedListPlugin.key]: withProps(PlateLeaf, { as: 'ul' }),
                [ListItemPlugin.key]: withProps(PlateLeaf, { as: 'li' }),
                [NumberedListPlugin.key]: withProps(PlateLeaf, { as: 'ol' }),
                [CustomCommentPlugin.key]: CommentedText
            }
        },
        plugins: [
            showFixedToolbar && FixedToolbarPlugin,
            showFloatingToolbar && FloatingToolbarPlugin,
            HeadingPlugin,
            BlockquotePlugin,
            CodeBlockPluginWithPrism,
            BoldPlugin,
            ItalicPlugin,
            UnderlinePlugin,
            CodePlugin,
            MarkdownPlugin,
            StrikethroughPlugin,
            SubscriptPlugin,
            SuperscriptPlugin,
            HorizontalRulePlugin,
            ImagePlugin,
            CustomLinkPlugin,
            ParagraphPlugin,
            HeadingPlugin,
            HighlightPlugin,
            TablePlugin,
            TableRowPlugin,
            TableCellPlugin,
            TableCellHeaderPlugin,
            ListPlugin,
            CustomCommentPlugin
        ].filter(Boolean)
    });
    useEffect(() => {
        if (internalValueRef.current !== value) {
            const deserializedValue = deserializeMdWithComments(editor, value || '', comments);
            editor.tf.setValue(deserializedValue);
        }
    }, [value, editor, comments]);
    useEffect(() => {
        if (comments && comments.length > 0) {
            const deserializedValue = deserializeMdWithComments(editor, internalValueRef.current || '', comments);
            editor.tf.setValue(deserializedValue);
        }
    }, [comments, editor]);
    const handleChange = ({ value }) => {
        const serializedValue = serializeToMd(value);
        internalValueRef.current = serializedValue;
        if (!readOnly) {
            onChange?.(serializedValue);
        }
    };
    const containerRef = useRef(null);
    const memoizedCommentsContext = useMemo(() => ({ comments: comments || [], renderCommentActions, markdownContainer: containerRef }), [comments, renderCommentActions]);
    return (_jsx(CommentsContext.Provider, { value: memoizedCommentsContext, children: _jsx(StyledMarkdownContainer, { ref: containerRef, children: _jsx(StyledMarkdownEditor, { className: className, children: _jsx(Plate, { editor: editor, onValueChange: handleChange, readOnly: readOnly, children: _jsx(StyledPlateContent, { placeholder: placeholder, className: 'ui-typography', disabled: readOnly }) }) }) }) }));
});
