import { ChangeDescriptor, FileOperation } from '@prophecy/ui/Editor/types';
import { LSP } from 'frontend/core/src/LSP/base/types';
import { castArray } from 'lodash-es';
import { Store } from 'redux';

import {
  BusinessRuleChatHistoryRequest,
  BusinessRuleRequest,
  CopilotDescribeParams,
  CopilotExpressionParams,
  CopilotFixParams,
  CopilotGetLabelParams,
  CopilotGetMacroParams,
  CopilotGetScripParams,
  CopilotGetUdfParams,
  CopilotGraphParams,
  CopilotModificationParams,
  CopilotSpecFlowParams,
  CopilotSuggestPromptParams,
  SuggestGemPropertiesParams
} from '../../common/Copilot/types';
import { CreateJobRequest } from '../../common/EntityToCreate/context/types';
import {
  BaseProcess,
  BaseProcessMetadata,
  BaseState,
  Change,
  CommonActionTypes,
  Connection,
  DidActionPayload,
  DidChangePayload,
  GenericGraph,
  Metadata
} from '../../common/types';
import { HistoryContext } from '../../HistoryManager/context';
import { frameId } from '../../HistoryManager/utils';
import { getCurrentProcessPath, getProcessPropertyPath, resolveBindingPath } from '../../Parser/bindings';
import { actionTypes } from '../../redux/action-types';
import {
  DidActionOptions,
  DidChangeOptions,
  DidResetOptions,
  DidSaveOptions,
  HistoryOptions,
  LSPHistoryEntryType
} from '../history/types';
import { handleActionTypeMethodHistory, handleRootHistory, historyDidChange, historyDidSave } from '../history/utils';
import { GenerateCodeRequest, TextDocument } from '../types';
import { BaseLSPClient, CompileParams, CreateGemParams, LoadViewParams } from './BaseLSPClient';
import { getRequestId } from './utils';

export function changeProperties<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  lspClient: BaseLSPClient<unknown>,
  store: Store<BaseState<T, Metadata>>,
  history: HistoryContext<LSPHistoryEntryType> | undefined
) {
  return async (payload: DidChangePayload, options: DidChangeOptions = {}) => {
    payload = castArray(payload);
    options.historyMergeId ||= frameId();
    let isStoreHandled = Boolean(history);

    const state = store.getState();
    const changes = payload.map(({ property, value, handleHistoryDidUpdate }) => {
      const requestId = getRequestId();

      property = resolveBindingPath(property, state.graphPath, state.currentComponentId);
      return {
        handleHistoryDidUpdate,
        requestId,
        type: 'partial',
        property,
        value,
        graphPath: getProcessPropertyPath(state)
      };
    });

    if (history) {
      isStoreHandled = Boolean(historyDidChange(history, store, payload, options));
    }

    if (!isStoreHandled) {
      // if store is not handled by history, dispatch the event here
      store.dispatch({ type: LSP.Method.propertiesDidChange, payload });
    }

    return Promise.all(
      changes.map(({ handleHistoryDidUpdate, requestId, ...change }) => {
        return lspClient.changeProperties(change as Change, requestId);
      })
    );
  };
}

export function promoteProperties(lspClient: BaseLSPClient<unknown>) {
  return async () => lspClient.promoteProperties();
}

export function saveProperty<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  lspClient: BaseLSPClient<unknown>,
  store: Store<BaseState<T, Metadata>>,
  history: HistoryContext<LSPHistoryEntryType> | undefined
) {
  return async (options?: DidSaveOptions) => {
    const savePromise = lspClient.saveProperties({ userInvokedCompilation: options?.userInvokedCompilation });

    //if awaited save is present add the history after save is finished, or else add it before hand
    if (history) {
      if (options?.isAwaitedSave) {
        savePromise.finally(() => historyDidSave(history, options));
      } else {
        historyDidSave(history, options);
      }
    }

    return savePromise;
  };
}

export function compileProperty<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return async (params?: CompileParams) => lspClient.compile(params);
}

export function publishTextDocument<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return async (uri: string) => lspClient.publishTextDocument(uri);
}
export function resetProperty<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  lspClient: BaseLSPClient<unknown>,
  store: Store<BaseState<T, Metadata>>,
  history: HistoryContext<LSPHistoryEntryType> | undefined
) {
  return async (options: DidResetOptions = {}) => {
    options.historyMergeId ||= frameId();
    history && handleRootHistory(history, store, options);
    return lspClient.resetProperties();
  };
}

export function cancelCompilation<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return async () => {
    return lspClient.cancelCompilation();
  };
}

export function callAction<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  lspClient: BaseLSPClient<unknown>,
  store: Store<BaseState<T, Metadata>>,
  history: HistoryContext<LSPHistoryEntryType> | undefined
) {
  return async (payload: DidActionPayload, options: DidActionOptions = {}) => {
    options.historyMergeId ||= frameId();
    const requestId = getRequestId();
    history && handleActionTypeMethodHistory(history, store, options, requestId);

    const state = store.getState();
    const { currentComponentId: componentId, graphPath } = state;
    const { graphPath: _graphPath, ...rest } = payload;
    // if graphPath & componentId are missing from dialog, read  graphPath from payload(non-component case)
    const processPath = getCurrentProcessPath(componentId as string, graphPath);
    let _path = _graphPath ? _graphPath : processPath;

    return lspClient.callAction(rest, _path, requestId);
  };
}

export function changeTextDocument<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return async (payload: ChangeDescriptor[]) => {
    const changes = payload;
    const results = [];
    for (const change of changes) {
      if (change.type === FileOperation.UPDATE) {
        results.push(lspClient.changeTextDocument(change.path, change.text));
      }
    }
    return Promise.all(results);
  };
}
export function saveTextDocument<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return async (payload: string) => lspClient.saveTextDocument(payload);
}
export function createTextDocument<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return (textDocument: TextDocument) => lspClient.createTextDocument(textDocument);
}
export function createGem<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return (payload: CreateGemParams) => lspClient.createGem(payload);
}
//editorCreateGem
export function deleteTextDocument<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return (uri: string) => lspClient.deleteTextDocument(uri);
}
export function moveTextDocument<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return (payload: { from: string; to: string }) => lspClient.moveTextDocument(payload);
}
export function switchTextDocument<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return (uri: string) => lspClient.switchTextDocument(uri);
}

export function metadataDidReload<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return async () => await lspClient.metadataDidReload();
}

export function loadView<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return async (payload: LoadViewParams) => await lspClient.loadView(payload);
}

export const toggleLoader =
  (
    store: Store<
      BaseState<GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>, Metadata>
    >
  ) =>
  (isLoading: boolean) => {
    store.dispatch({ type: isLoading ? CommonActionTypes.loaderOn : CommonActionTypes.loaderOff });
  };

export function generateExecutableCode<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return async (params: GenerateCodeRequest, options?: { [keys: string]: unknown }) => {
    if (options?.isUTexecution) {
      store.dispatch({
        type: actionTypes.executeComponentTestStarted,
        payload: { processId: params.processId, componentsWithTests: options?.componentsWithTests }
      });
    }
    try {
      return await lspClient.generateExecutableCode(params);
    } finally {
    }
  };
}

export function metadataGetDatasets<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return async () => {
    const resp = (await lspClient.metadataGetDatasets()) as { datasets: [] };
    if (resp?.datasets) {
      store.dispatch({
        type: actionTypes.DatasetsResult,
        payload: resp.datasets || []
      });
    }
    return resp;
  };
}

export function generateExpressionSuggestions(lspClient: BaseLSPClient<unknown>) {
  return async (params: CopilotExpressionParams) => {
    return await lspClient.generateExpressionSuggestions(params);
  };
}

export function generateGraphSuggestions<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  lspClient: BaseLSPClient<unknown>,
  store: Store<BaseState<T, Metadata>>,
  history: HistoryContext<LSPHistoryEntryType> | undefined
) {
  return async (params: CopilotGraphParams, options: HistoryOptions = {}) => {
    options.historyMergeId ||= frameId();
    const requestId = getRequestId();
    history && handleRootHistory(history, store, options);

    return await lspClient.generateGraphSuggestions(params, requestId);
  };
}

export function getCopilotGraphById(lspClient: BaseLSPClient<unknown>) {
  return async (summaryOrPromptId: string) => {
    return await lspClient.getCopilotGraphById(summaryOrPromptId);
  };
}
export function getSuggestionGraphDifference(lspClient: BaseLSPClient<unknown>) {
  return async () => {
    return await lspClient.getSuggestionGraphDifference();
  };
}
export function copilotSpecFlow(lspClient: BaseLSPClient<unknown>) {
  return async (params: CopilotSpecFlowParams) => {
    return await lspClient.copilotSpecFlow(params);
  };
}
export function copilotEditBusinessSpec(lspClient: BaseLSPClient<unknown>) {
  return async (params: { content: string }) => {
    return await lspClient.copilotEditBusinessSpec(params);
  };
}

export function copilotSaveSpecMarkdown<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return async (params: { content: string; changes: Change[] }) => {
    const state = store.getState();
    const changes = params.changes.map(({ property, value }) => {
      const requestId = getRequestId();

      property = resolveBindingPath(property, state.graphPath, state.currentComponentId);
      return {
        requestId,
        type: 'partial',
        property,
        value,
        graphPath: getProcessPropertyPath(state)
      };
    });

    store.dispatch({ type: LSP.Method.propertiesDidChange, payload: params.changes });

    return await lspClient.copilotSaveSpecMarkdown({ ...params, changes: changes as Change[] });
  };
}

export function generateModificationSuggestions<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  lspClient: BaseLSPClient<unknown>,
  store: Store<BaseState<T, Metadata>>,
  history: HistoryContext<LSPHistoryEntryType> | undefined
) {
  return async (params: CopilotModificationParams, options: HistoryOptions = {}) => {
    options.historyMergeId ||= frameId();
    const requestId = getRequestId();
    history && handleRootHistory(history, store, options);
    return await lspClient.generateModificationSuggestions(params, requestId);
  };
}

export function copilotDescribe(lspClient: BaseLSPClient<unknown>) {
  return async (params: CopilotDescribeParams) => {
    return await lspClient.copilotDescribe(params);
  };
}

export function getLabelSuggestion(lspClient: BaseLSPClient<unknown>) {
  return async (params: CopilotGetLabelParams) => {
    return await lspClient.getLabelSuggestion(params);
  };
}

export function getScriptSuggestion(lspClient: BaseLSPClient<unknown>) {
  return async (params: CopilotGetScripParams) => {
    return await lspClient.getScriptSuggestion(params);
  };
}

export function getUdfSuggestion(lspClient: BaseLSPClient<unknown>) {
  return async (params: CopilotGetUdfParams) => {
    return await lspClient.getUdfSuggestion(params);
  };
}

export function getMacroSuggestion(lspClient: BaseLSPClient<unknown>) {
  return async (params: CopilotGetMacroParams) => {
    return await lspClient.getMacroSuggestion(params);
  };
}

export function getDataTestSuggestion(lspClient: BaseLSPClient<unknown>) {
  return async (params: CopilotGetMacroParams) => {
    return await lspClient.getDataTestSuggestion(params);
  };
}

export function copilotFix(lspClient: BaseLSPClient<unknown>) {
  return async (params: CopilotFixParams) => {
    return await lspClient.copilotFix(params);
  };
}

export function suggestGemProperties(lspClient: BaseLSPClient<unknown>) {
  return async (params: SuggestGemPropertiesParams) => {
    return await lspClient.suggestGemProperties(params);
  };
}

export function suggestPrompts(lspClient: BaseLSPClient<unknown>) {
  return async (params: CopilotSuggestPromptParams) => {
    return await lspClient.suggestPrompts(params);
  };
}

export function getBusinessRuleChat(lspClient: BaseLSPClient<unknown>) {
  return async (params: BusinessRuleChatHistoryRequest) => {
    return await lspClient.getBusinessRuleChat(params);
  };
}

export function getBusinessRule(lspClient: BaseLSPClient<unknown>) {
  return async (params: BusinessRuleRequest) => {
    return await lspClient.getBusinessRule(params);
  };
}

export function dataExplorerSaveAsGem<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  lspClient: BaseLSPClient<unknown>,
  store: Store<BaseState<T, Metadata>>,
  history: HistoryContext<LSPHistoryEntryType> | undefined
) {
  return async (params: { processId: string; portId?: string }, options: HistoryOptions = {}) => {
    options.historyMergeId ||= frameId();
    const requestId = getRequestId();
    history && handleActionTypeMethodHistory(history, store, options, requestId);

    return await lspClient.dataExplorerSaveAsGem(params, requestId);
  };
}

export function metadataCreateJob<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(lspClient: BaseLSPClient<unknown>, store: Store<BaseState<T, Metadata>>) {
  return async (payload: CreateJobRequest) => await lspClient.metadataCreateJob(payload);
}
