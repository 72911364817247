import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isNodeEvnProduction } from '@prophecy/utils/env';
import { uniqueId } from 'lodash-es';
import { forwardRef, useMemo } from 'react';
import { LoadingDIcon, XCircleIcon } from '../Icons';
import { renderWithTooltip } from '../Tooltip';
import { Text } from '../Typography/Text';
import { StyledInputContainer, StyledInput, StyledIconWrapper, StyledLabel, InputWrap, StyledHelpText, StyledInputRoot, StyledAddOnWrapper, StyledLabelWithHint, StyledLabelWrapper, StyledAsteriskIcon } from './styled';
import { tokens } from './tokens';
import { InputVariants, Placement, InputSize } from './types';
import { TriggerKind, useInput } from './useInput';
export const Input = forwardRef(({ variant = InputVariants.primary, type = 'text', value, iconPlacement = Placement.right, addOnPlacement = Placement.left, addOnSeparator = true, icon, addOn, title, maxLength, required = false, label, inputSize = InputSize.m, placeholder, disabled, min, max, hint, tooltip, delay = 0, preFormat, onChange, onPressEnter, onClear, onKeyDown, onBlur, helpText, allowEscapeSequence, style, className, allowClear, onClick, id, loading, ...restProps }, ref) => {
    const inputId = useMemo(() => id || uniqueId('input'), [id]);
    const { showClear, setFocus, _value, setRef, handleChange, handleBlur, handleKeyDown, handleClear, valueLength } = useInput({
        value,
        onChange,
        delay,
        allowEscapeSequence,
        onBlur,
        onPressEnter,
        onKeyDown,
        preFormat,
        ref,
        allowClear,
        onClear
    });
    const showIcon = icon && !(showClear && iconPlacement === Placement.right) && !(loading && iconPlacement === Placement.left);
    // validation for props, this will be trimmed on production build
    if (!isNodeEvnProduction()) {
        if (inputSize !== InputSize.l && title) {
            throw new Error('title prop is only available for large size input');
        }
    }
    let inputUI = (_jsxs(InputWrap, { align: 'start', alignY: 'start', direction: 'vertical', addOnPlacement: addOn ? addOnPlacement : undefined, iconPlacement: icon ? iconPlacement : undefined, "data-placeholder": placeholder, "data-value": _value, children: [title && (_jsx(Text, { level: 'xxs', color: tokens.Input.Title.color, children: title })), _jsx(StyledInput, { ...restProps, ref: setRef, id: inputId, type: type, maxLength: maxLength, min: min, max: max, addOn: addOn, value: _value, placeholder: placeholder, onChange: (e) => handleChange(e, TriggerKind.User), onBlur: handleBlur, disabled: disabled, onKeyDown: handleKeyDown })] }));
    if (tooltip) {
        inputUI = _jsx(_Fragment, { children: renderWithTooltip(inputUI, tooltip) });
    }
    if (!label && hint) {
        inputUI = _jsx(StyledLabelWithHint, { hint: hint, children: inputUI });
    }
    const labelUI = label ? _jsx(StyledLabel, { htmlFor: inputId, children: label }) : null;
    const labelUIWrapper = label ? (_jsxs(StyledLabelWrapper, { children: [required && label && _jsx(StyledAsteriskIcon, { children: "*" }), labelUI] })) : null;
    return (_jsxs(StyledInputRoot, { variant: variant, size: inputSize, direction: 'vertical', gap: tokens.Root.gap, style: style, className: className, onClick: (e) => {
            onClick?.(e);
            setFocus();
        }, children: [hint && label ? (_jsx(StyledLabelWithHint, { level: 'xs', hint: hint, children: labelUIWrapper })) : (labelUIWrapper), _jsxs(StyledInputContainer, { type: type, variant: variant, inputSize: inputSize, disabled: disabled, children: [inputUI, showClear && (_jsx(StyledIconWrapper, { iconPlacement: Placement.right, addOnPlacement: addOn ? addOnPlacement : undefined, children: _jsx(XCircleIcon, { type: 'solid', onClick: handleClear }) })), loading && (_jsx(StyledIconWrapper, { iconPlacement: Placement.left, addOnPlacement: addOn ? addOnPlacement : undefined, children: _jsx(LoadingDIcon, { type: 'extended', spin: true }) })), showIcon && (_jsx(StyledIconWrapper, { iconPlacement: iconPlacement, addOnPlacement: addOn ? addOnPlacement : undefined, children: icon })), addOn && (_jsx(StyledAddOnWrapper, { size: inputSize, "$variant": variant, iconPlacement: icon ? iconPlacement : undefined, addOnPlacement: addOnPlacement, addOnSeparator: !!addOnSeparator, children: addOn }))] }), helpText && (_jsxs(StyledHelpText, { variant: variant, direction: 'horizontal', align: 'space-between', children: [helpText, type !== 'number' && maxLength && maxLength > 0 ? _jsx("span", { children: valueLength + '/' + maxLength }) : null] }))] }));
});
