import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import styled from 'styled-components';
const alignments = {
    stretch: 'stretch',
    start: 'flex-start',
    end: 'flex-end',
    baseline: 'baseline',
    center: 'center',
    'space-between': 'space-between',
    'space-around': 'space-around',
    'space-evenly': 'space-evenly'
};
function gridStyles(props) {
    const { direction = 'vertical', height = 'auto', width = 'auto', align = 'start', alignY = 'stretch', gap, padding = '0px' } = props;
    const _alignY = alignments[alignY];
    const _align = alignments[align];
    if (direction === 'vertical') {
        return `
      padding: ${padding};
      flex-direction:column;
      justify-content: ${_align};
      align-items: ${_alignY};
      gap: ${gap || '0px'};
      width:${width};
      height:${height};
    `;
    }
    else {
        return `
      padding: ${padding};
      justify-content: ${_align};
      align-items: ${_alignY};
      gap: ${gap || '0px'};
      width:${width};
      height:${height};
      `;
    }
}
const StackComponent = forwardRef(({ direction, height, width, align, alignY, gap, padding, ...rest }, ref) => _jsx("div", { ref: ref, ...rest }));
export const Stack = styled(StackComponent) `
  display: flex;
  ${gridStyles};
  ${({ grow }) => (grow ? `flex-grow: ${grow};` : '')};
  ${({ shrink }) => (shrink ? `flex-shrink: ${shrink};` : '')};
  ${({ wrap }) => {
    if (typeof wrap === 'boolean' && wrap) {
        return 'flex-wrap: wrap';
    }
    else {
        return `flex-wrap: ${wrap}`;
    }
}};
`;
export const StackItem = styled.div `
  /**
    * one drawback of flex, is even if flex 1 is provided, it doesn't shrink  beyond its content height. For it to work,
    * either we have to give min-height: 0; or min-width: 0 based on the direction.
    * So we are making it a default value on our StackItem . Though one side-effect is if two child are flex, then it will always respect flex value,
    * and ignore the content height/width which looks like an expected behaviour for us
    */
  min-height: 0;
  min-width: 0;
  ${(props) => (props.align ? `align-self: ${props.align}` : '')};
  ${(props) => (props.width ? `width: ${props.width}` : '')};
  ${(props) => (props.height ? `height: ${props.height}` : '')};
  padding: ${(props) => props.padding ?? props.gap ?? '0px'};
  flex-basis: ${(props) => props.basis ?? 'auto'};
  flex-grow: ${(props) => props.grow ?? '0'};
  flex-shrink: ${(props) => props.shrink ?? '1'}; /** Default value of flex-shrink is 1 */
`;
