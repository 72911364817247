import { theme } from '../theme';
export const tokens = {
    Dialog: {
        Overlay: {
            backgroundColor: theme.colors.gray900,
            zIndex: theme.zLayer.l,
            topLayerIndex: theme.zLayer.xxxl
        },
        Content: {
            boxShadow: theme.shadows.md,
            iconPadding: theme.spaces.x12,
            fontFamily: theme.fontFamily.text,
            fontSize: theme.fontSizes.x14,
            transition: theme.transition.m,
            backgroundColor: theme.colors.white,
            headingFontWeight: theme.fontWeight.medium,
            headingColor: theme.colors.gray900,
            subHeadingColor: theme.colors.gray500,
            iconSize: theme.sizes.x48,
            padding: theme.spaces.x24,
            footerPadding: theme.spaces.x12,
            borderRadius: theme.radius.x12,
            zIndex: theme.zLayer.xxl
        },
        CloseButton: {
            backgroundColor: theme.colors.white,
            hover: {
                backgroundColor: theme.colors.white
            }
        },
        Footer: {
            borderColor: theme.colors.gray200
        },
        size: {
            m: {
                width: 400
            },
            l: {
                width: 557
            },
            xl: {
                width: 700
            },
            xxl: {
                width: 900
            }
        }
    }
};
export const DialogComponentNames = {
    title: 'dialog.title',
    content: 'dialog.content',
    overlay: 'dialog.overlay',
    subTitle: 'dialog.subTitle'
};
