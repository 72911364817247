import { CommentsPositioner } from '@udecode/plate-comments/react';
import { PlateContent, PlateLeaf } from '@udecode/plate-common/react';
import styled, { css } from 'styled-components';
import { Button } from '../Button';
import { tokens as buttonTokens } from '../Button/tokens';
import { ChevronDownIcon } from '../Icons';
import { ICON_CLASS, tokens as iconTokens } from '../Icons/tokens';
import { Input } from '../Input';
import { StyledInputContainer } from '../Input/styled';
import { Stack } from '../Layout';
import { theme } from '../theme';
export const StyledCommentedText = styled(PlateLeaf) `
  background: ${theme.colors.purple100};
  position: relative;
  padding: ${theme.spaces.x4} 0;

  ${({ isActive }) => isActive &&
    css `
      &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        height: 2px;
        width: 100%;
        left: 0%;
        background: ${theme.colors.purple300};
      }
    `}
`;
export const StyledCommentsPositioner = styled(CommentsPositioner) `
  position: absolute;
  z-index: ${theme.zLayer.xl};
`;
export const StyledCommentPopup = styled(Stack) `
  border: 1px solid ${theme.colors.gray200};
  padding: ${theme.spaces.x10} ${theme.spaces.x14} ${theme.spaces.x14};
  background: ${theme.colors.white};
  border-radius: ${theme.radius.x8};
  width: 300px;
`;
export const StyledMarkdownContainer = styled.div `
  width: 100%;
  height: 100%;
`;
export const StyledMarkdownEditor = styled(Stack) `
  border-radius: ${theme.radius.x8};
  border: 1px solid ${theme.colors.gray200};
  height: 100%;

  .ui-typography {
    table {
      td,
      th {
        min-width: 200px;
        border-right: 1px solid ${theme.colors.gray300};

        &:last-child {
          border-right: none;
        }
        & > p {
          margin: 0;
          display: inline;
        }
      }
    }

    // if all td inside tr has highlight element then we don't need to show highlight

    img {
      width: 100%;
      max-width: 1000px;
    }
  }
`;
export const StyledPlateContent = styled(PlateContent) `
  border: 0;
  outline: none;
  padding: ${theme.spaces.x24};
  overflow: auto;
  flex-grow: 1;
`;
export const StyledButton = styled(Button) `
  padding: ${theme.spaces.x6};
  width: ${({ width }) => width || theme.sizes.x28};
  height: ${theme.sizes.x28};
  color: ${theme.colors.gray600};
  transition: none;

  && {
    &:hover,
    &:focus,
    &:active,
    &[data-active='true'] {
      color: ${theme.colors.gray700};
    }
  }

  &[data-active='true'] {
    background-color: ${buttonTokens.Button.default.tertiaryGray.hover.backgroundColor};
  }
`;
export const StyledDropdownHandle = styled(Button) `
  padding: ${theme.spaces.x6};
  height: ${theme.sizes.x32};
  text-wrap: nowrap;
  word-break: keep-all;

  color: ${theme.colors.gray600};

  && {
    &:hover,
    &:focus,
    &:active {
      border: none;
      color: ${theme.colors.gray700};
    }
  }
`;
export const StyledFixedToolbar = styled(Stack) `
  background: ${theme.colors.gray25};
  border-bottom: 1px solid ${theme.colors.gray200};
  border-top-left-radius: ${theme.radius.x8};
  border-top-right-radius: ${theme.radius.x8};
  padding: ${theme.spaces.x2} ${theme.spaces.x10};
  height: ${theme.sizes.x44};
  flex-shrink: 0;
`;
export const StyledFloatingToolbar = styled(Stack) `
  background: ${theme.colors.white};
  border-radius: ${theme.radius.x8};
  border: 1px solid ${theme.colors.gray200};
  padding: 0 ${theme.spaces.x8};
  height: ${theme.sizes.x40};
  box-shadow: ${theme.shadows.md};
`;
export const StyledLinkInput = styled(Input) `
  width: 400px;
  height: 100%;
  max-width: 60vw;

  ${StyledInputContainer} {
    border: none;
    box-shadow: none;
    height: 100%;
    padding: 0 ${theme.spaces.x6};
  }
`;
export const StyledToolbarArrowIcon = styled(ChevronDownIcon) `
  &&& {
    font-size: ${iconTokens.Icon.xs.size};
  }
`;
export const StyledIconWithLabel = styled(Stack) `
  .${ICON_CLASS} {
    color: ${({ disabled }) => (disabled ? theme.colors.gray300 : theme.colors.gray600)};
    font-size: ${theme.sizes.x18};
  }
`;
