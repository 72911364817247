export enum PageType {
  workflow = 'workflow',
  sql = 'sql',
  job = 'job'
}

export enum ProcessKind {
  Source = 'Source',
  Lookup = 'Lookup',
  Target = 'Target',
  Script = 'Script',
  Model = 'Model',
  Join = 'Join',
  Reformat = 'Reformat',
  Aggregate = 'Aggregate',
  Subgraph = 'Subgraph',
  StreamingSource = 'StreamingSource',
  StreamingTarget = 'StreamingTarget',
  ControlFlowOutput = 'ControlFlowOutput',
  ControlFlowInput = 'ControlFlowInput',
  Comment = 'Comment'
}

export enum SqlProcessKind {
  Model = 'Model',
  Snapshot = 'Snapshot',
  Seed = 'Seed',
  SubFlow = 'SubFlow',
  Source = 'Source',
  Macro = 'Macro',
  UnreferencedSource = 'UnreferencedSource',
  ExternalSource = 'ExternalSource',
  TargetModel = 'TargetModel',
  TargetSnapshot = 'TargetSnapshot',
  SingularDataTest = 'SingularDataTest',
  GenericDataTest = 'GenericDataTest',
  Test = 'Test',
  TargetSingularDataTest = 'TargetSingularDataTest',
  OrchestrationSource = 'OrchestrationSource',
  OrchestrationTarget = 'OrchestrationTarget',
  Dataset = 'Dataset',
  Table = 'Table'
}
