import { jsx as _jsx } from "react/jsx-runtime";
import { waitForElement } from '@prophecy/utils/dom';
import { forwardRef, useState } from 'react';
import { EyeOffIcon, EyeIcon } from '../Icons';
import { theme } from '../theme';
import { Input } from './Input';
export const Password = forwardRef((props, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    /*
    remove 1Password icon,if Password is used in dialog to avoid closing of dialog on clicking on 1Password icon
    */
    async function remove1Password(e) {
        const dialogEl = e.currentTarget.closest('[role="dialog"]');
        if (dialogEl)
            try {
                const _1PasswordEl = await waitForElement('com-1password-button', document.body, 1000);
                _1PasswordEl.remove();
            }
            catch (error) {
                // muting error
            }
    }
    function onClick(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowPassword(!showPassword);
    }
    const type = showPassword ? 'text' : 'password';
    const Icon = showPassword ? EyeOffIcon : EyeIcon;
    return (_jsx(Input, { autoComplete: props.name ? `new_${props.name}` : 'off', ...props, ref: ref, type: type, onFocus: (e) => {
            remove1Password(e);
            props.onFocus?.(e);
        }, icon: _jsx(Icon, { type: 'default', onClick: onClick, style: { color: theme.colors.gray300 } }), iconPlacement: 'right' }));
});
