import { Root, Viewport } from '@radix-ui/react-toast';
import styled from 'styled-components';
import { Button } from '../Button';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { tokens } from './tokens';
import { ToastPlacement, Variant } from './types';
export const containerStyle = (placement) => `
   position: fixed;
    z-index: ${theme.zLayer.xxl};
    pointer-events: auto;
    bottom: ${placement === ToastPlacement.bottom ? '65px' : 'auto'};
    transform: translateX(-50%);
    left: 50%;
    top: ${placement === ToastPlacement.top ? '65px' : 'auto'};
`;
export const ToastCopyContainer = styled.div `
  display: none;
  position: absolute;
  top: ${theme.spaces.x2};
  right: ${theme.spaces.x16};
  z-index: ${theme.zLayer.l};
  overflow: hidden;
`;
export const DialogCopyContainer = styled.div `
  display: block;
  float: right;
  overflow: hidden;
  background: white;
`;
export const StyledToastContainer = styled(Root) `
  font-size: ${tokens.Toast.fontSize};
  position: relative;
  z-index: ${theme.zLayer.l};
  font-family: ${theme.fontFamily.text};
  line-height: ${theme.lineHeight.x24};
  color: ${tokens.Toast.color};
  box-shadow: ${tokens.Toast.Content.boxShadow};
  width: ${({ width }) => width};
  min-width: 400px;
  padding: ${({ variant }) => (variant === Variant.plain ? theme.spaces.x20 : tokens.Toast.size.padding)};
  border-radius: ${tokens.Toast.size.borderRadius};
  background: ${({ variant }) => tokens.Toast[variant].backgroundColor};
  ${({ variant }) => variant !== Variant.plain ? `.${variant}{color: ${tokens.Toast[variant].iconColor}; margin-left: 3px;}` : ''};
  pointer-events: auto !important; //important required for overriding inline style

  &:hover ${ToastCopyContainer} {
    display: block;
  }

  ${({ isDraggable }) => isDraggable
    ? `
    user-select: none;
    cursor: grab;
    pointer-events: auto;

    &:active {
      cursor: grabbing;
    }
  `
    : ''}
`;
export const StyledButton = styled(Button) `
  background: transparent;
  border: none;
  width: auto;
  height: auto;
  pointer-events: auto;
  cursor: pointer;
`;
export const CloseButton = styled(StyledButton) `
  padding: 0;
`;
export const ActionWrapper = styled.div `
  display: flex;
  gap: ${theme.spaces.x2};
  justify-content: center;
  align-item: center;
`;
export const AbsoluteActionWrapper = styled(ActionWrapper) `
  position: absolute;
  top: ${theme.spaces.x2};
  right: ${theme.spaces.x2};
`;
export const StyledSuccessIcon = styled(StyledButton) `
  color: ${theme.colors.greenLight500};
`;
export const StyledViewPort = styled(Viewport) `
  list-style: none;
  position: relative;
  margin: 0;
  pointer-events: auto;
  padding: 0;
  ${({ isRelative, placement }) => (isRelative ? '' : containerStyle(placement))}
`;
export const StyledStack = styled(Stack) `
  position: relative;
`;
export const ContentWrapper = styled(Stack) `
  flex-grow: 1;
  max-height: 250px;
  overflow-y: auto;
  word-break: break-word;
`;
