import { authorizedFetch } from './autorizedFetch';

export async function makeRestCall(url: RequestInfo, requestConfig?: RequestInit) {
  const response = await authorizedFetch(url, requestConfig);

  let data;
  const clone = response.clone();
  try {
    data = await response.json();
  } catch (error) {
    data = await clone.text();
  }
  return data;
}
