import { jsx as _jsx } from "react/jsx-runtime";
import { StyledPanelCellRederer } from '../styled';
export const MonthCellRenderer = function ({ date, selectedDate, selectedRange, ...props }) {
    const month = date.toLocaleString('default', { month: 'long' });
    let selectedDateMonth;
    if (selectedRange) {
        let rangeIdx = props.info.range === 'start' ? 0 : 1;
        let d = selectedRange[rangeIdx];
        selectedDateMonth = (d || props.info.today).toLocaleString('default', { month: 'long' });
    }
    else {
        selectedDateMonth = (selectedDate || props.info.today).toLocaleString('default', { month: 'long' });
    }
    return _jsx(StyledPanelCellRederer, { selected: month === selectedDateMonth, children: month });
};
