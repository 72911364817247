import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getAllRowKeys, PATH_KEY } from '@prophecy/utils/nestedData';
import { useControlledState, useDeepCompareMemo, useLatestRef, usePersistentCallback } from '@prophecy/utils/react/hooks';
import RcTable from 'rc-table';
import { convertChildrenToColumns } from 'rc-table/es/hooks/useColumns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Empty } from '../Empty';
import { CaretRightIcon, XCloseIcon } from '../Icons';
import { evaluateViewBoxCalcExp, useBoxDimension, useBoxUnitWithBox } from '../ViewBox';
import { useObservableTable } from './BottomObserverElement';
import { useColumnFilters } from './ColumnFilters';
import { ResizableTableHeader, addResizableColumnHeaderProps } from './ColumnResize';
import { useColumnSorting } from './ColumnSort';
import { TableComponentContext, useTableComponents } from './components';
import { FocusableCells } from './FocusableCells';
import { useComputedValue, useDataWithRecordPath, useEllipsisInTable, usePlaceholderRows, useRowActions, useSearchStyleInCols, useTableReorder } from './hooks';
import { useTableRowSelection } from './rowSelection';
import { StyledTable, EmptyMessage, FULL_WIDTH_TR_CLS, SelectionActionsContainer, StyledSpinner } from './styled';
import { StyledExpandIcon, StyledIconButton, TableIconButton } from './TableIconButton';
import { ROW_MINIMUM_HEIGHT } from './tokens';
import { useVirtualization } from './VirtualizedTableComponents';
function ExpandIcon({ expanded, record, onExpand, expandable }) {
    if (!expandable)
        return null;
    return (_jsx(StyledExpandIcon, { className: `ui-table-row-expand-icon ui-table-row-expand-icon-${expanded ? 'expanded' : 'collapsed'}`, onClick: (e) => onExpand(record, e), icon: _jsx(CaretRightIcon, { type: 'extended', rotate: expanded ? 90 : 0 }) }));
}
export function Table({ className, style = {}, width = style.width, height = style.height, placeholderRows: _placeholderRows = 0, dataSource = [], columns, children, fixedHeader, showHeader = true, 
//Need to pass a unique key for each row data to support the virtualization
virtualize = false, 
//Need to pass unique key for each column and also should have specified width for each column
virtualizeColumns = false, virtualizeAfterHeight, reorder, buildNewRow, rowSelection, rowKey = 'key', components, loading = false, loadingMessage, showEmptyMessage = true, rowAction, expandable, rowHeight, getVirtualizationControls, expandIconColumnIndex = 0, sortConfig, maxIndent, showAddNewRowButton, newRowLabel = 'Row', onScroll, autoScrollOnAddition, measureRowHeights, searchProps, sticky, stickyHeader, ...tableProps }) {
    const all = dataSource.length;
    const placeholderRows = all > _placeholderRows ? 0 : _placeholderRows - all;
    const boxRef = useRef(null);
    const headerDim = useBoxDimension(() => boxRef.current?.querySelector('.ui-table-header'));
    const titleDim = useBoxDimension(() => boxRef.current?.querySelector('.ui-table-title'));
    const footerDim = useBoxDimension(() => boxRef.current?.querySelector('.ui-table-footer'));
    const defaultRowHeight = rowHeight ?? ROW_MINIMUM_HEIGHT;
    // when column virtualization is enabled the header is part of table, so no need to consider header height there.
    const defaultHeaderHeight = showHeader && !virtualizeColumns ? defaultRowHeight : 0;
    const headerHeight = showHeader ? headerDim?.height || defaultHeaderHeight : 0; // keep a default estimated value to minimize layout jump
    const footerHeight = footerDim?.height || 0; // possibly with no footer use 0
    const titleHeight = titleDim?.height || 0;
    const nonBodyHeight = 2 + headerHeight + footerHeight + titleHeight; // 2px for borders
    // remove border height, and header height from the table
    const tableBodyHeight = useBoxUnitWithBox(`100bh  - ${nonBodyHeight}px  `, () => boxRef.current) || '';
    const emptyBoxBodyHeight = useBoxUnitWithBox(`100bh - 2px - ${headerHeight + footerHeight + titleHeight}px - ${defaultRowHeight} `, () => boxRef.current) || '';
    const boxWidth = useBoxUnitWithBox('100bw', () => boxRef.current);
    // get columns from props or children
    const _columns = columns || convertChildrenToColumns(children);
    const { addSortingToColumns, sortDataSource, onHeaderClick } = useColumnSorting(_columns, sortConfig);
    const { addFiltersOnColumns, filterDataSource } = useColumnFilters({
        columns: _columns,
        onHeaderClick,
        container: boxRef
    });
    const { columns: columnsWithRowSelection, selectionState } = useTableRowSelection(rowSelection, _columns, dataSource, rowKey);
    const [showSelectionActions, toggleSelectionActions] = useState(false);
    const selectionActions = useDeepCompareMemo(() => {
        return rowSelection?.selectedRowKeys &&
            rowSelection?.selectedRowKeys.length > 0 &&
            rowSelection?.renderSelectActions
            ? rowSelection.renderSelectActions(selectionState)
            : null;
    }, [rowSelection, selectionState]);
    useEffect(() => {
        toggleSelectionActions(Boolean(selectionActions));
    }, [selectionActions]);
    const sortedDataSource = sortDataSource(dataSource);
    const filteredDataSource = filterDataSource(sortedDataSource);
    // if data is filtered don't allow reorder, as it can be have unexpected results
    let _reorder = filteredDataSource.length !== dataSource.length ? undefined : reorder;
    const { columns: columnsWithReorder, getReorderProps, reorderInProgress } = useTableReorder({
        reorder: _reorder,
        columns: columnsWithRowSelection,
        dataSource: filteredDataSource,
        selectionState
    });
    const { getRowActionProps, rowActionButton } = useRowActions(boxRef, rowAction);
    const creatableDataSource = useMemo(() => {
        const newRowPath = `[${dataSource.length}]`;
        /**
         * buildNewRow can also return row with PATH_KEY, in such case give preference to what's coming from buildNewRow,
         * as it has more accurate data source information
         */
        return buildNewRow
            ? [...filteredDataSource, { [PATH_KEY]: newRowPath, ...buildNewRow(newRowPath) }]
            : filteredDataSource;
    }, [buildNewRow, dataSource.length, filteredDataSource]);
    const currentKeys = useMemo(() => getAllRowKeys(filteredDataSource, rowKey), [filteredDataSource, rowKey]);
    const originalKeys = useMemo(() => getAllRowKeys(dataSource, rowKey), [dataSource, rowKey]);
    //Expand rows by default if search is applied
    const hasSearchApplied = originalKeys.length > currentKeys.length;
    const [expandedKeys = [], setExpandedKeys] = useControlledState({
        defaultValue: expandable?.defaultExpandedRowKeys,
        value: expandable?.expandedRowKeys,
        onChange: expandable?.onExpandedRowsChange
    });
    const expandedKeysRef = useLatestRef(expandedKeys);
    useEffect(() => {
        if (expandedKeysRef.current.join() !== currentKeys.join() && hasSearchApplied) {
            setExpandedKeys(currentKeys);
        }
    }, [expandedKeysRef, currentKeys, setExpandedKeys, hasSearchApplied]);
    const dataSourceWithRecordPath = useDataWithRecordPath(creatableDataSource);
    const columnsWithExpandedRow = columnsWithReorder;
    const { columns: patchedColumns, dataSource: patchedDataSource, getPlaceholderRowProps } = usePlaceholderRows(placeholderRows, columnsWithExpandedRow, dataSourceWithRecordPath, rowKey);
    if (_reorder) {
        expandIconColumnIndex += 1;
    }
    if (rowSelection) {
        expandIconColumnIndex += 1;
    }
    let emptyMessage = null;
    const columnsWithEllipsis = useEllipsisInTable(patchedColumns);
    const columnsWithSorting = addSortingToColumns(columnsWithEllipsis);
    const columnsWithFilters = addFiltersOnColumns(columnsWithSorting);
    const columnsWithComputedValue = useComputedValue(columnsWithFilters);
    const columnsWithResizableHeaders = addResizableColumnHeaderProps(columnsWithComputedValue);
    const columnsWithSearchStyles = useSearchStyleInCols(columnsWithResizableHeaders, defaultRowHeight, searchProps);
    if (showEmptyMessage && patchedDataSource.length === 0) {
        const emptyProps = typeof showEmptyMessage === 'object' ? showEmptyMessage : {};
        emptyMessage = (_jsx(EmptyMessage, { alignY: 'center', align: 'center', style: { '--height': emptyBoxBodyHeight }, children: _jsx(Empty, { ...emptyProps }) }));
    }
    let heightInPx = evaluateViewBoxCalcExp(tableBodyHeight);
    if (showEmptyMessage && patchedDataSource.length === 0) {
        heightInPx = Math.max(heightInPx, 200);
    }
    if (virtualizeAfterHeight) {
        heightInPx = virtualizeAfterHeight - nonBodyHeight;
    }
    const scrollProp = fixedHeader || stickyHeader || virtualize || virtualizeAfterHeight !== undefined ? { y: heightInPx } : undefined;
    const _components = useTableComponents(components);
    const _virtualize = Boolean(virtualize || virtualizeAfterHeight);
    const { virtualizedComponents, VirtualContainer } = useVirtualization({
        virtualize: _virtualize,
        virtualizeColumns,
        bodyHeight: heightInPx,
        rowHeight: defaultRowHeight,
        rowKey,
        getVirtualizationControls,
        onScroll,
        components: _components,
        measureRowHeights,
        columns: columnsWithSearchStyles,
        data: patchedDataSource,
        expandedKeys,
        containerRef: boxRef,
        focusedCellId: searchProps ? searchProps.searchMatchedCells[searchProps.focusedCellMatchIndex] : undefined
    });
    const _tableComponents = _virtualize ? virtualizedComponents : _components;
    const { table, addButton } = useObservableTable({
        dataSource,
        showAddNewRowButton,
        rowHeight: defaultRowHeight,
        newRowLabel,
        autoScrollOnAddition,
        placeholderRows,
        tableComponent: _tableComponents.table,
        boxRef
    });
    const headerCell = usePersistentCallback((cellProps) => {
        return (_jsx(ResizableTableHeader, { ...cellProps, columns: columnsWithSearchStyles, CellComponent: _tableComponents.header?.cell }));
    });
    // do not update reference of components object on every render
    const tableComponents = useMemo(() => {
        return {
            ..._tableComponents,
            table,
            header: {
                ..._tableComponents.header,
                cell: headerCell
            }
        };
    }, [_tableComponents, table, headerCell]);
    const onRow = usePersistentCallback((row, rowIndex) => {
        const rowProps = {
            expandIconColumnIndex,
            'data-row-index': rowIndex,
            'data-test-id': rowIndex === dataSource.length && buildNewRow ? 'newRow' : `row-${rowIndex}`,
            'data-new-row': Boolean(rowIndex === dataSource.length && buildNewRow),
            ...tableProps.onRow?.(row, rowIndex),
            ...getReorderProps(row), // we don't have nested columns so rowIndex will always be there
            ...getPlaceholderRowProps(row),
            ...getRowActionProps(row)
        };
        return {
            RowComponent: row.RowComponent,
            className: row.RowComponent ? `${FULL_WIDTH_TR_CLS} ${rowProps.className || ''}` : rowProps.className,
            ...rowProps
        };
    });
    const expandableProps = useMemo(() => {
        return {
            indentSize: 23,
            ...expandable,
            expandedRowKeys: expandedKeys,
            onExpandedRowsChange: setExpandedKeys,
            expandIconColumnIndex,
            childrenColumnName: 'children',
            expandIcon: ExpandIcon
        };
    }, [expandable, expandedKeys, expandIconColumnIndex, setExpandedKeys]);
    // if virtualizeColumn is set, we always fallback to sticky header
    const _stickyHeader = Boolean(virtualizeColumns || stickyHeader);
    return (_jsx(TableComponentContext.Provider, { value: components, children: _jsxs(StyledSpinner, { ref: boxRef, spinning: loading, tip: loadingMessage, style: {
                ...style,
                width,
                height,
                maxHeight: virtualizeAfterHeight ?? style.maxHeight,
                pointerEvents: 'initial'
            }, className: className, children: [_jsx(VirtualContainer, { children: _jsx(FocusableCells, { containerRef: boxRef, focusedCellId: searchProps?.searchMatchedCells[searchProps?.focusedCellMatchIndex - 1 || 0], children: _jsx(StyledTable, { ...tableProps, showHeader: showHeader, rowKey: rowKey, prefixCls: 'ui-table', dynamicVirtualization: virtualizeAfterHeight !== undefined, hasRowSelection: !!selectionActions, style: { '--width': boxWidth }, maxIndent: maxIndent, expandable: expandableProps, columns: columnsWithResizableHeaders, data: patchedDataSource, scroll: tableProps.scroll || scrollProp, emptyText: emptyMessage, components: tableComponents, 
                            // disable rc-table sticky prop in case of css based sticky header is enabled
                            sticky: _stickyHeader ? false : sticky, onRow: onRow }) }) }), showSelectionActions && (_jsxs(SelectionActionsContainer, { direction: 'horizontal', align: 'space-between', alignY: 'center', children: [selectionActions, _jsx(StyledIconButton, { variant: 'linkGray', size: 'm', tooltip: 'Close selected actions and show columns', icon: _jsx(XCloseIcon, { type: 'default' }), onClick: () => toggleSelectionActions(false) })] })), reorderInProgress ? null : rowActionButton, reorderInProgress ? null : addButton] }) }));
}
// add column and column group to table
//TODO type fix for rcTable column to support filter and sort
Table.Column = RcTable.Column;
Table.ColumnGroup = RcTable.ColumnGroup;
Table.IconButton = TableIconButton;
