import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { theme } from '../theme';
import { Stack } from './Stack';
export const Container = styled.div `
  width: 100%;
  max-width: 1280px;
  overflow-x: auto;
  height: 100%;
  cursor: default;
  ${({ noPadding }) => (noPadding ? '' : `padding: ${theme.spaces.x40};`)}
  @media (max-width: 1280px) {
    max-width: 100%;
    ${({ noPadding }) => (noPadding ? '' : `padding: ${theme.spaces.x24};`)}
  }
`;
export const HeaderHeight = '44px';
export const FooterHeight = '30px';
export const SIDE_NAVBAR_WIDTH = '50px';
export const ContainerPadding = theme.spaces.x32;
const ContainerWrapper = styled.div `
  display: flex;
  justify-content: center;
  overflow: auto;
`;
const Container1 = styled(Stack) `
  width: calc(100% - ${SIDE_NAVBAR_WIDTH});
  min-width: calc(${theme.newBreakpoints.m} - ${SIDE_NAVBAR_WIDTH});
  max-width: calc(${theme.newBreakpoints.xl} - ${SIDE_NAVBAR_WIDTH});
  padding: ${theme.spaces.x40} ${ContainerPadding} ${ContainerPadding};
  margin-left: auto;
  margin-right: auto;
`;
const GridContainer = styled.div `
  display: grid;
  overflow: hidden;
  height: 100%;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: ${({ hasHeader }) => (hasHeader ? HeaderHeight : '')} minmax(0, 1fr) ${({ hasFooter, footerHeight }) => (hasFooter ? footerHeight || FooterHeight : '')};
  background: ${({ background }) => background ?? theme.colors.gray25};
`;
const FooterRoot = styled.div `
  background: ${({ footerBackground }) => footerBackground || theme.colors.gray50};
  width: 100%;
  ${({ footerBackground }) => (footerBackground ? `border-top: 1px solid ${theme.colors.gray200}` : '')}
`;
export const HeaderRoot = styled(Stack) `
  background: ${theme.colors.gray50};
  border: 1px solid ${theme.colors.gray200};
`;
export function MetadataPageContainer({ main, header, footer, hasLayoutContainer = true, footerBackground, background, ...rest }) {
    const hasFooter = Boolean(footer);
    const hasHeader = Boolean(header);
    return (_jsxs(GridContainer, { hasFooter: hasFooter, hasHeader: hasHeader, background: background, ...rest, children: [hasHeader ? (_jsx(HeaderRoot, { padding: `0 ${theme.spaces.x12} 0 ${theme.spaces.x8}`, direction: 'horizontal', alignY: 'center', align: 'space-between', width: '100%', children: header })) : null, hasLayoutContainer ? (_jsx(ContainerWrapper, { children: _jsx(Container1, { height: '100%', children: main }) })) : (main), hasFooter ? _jsx(FooterRoot, { footerBackground: footerBackground, children: footer }) : null] }));
}
