import { createElement as _createElement } from "react";
import { useControlledState, useLazyEffect, usePersistentCallback } from '@prophecy/utils/react/hooks';
import { clamp, isNil } from 'lodash-es';
import { nanoid } from 'nanoid';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Input } from './Input';
export const NumberInput = forwardRef(({ onChange, min, max, onBlur, onPressEnter, value, defaultValue, requiredMin, reactiveMinMax = true, allowDecimal = true, allowNegative = true, ...restProps }, inRef) => {
    const ref = useRef(null);
    useImperativeHandle(inRef, () => ref.current, []);
    // TODO: this is a hack to reset the local state of the Input component as in Input component
    // we do not use the useControlledState
    const [renderId, setRenderId] = useState(() => nanoid());
    const [state = '', setState] = useControlledState({
        value,
        defaultValue,
        onChange: (value) => {
            const _value = Number(value);
            if (!isNil(min) && _value < min)
                return;
            if (!isNil(max) && _value > max)
                return;
            onChange?.(value);
        }
    });
    const handleMinMax = usePersistentCallback((value) => {
        if (value === '') {
            if (requiredMin && !isNil(min)) {
                setState?.(min);
                setRenderId(nanoid());
            }
            return;
        }
        let _value = Number(value);
        const _min = Math.min(min ?? Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
        const _max = Math.max(max ?? Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);
        const clampedValue = clamp(_value, _min, _max);
        if (clampedValue !== _value) {
            //trigger on change if value is updated
            setState?.(clampedValue);
            setRenderId(nanoid());
        }
    });
    function handleChange(_value) {
        const val = _value === '' ? undefined : Number(_value);
        if (requiredMin && !isNil(min) && val === undefined)
            return;
        if (val !== value) {
            setState?.(val);
        }
    }
    useLazyEffect(() => {
        if (reactiveMinMax && ref.current !== document.activeElement) {
            handleMinMax(ref.current?.value || '');
        }
    }, [min, max]);
    return (_createElement(Input, { ...restProps, value: state, ref: ref, onBlur: (e) => {
            handleMinMax(e.currentTarget.value);
            onBlur?.(e);
        }, onPressEnter: (e) => {
            handleMinMax(e.currentTarget.value);
            onPressEnter?.(e);
        }, onKeyDown: (e) => {
            if ((!allowDecimal && e.key === '.') || (!allowNegative && e.key === '-')) {
                e.preventDefault();
            }
            restProps.onKeyDown?.(e);
        }, key: renderId, type: 'number', onChange: handleChange }));
});
