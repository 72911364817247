import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, forwardRef, useContext, useMemo, useRef } from 'react';
import { Button } from '../Button';
import { CaretDownIcon, CaretUpIcon } from '../Icons';
import { StyledCellRoot } from './styled';
import { TABLE_CELL_CLASS } from './tokens';
export const CUSTOM_ROW_COMPONENT_CLASS = 'ui-table-custom-row';
export const TableRow = forwardRef((rowProps, ref) => {
    const props = getRowComponentProps(rowProps);
    const components = useContext(TableComponentContext);
    const TRElm = components?.body?.row || 'tr';
    return _jsx(TRElm, { ref: ref, ...props });
});
export const TableComponentContext = createContext(undefined);
function getRowComponentProps({ children, RowComponent, ...rest }) {
    return {
        ...rest,
        children: RowComponent ? (_jsx("td", { className: `${TABLE_CELL_CLASS} ${CUSTOM_ROW_COMPONENT_CLASS}`, colSpan: React.Children.count(children), children: RowComponent })) : (children)
    };
}
export function useTableComponents(components) {
    const componentsRef = useRef(components);
    componentsRef.current = components;
    const tableComponents = useMemo(() => ({
        table: componentsRef.current?.table,
        header: componentsRef.current?.header,
        body: {
            wrapper: componentsRef.current?.body?.wrapper,
            row: TableRow,
            cell: componentsRef.current?.body?.cell
        }
    }), []);
    return tableComponents;
}
export const WrappedTableCell = ({ children, ...restProps }) => {
    return (_jsx("td", { ...restProps, children: _jsx(StyledCellRoot, { direction: 'horizontal', alignY: 'center', width: '100%', height: '100%', children: children }) }));
};
export const TableRowExpandButton = ({ expanded, ...rest }) => {
    return (_jsx(Button, { ...rest, variant: 'secondaryGray', size: 's', icon: expanded ? _jsx(CaretUpIcon, { type: 'extended' }) : _jsx(CaretDownIcon, { type: 'extended' }) }));
};
