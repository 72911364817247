import styled, { createGlobalStyle } from 'styled-components';
import { Stack } from '../Layout';
import { Select } from '../Select';
import { SELECT_ITEM_OPTION_CONTENT_CLASS, SELECT_SELECTION_OVERFLOW_CLASS } from '../Select/tokens';
import { theme } from '../theme';
import { itemStyles } from './../Dropdown/styled';
import { tokens } from './tokens';
export const StyledCronRoot = styled.div `
  padding: ${theme.outlineWidth} 0;
`;
export const ReadOnlyValue = styled.div `
  position: absolute;
  bottom: ${tokens.CronInput.ReadOnlyValue.bottom};
  left: ${tokens.CronInput.ReadOnlyValue.left};
  color: ${tokens.CronInput.ReadOnlyValue.color};
  font-size: ${tokens.CronInput.ReadOnlyValue.fontSize};
`;
export const CronGeneratorLink = styled.a `
  &,
  &:visited,
  &:hover,
  &:active {
    text-decoration: underline;
    color: ${theme.colors.gray500};
  }
`;
export const StyledCronGenerator = styled(Stack) `
  font-family: ${theme.fontFamily.text};
`;
export const Option = styled.button `
  all: unset;
  ${itemStyles};
  box-sizing: border-box;
`;
export const RangeSelector = styled(Select) `
  .${SELECT_SELECTION_OVERFLOW_CLASS} {
    gap: 0;
  }
`;
export const CronSelectDropdownStyles = createGlobalStyle `
  .ui-cron-center-aligned-content {
    .${SELECT_ITEM_OPTION_CONTENT_CLASS} {
      text-align: center;
    }
  }
`;
