import styled, { css } from 'styled-components';
import { ICON_CLASS } from '../../Icons/tokens';
import { Stack } from '../../Layout';
import { theme, hexToRGBA, resolveCssVariable } from '../../theme';
import { tokens } from './tokens';
import { Variant } from './types';
const createGradientBorder = (color) => `linear-gradient(180deg, 
    ${hexToRGBA(resolveCssVariable(color), 0.85)}, 
    ${hexToRGBA(resolveCssVariable(color), 1)})`;
const getColorVariable = (color, shade) => {
    if (shade === '0')
        return `var(--var-colors-white)`;
    return `var(--var-colors-${color}${shade})`;
};
const plainColorStyle = css `
  background: ${theme.colors.transparent};
  .${ICON_CLASS} {
    ${({ $color, $variant }) => $variant === Variant.gray
    ? `color: ${theme.colors.gray500};`
    : $variant === Variant.singleColor
        ? `color: ${getColorVariable($color, tokens.colorShade.color)};`
        : `color: ${getColorVariable($color, tokens.colorShade.color)};`}
  }
`;
const boxLightStyle = css `
  background: ${({ $color }) => getColorVariable($color, tokens.colorShade.backgroundType.light.background)};
  outline-offset: -1px;
  outline: 1px solid ${({ $color }) => getColorVariable($color, tokens.colorShade.backgroundType.light.borderColor)};
  box-shadow: ${tokens.BoxShadowLightBox};

  .${ICON_CLASS} {
    color: ${({ $color }) => getColorVariable($color, tokens.colorShade.backgroundType.light.color)};
  }
`;
const boxDarkStyle = css `
  background: ${({ $color }) => getColorVariable($color, tokens.colorShade.backgroundType.dark.background)};

  ${({ $variant, $color, $size }) => $variant !== Variant.gray &&
    `
    &::before {
        box-sizing: border-box;
        content: '';
        position: absolute;
        inset: -1px;
        border-radius: calc(${tokens.Size[$size].borderRadius} + 1px);
        background: ${createGradientBorder(getColorVariable($color, tokens.colorShade.backgroundType.dark.background))};
        box-shadow: ${tokens.BoxShadowDarkBox};
        z-index: -1;
    }
    `}

  .${ICON_CLASS} {
    color: ${theme.colors.white};
  }
`;
// Main Styled Component
export const StyledEntityIcon = styled(Stack) `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: ${({ $size }) => tokens.Size[$size].padding};
  border-radius: ${({ $size }) => tokens.Size[$size].borderRadius};
  width: ${({ $size }) => tokens.Size[$size].containerSize};
  height: ${({ $size }) => tokens.Size[$size].containerSize};

  .${ICON_CLASS} {
    width: ${({ $size }) => tokens.Size[$size].iconSize};
    height: ${({ $size }) => tokens.Size[$size].iconSize};
    color: ${({ $color }) => getColorVariable($color, tokens.colorShade.color)};

    path {
      &[stroke]:not([fill]) {
        ${({ $variant, $color }) => ($variant === Variant.dualColor ? `fill: ${getColorVariable($color, '50')};` : '')}
      }

      &[stroke] {
        stroke-width: ${({ $size }) => tokens.Size[$size].iconStrokeWidth};
      }
    }
  }

  ${({ $variant }) => {
    if ($variant === Variant.gray || $variant === Variant.singleColor || $variant === Variant.dualColor)
        return plainColorStyle;
    if ($variant === Variant.boxLight)
        return boxLightStyle;
    if ($variant === Variant.boxDark)
        return boxDarkStyle;
}}
`;
