import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Stack } from '../Layout';
import { Select } from '../Select';
import { Text } from '../Typography/Text';
import { TimePicker } from './TimePicker';
import { tokens } from './tokens';
import { Hours24, getCronDefaults } from './utils';
const StyledTimePicker = styled(TimePicker) `
  width: 180px;
`;
export function HourTab({ cronType, cronValue, setCronValue, disabled }) {
    const hourStep = cronValue.hours?.every || 1;
    let hours = cronValue.hours?.start || 0;
    let minutes = cronValue.minutes || 0;
    // if hours and minutes are on different format * or range, reset it to 0,0
    if (typeof hours !== 'number')
        hours = 0;
    if (typeof minutes !== 'number')
        minutes = 0;
    const cronDefaults = getCronDefaults(cronType);
    const handleHourChange = (value) => {
        // reset all other fields and set hour and minute
        setCronValue({
            ...cronDefaults,
            hours: { every: value, start: hours },
            minutes: minutes
        });
    };
    const handleTimeChange = ([hours, minutes]) => {
        // reset all other fields and set hour and minute
        setCronValue({
            ...cronDefaults,
            hours: { every: hourStep, start: hours },
            minutes: minutes
        });
    };
    return (_jsxs(Stack, { direction: 'horizontal', alignY: 'center', gap: tokens.CronGenerator.Fields.gap, children: [_jsx(Text, { level: 'sm', children: "Repeat every" }), _jsx(Select, { value: hourStep, options: Hours24.slice(1).map((hour) => ({ label: hour, value: hour })), onChange: handleHourChange, disabled: disabled }), _jsx(Text, { level: 'sm', children: "hour(s), from" }), _jsx(StyledTimePicker, { value: [hours, minutes], onChange: handleTimeChange })] }));
}
