import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { insertNodes, isUrl } from '@udecode/plate-common';
import { useEditorRef } from '@udecode/plate-common/react';
import { ImagePlugin } from '@udecode/plate-media/react';
import { useState } from 'react';
import { Dialog } from '../Dialog';
import { MarkdownImageIcon } from '../Icons';
import { Input } from '../Input';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { toast } from '../Toast';
import { StyledButton } from './styled';
function ImageUrlDialog({ open, onClose }) {
    const editor = useEditorRef();
    const [url, setUrl] = useState('');
    const [caption, setCaption] = useState('');
    const embedMedia = () => {
        if (!isUrl(url))
            return toast.error({ content: 'Invalid URL' });
        onClose();
        insertNodes(editor, {
            children: [{ text: '' }],
            name: undefined,
            type: ImagePlugin.key,
            alt: caption,
            url
        });
        setUrl('');
        setCaption('');
    };
    return (_jsx(Dialog, { open: open, onClose: onClose, onOk: embedMedia, size: 'l', title: 'Insert Image', okButton: {
            children: 'Insert'
        }, children: _jsxs(Stack, { padding: `${theme.spaces.x12} ${theme.outlineWidth}`, gap: theme.spaces.x12, children: [_jsx(Input, { label: 'Image URL', iconPlacement: 'left', placeholder: 'https://example.com/image.png', value: url, onChange: (url) => setUrl(url), onPressEnter: embedMedia }), _jsx(Input, { label: 'Image Caption', placeholder: 'Description of the image', value: caption, onChange: (caption) => setCaption(caption) })] }) }));
}
export function InsertImageButton() {
    const [open, setOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(StyledButton, { variant: 'tertiaryGray', size: 'm', icon: _jsx(MarkdownImageIcon, { type: 'extended' }), onClick: () => setOpen(true) }), _jsx(ImageUrlDialog, { open: open, onClose: () => setOpen(false) })] }));
}
