import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useControlledState, useDebounce, usePersistentCallback } from '@prophecy/utils/react/hooks';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { Dropdown } from '../Dropdown';
import { Ellipsis } from '../Ellipsis';
import { Stack } from '../Layout';
import { StyledBreadcrumbContainer, StyledInput, StyledButton, StyledChevronRightIcon, StyledEditIcon, StyledLabel, StyledCrumbWrapper, StyledChevronDownIcon, DropdownOverlayStyle } from './styled';
import { Variant, buttonVariant } from './types';
export const CRUMB_ATTR = {
    Index: 'data-crumb-index'
};
// A long delay so onEdit is only called when user blurs from the input
export const DELAY_EDIT_ON_BLUR = Number.MAX_SAFE_INTEGER;
function Crumb({ editable, variant = Variant.text, children, value, editableLabel = children, index, onEdit, active, onSelect, editDelay, ...restProps }) {
    const inputRef = useRef(null);
    const [isEdit, setIsEdit] = useState(false);
    const containerRef = useRef(null);
    const handleExitEdit = () => {
        setIsEdit(false);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleExitEdit();
        }
    };
    const handleEditClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.nativeEvent.pressure <= 1) {
            // Only trigger for normal clicks
            setIsEdit(true);
        }
    };
    const handleClickOutside = usePersistentCallback((e) => {
        if (!containerRef.current?.contains(e.target)) {
            handleExitEdit();
        }
    });
    /*
    Timeout needed: Focus shifts before element updates on outside clicks,
    causing inconsistent event application. so we changing it event loop priority by adding event listener
    */
    useEffect(() => {
        if (isEdit) {
            const timeoutId = setTimeout(() => {
                document.addEventListener('mousedown', handleClickOutside);
            }, 0);
            return () => {
                clearTimeout(timeoutId);
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [handleClickOutside, isEdit]);
    const attr = { [CRUMB_ATTR.Index]: index };
    return (_jsxs(Stack, { ref: containerRef, children: [!isEdit && (_jsx(StyledButton, { ...attr, disablePointerFocus: true, onClick: () => {
                    onSelect?.(value);
                }, size: 'xs', variant: buttonVariant[variant], "$variant": variant, ...restProps, iconPlacement: 'left', active: active === value, children: children ? (_jsxs(Stack, { direction: 'horizontal', alignY: 'center', align: 'space-between', children: [_jsx(StyledLabel, { children: _jsx(Ellipsis, { tooltip: true, title: children, children: children }) }), editable && (_jsx(StyledEditIcon, { type: 'solid', "$variant": variant, onClick: handleEditClick, onPointerDown: (e) => e.preventDefault() }))] })) : null })), isEdit && (_jsx(StyledInput, { ref: inputRef, value: editableLabel, onChange: (editedValue) => onEdit?.(editedValue, index), onKeyDown: handleKeyDown, delay: editDelay, autoFocus: true }))] }));
}
const BreadcrumbIcon = ({ single, ...props }) => single ? _jsx(StyledChevronDownIcon, { ...props, type: 'default' }) : _jsx(StyledChevronRightIcon, { ...props, type: 'default' });
const renderCrumbs = ({ onSelect, crumbs = [], children = [], variant = Variant.text, separator, active, visibleIndex, setVisibleIndex }) => {
    // Handle both crumbs array and React children
    const items = crumbs.length ? crumbs : children;
    const length = items.length;
    const defaultSeparator = _jsx(BreadcrumbIcon, { single: length === 1 });
    // Set first item as active by default if there's only one item and no active item set
    const activeValue = length === 1 && !active ? (crumbs.length ? crumbs[0].value : children[0]?.props?.value) : active;
    const renderItem = (item, i) => {
        const itemProps = crumbs.length
            ? item
            : item.props;
        const showSingleCrumbDropdown = length === 1 && itemProps.menu;
        const sep = separator || defaultSeparator;
        if (itemProps.menu) {
            return (_jsx(Dropdown, { overlay: itemProps.menu, overlayStyle: DropdownOverlayStyle, visible: visibleIndex === i, onVisibleChange: (visible) => {
                    if (visible) {
                        setVisibleIndex(i);
                    }
                    else {
                        setVisibleIndex(-1);
                    }
                }, onCloseAutoFocus: (e) => {
                    e.preventDefault();
                }, children: _jsxs(StyledCrumbWrapper, { children: [_jsx(Crumb, { ...itemProps, onSelect: onSelect, active: activeValue, index: i, variant: variant }), showSingleCrumbDropdown ? sep : i < length - 1 && sep] }) }, i));
        }
        return (_jsxs(StyledCrumbWrapper, { children: [_jsx(Crumb, { ...itemProps, onSelect: onSelect, active: activeValue, index: i, variant: variant }), showSingleCrumbDropdown ? sep : i < length - 1 && sep] }, i));
    };
    return crumbs.length ? items.map(renderItem) : React.Children.map(items, renderItem);
};
function useCrumbEllipsis({ children, crumbs }) {
    const containerRef = useRef(null);
    const crumbsLength = children ? children.length : crumbs.length;
    const [crumbsToHide, setCrumbsToHide] = useState(0);
    const crumbsWidths = useRef([]);
    const calculateCrumbsWidth = usePersistentCallback(() => {
        const container = containerRef.current;
        if (!container)
            return;
        crumbsWidths.current = Array.from(container.children).map((child, i) => {
            return child.offsetWidth;
        });
    });
    const hideCrumbs = usePersistentCallback(() => {
        const container = containerRef.current;
        const _crumbsWidth = crumbsWidths.current;
        if (!container || !_crumbsWidth.length)
            return;
        const containerWidth = container.offsetWidth;
        const lastElementWidth = _crumbsWidth[crumbsLength - 1] || 0;
        const firstElementWidth = _crumbsWidth[0];
        // for auto growing parent based on children width. if all children is fitting well, then no need to hide anything
        const totalCrumbsWidth = _crumbsWidth.reduce((a, b) => a + b, 0);
        if (totalCrumbsWidth < containerWidth) {
            setCrumbsToHide(0);
            return;
        }
        let occupiedWidth = 0;
        /**
         * We always need to show first and last element, and the ellipsis in between them (45px)
         * So we remove all of there width from container width to figure available space
         */
        const availableWidth = containerWidth - firstElementWidth - lastElementWidth - 45;
        if (availableWidth < 0) {
            /*
            Case when no space is available after accommodating   firstElementWidth+lastElementWidth+45 .
            In such case, show the last element only.
            */
            setCrumbsToHide(crumbsLength - 1);
            return;
        }
        else {
            // find how many elements can fit in available space, and hide the rest
            for (let i = 1; i < crumbsLength - 1; i++) {
                const crumbWidth = _crumbsWidth[i];
                occupiedWidth = occupiedWidth + crumbWidth;
                if (occupiedWidth > availableWidth) {
                    setCrumbsToHide(crumbsLength - i);
                    return;
                }
            }
        }
        setCrumbsToHide(0);
    });
    const debouncedHideCrumbs = useDebounce(() => {
        // first show all crumbs so auto parent taking content width can stretch as much as possible and then add ellipsis in middle to fit, extra crumbs
        setCrumbsToHide(0);
        flushSync(() => { });
        hideCrumbs();
    }, 100);
    useEffect(() => {
        // update crumbs on window resize
        window.addEventListener('resize', debouncedHideCrumbs);
        return () => window.removeEventListener('resize', debouncedHideCrumbs);
    }, [debouncedHideCrumbs]);
    useLayoutEffect(() => {
        // to calculate the width of the crumbs, if they have changed, we need to render them first
        if (crumbsWidths.current.length && crumbsWidths.current.length !== crumbsLength) {
            setCrumbsToHide(0);
            flushSync(() => { });
        }
        // recalculate the width of the crumbs, if we haven't calculated them already
        calculateCrumbsWidth();
        hideCrumbs();
    }, [calculateCrumbsWidth, crumbsLength, hideCrumbs]);
    return { containerRef, crumbsToHide };
}
export const Breadcrumb = (props) => {
    const { onSelect, crumbs = [], active, defaultActive, children, separator = _jsx(BreadcrumbIcon, { single: crumbs.length === 1 }), ...restProps } = props;
    const [currentCrumb, setActiveValue] = useControlledState({
        value: active,
        defaultValue: defaultActive,
        onChange: onSelect
    });
    const { containerRef, crumbsToHide } = useCrumbEllipsis({ children, crumbs });
    const [visibleIndex, setVisibleIndex] = useState(-1);
    const currentProps = { ...props, active: currentCrumb, onSelect: setActiveValue, visibleIndex, setVisibleIndex };
    let crumbsElements = renderCrumbs(currentProps);
    const crumbsLength = crumbsElements.length - 1;
    if (crumbsToHide > 0) {
        const crumbsToRender = crumbsLength === crumbsToHide && crumbsLength > 0 ? [] : crumbsElements.slice(0, crumbsLength - crumbsToHide);
        crumbsToRender.push(_jsxs(StyledCrumbWrapper, { children: [_jsx("span", { children: "..." }), separator] }), crumbsElements[crumbsLength]);
        crumbsElements = crumbsToRender;
    }
    return (_jsx(StyledBreadcrumbContainer, { ref: containerRef, ...restProps, direction: 'horizontal', alignY: 'center', children: crumbsElements }));
};
Breadcrumb.Crumb = Crumb;
Breadcrumb.Icon = BreadcrumbIcon;
