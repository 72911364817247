import { tokens as dialogTokens } from '../Dialog/tokens';
import { theme } from '../theme';
export const tokens = {
    CronInput: {
        ReadOnlyValue: {
            color: theme.colors.gray500,
            bottom: dialogTokens.Dialog.Content.padding,
            left: dialogTokens.Dialog.Content.padding,
            fontSize: theme.fontSizes.x13
        }
    },
    CronGenerator: {
        gap: theme.spaces.x24,
        Category: {
            gap: theme.spaces.x8,
            backgroundColor: theme.colors.gray50
        },
        Fields: {
            gap: theme.spaces.x12
        }
    },
    TimePicker: {
        verticalGap: theme.spaces.x12,
        horizontalGap: theme.spaces.x8
    },
    DatePicker: {
        Option: {
            gap: theme.spaces.x2
        }
    }
};
