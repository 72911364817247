import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import RCTree from 'rc-tree';
import { useEffect, useRef, useState } from 'react';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { Ellipsis } from '../Ellipsis';
import { ChevronRightIcon } from '../Icons';
import { resolveCssVariable } from '../theme';
import { useBoxDimension } from '../ViewBox';
import { StyledTree, StyledTitleWrap, StyledTitle, StyledTitleInfo } from './styled';
import { TREE_CHECKBOX_CHECKED_CLASS, TREE_CHECKBOX_CLASS, TREE_CHECKBOX_DISABLED_CLASS, TREE_CHECKBOX_INDETERMINATE_CLASS, TREE_NODE_CLASS, TREE_SWITCHER_CLASS, tokens } from './tokens';
function TreeCheckbox({ variant }) {
    const ref = useRef(null);
    const [{ checked, indeterminate, disabled }, setSelectCheckboxState] = useState({
        checked: false,
        indeterminate: false,
        disabled: false
    });
    /**
     * Note: RC tree doesn't allow changing checkbox element, instead it renders
     * an element with classnames, to control the style using classnames.
     * This is a hack to get the checked state from its parent element.
     *
     * We want to call this effect every time it render's so we can capture the side-effect of className
     * The infinite setState check is done manually. That's whys eslint is disabled.
     */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    useEffect(() => {
        const rcTreeCheckbox = ref.current?.closest(`.${TREE_CHECKBOX_CLASS}`);
        const _checked = Boolean(rcTreeCheckbox?.classList.contains(TREE_CHECKBOX_CHECKED_CLASS));
        const _indeterminate = Boolean(rcTreeCheckbox?.classList.contains(TREE_CHECKBOX_INDETERMINATE_CLASS));
        const _disabled = Boolean(rcTreeCheckbox?.classList.contains(TREE_CHECKBOX_DISABLED_CLASS));
        if (_checked !== checked || _indeterminate !== indeterminate || _disabled !== disabled) {
            setSelectCheckboxState({ checked: _checked, indeterminate: _indeterminate, disabled: _disabled });
        }
    });
    return (_jsx("div", { ref: ref, children: _jsx(Checkbox, { checked: checked, indeterminate: indeterminate, disabled: disabled }) }));
}
const useActiveKey = (key) => {
    const [activeKey, setActiveKey] = useState();
    useEffect(() => {
        setActiveKey(key);
        // set activeKey on initial load only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (activeKey)
            setActiveKey(undefined);
    }, [activeKey]);
    return activeKey;
};
export function Tree({ checkable = false, selectable = false, virtual, checkedKeys, defaultCheckedKeys, onCheck, className, style, variant, treeData, initialActiveKey, titleRender, treeRef, ...restProps }) {
    const activeKey = useActiveKey(virtual ? initialActiveKey : undefined);
    const [_checkedKeys, updatedCheckedKeys] = useControlledState({
        value: checkedKeys,
        defaultValue: defaultCheckedKeys,
        onChange: (checkedKeys, checkedInfo) => {
            onCheck?.(checkedKeys, checkedInfo);
        }
    });
    const rootRef = useRef(null);
    const containerDim = useBoxDimension(() => rootRef.current);
    const handleTreeLabelClick = (e) => {
        e?.target
            ?.closest(`.${TREE_NODE_CLASS}`)
            ?.querySelector(`.${TREE_SWITCHER_CLASS}`)?.click();
    };
    // have some minimum height for the tree or else the tree internally tries to calculate height and it breaks.
    const virtualTreeHeight = containerDim?.height || 1;
    //we need to define tree height only in case virutalization is enabled. In non virtual case, it should expand as required
    const treeHeight = virtual ? virtualTreeHeight : undefined;
    return (_jsx(StyledTree, { ref: rootRef, onClick: handleTreeLabelClick, checkable: checkable, selectable: selectable, className: className, style: style, children: virtual && containerDim?.height === undefined ? null : (_jsx(RCTree, { ref: treeRef, height: treeHeight, virtual: virtual, activeKey: activeKey, itemHeight: virtual ? parseInt(resolveCssVariable(tokens.Node.height)) : undefined, checkable: checkable ? _jsx(TreeCheckbox, { variant: variant }) : undefined, prefixCls: 'ui-tree', checkedKeys: _checkedKeys, onCheck: updatedCheckedKeys, selectable: selectable, switcherIcon: ({ isLeaf, expanded, className }) => {
                if (isLeaf)
                    return null;
                return (_jsx(ChevronRightIcon, { type: 'default', size: 'xs', color: tokens.ExpandIcon.color, className: className, rotate: expanded ? 90 : 0 }));
            }, titleRender: (node) => {
                // If parent returns something, use that. Otherwise, fallback to the default UI.
                const parentResult = titleRender?.(node);
                if (parentResult) {
                    return parentResult;
                }
                const { title, disabled, info } = node;
                return (_jsx(Button, { variant: 'plain', disabled: disabled, children: _jsxs(StyledTitleWrap, { direction: 'horizontal', align: 'space-between', alignY: 'center', children: [_jsx(StyledTitle, { grow: '1', children: _jsx(Ellipsis, { tooltip: true, children: title }) }), info && _jsx(StyledTitleInfo, { children: info })] }) }));
            }, treeData: treeData, ...restProps })) }));
}
