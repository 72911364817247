import { ArrayElement } from '@prophecy/interfaces/generic';
import {
  Avatar,
  Dialog,
  StackItem,
  Ellipsis,
  isDialogOpened,
  message,
  overlayStyle,
  Select,
  Stack,
  Text,
  theme,
  toast,
  Tooltip
} from '@prophecy/ui';
import { isCommentNodeEditorActive } from '@prophecy/ui-v3/MarkdownEditor/utils';
import { tokens as AvatarTokens } from '@prophecy/ui/Avatar/tokens';
import { useEmptyCell, useFitView } from '@prophecy/ui/Graph/hooks';
import { getLayoutedElements } from '@prophecy/ui/Graph/layout';
import { ADD_NEW_PORT_HANDLE_ID, NODE_SPACING, NODE_DIMENSION } from '@prophecy/ui/Graph/tokens';
import {
  EdgeData,
  LayoutAlgo,
  NodeData,
  NodeStates,
  NodeStateTypes,
  NodeType,
  Point,
  StrippedNodeData
} from '@prophecy/ui/Graph/types';
import { isNodeNotPositioned, snapPosition } from '@prophecy/ui/Graph/utils';
import { matchesPropertyPath, produceWithoutFreeze } from '@prophecy/utils/data';
import { afterAnimationFrame, getFirstFocusableElement } from '@prophecy/utils/dom';
import { isNodeEvnProduction } from '@prophecy/utils/env';
import { ProphecyError } from '@prophecy/utils/error';
import { doubleId } from '@prophecy/utils/id';
import { useFocusTrap } from '@prophecy/utils/react/focus';
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { usePrompt } from '@prophecy/utils/react/router';
import { parseJSON, pluralize } from '@prophecy/utils/string';
import { validateEntityName } from '@prophecy/utils/validation';
import { Transition } from 'history';
import produce from 'immer';
/**
 * Note: clone with JSON.parse(JSON.stringify(obj)) looses constructor information.
 * Due to which isEqual check between two obj can break. So we clone graph using lodash deepClone
 * which maintains constructor information added by immer.
 */
import { cloneDeep, isEqual, isUndefined, merge, set } from 'lodash-es';
import { nanoid } from 'nanoid';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useParams, useSearchParams } from 'react-router-dom';
import { Connection, Edge, getConnectedEdges, isEdge, isNode, Node, Rect, useReactFlow, XYPosition } from 'reactflow';
import styled from 'styled-components';
import { format as timeAgoFormat } from 'timeago.js';

import { type PortsAtomProps } from '../components/Ports';
import { JOB_IDE_ENTITIES } from '../constants/ide';
import { PortSchemaProps, PortType } from '../generic-components/PortSchema/types';
import { AspectKindType, UserAttributes, WorkflowMode } from '../graphqlTypes/enums';
import { useHistory } from '../HistoryManager/context';
import { HistoryType } from '../HistoryManager/types';
import { frameId } from '../HistoryManager/utils';
import type { PropertiesDidChange, PropertiesDidSave } from '../LSP/base/hook';
import { useLSPMethod } from '../LSP/base/hook';
import { LSP } from '../LSP/base/types';
import { DidChangeOptions } from '../LSP/history/types';
import { Diagnostic } from '../LSP/types';
import { useDiagnosticsForPath } from '../LSP/util';
import {
  getConnectionPath,
  getCurrentProcessGraphPath,
  getGraphPathAndProcess,
  getGraphPathByProcess,
  getGraphProcess,
  getGraphProperty,
  getParentGraphPath,
  getParentPathOfProcess,
  getProcessesPath,
  getProcessIdFromPropertyPath,
  isComponentProperty,
  isGraphProperty,
  isMetadataProperty,
  isMetaInputGemType,
  isMetaOutputGemType,
  isSubGraphOrMetaGem,
  updateComponentProperty,
  updateGraphProperty,
  updateMetadataProperty
} from '../Parser/bindings';
import { GemSpec, GemType, UISpecCategory } from '../Parser/types';
import { Port, ReusableSubgraphProperties, WorkflowGraph, WorkflowState } from '../redux/types';
import { spaceToUnderscore, uniqueID } from '../utils';
import {
  LAST_JOB_IDE_URL,
  LAST_OBSERVATION_IDE_URL,
  LAST_VISITED_IDE_URL,
  NEW_PORT_ADDED_TOAST_SHOWN,
  SELECTED_LAYOUT_ALGO_KEY
} from '../utils/localstorage-keys';
import { type CategoryMap } from './categoryMap';
import { PATH_ROOTS, SUB_GRAPH_NODE_TYPES } from './constants';
import { EntityIcon, EntityIconMap, EntityIconType } from './Entity/EntityIcons';
import { getGemSpec } from './getGemSpec';
import { getGemTypeByProcess } from './getGemTypeByProcess';
import { useSpecs } from './graph/gem-specs';
import { ProcessKind, SqlProcessKind } from './graph/types';
import { useForwardOutputSlugs } from './graph/useForwardOutputSlugs';
import {
  getChangePayloadForLabelUpdate,
  getChangePayloadForPropertiesUpdate,
  isReusableSubgraph,
  useReactFlowInstance
} from './graph/utils';
import { findUpOrDownStreamProcessAndIds } from './ide/useStaleDownStreamProcesses';
import { InterimKey } from './interims/types';
import { toInterimKey } from './interims/useInterimRunId';
import { JobCategoryMap } from './Job/constants';
import { JobCategoryTypes } from './Job/types';
import { getJobNodeIcon } from './Job/utils';
import { Aspect, VersionedAspect } from './queries/common';
import { gemTypeToString } from './selectors';
import { uiSpecAtomPropsCacheManager, useUISpecProps } from './spec/utils';
import {
  BaseProcess,
  BaseProcessMetadata,
  BaseState,
  CommonActionTypes,
  Connection as GraphConnection,
  DiagnosticsMap,
  DidChangePayload,
  DidUpdatePayload,
  GenericGraph,
  GenericGraphProcess,
  GenericGraphProcesses,
  GenericGraphProcessType,
  GraphElements,
  Metadata,
  SubgraphConnectionMap,
  CommonGraph,
  Process
} from './types';
import { Entity, EntityWitUrl, IDEEntity } from './types/Entity';
import type { InterimData } from './unit-test/utils';
import { CallBackEntityParams, getGemIdeUrl, getIDEUrl, Private_Routes } from './url';

type isSubgraphFunctionType = (
  process: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
) => boolean;

const SUBFLOW_OFFSET = 20;

export function useDecodedParams<P extends Record<string, string>>() {
  const params = useParams<P>();
  const decodedParams = {} as P;
  type ParamKeys = keyof P;
  Object.keys(params).forEach((key: ParamKeys) => {
    const value = decodeURIComponent(params[key] || '');
    decodedParams[key] = value as P[keyof P];
  });
  return decodedParams;
}

const StyledList = styled.ul`
  max-height: 150px;
  max-width: 450px;
  overflow: auto;
  padding-left: ${theme.spaces.x16};
  margin: 0;
`;

const isSeverity = (severity: number, diagnostics?: Diagnostic[]) => {
  return !!diagnostics?.find((d) => d.severity === severity);
};

export function getDiagnosticStatus(
  componentDiagnostics?: Diagnostic[],
  compilationInProgress?: boolean
): {
  status?: NodeStates;
  tooltip?: React.ReactNode;
} {
  const isError = isSeverity(1, componentDiagnostics);
  const isWarning = isSeverity(2, componentDiagnostics);

  if ((isError || isWarning) && componentDiagnostics) {
    let tooltip: React.ReactNode;

    if (componentDiagnostics.length > 1 || compilationInProgress) {
      tooltip = (
        <StyledList>
          {componentDiagnostics.map((diagnostic, index) => (
            <li key={index}>{diagnostic?.message}</li>
          ))}
        </StyledList>
      );
    } else if (componentDiagnostics?.length === 1) {
      tooltip = componentDiagnostics[0].message;
    }

    if (compilationInProgress && tooltip) {
      tooltip = (
        <Stack>
          <StackItem>
            Compilation is in progress. The below {isError ? 'errors' : 'warnings'} might be out-of-date.
          </StackItem>
          <StackItem>{tooltip}</StackItem>
        </Stack>
      );
    }

    return {
      status: isError ? NodeStateTypes.error : NodeStateTypes.warning,
      tooltip
    };
  }
  return {};
}

export function getPortStatus(process: Process, diagnostics: Diagnostic[]) {
  const portsDiagnostics = diagnostics
    .map((diagnostic) => {
      const [, portSubPath] = diagnostic.property.split(`${process.id}.ports.`);
      return {
        ...diagnostic,
        portSubPath: portSubPath
      };
    })
    .filter((diagnostic) => diagnostic.portSubPath);

  const getStatus = (portType: PortType) => {
    return process.ports?.inputs?.map((_, index) => {
      const diagnostics = portsDiagnostics.filter((diagnostic) =>
        diagnostic.portSubPath?.startsWith(`${portType === PortType.input ? 'inputs' : 'outputs'}[${index}]`)
      );

      return {
        hasError: diagnostics.length > 0
      };
    });
  };

  return { inputs: getStatus(PortType.input), outputs: getStatus(PortType.output) };
}

export const isNotSubGraphNode = (node: Node) =>
  !(node.type === SUB_GRAPH_NODE_TYPES.subGraphOutNode || node.type === SUB_GRAPH_NODE_TYPES.subGraphInNode);

export const filterAutoPositionedNodes = (node: Node<StrippedNodeData>) => {
  return isNotSubGraphNode(node) && !isMetaInputGemType(node.data.gemType) && !isMetaOutputGemType(node.data.gemType);
};

export const snapProcessToGrid = (
  process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
) => {
  const { x, y } = process.metadata;
  return {
    ...process,
    metadata: {
      ...process.metadata,
      ...snapPosition({ x, y })
    }
  };
};

export function useProcessDiagnosticsMap() {
  const diagnostics = useDiagnosticsForPath(['']);
  const map: DiagnosticsMap = useMemo(() => {
    const map: DiagnosticsMap = {};
    diagnostics.forEach((diagnostic) => {
      if (diagnostic.property) {
        const processId = getProcessIdFromPropertyPath(diagnostic.property);
        if (processId) {
          if (map[processId]) {
            map[processId].push(diagnostic);
          } else {
            map[processId] = [diagnostic];
          }
        }
      }
    });
    return map;
  }, [diagnostics]);

  return map;
}

async function showDeleteProcessDialog(
  title = 'Delete',
  message = 'Process and all configuration inside will be deleted'
) {
  return await Dialog.alert({
    title,
    children: <Text level='sm'>{message}. Do you want to continue? </Text>
  });
}

export function getAllProcessLabels(
  graph: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  list: string[] = []
) {
  const processes = Object.values(graph.processes);
  for (let index = 0; index < processes.length; index++) {
    const process = processes[index] as GenericGraph<
      unknown,
      BaseProcessMetadata,
      BaseProcess<BaseProcessMetadata>,
      GraphConnection
    >;
    list.push(process.metadata.label);
    //Is rename will handle the case for published or reusable subgraph in same project
    if (Object.keys(process.processes || {}).length > 0 && !isReusableSubgraph(process)) {
      getAllProcessLabels(process, list);
    }
  }
  return list;
}

export function getGraphToExtractProcessLabels<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(rootGraph: G, graph: G) {
  // if current graph is reusable subgraph use that as root
  if (isReusableSubgraph(graph)) {
    return graph;
  }
  // if rootGraph has singular test graph, use that as rootGraph
  else if ('currentSingularTestGraph' in rootGraph && typeof rootGraph.currentSingularTestGraph === 'object') {
    return rootGraph.currentSingularTestGraph as G;
  }
  // if rootGraph has snapshot graph, use that as rootGraph
  else if ('currentSnapshotGraph' in rootGraph && typeof rootGraph.currentSnapshotGraph === 'object') {
    return rootGraph.currentSnapshotGraph as G;
  }
  // if rootGraph has modelGraph, use that as rootGraph
  else if ('currentModelGraph' in rootGraph && typeof rootGraph.currentModelGraph === 'object') {
    return rootGraph.currentModelGraph as G;
  }
  // if rootGraph has pipeline graph, use that as rootGraph
  else if ('currentPipelineGraph' in rootGraph && typeof rootGraph.currentPipelineGraph === 'object') {
    return rootGraph.currentPipelineGraph as G;
  }

  return rootGraph;
}

function showNewPortAddedSuccessToast() {
  const isNewPortAddedToastShown = localStorage.getItem(NEW_PORT_ADDED_TOAST_SHOWN) === 'true';
  if (!isNewPortAddedToastShown) {
    toast.info({
      width: '550px',
      content: (
        <Stack gap={theme.spaces.x8}>
          <Text weight={theme.fontWeight.bold} level='sm'>
            New input added successfully to the component 👏
          </Text>
          <Text level='sm'>To edit existing ports, open the gem and modify the ports directly</Text>
        </Stack>
      ),
      closeable: true
    });
    localStorage.setItem(NEW_PORT_ADDED_TOAST_SHOWN, 'true');
  }
}

export function useOnConnect(
  graph: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  rootGraph: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  graphPath: string,
  propertiesDidChange: PropertiesDidChange,
  propertiesDidSave: PropertiesDidSave,
  rootPath: string,
  forwardSlug?: boolean
) {
  const instance = useReactFlowInstance<NodeData, EdgeData>();
  const forwardOutputSlugs = useForwardOutputSlugs(graph, graphPath);
  const specs = useSpecs();
  const { getAtomProps } = useUISpecProps();
  return async function onConnect(params: Edge | Connection) {
    const { connections } = graph;
    const id = nanoid();
    const connectedToVirtualNode = instance.current
      .getNodes()
      .some((node) => node.data.virtual && (node.id === params.source || node.id === params.target));
    if (!connectedToVirtualNode) {
      const connection: GraphConnection = {
        id: id,
        source: params.source as string,
        sourcePort: params.sourceHandle as string,
        target: params.target as string,
        targetPort: params.targetHandle as string
      };

      if (params.targetHandle === ADD_NEW_PORT_HANDLE_ID) {
        const targetNode = instance.current.getNodes().find((node) => node.id === params.target);
        const newPortId = doubleId();
        if (targetNode) {
          const process = getGraphProcess(rootGraph, graphPath, targetNode.id, rootPath);
          const inputPorts = process.ports?.inputs || [];
          const portsAtomProps = getAtomProps<PortsAtomProps>('Ports', specs, process, process.component);
          const portSchemaProps = getAtomProps<PortSchemaProps>('PortSchema', specs, process, process.component);
          const minPorts = portsAtomProps?.minInputPorts || portSchemaProps?.minPorts || 0;
          const slug = getSlugForPort(
            PortType.input,
            minPorts,
            inputPorts.map((port) => port.slug)
          );
          const newPort = {
            id: newPortId,
            slug
          } as Port;
          connection.targetPort = newPortId;
          propertiesDidChange({
            property: `${getProcessesPath(graphPath)}.${params.target}.ports.inputs`,
            value: [...inputPorts, newPort]
          });
          // wait for port update to be applied on client state
          await Promise.resolve();
        }
      }

      if (forwardSlug) {
        forwardOutputSlugs([connection]);
      }

      propertiesDidChange({
        property: getConnectionPath(graphPath),
        value: [...connections, connection]
      }).then(() => {
        if (params.targetHandle === ADD_NEW_PORT_HANDLE_ID) {
          showNewPortAddedSuccessToast();
        }
      });

      propertiesDidSave();
    }
  };
}

export function useOnSelectionDragStop(
  root: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  graphPath: string,
  propertiesDidChange: PropertiesDidChange,
  propertiesDidSave: PropertiesDidSave
) {
  return function onSelectionDragStop(event: React.MouseEvent, nodes: Node[]) {
    propertiesDidChange(
      nodes.map((node) => ({
        property: `${getProcessesPath(graphPath)}.${node.id}.metadata`,
        value: {
          ...(root.processes[node.id]?.metadata || {}),
          ...snapPosition(node.position)
        }
      }))
    );

    propertiesDidSave();
  };
}

export function useOnNodeDragStop(
  root: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  graphPath: string,
  propertiesDidChange: PropertiesDidChange,
  propertiesDidSave: PropertiesDidSave
) {
  const instance = useReactFlow();
  return function onNodeDragStop() {
    const internalNodes: Node[] = instance.getNodes();

    // on drag stop snap all nodes
    const updatedNodes = internalNodes.map((node) => {
      if (isNotSubGraphNode(node)) {
        return { ...node, position: snapPosition(node.position), dragging: false };
      }
      return node;
    });

    // update the snapped nodes only if positions are changed
    if (
      !isEqual(
        updatedNodes.map((node) => node.position),
        internalNodes.map((node) => node.position)
      )
    ) {
      instance.setNodes(updatedNodes);
    }

    // Note: react-flow only invoke drag event for last selected node in case of multiple-selection
    const nodesMoved: { id: string; x: number; y: number }[] = [];
    updatedNodes.filter(isNotSubGraphNode).forEach((node) => {
      if (!root.processes[node.id]) return;
      const { x, y } = root.processes[node.id]?.metadata;
      const { x: newX, y: newY } = node.position;

      // if the snapped position is not updated no need to call didChange
      if (newX !== x || newY !== y) {
        nodesMoved.push({ id: node.id, ...node.position });
      }
    });

    if (!nodesMoved.length) return;

    propertiesDidChange(
      nodesMoved.map((node) => {
        return {
          property: `${getProcessesPath(graphPath)}.${node.id}.metadata`,
          value: {
            ...root.processes[node.id].metadata,
            x: node.x,
            y: node.y
          }
        };
      })
    );

    propertiesDidSave();
  };
}

function getCurrentIDELayoutAlgo(supportedLayoutAlgo: LayoutAlgo[]) {
  const savedAlgo = localStorage.getItem(SELECTED_LAYOUT_ALGO_KEY) as LayoutAlgo | null;

  return savedAlgo && supportedLayoutAlgo.includes(savedAlgo) ? savedAlgo : supportedLayoutAlgo[0];
}

export function useOnLayout(
  root: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  onSuccess: (
    processes: GenericGraphProcesses<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
    skipHistory?: boolean
  ) => void,
  prepareProcess: (
    process: BaseProcess<BaseProcessMetadata>,
    node: Node<NodeData>
  ) => BaseProcess<BaseProcessMetadata> = (process) => process,
  supportedLayoutAlgo: LayoutAlgo[] = [LayoutAlgo.elk, LayoutAlgo.dagre],
  filterNodes: (node: Node) => boolean = filterAutoPositionedNodes
) {
  const fitView = useFitView(filterNodes, false);
  const history = useHistory();

  return usePersistentCallback(async function (
    nodes: Node<NodeData>[] | ((layoutAlgo?: LayoutAlgo) => Promise<Node<NodeData>[]>),
    {
      showPrompt = true,
      skipHistory,
      fitToView
    }: { showPrompt?: boolean; skipHistory?: boolean; fitToView?: boolean } = {}
  ) {
    if (showPrompt) {
      const doLayout = await Dialog.alert({
        title: `Auto Layout`,
        children: (
          <Stack gap={theme.spaces.x24} padding={theme.outlineWidth}>
            <Text level='sm'>Auto layout will reset the position of all process. Do you want to continue? </Text>
            {supportedLayoutAlgo.length > 1 ? (
              <Select
                label='Choose Layout Algorithm'
                defaultValue={getCurrentIDELayoutAlgo(supportedLayoutAlgo)}
                onChange={(value) => {
                  localStorage.setItem(SELECTED_LAYOUT_ALGO_KEY, value);
                }}
                options={supportedLayoutAlgo.map((algo) => ({ value: algo, label: algo }))}
              />
            ) : null}
          </Stack>
        )
      });

      if (!doLayout) return;
    }

    const _nodes = typeof nodes === 'function' ? await nodes(getCurrentIDELayoutAlgo(supportedLayoutAlgo)) : nodes;

    const processes = produceWithoutFreeze(root?.processes, (draft) => {
      _nodes.forEach((node) => {
        const process = draft[node.id];
        if (!process) return;

        process.metadata.x = node.position.x;
        process.metadata.y = node.position.y;
        // update process width and height if node is subflow
        if (node.type === NodeType.subFlow) {
          process.metadata.width = node.width as number;
          process.metadata.height = node.height as number;
        }

        prepareProcess(process, node);
      });
      return draft;
    });

    const fitViewAfterLayout = () => {
      afterAnimationFrame(() => {
        fitView();
      });
    };

    onSuccess(processes, skipHistory);

    if (fitToView) {
      fitViewAfterLayout();
    }

    // add fit logic in history as well, so on undo redo, its center aligned
    if (fitToView && !skipHistory) {
      history?.mergeOrTrackChange(
        {
          prevValue: fitViewAfterLayout,
          nextValue: fitViewAfterLayout,
          metadata: { type: HistoryType.callback }
        },
        frameId()
      );
    }
  });
}

export function useAddPartialGraph<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(graph: G, graphPath: string, forwardSlug?: boolean) {
  const { method: propertiesDidChange } = useLSPMethod(LSP.Method.propertiesDidChange);
  const { method: propertiesDidSave } = useLSPMethod(LSP.Method.propertiesDidSave);
  const forwardOutputSlugs = useForwardOutputSlugs(graph, graphPath);

  return (partialGraph: G) => {
    const { processes, connections } = cloneDeep(partialGraph);

    const processList = Object.values(processes);

    if (!processList.length) return;

    /**
     * TODO: backend has bug where if updated port is sent along with the new process,
     * it doesn't update the properties, so we send the update input ports as separate didChange
     * Once fixed remove this logic
     */
    const processInputPorts: { inputPorts: Port[]; processId: string }[] = [];

    propertiesDidChange([
      ...processList.map((process) => {
        if (process.ports?.inputs?.length) {
          processInputPorts.push({ inputPorts: process.ports.inputs, processId: process.id });
          process.ports.inputs = process.ports.inputs.map((port, index) => ({
            ...port,
            slug: `${PortType.input}${index}`
          }));
        }

        return {
          property: `${getProcessesPath(graphPath)}.${process.id}`,
          value: snapProcessToGrid(process)
        };
      }),
      ...processInputPorts.map(({ processId, inputPorts }) => {
        return {
          property: `${getProcessesPath(graphPath)}.${processId}.ports.inputs`,
          value: inputPorts
        };
      })
    ]);

    if (forwardSlug) {
      forwardOutputSlugs(connections, processes);
    }

    propertiesDidChange({
      property: getConnectionPath(graphPath),
      value: [...graph.connections, ...connections]
    });

    propertiesDidSave();
  };
}

export function useRemoveConnections(
  root: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  graphPath: string,
  propertiesDidChange: PropertiesDidChange
) {
  return usePersistentCallback(function removeConnections(connectionIds: string[]) {
    propertiesDidChange({
      property: getConnectionPath(graphPath),
      value: root.connections.filter((c) => !connectionIds.includes(c.id))
    });
  });
}

export function useRemoveProcesses(graphPath: string, propertiesDidChange: PropertiesDidChange) {
  return usePersistentCallback(function removeProcesses(processIds: string[], options: DidChangeOptions = {}) {
    propertiesDidChange(
      processIds.map((processId) => {
        return {
          property: `${getProcessesPath(graphPath)}.${processId}`,
          value: null
        };
      }),
      options
    );
  });
}
type ReturnTypeUseRemoveProcesses = ReturnType<typeof useRemoveProcesses>;
export function useRemoveProcessForSubgraph(
  _removeProcesses: ReturnTypeUseRemoveProcesses,
  graph: WorkflowGraph,
  specs: GemSpec[]
) {
  return usePersistentCallback(function removeProcesses(processIds: string[]) {
    if (processIds.some((processId) => isSubGraphOrMetaGem(graph.processes[processId], specs))) {
      // if one of process is subgraph, saveRoot for undo ops, bcoz subgraph configurations are stored at pipeline root, which to be restore during undo ops
      _removeProcesses(processIds, { shouldSaveRoot: true });
    } else {
      _removeProcesses(processIds);
    }
  });
}
export function checkAndFilterMetaGems<N extends NodeData | StrippedNodeData>(_nodes: Node<N>[]) {
  const nodes: Node<N>[] = [];
  let hasMetaInputGem = false;
  let hasMetaOutputGem = false;
  _nodes.forEach((node) => {
    if (isMetaInputGemType(node.data.gemType)) {
      hasMetaInputGem = true;
    } else if (isMetaOutputGemType(node.data.gemType)) {
      hasMetaOutputGem = true;
    } else {
      nodes.push(node);
    }
  });
  return { hasMetaInputGem, hasMetaOutputGem, nodes };
}
export type onElementsRemoveParams = {
  elementsToRemove: GraphElements;
  message?: string;
  checkForDialog?: boolean;
  showConfirmDialog?: boolean;
};
export type onElementsRemoveType = (params: onElementsRemoveParams) => void;

export function useOnElementsRemove(
  removeConnections: ReturnType<typeof useRemoveConnections>,
  removeProcesses: ReturnTypeUseRemoveProcesses,
  propertiesDidSave: PropertiesDidSave
) {
  const instance = useReactFlow();

  return usePersistentCallback(async function ({
    elementsToRemove,
    message,
    checkForDialog,
    showConfirmDialog = true
  }: onElementsRemoveParams) {
    if ((checkForDialog && isDialogOpened()) || isCommentNodeEditorActive()) {
      // if any dialog/overlay is open, do nothing,
      return;
    }
    let edgesToRemove: Edge<EdgeData>[] = [];
    const _nodesToRemove: Node<NodeData>[] = [];

    elementsToRemove.forEach((el) => {
      if (isEdge(el)) {
        edgesToRemove.push(el);
      } else if (isNode(el)) {
        _nodesToRemove.push(el);
      }
    });
    const { hasMetaInputGem, hasMetaOutputGem, nodes: nodesToRemove } = checkAndFilterMetaGems(_nodesToRemove);
    if (hasMetaOutputGem && hasMetaInputGem) {
      toast.info({
        content: `Default ${ProcessKind.ControlFlowOutput} & ${ProcessKind.ControlFlowInput} can't be deleted. Delete the input/output port for the subgraph, if you do not want any input/output`
      });
    } else if (hasMetaInputGem) {
      toast.info({
        content: `Default ${ProcessKind.ControlFlowInput} can't be deleted. Delete the input port for the subgraph, if you do not want any input`
      });
    } else if (hasMetaOutputGem) {
      toast.info({
        content: `Default ${ProcessKind.ControlFlowOutput} can't be deleted. Delete the output port for the subgraph, if you do not want any output`
      });
    }
    let edgeIdsToRemove: string[] = [];
    const nodeIdsToRemove = nodesToRemove.map((node) => node.id);

    if (nodesToRemove.length > 0) {
      const connectedEdges = getConnectedEdges(nodesToRemove, instance.getEdges());
      edgesToRemove = [...edgesToRemove, ...connectedEdges];
      edgeIdsToRemove = edgesToRemove.reduce<string[]>((res, edge) => {
        if (!res.includes(edge.id)) {
          res.push(edge.id);
        }
        return res;
      }, []);
    } else {
      edgeIdsToRemove = edgesToRemove.map((edge) => edge.id);
    }
    if (!message) {
      const nodeLabelsToRemove = nodesToRemove.map((node) => node.data.label).filter(Boolean);

      if (nodeLabelsToRemove.length > 0) {
        let nodeLabelsCSV = nodeLabelsToRemove.slice(0, 3).join(', ');

        if (nodeLabelsToRemove.length > 3) {
          message = `${nodeLabelsCSV} and all other selected processes will be deleted along with all its configurations`;
        } else {
          message = `${nodeLabelsCSV} will be deleted along with all its configurations`;
        }
      }
    }
    if (nodeIdsToRemove.length > 0) {
      const shouldDelete = showConfirmDialog
        ? await showDeleteProcessDialog(`Delete ${pluralize(nodeIdsToRemove.length, 'Process', 'es')}`, message)
        : true;

      if (!shouldDelete) return;

      if (edgeIdsToRemove) {
        removeConnections(edgeIdsToRemove);
      }
      if (nodeIdsToRemove.length) {
        removeProcesses(nodeIdsToRemove);
      }
      propertiesDidSave();
    } else if (edgeIdsToRemove.length > 0) {
      removeConnections(edgeIdsToRemove);
      propertiesDidSave();
    }
  });
}

const mergePayload = (payload: DidUpdatePayload) => {
  const outputPayload: Map<string, ArrayElement<DidUpdatePayload>> = new Map();

  payload.forEach((nextChange) => {
    const { property } = nextChange;
    let foundMatch = false;

    outputPayload.forEach((prevChange) => {
      const { property: prevProperty } = prevChange;
      // if this property has already been modified replace its value with new one
      if (property === prevProperty) {
        outputPayload.set(property, nextChange);
        foundMatch = true;
        return;
      } else if (matchesPropertyPath(property, prevProperty)) {
        let mergedChange = nextChange;

        // if a parent has already been modified in same entry, merge the change to the parent change instead
        // as when doing undo we do in reverse order, if parent was newly added sending child property change without parent will cause an error
        // prevProperty will be the parentPath in property
        // prevProperty can be be array or object, so property path can be 'a.b.c' or a.b[2],
        // so if we remove a.b from path we need to remove preceding dot in case of oject property
        const childPath = property.replace(prevProperty, '').replace(/^\./, '');
        const value = produce(prevChange.value as Object, (draft) => {
          set(draft, childPath, nextChange.value);
        });
        mergedChange = { ...prevChange, value };

        foundMatch = true;
        outputPayload.set(prevProperty, mergedChange);
      } else if (matchesPropertyPath(prevProperty, property)) {
        // if the parent path is set the child path would be reset, so remove them.
        outputPayload.delete(prevProperty);
      }
    });

    // if the property was not already tracked
    if (!foundMatch) {
      outputPayload.set(nextChange.property, nextChange);
    }
  });

  return Array.from(outputPayload.values());
};

function applyChanges<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(graph: G, metadata: Metadata, changes: DidUpdatePayload, rootPath: string, graphPath?: string, componentId?: string) {
  let wipGraph = graph;
  let wipMetadata = metadata;

  // recursive set is causing immer to throw readonly property error
  // ex: set('a', { b }) -> set('a.b') is throwing error cannot set 'b' of readonly a
  // merging will first merge the second change into first before setting to immer, to avoid this issue
  mergePayload(changes).forEach((change) => {
    if (change.type === 'partial') {
      const { property, value } = change;
      if (!property) {
        if (isNodeEvnProduction()) {
          // silently skip update
          return;
        } else {
          // throw error in development environment
          throw new ProphecyError(`Property path can't be empty`);
        }
      }

      if (isComponentProperty(property)) {
        // component.properties.expression "abc"
        // allow only changes at process level
        const processGraphPath = getCurrentProcessGraphPath(componentId as string, graphPath as string);
        const process = getGraphProcess(wipGraph, processGraphPath, componentId as string, rootPath);
        updateComponentProperty(process, property, value);
      } else if (isGraphProperty(property, rootPath)) {
        wipGraph = updateGraphProperty(wipGraph, property, value, rootPath);
      } else if (isMetadataProperty(property)) {
        wipMetadata = updateMetadataProperty(wipMetadata, property, value);
      }
    } else if (change.type === 'full') {
      const { value } = change;
      const { workflow, job, metadata } = value as {
        job: G;
        workflow: G;
        metadata: Metadata;
      };
      wipGraph = workflow || job;
      wipMetadata = metadata;
    }
  });

  return { graph: wipGraph, metadata: wipMetadata };
}

export function useRenameProcess<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(rootGraph: G, graph: G, propertiesDidChange: PropertiesDidChange, currentGraphPath: string, forwardSlug?: boolean) {
  const forwardOutputSlugs = useForwardOutputSlugs(graph, currentGraphPath);

  return usePersistentCallback(function onRenameProcess<
    P extends GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
  >(value: string, process: P, graphPath = currentGraphPath) {
    let result = false;
    const validateResult = validateEntityName(value);
    if (validateResult) {
      message.error({ content: validateResult });
    } else if (
      // for reusable subgraph we limit the name to that subgraph scope. So no need to lookup from parent.
      getAllProcessLabels(getGraphToExtractProcessLabels(rootGraph, graph), [])
        .filter((label) => process.metadata.label !== label)
        .includes(value)
    ) {
      message.error({ content: `Label is already in use.` });
    } else {
      const payload = getChangePayloadForLabelUpdate(value, process, graphPath);
      propertiesDidChange(payload);

      const outPorts = process.ports?.outputs;
      // forward the process label as input slug for the next component only if there is single output port, in other case
      // we forward output port slug
      if (forwardSlug && outPorts && outPorts.length === 1) {
        const updatedProcess = { [process.id]: { ...process, metadata: payload.value } };
        const connections = graph.connections.filter((connection) => connection.source === process.id);

        forwardOutputSlugs(connections, updatedProcess);
      }

      result = true;
    }
    return result;
  });
}

export function useModifyPropertiesAttribute(currentGraphPath: string, saveAfterChange?: boolean) {
  const { method: propertiesDidChange } = useLSPMethod(LSP.Method.propertiesDidChange);
  const { method: propertiesDidSave } = useLSPMethod(LSP.Method.propertiesDidSave);

  return usePersistentCallback(function onModifyPropertiesAttribute<
    P extends GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
  >(property: string, value: string, process: P, graphPath = currentGraphPath) {
    const payload = getChangePayloadForPropertiesUpdate(property, value, process.id, graphPath);
    propertiesDidChange(payload);
    if (saveAfterChange) propertiesDidSave();
  });
}

export function $updateState<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(
  action: { type: CommonActionTypes.$updateState; payload: Partial<BaseState<T, Metadata>> },
  draft: BaseState<T, Metadata>
) {
  Object.entries(action.payload).forEach((item) => {
    const [key, value] = item as [keyof BaseState<T, Metadata>, never];
    draft[key] = value;
  });
}

export function propertiesDidReset<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(draft: BaseState<T, Metadata>) {
  draft.currentGraph = draft.$graph;
}

export function propertiesDidSave<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(draft: BaseState<T, Metadata>) {
  draft.$graph = draft.currentGraph;
}

export function propertiesDidUpdate<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(
  action: {
    type: CommonActionTypes.propertyDidUpdate;
    savePending?: boolean | undefined;
    payload: DidUpdatePayload;
  },
  draft: BaseState<T, Metadata>
) {
  const { savePending, payload: changes } = action;

  //TODO remove it once log lifecycle maintained on backend
  draft.logMessage = '';

  const { metadata, graph: currentGraph } = applyChanges(
    draft.currentGraph as T,
    draft.metadata as Metadata,
    changes,
    draft.root
  );
  draft.currentGraph = currentGraph;
  draft.metadata = metadata;

  /**
   * if any save is pending keep applying updates on main graph as well
   * otherwise if dirty state changes are discarded, we will loose updates triggered by a save
   */
  if (savePending) {
    const { graph: $graph } = applyChanges(draft.$graph as T, draft.metadata as Metadata, changes, draft.root);
    draft.$graph = $graph;
  }
}

export function propertiesDidMasterUpdate<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(
  action: {
    type: CommonActionTypes.propertiesDidMasterUpdate;
    payload: DidUpdatePayload;
  },
  draft: BaseState<T, Metadata>
) {
  const { payload: changes } = action;

  // apply changes to dirty graph
  const { metadata, graph: currentGraph } = applyChanges(
    draft.currentGraph as T,
    draft.metadata as Metadata,
    changes,
    draft.root
  );
  draft.currentGraph = currentGraph;
  draft.metadata = metadata;

  // apply changes to main graph
  const { graph: $graph } = applyChanges(draft.$graph as T, draft.metadata as Metadata, changes, draft.root);
  draft.$graph = $graph;
}

export function applyChangesToMasterAndDirtyGraph<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(
  action: {
    type: CommonActionTypes.propertiesDidSchemaUpdate;
    payload: DidUpdatePayload;
  },
  draft: BaseState<T, Metadata>
) {
  const { payload: changes } = action;

  // apply changes to dirty graph
  const { metadata, graph: currentGraph } = applyChanges(
    draft.currentGraph as T,
    draft.metadata as Metadata,
    changes,
    draft.root
  );
  draft.currentGraph = currentGraph;
  draft.metadata = metadata;

  // apply changes to main graph
  const { graph: $graph } = applyChanges(draft.$graph as T, draft.metadata as Metadata, changes, draft.root);
  draft.$graph = $graph;
}

export function propertiesDidChange<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(
  action: {
    type: LSP.Method.propertiesDidChange;
    payload: DidChangePayload;
  },
  draft: BaseState<T, Metadata>
) {
  const payload = Array.isArray(action.payload) ? action.payload : [action.payload];
  const updatedPayload = payload.map((change) => ({ ...change, type: 'partial' })) as DidUpdatePayload;

  const { metadata, graph: currentGraph } = applyChanges(
    draft.currentGraph as T,
    draft.metadata as Metadata,
    updatedPayload,
    draft.root,
    draft.graphPath,
    draft.currentComponentId
  );
  draft.currentGraph = currentGraph;
  draft.metadata = metadata;
}

export function shortenCommitHash(value: string) {
  return value.substring(0, 10);
}

export function renderCommitHash(value?: string) {
  return (
    <Tooltip title={value}>
      <Stack width='min-content'>
        <Text level='sm13' tone={theme.colors.grey500}>
          {!isUndefined(value) ? shortenCommitHash(value) : '--'}
        </Text>
      </Stack>
    </Tooltip>
  );
}

export function renderCommitMessage(value: string) {
  return (
    <Text level='sm13' tone={theme.colors.grey500}>
      <Ellipsis
        tooltip={true}
        tooltipProps={{
          overlayStyle
        }}>
        {!isUndefined(value) ? value : '--'}
      </Ellipsis>
    </Text>
  );
}

export function renderColumnWithEllipsis(value: string, _?: unknown, index?: number, isMarkdown: boolean = true) {
  return (
    <Text level='sm13' className='column-cell'>
      <Ellipsis
        tooltip={true}
        tooltipProps={{
          overlayStyle,
          isMarkdown: isMarkdown ? 'title' : undefined
        }}>
        {!isUndefined(value) ? value : ''}
      </Ellipsis>
    </Text>
  );
}

export function renderDescriptionWithEllipsis(_value: string, record: { description?: string }) {
  const value = _value ?? record.description;
  return (
    <Text level='sm13'>
      <Ellipsis
        tooltip={true}
        tooltipProps={{
          overlayStyle
        }}>
        {!isUndefined(value) ? value : ''}
      </Ellipsis>
    </Text>
  );
}

export function renderTimeColumnWithEllipsis(value: number | string, record?: unknown, index?: number, color?: string) {
  return (
    <Text level='sm13' tone={color || theme.colors.grey900}>
      <Ellipsis tooltip={true}>{!isUndefined(value) ? timeAgoFormat(value) : '--'}</Ellipsis>
    </Text>
  );
}

const StyledAvatar = styled(Avatar)`
  &[data-state='closed'] {
    background: ${theme.colors.white};
  }
  &[data-state='delayed-open'] {
    background: ${AvatarTokens.background};
  }
`;

export function renderCommitAuthor(name: string, email: string, hasAuthorName?: boolean) {
  const avatarUi = (
    <Stack width={theme.spaces.x24}>
      <StyledAvatar withBorder={true} size='xs' text={name} />
    </Stack>
  );
  if (hasAuthorName) {
    return (
      <Stack direction='horizontal' gap={theme.spaces.x8} alignY='center'>
        {avatarUi}
        <StackItem grow='1'>
          <Text level='sm13' tone={theme.colors.grey900}>
            <Ellipsis tooltip={true}>{name}</Ellipsis>
          </Text>
        </StackItem>
      </Stack>
    );
  }

  return (
    <Tooltip
      title={
        <Stack gap={theme.spaces.x4}>
          <Text level='xs' tone={theme.colors.white} weight={theme.fontWeight.medium}>
            {name}
          </Text>
          <Text level='xs' tone={theme.colors.grey400} weight={theme.fontWeight.medium}>
            {email}
          </Text>
        </Stack>
      }>
      {avatarUi}
    </Tooltip>
  );
}

export function renderTimeColumnInDaysWithEllipsis(value: number) {
  const currentDay = new Date().getTime();
  const differenceInTime = value - currentDay;
  const days = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  const dateMsg = days === 1 ? 'Today' : days === 2 ? 'Tomorrow' : `In ${days} days`;

  return (
    <Text level='sm13' tone={theme.colors.grey900}>
      <Ellipsis tooltip={true}>{!isUndefined(value) ? dateMsg : '--'}</Ellipsis>
    </Text>
  );
}

export function getPointsFromProcesses(
  processes: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>[]
): Point[] {
  return processes.map((p) => ({ x: p.metadata.x, y: p.metadata.y }));
}

export function createProcess<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(gemSpecs: BaseState<G, Metadata>['gemSpecs'], rootGraph: G, graph: G, gemType: GemType, extraProps?: object) {
  const spec = getGemSpec(gemSpecs, gemType);
  let newProcess = cloneDeep(
    spec?.defaultState ?? { metadata: {}, properties: {}, ports: { outputs: [], inputs: [] } }
  ) as GenericGraphProcessType<G>;
  const component = gemType.component as string;
  const name = uniqueID(`${component}_`, getAllProcessLabels(getGraphToExtractProcessLabels(rootGraph, graph), []));
  newProcess.id = doubleId();
  newProcess.component = gemType.component;
  newProcess.componentInfo = gemType.componentInfo;
  newProcess.category = gemType.category;
  newProcess.metadata.label = name;
  newProcess.metadata.slug = spaceToUnderscore(name);

  newProcess.ports?.inputs?.forEach((p) => {
    p.id = doubleId();
  });
  newProcess.ports?.outputs?.forEach((p) => {
    p.id = doubleId();
  });

  if (extraProps) {
    newProcess = merge(newProcess, extraProps);
  }

  // this is a hack as backend cannot send the default state for
  // gem based on the ui spec as it is too complicated
  const portsAtomProps =
    uiSpecAtomPropsCacheManager.getAtomProps<PortsAtomProps>('Ports', gemSpecs, newProcess, gemType.component) ||
    uiSpecAtomPropsCacheManager.getAtomProps<PortSchemaProps>('PortSchema', gemSpecs, newProcess, gemType.component);
  if (portsAtomProps && newProcess.ports) {
    // script component has `isCustomOutputSchema` true by default and we need
    // to respect it until it is fixed in the backend
    newProcess.ports.isCustomOutputSchema =
      newProcess.ports.isCustomOutputSchema || portsAtomProps.defaultCustomOutputSchema;
  }

  return newProcess;
}

export function useAddNewProcess<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(
  gemSpecs: BaseState<G, Metadata>['gemSpecs'],
  rootGraph: G,
  graph: G,
  graphPath: string,
  prepareProcess?: (process: GenericGraphProcessType<G>) => GenericGraphProcessType<G>
) {
  const getEmptyCell = useEmptyCell();
  const { method: propertiesDidChange } = useLSPMethod(LSP.Method.propertiesDidChange);
  const { method: propertiesDidSave } = useLSPMethod(LSP.Method.propertiesDidSave);

  const _createProcess = usePersistentCallback(function _createProcess(gemType: GemType, extraProps?: object) {
    return createProcess(gemSpecs, rootGraph, graph, gemType, extraProps);
  });

  const dropProcess = usePersistentCallback(function dropProcess(
    process: GenericGraphProcessType<G>,
    position?: XYPosition
  ) {
    let x: number, y: number;

    if (position) {
      ({ x, y } = position);
    } else {
      [x, y] = getEmptyCell(getPointsFromProcesses(Object.values(graph.processes)));
    }

    process.metadata.x = x;
    process.metadata.y = y;

    return snapProcessToGrid(process);
  });

  const saveProcess = usePersistentCallback(function saveProcess(process: GenericGraphProcessType<G>) {
    propertiesDidChange({
      property: `${getProcessesPath(graphPath)}.${process.id}`,
      value: process
    });
    return propertiesDidSave();
  });

  const addProcess = usePersistentCallback(function addProcess(
    gemType: GemType,
    position?: XYPosition,
    extraProps?: object,
    save?: boolean
  ) {
    const _save = save ?? true;

    let newProcess = _createProcess(gemType, extraProps);

    dropProcess(newProcess, position);

    // modify the process before saving
    newProcess = prepareProcess ? prepareProcess(newProcess) : newProcess;

    if (_save) {
      saveProcess(newProcess);
    }

    return newProcess;
  });

  return { addProcess, createProcess: _createProcess, dropProcess, saveProcess };
}

export function getEntityUrl(type: EntityWitUrl, uid: string, projectId?: string) {
  let to = '';
  switch (type) {
    case Entity.Dataset: {
      to = Private_Routes.Dataset.home.getUrl({ uid });
      break;
    }
    case Entity.Gem: {
      if (!projectId) {
        throw new ProphecyError(`projectId is missing for gem ${uid}`);
      }
      to = getGemIdeUrl(projectId, uid, false);
      break;
    }
    case Entity.Pipeline: {
      to = getIDEUrl({ uid, entity: Entity.Pipeline });
      break;
    }
    case Entity.Configuration: {
      to = getIDEUrl({ uid, entity: Entity.Configuration });
      break;
    }
    case Entity.Job: {
      to = getIDEUrl({ uid, entity: Entity.Job });
      break;
    }
    case Entity.Project: {
      to = Private_Routes.Project.home.getUrl({ uid });
      break;
    }
    case Entity.Subgraph: {
      to = Private_Routes.Subgraph.home.getUrl({ uid });
      break;
    }
  }
  return to;
}

export function useSubFlow<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(graphPath: string, graph: G) {
  const instance = useReactFlow();

  const { method: propertiesDidChange } = useLSPMethod(LSP.Method.propertiesDidChange);

  const { method: propertiesDidSave } = useLSPMethod(LSP.Method.propertiesDidSave);

  const resizeSubFlow = usePersistentCallback((nodeId: string, { x, y, width, height }: Rect) => {
    const process = graph.processes[nodeId];

    const currentX = process.metadata.x;
    const currentY = process.metadata.y;

    const changes: DidChangePayload = [];

    // if x and y positions are changed make sure same is reflected to all the children
    if (currentX !== x || currentY !== y) {
      const deltaX = currentX - x;
      const deltaY = currentY - y;

      const childrenProcess = Object.values(graph.processes).filter((process) => process.parent === nodeId);

      childrenProcess.forEach((child) => {
        changes.push({
          property: `${getProcessesPath(graphPath)}.${child.id}.metadata`,
          value: {
            ...child.metadata,
            x: child.metadata.x + deltaX,
            y: child.metadata.y + deltaY
          }
        });
      });
    }

    changes.push({
      property: `${getProcessesPath(graphPath)}.${nodeId}.metadata`,
      value: { ...process.metadata, x, y, width, height }
    });

    propertiesDidChange(changes);

    propertiesDidSave();
  });

  const wrapSubFlow = usePersistentCallback(() => {
    const nodes: Node[] = instance.getNodes();

    const subFlowNodes = nodes.filter((node) => node.type === NodeType.subFlow);
    const subFlowNodeMap = Object.fromEntries((subFlowNodes as Node[]).map((node) => [node.id, node]));
    const processNodes = nodes.filter((node) => node.type === 'gem');

    const nodesToUpdate: Record<string, { parentNode?: string | null; x: number; y: number }> = {};

    processNodes.forEach((node) => {
      // if node is bounded by any subflow node, but moved outside of the subflow node, remove it and change the position
      if (node.parentNode) {
        const subflow = subFlowNodeMap[node.parentNode];

        const { x, y } = node.position;

        if (
          x < -SUBFLOW_OFFSET ||
          y < -SUBFLOW_OFFSET ||
          x + (node.width as number) > (subflow.width as number) + SUBFLOW_OFFSET ||
          y + (node.height as number) > (subflow.height as number) + SUBFLOW_OFFSET
        ) {
          node.position.x = subflow.position.x + x;
          node.position.y = subflow.position.y + y;
          node.parentNode = undefined;

          nodesToUpdate[node.id] = {
            parentNode: null,
            x: node.position.x,
            y: node.position.y
          };
        } else {
          return;
        }
      }

      // if node belongs to another subflow, update their position and parent node
      for (let subflow of subFlowNodes) {
        const { position: subFlowPosition, width: subFlowWidth, height: subFlowHeight } = subflow;
        const { position, width, height } = node;

        if (
          subFlowPosition.x < position.x &&
          subFlowPosition.y < position.y &&
          subFlowPosition.x + (subFlowWidth as number) > position.x + (width as number) &&
          subFlowPosition.y + (subFlowHeight as number) > position.y + (height as number) &&
          node.parentNode !== subflow.id
        ) {
          node.position.x = position.x - subFlowPosition.x;
          node.position.y = position.y - subFlowPosition.y;
          nodesToUpdate[node.id] = {
            parentNode: subflow.id,
            x: node.position.x,
            y: node.position.y
          };
          return;
        }
      }
    });

    const nodeIds = Object.entries(nodesToUpdate);

    if (nodeIds.length) {
      const changes: DidChangePayload = [];

      Object.entries(nodesToUpdate).forEach(([nodeId, { parentNode, x, y }]) => {
        const process = graph.processes[nodeId];

        // update parent
        changes.push({
          property: `${getProcessesPath(graphPath)}.${nodeId}.parent`,
          value: parentNode
        });

        // update position based on subflow
        changes.push({
          property: `${getProcessesPath(graphPath)}.${nodeId}.metadata`,
          value: { ...process.metadata, ...snapPosition({ x, y }) }
        });
      });

      propertiesDidChange(changes);

      propertiesDidSave();
    }
  });

  const removeSubFlow = usePersistentCallback((nodeId: string) => {
    const subFlowProcess = graph.processes[nodeId];

    const changes: DidChangePayload = [];

    // remove parent information and update position for all children process
    Object.values(graph.processes).forEach((process) => {
      if (process.parent === nodeId) {
        changes.push({
          property: `${getProcessesPath(graphPath)}.${process.id}.parent`,
          value: null
        });

        changes.push({
          property: `${getProcessesPath(graphPath)}.${process.id}.metadata`,
          value: {
            ...process.metadata,
            x: process.metadata.x + subFlowProcess.metadata.x,
            y: process.metadata.y + subFlowProcess.metadata.y
          }
        });
      }
    });

    // remove the subflow process
    changes.push({
      property: `${getProcessesPath(graphPath)}.${nodeId}`,
      value: null
    });

    propertiesDidChange(changes);
    propertiesDidSave();
  });

  return {
    wrapSubFlow,
    resizeSubFlow,
    removeSubFlow
  };
}

export const getIDEStorageKey = (entity: IDEEntity | Entity.Project) => {
  if (JOB_IDE_ENTITIES.includes(entity)) {
    return LAST_JOB_IDE_URL;
  } else if (entity === Entity.Observation) {
    return LAST_OBSERVATION_IDE_URL;
  }

  return LAST_VISITED_IDE_URL;
};

export function getLastVisitedEntityUID(entity: IDEEntity | Entity.Project) {
  const uri = localStorage.getItem(getIDEStorageKey(entity));
  if (!uri) return;

  const [pathname] = uri ? uri.split('?') : [];
  const sparkIdeMatch = matchPath(Private_Routes.IDE.url, pathname);
  if (sparkIdeMatch) return sparkIdeMatch.params.uid;

  const sqlIdeMatch = matchPath(Private_Routes.SQL_IDE.url, pathname);
  if (sqlIdeMatch) return sqlIdeMatch.params.uid;
}

export const getVersionAspectValue = (aspectType: AspectKindType, aspects: VersionedAspect[]) => {
  const currentAspect = aspects.find((a) => a.VersionedAspectName === aspectType);
  const aspectValue = currentAspect?.VersionedAspectValue;
  return aspectValue;
};

export const versionedAspectPathResolver = (
  aspectType: AspectKindType,
  aspects: VersionedAspect[],
  propertyName?: string
) => {
  const aspectValue = getVersionAspectValue(aspectType, aspects);
  const parsedData = aspectValue ? JSON.parse(aspectValue) : {};
  return propertyName ? parsedData[propertyName] : parsedData;
};

export const getInterimDetail = (
  specs: GemSpec[],
  processId: string,
  workflow: WorkflowGraph,
  interimData: WorkflowState['interimData'],
  interimRunId: string
) => {
  const graphPath = getGraphPathByProcess(specs, workflow, processId, PATH_ROOTS.WORKFLOW);
  const interimKeys = getProcessPortsInterimKeys(specs, graphPath as string, workflow, processId, PATH_ROOTS.WORKFLOW);

  const interims: InterimData = {};
  let isInterimAvailable = true;
  interimKeys.forEach(({ processId, portId }) => {
    const interimKey = toInterimKey({ processId, portId, runId: interimRunId });
    const interim = interimData[interimKey];
    if (!interim) {
      isInterimAvailable = false;
    } else {
      interims[interimKey] = interim;
    }
  });

  return { interims, isInterimAvailable, graphPath, interimKeys };
};

const getProcessPortsInterimKeys = (
  specs: GemSpec[],
  graphPath: string,
  workflow: WorkflowGraph,
  componentId: string,
  rootPath: string
) => {
  const isSubgraph = (currentProcess: CommonGraph) => isSubGraphOrMetaGem(currentProcess, specs);
  const { process: subProcess } = getGraphPathAndProcess(graphPath, workflow, rootPath);
  const interimKeys: InterimKey[] = [];
  let connections = workflow.connections;
  if (subProcess && isSubgraph(subProcess as CommonGraph)) {
    connections = (subProcess as CommonGraph).connections;
  }

  connections.forEach((connection) => {
    const source = connection.source;
    if (connection.target === componentId) {
      const currentProcess = getGraphProcess(workflow, graphPath, source, rootPath);
      const isProcessSubgraph = currentProcess && isSubGraphOrMetaGem(currentProcess, specs);
      const isParentSubgraph = source === subProcess?.id;

      if (isParentSubgraph || isProcessSubgraph) {
        const subgraphDetails = isProcessSubgraph
          ? findSubgraphOutgoingConnections(currentProcess as WorkflowGraph, isSubgraph)
          : findSubgraphIncomingConnections(isSubgraph, source, graphPath, workflow, rootPath);
        const subgraphInConnection = subgraphDetails[connection.sourcePort];
        subgraphInConnection &&
          interimKeys.push({
            processId: subgraphInConnection.source.id,
            portId: subgraphInConnection.sourcePort,
            target: connection.target,
            targetPort: subgraphInConnection.targetPort
          });
      } else {
        interimKeys.push({
          processId: source,
          target: connection.target,
          portId: connection.sourcePort,
          targetPort: connection.targetPort
        });
      }
    }
  });
  return interimKeys;
};

export function findSubgraphOutgoingConnections<
  G extends GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(subGraph: G, isSubgraph: isSubgraphFunctionType) {
  const map: SubgraphConnectionMap = {};
  const { connections, id, processes } = subGraph as CommonGraph;
  for (let index = 0; index < connections.length; index++) {
    const connection = connections[index];
    // looking only outgoing connections with in subgraph
    if (connection.target === id) {
      const { source, sourcePort, targetPort } = connection;
      const process = processes[source];
      if (isSubgraph(process as CommonGraph)) {
        // subgraph connected to outer subgraph
        const connectionMap = findSubgraphOutgoingConnections(process as G, isSubgraph);
        const _connection = connectionMap[connection.sourcePort];
        if (_connection) {
          map[connection.targetPort] = {
            source: _connection.source,
            sourcePort: _connection.sourcePort,
            targetPort: _connection.targetPort
          };
        }
      } else {
        map[connection.targetPort] = {
          source: process,
          sourcePort: sourcePort,
          targetPort
        };
      }
    }
  }
  return map;
}

export function findSubgraphIncomingConnections<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(isSubgraph: isSubgraphFunctionType, subgraphId: string, graphPath: string, rootGraph: G, rootPath: string) {
  const parentPath = getParentPathOfProcess(graphPath, subgraphId);
  const parentGraph: G = getGraphProperty(rootGraph, parentPath, rootPath);
  const connections = parentGraph.connections;
  const map: SubgraphConnectionMap = {};
  for (let index = 0; index < connections.length; index++) {
    const connection = connections[index];
    if (connection.target === subgraphId) {
      const currentProcess = getGraphProcess(
        rootGraph,
        graphPath.replace(`.processes.${subgraphId}`, ''),
        connection.source,
        rootPath
      );

      //Need to check if subgraph is connected to subgraph then check incoming connections of subgraph's last child node
      const isProcessSubgraph = currentProcess && isSubgraph(currentProcess as CommonGraph);
      if (connection.source === parentGraph.id || isProcessSubgraph) {
        // subgraph connected to outer subgraph
        const connectionMap = isProcessSubgraph
          ? findSubgraphOutgoingConnections(currentProcess as unknown as G, isSubgraph)
          : findSubgraphIncomingConnections(isSubgraph, parentGraph.id, parentPath, rootGraph, rootPath);
        const _connection = connectionMap[connection.sourcePort];
        if (_connection) {
          map[connection.targetPort] = {
            source: _connection.source,
            sourcePort: _connection.sourcePort,
            targetPort: _connection.targetPort
          };
        }
      } else {
        map[connection.targetPort] = {
          source: currentProcess,
          sourcePort: connection.sourcePort,
          targetPort: connection.targetPort
        };
      }
    }
  }
  return map;
}

export function getSubgraphOutgoingConnectionMap<
  P extends GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(processes: P[], isSubgraph: isSubgraphFunctionType) {
  const subgraphOutgoingConnectionMap: { [key: string]: SubgraphConnectionMap } = {};
  for (let index = 0; index < processes.length; index++) {
    const process = processes[index];
    if (isSubgraph(process as CommonGraph)) {
      subgraphOutgoingConnectionMap[process.id] = findSubgraphOutgoingConnections(process, isSubgraph);
    }
  }

  return subgraphOutgoingConnectionMap;
}

export function isIOComponent(component: ProcessKind) {
  return [ProcessKind.Source, ProcessKind.Target, ProcessKind.StreamingSource, ProcessKind.StreamingTarget].includes(
    component
  );
}

export function isStreamingIOComponent(component: ProcessKind) {
  return [ProcessKind.StreamingSource, ProcessKind.StreamingTarget].includes(component);
}

const isSharedSubgraph = (
  specs: GemSpec[],
  graph: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  path: string,
  rootPath: string,
  subgraphs: WorkflowState['subgraphs']
) => {
  let isReadOnly = false;
  if (isSubGraphOrMetaGem(graph, specs) && rootPath !== path) {
    const { externalId } = graph.properties as ReusableSubgraphProperties;
    isReadOnly = externalId ? subgraphs[externalId]?.isReadOnly : false;
  }
  return isReadOnly;
};

export function getDialogProcess(currentGraph: CommonGraph, processId: string) {
  return currentGraph.id === processId ? currentGraph : currentGraph.processes[processId];
}

function isGraphReadOnly(
  specs: GemSpec[],
  graph: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  graphPath: string,
  rootPath: string,
  subgraphs: WorkflowState['subgraphs']
): boolean {
  const currentGraph = getGraphProperty(graph, graphPath, rootPath);
  const isReadOnly =
    isSubGraphOrMetaGem(currentGraph, specs) && isSharedSubgraph(specs, currentGraph, graphPath, rootPath, subgraphs);
  if (isReadOnly) return true;
  // check if parent graph is read only
  const parentPath = getParentGraphPath(graphPath);
  if (parentPath) {
    const parentGraph = getGraphProperty(graph, parentPath, rootPath) as WorkflowGraph;
    if (isSubGraphOrMetaGem(parentGraph, specs)) {
      return isGraphReadOnly(specs, graph, parentPath, rootPath, subgraphs);
    }
  }
  return false;
}

export function useReadOnlyForReusableGraph(
  specs: GemSpec[],
  graph: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  graphPath: string
) {
  const rootPath = useSelector<WorkflowState, string>((state) => state.root);
  const subgraphs = useSelector<WorkflowState, WorkflowState['subgraphs']>((state) => state.subgraphs);
  return isGraphReadOnly(specs, graph, graphPath, rootPath, subgraphs);
}

export function getUsedPorts(edges: Edge<EdgeData>[]) {
  const usedPorts: Record<string, boolean> = {};
  edges.forEach((edge) => {
    usedPorts[edge.sourceHandle as string] = true;
    usedPorts[edge.targetHandle as string] = true;
  });
  return usedPorts;
}

export function getAspectValue<T>(aspect?: Aspect<T>[]) {
  return parseJSON<T | null>(aspect?.[0]?.AspectValue, null);
}

function isSteamingProcess<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(process: GenericGraphProcessType<G>) {
  return ([ProcessKind.StreamingSource, ProcessKind.StreamingTarget] as string[]).includes(process.component);
}

export function getGemMode<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(process: GenericGraphProcessType<G>) {
  return isSteamingProcess(process) ? WorkflowMode.stream : WorkflowMode.batch;
}

export const useIDEPrompt = ({
  enabled,
  message = 'The execution is in progress. Are you sure you want to leave?',
  isQuerySame = (prevLocation: Location, location: Transition['location']) => prevLocation.search === location.search
}: {
  enabled: boolean;
  message?: string;
  isQuerySame?: (prevLocation: Location, location: Transition['location']) => boolean;
}) => {
  const _isQuerySame = usePersistentCallback(isQuerySame);

  usePrompt((prevLocation, location) => {
    if (prevLocation.pathname === location.pathname && _isQuerySame(prevLocation, location)) return;
    return message;
  }, enabled);
};

export function useFocusFirstElement() {
  const ref = useFocusTrap(true);
  useEffect(() => {
    if (ref?.current) {
      getFirstFocusableElement(ref.current)?.focus();
    }
  }, [ref]);
  return ref;
}

export function isDeploymentUXEnabled(user: { attributes: string[] }) {
  return user.attributes.includes(UserAttributes.DeploymentAttr);
}

// this layout adds new nodes adjacent to the source node
// by shifting all the downstream nodes by a value of
// no_of_new_nodes * NODE_SPACING + no_of_new_nodes * NODE_DIMENSION
// so new nodes are placed between the source node and all it's
// downstream node and does not affect the layout of rest of the graph
export async function performShiftAdjacentNodesXLayout(
  specs: GemSpec[],
  sourceNode: Node<NodeData, string | undefined>,
  graph: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  nodes: Node<NodeData, string | undefined>[],
  edges: Edge<EdgeData>[]
) {
  const newNodes = nodes.filter(isNodeNotPositioned);
  const newLayoutedNodes = await getLayoutedElements(newNodes, edges);
  const newLayoutedNodesMap = Object.fromEntries(newLayoutedNodes.map((node) => [node.id, node]));
  const downStreamProcesses = findUpOrDownStreamProcessAndIds({
    specs,
    graph,
    upOrDownStreamProcessIds: [],
    inPortProcessId: sourceNode.id,
    isSubgraphChild: false,
    expandSubgraph: false
  });
  const nodeIdsToMove = downStreamProcesses
    .map((downStreamProcess) => downStreamProcess.processId)
    .filter((processId) => processId !== sourceNode.id);
  const downStreamProcessIdMap = Object.fromEntries(nodeIdsToMove.map((processId) => [processId, true]));
  const shiftX = newNodes.length * NODE_DIMENSION + newNodes.length * NODE_SPACING;

  const updatedNodes = nodes
    .filter((node) => downStreamProcessIdMap[node.id])
    .map((node) => {
      // for new nodes use interimNode as reference and align them, for others just shift.
      if (isNodeNotPositioned(node)) {
        const layoutedNode = newLayoutedNodesMap[node.id];
        node.position.x = sourceNode.position.x + NODE_SPACING + layoutedNode.position.x;
        node.position.y = sourceNode.position.y;
      } else {
        node.position.x += shiftX;
      }

      return node;
    });

  return updatedNodes;
}

export const useTranspilerImportParams = () => {
  const [params] = useSearchParams();
  const callBackEntity = params.get('entity') as Entity;
  const callBackEntityId = params.get('entityId');
  const name = params.get('name') || '';
  const convertLookupsToJoin = params.get('convertLookupsToJoin') || '';
  const isJobTranspile = params.get('isJobTranspile') || '';
  const isLanguageEnabled = params.get('isLanguageEnabled') || '';
  const clusterSize = params.get('clusterSize') || '';
  const status = params.get('status') || '';
  const branch = params.get('branch') || '';
  const fabricId = params.get('fabricId') || '';
  const airflowFabricId = params.get('airflowFabricId') || '';
  const language = params.get('language') || '';
  const projectId = params.get('projectId') || '';
  const teamId = params.get('teamId') || '';
  const step = (params.get('step') || 1) as string;
  const source = params.get('source') || '';
  const searchParams: CallBackEntityParams = useMemo(
    () => ({
      step,
      source,
      teamId,
      language,
      branch,
      status,
      convertLookupsToJoin,
      name,
      projectId,
      fabricId,
      airflowFabricId,
      isJobTranspile,
      clusterSize,
      isLanguageEnabled
    }),
    [
      step,
      source,
      teamId,
      language,
      branch,
      status,
      convertLookupsToJoin,
      name,
      projectId,
      fabricId,
      airflowFabricId,
      isJobTranspile,
      clusterSize,
      isLanguageEnabled
    ]
  );
  return { teamId, callBackEntity, searchParams, callBackEntityId };
};
export const useTranspilerImportURL = () => {
  const { searchParams, teamId, callBackEntity, callBackEntityId } = useTranspilerImportParams();
  let url = Private_Routes.TranspilerImport.home.getUrl(undefined, searchParams);
  if (callBackEntityId) {
    url = Private_Routes.TranspilerImport.tab.getUrl({ transpileId: callBackEntityId }, searchParams);
  }

  const getCompleteUrl = (value: string, key: string) => {
    if (callBackEntityId && value) {
      if (key === 'projectId') {
        delete searchParams.branch;
      } else if (key === 'teamId') {
        delete searchParams.branch;
        delete searchParams.fabricId;
        delete searchParams.projectId;
        delete searchParams.airflowFabricId;
        delete searchParams.clusterSize;
      }
      return Private_Routes.TranspilerImport.tab.getUrl(
        { transpileId: callBackEntityId },
        {
          ...searchParams,
          [key]: value
        }
      );
    }
    return url;
  };
  return {
    transpilerImportURl: url,
    teamId,
    isTranspiler: callBackEntity === Entity.Transpiler,
    searchParams,
    getCompleteUrl
  };
};

export function getGemType<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(graph: G, graphPath: string, componentId: string, rootPath: string) {
  const process = getGraphProcess(graph, graphPath, componentId, rootPath);
  return process ? getGemTypeByProcess(process) : undefined;
}

export function removeSlashAndSpace(str: string) {
  return str.replaceAll(/\/|\s/g, '');
}

export function getEntityIconByProcess(
  process: GenericGraphProcess<
    unknown,
    BaseProcessMetadata,
    BaseProcess<BaseProcessMetadata, unknown>,
    GraphConnection
  >,
  categoryMap: CategoryMap
) {
  const gemType = getGemTypeByProcess(process);
  const gemTypeStr = gemTypeToString(gemType);
  // fallback icon
  let icon = <EntityIcon size='xs' entity={Entity.Gem} />;

  if (EntityIconMap[gemTypeStr as EntityIconType]) {
    icon = <EntityIcon size='xs' entity={gemTypeStr as EntityIconType} />;
  }
  // icons for job IDE
  else if (Object.keys(JobCategoryMap).includes(gemTypeStr)) {
    icon = getJobNodeIcon(gemTypeStr as JobCategoryTypes);
  } else {
    // icons for workflow IDE
    const category = categoryMap?.[gemTypeStr] || '';
    let categoryWithSlash = removeSlashAndSpace(category);
    if (category === UISpecCategory.Hidden) categoryWithSlash = Entity.Subgraph;
    if (category === UISpecCategory.SourceTarget) categoryWithSlash = Entity.Dataset;
    if (process.component === SqlProcessKind.TargetModel) categoryWithSlash = Entity.Model;
    if (process.component === SqlProcessKind.TargetSnapshot) categoryWithSlash = Entity.Snapshot;
    if (process.component === SqlProcessKind.SingularDataTest) categoryWithSlash = Entity.SingularDataTest;

    const mappedEntityExist = Boolean(EntityIconMap[categoryWithSlash as EntityIconType]);
    if (mappedEntityExist) {
      icon = <EntityIcon size='xs' entity={categoryWithSlash as EntityIconType} />;
    }
  }

  return icon;
}

export function getSlugForPort(portType: string, minPorts: number, existingSlugs: string[]) {
  let reservedPortLabels: string[] = [];

  for (let index = 0; index < minPorts; index++) {
    reservedPortLabels.push(`${portType}${index}`);
  }

  return uniqueID(portType, [...existingSlugs, ...reservedPortLabels]);
}
