import { getIndexAndParentPath, PATH_KEY, PATH_SUFFIX_DELIMITER } from '@prophecy/utils/nestedData';
export const EXPANDED_SUFFIX = `${PATH_SUFFIX_DELIMITER}expanded`;
export function isRowExpanded(rowElm, row) {
    return Boolean(rowElm.querySelector('.ui-table-row-expand-icon-expanded') && row.children?.length);
}
export function haveSameParent(rowFromPath, rowToPath) {
    const { parentPath: parentPathFrom } = getIndexAndParentPath(rowFromPath);
    const { parentPath: parentPathTo } = getIndexAndParentPath(rowToPath);
    return parentPathFrom === parentPathTo;
}
export function isCreatableRow(row, index, dataSource) {
    const path = row[PATH_KEY] || `[${index}]`;
    const creatableElementPath = `[${dataSource.length}]`;
    return path === creatableElementPath;
}
export function getExpandedDetailsRowsData(data, renderExpandedCell, rowKey, expandedRows) {
    const updatedData = [];
    data?.forEach((item) => {
        const obj = { ...item };
        if (item.children) {
            obj.children = getExpandedDetailsRowsData(item.children, renderExpandedCell, rowKey, expandedRows);
        }
        updatedData.push(obj);
        const key = obj[rowKey];
        const expandedKey = obj[rowKey] + EXPANDED_SUFFIX;
        if (expandedRows[key]) {
            updatedData.push({
                ...obj,
                [rowKey]: expandedKey,
                RowComponent: renderExpandedCell(item)
            });
        }
    });
    return updatedData;
}
