import { theme } from '../theme';
import { Variant } from './types';
export const tokens = {
    BadgesRoot: {
        borderRadius: theme.radius.full,
        borderColor: theme.colors.brand100,
        borderWidth: theme.spaces.x1,
        outlineBorderwidth: '1.5px',
        white: theme.colors.white,
        fontWeight: theme.fontWeight.medium,
        variant: {
            [Variant.pillLight]: {
                borderRadius: theme.radius.full,
                backgroundShade: theme.colorShades.x50,
                borderShade: theme.colorShades.x200,
                color: theme.colors.gray700,
                colorShade: theme.colorShades.x700,
                iconShade: theme.colorShades.x500,
                closeIconShade: theme.colorShades.x400
            },
            [Variant.pillOutline]: {
                borderRadius: theme.radius.full,
                backgroundShade: theme.colorShades.x50,
                borderShade: theme.colorShades.x600,
                color: theme.colors.gray700,
                colorShade: theme.colorShades.x700,
                iconShade: theme.colorShades.x500,
                closeIconShade: theme.colorShades.x400
            },
            [Variant.pillDark]: {
                borderRadius: theme.radius.full,
                backgroundShade: theme.colorShades.x700,
                borderShade: theme.colorShades.x700,
                color: theme.colors.white,
                colorShade: theme.colorShades.x0,
                iconShade: theme.colorShades.x0,
                closeIconShade: theme.alphaColor.whiteAlpha60
            },
            [Variant.badgeColor]: {
                borderRadius: theme.radius.x6,
                backgroundShade: theme.colorShades.x50,
                borderShade: theme.colorShades.x200,
                color: theme.colors.gray700,
                colorShade: theme.colorShades.x700,
                iconShade: theme.colorShades.x600,
                closeIconShade: theme.colorShades.x400
            },
            [Variant.badgeModern]: {
                borderRadius: theme.radius.x6,
                backgroundShade: theme.colorShades.x0,
                borderShade: theme.colorShades.x300,
                color: theme.colors.gray700,
                colorShade: theme.colorShades.x0,
                iconShade: theme.colorShades.x500,
                closeIconShade: theme.colorShades.x400
            }
        },
        size: {
            xs: {
                padding: `${theme.spaces.x1} ${theme.spaces.x6}`,
                height: theme.sizes.x16,
                iconFontSize: theme.fontSizes.x12,
                fontSize: theme.fontSizes.x10
            },
            s: {
                padding: `${theme.spaces.x2} ${theme.spaces.x8}`,
                iconFontSize: theme.fontSizes.x12,
                fontSize: theme.text.xs.fontSize,
                height: theme.sizes.x22
            },
            m: {
                padding: `${theme.spaces.x2} ${theme.spaces.x10}`,
                fontSize: theme.text.sm.fontSize,
                iconFontSize: theme.fontSizes.x12,
                height: theme.sizes.x24
            },
            l: {
                padding: `${theme.spaces.x4} ${theme.spaces.x12}`,
                sidePadding: theme.spaces.x4,
                fontSize: theme.text.sm.fontSize,
                iconFontSize: theme.fontSizes.x12,
                height: theme.sizes.x28
            }
        }
    },
    BadgeGroup: {
        borderRadius: theme.radius.x10,
        iconModernBadgeColor: theme.colors.gray500,
        gap: {
            rootContainer: theme.spaces.x8,
            content: {
                trailing: theme.spaces.x4,
                leading: theme.spaces.x6
            }
        },
        size: {
            m: {
                height: theme.sizes.x30,
                iconFontSize: theme.fontSizes.x16,
                fontSize: theme.text.sm.fontSize
            },
            l: {
                height: theme.sizes.x32,
                iconFontSize: theme.fontSizes.x16,
                fontSize: theme.text.sm.fontSize
            }
        },
        padding: {
            left: `${theme.spaces.x4} ${theme.spaces.x10} ${theme.spaces.x4} ${theme.spaces.x4}`,
            right: `${theme.spaces.x4} ${theme.spaces.x4} ${theme.spaces.x4} ${theme.spaces.x10}`
        }
    }
};
export const BadgesComponentNames = {
    container: 'badges.container'
};
