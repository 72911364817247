import { Content } from '@radix-ui/react-tooltip';
import styled, { css, keyframes } from 'styled-components';
import { CopyAIcon } from '../Icons';
import { Markdown } from '../Markdown';
import { getInterFontWeightCSS, theme } from '../theme';
export const tokens = {
    TooltipContent: {
        borderRadius: theme.radius.x8,
        fontSize: theme.text.xs.fontSize,
        fontWeight: theme.fontWeight.semiBold,
        padding: `${theme.spaces.x8} ${theme.spaces.x12}`,
        zIndex: theme.zLayer.xxxl,
        transition: theme.transition.m,
        backgroundColor: theme.colors.gray950,
        color: theme.colors.white
    },
    TooltipDetails: {
        color: theme.colors.gray300,
        paddingTop: theme.spaces.x4,
        paddingRight: theme.spaces.x4
    }
};
const appear = keyframes `
  0% { 
    opacity: 0;
  }
  100% {
    opacity: 1;
  };
`;
export const tooltipAnimation = css `
  animation: ${appear} ${tokens.TooltipContent.transition} forwards;
`;
export const StyledDetail = styled.span `
  color: ${tokens.TooltipDetails.color};
  padding-top: ${(props) => (props.title ? tokens.TooltipDetails.paddingTop : '0')};
  padding-right: ${(props) => (!props.title ? tokens.TooltipDetails.paddingRight : '0')};
`;
export const StyledMarkDown = styled(Markdown) `
  color: ${tokens.TooltipContent.color};
`;
export const StyledContent = styled(Content) `
  font-family: ${theme.fontFamily.text};
  font-size: ${tokens.TooltipContent.fontSize};
  ${getInterFontWeightCSS(tokens.TooltipContent.fontWeight)};
  padding: ${tokens.TooltipContent.padding};
  background: ${tokens.TooltipContent.backgroundColor};
  border-radius: ${tokens.TooltipContent.borderRadius};
  color: ${tokens.TooltipContent.color};
  z-index: ${tokens.TooltipContent.zIndex};
  max-width: 80vw;
  gap: ${theme.spaces.x4};
  line-height: ${theme.text.xs.lineHeight};
  ${tooltipAnimation}

  ${({ $forceTooltipContentColor }) => $forceTooltipContentColor &&
    css `
      * {
        color: ${tokens.TooltipContent.color} !important;
      }
    `}
`;
export const StyledCopyIcon = styled(CopyAIcon) `
  cursor: pointer;
  z-index: ${theme.zLayer.l};
  background: inherit;
  align-self: center;
`;
