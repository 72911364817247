import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { uniqueId } from 'lodash-es';
import { forwardRef, useMemo } from 'react';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { StyledLabel, StyledHelpText, StyledLabelWithHint, StyledLabelWrapper, StyledAsteriskIcon } from './styled';
import { TextAreaRoot, StyledTextAreaContainer, StyledTextArea } from './TextArea.styled';
import { tokens } from './tokens';
import { InputVariants, ResizeDirection } from './types';
import { TriggerKind, useInput } from './useInput';
export const TextArea = forwardRef(({ label, required = false, helpText, maxLength, className, style, disabled, variant = InputVariants.primary, value, resize = ResizeDirection.vertical, onChange, placeholder, placeholderSuggestion, delay = 250, hint, tooltip, id, onBlur, onFocus, renderTextArea = (node) => node, ...restProps }, ref) => {
    const textAreaId = useMemo(() => id || uniqueId('textArea'), [id]);
    const { _value, valueLength, handleChange, handleBlur } = useInput({
        value,
        onChange,
        delay,
        onBlur
    });
    const _onFocus = (e) => {
        if (placeholderSuggestion) {
            e.currentTarget.value = placeholderSuggestion;
            e.currentTarget.select();
            handleChange(e, TriggerKind.Auto);
        }
        onFocus?.(e);
    };
    const labelUI = label ? _jsx(StyledLabel, { htmlFor: textAreaId, children: label }) : null;
    const labelUIWrapper = (_jsxs(StyledLabelWrapper, { children: [required && _jsx(StyledAsteriskIcon, { children: "*" }), labelUI] }));
    let textAreadUI = renderTextArea(_jsx(StyledTextAreaContainer, { variant: variant, disabled: disabled, "data-placeholder": placeholder, "data-value": _value, children: _jsx(StyledTextArea, { ref: ref, id: textAreaId, disabled: disabled, value: _value, resize: resize, onChange: (e) => handleChange(e, TriggerKind.User), onBlur: handleBlur, onFocus: _onFocus, placeholder: placeholderSuggestion || placeholder, "data-placeholder": placeholder, "data-value": _value, ...restProps }) }));
    if (tooltip) {
        textAreadUI = _jsx(_Fragment, { children: renderWithTooltip(textAreadUI, tooltip) });
    }
    if (!label && hint) {
        textAreadUI = _jsx(LabelWithHint, { hint: hint, children: textAreadUI });
    }
    return (_jsxs(TextAreaRoot, { style: style, className: className, gap: tokens.Root.gap, alignY: 'stretch', children: [hint && label ? (_jsx(StyledLabelWithHint, { level: 'xs', hint: hint, children: labelUIWrapper })) : (labelUIWrapper), textAreadUI, helpText && (_jsxs(StyledHelpText, { variant: variant, direction: 'horizontal', align: 'space-between', children: [helpText, maxLength && maxLength > 0 ? _jsx("span", { children: valueLength + '/' + maxLength }) : null] }))] }));
});
