import { toast } from '@prophecy/ui/Toast';
import { ProphecyError } from '@prophecy/utils/error';
import { clearStorage } from '@prophecy/utils/history';

import { captureException } from '../../common/sentry';
import { HTTPErrorTags, SentryTags } from '../../common/sentry/SentryTags';
import { isPublicURL, isAuthRoute } from '../../common/url';
import { csrf } from '../../utils/csrf';
import { AppLogLevel, appLogger } from '../apis/appLogger';
import { fetchURL } from './fetchUrl';
import { redirectToAuth } from './redirectToAuth';

export const handleUnAuthorizedCall = (status: number, url: string) => {
  const path = window.location.pathname;
  let isAuthorized = true;
  if (status === 401 && !isAuthRoute(path) && !isPublicURL(path)) {
    appLogger({
      entity: 'User',
      operation: '401 Redirect',
      log: `Previous API call: ${url}
        Current Path: ${path}`,
      logLevel: AppLogLevel.error
    });
    csrf.delete();
    toast.error({ content: `Login Expired` });
    clearStorage();
    redirectToAuth();
    captureException({
      exception: new ProphecyError(`Login Expired`),
      errorTags: { [SentryTags.ProphecyErrorHttpType]: HTTPErrorTags.SessionExpire }
    });
    isAuthorized = false;
  }
  return isAuthorized;
};

export function authorizedFetch(url: URL | RequestInfo, requestConfig: RequestInit | undefined) {
  return fetchURL(url, requestConfig, handleUnAuthorizedCall);
}
