import { jsx as _jsx } from "react/jsx-runtime";
import { BoldPlugin, ItalicPlugin, UnderlinePlugin } from '@udecode/plate-basic-marks/react';
import { useToggleCodeBlockButton } from '@udecode/plate-code-block/react';
import { useMarkToolbarButton, useMarkToolbarButtonState } from '@udecode/plate-common/react';
import { BulletedListPlugin, NumberedListPlugin, useListToolbarButtonState, useListToolbarButton } from '@udecode/plate-list/react';
import { MarkdownBoldIcon, MarkdownItalicIcon, MarkdownBulletListIcon, MarkdownNumberedListIcon, MarkdownUnderlineIcon, MarkdownCodeIcon } from '../Icons';
import { StyledButton } from './styled';
export function BoldButton() {
    const state = useMarkToolbarButtonState({ nodeType: BoldPlugin.key });
    const { props } = useMarkToolbarButton(state);
    return (_jsx(StyledButton, { variant: 'tertiaryGray', size: 'm', "data-active": state.pressed, ...props, icon: _jsx(MarkdownBoldIcon, { type: 'extended' }), tooltip: 'Bold (\u2318+B)' }));
}
export function ItalicButton() {
    const state = useMarkToolbarButtonState({ nodeType: ItalicPlugin.key });
    const { props } = useMarkToolbarButton(state);
    return (_jsx(StyledButton, { variant: 'tertiaryGray', size: 'm', "data-active": state.pressed, ...props, icon: _jsx(MarkdownItalicIcon, { type: 'extended' }), tooltip: 'Italic (\u2318+I)' }));
}
export function UnderlineButton() {
    const state = useMarkToolbarButtonState({ nodeType: UnderlinePlugin.key });
    const { props } = useMarkToolbarButton(state);
    return (_jsx(StyledButton, { variant: 'tertiaryGray', size: 'm', "data-active": state.pressed, ...props, icon: _jsx(MarkdownUnderlineIcon, { type: 'extended' }), tooltip: 'Underline (\u2318+U)' }));
}
export function UnorderedListButton() {
    const state = useListToolbarButtonState({ nodeType: BulletedListPlugin.key });
    const { props } = useListToolbarButton(state);
    return (_jsx(StyledButton, { variant: 'tertiaryGray', size: 'm', ...props, icon: _jsx(MarkdownBulletListIcon, { type: 'extended' }), tooltip: 'Unordered List' }));
}
export function OrderedListButton() {
    const state = useListToolbarButtonState({ nodeType: NumberedListPlugin.key });
    const { props } = useListToolbarButton(state);
    return (_jsx(StyledButton, { variant: 'tertiaryGray', size: 'm', ...props, icon: _jsx(MarkdownNumberedListIcon, { type: 'extended' }), tooltip: 'Ordered List' }));
}
export function CodeBlockButton() {
    const { props } = useToggleCodeBlockButton();
    return (_jsx(StyledButton, { variant: 'tertiaryGray', size: 'm', ...props, icon: _jsx(MarkdownCodeIcon, { type: 'extended' }), tooltip: 'Code Block' }));
}
