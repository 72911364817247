export var PluginType;
(function (PluginType) {
    PluginType["TextType"] = "TextType";
    PluginType["Code"] = "Code";
    PluginType["Markdown"] = "Markdown";
    PluginType["Bold"] = "Bold";
    PluginType["Italic"] = "Italic";
    PluginType["Underline"] = "Underline";
    PluginType["Strikethrough"] = "Strikethrough";
    PluginType["Subscript"] = "Subscript";
    PluginType["Superscript"] = "Superscript";
    PluginType["HorizontalRule"] = "HorizontalRule";
    PluginType["Image"] = "Image";
    PluginType["Link"] = "Link";
    PluginType["Highlight"] = "Highlight";
    PluginType["Table"] = "Table";
    PluginType["List"] = "List";
    PluginType["Comment"] = "Comment";
})(PluginType || (PluginType = {}));
