import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { EditBIcon, LoadingDIcon } from '../Icons';
import { ICON_CLASS } from '../Icons/tokens';
import { theme } from '../theme';
import { StyledHelpText } from './styled';
import { TextArea } from './TextArea';
import { StyledTextAreaContainer, StyledTextArea } from './TextArea.styled';
import { tokens } from './tokens';
import { InputVariants, ResizeDirection } from './types';
const iconStyles = css `
  font-size: ${tokens.GhostDescription.iconSize};
  color: ${tokens.GhostInput.iconColor};
  fill: ${tokens.GhostInput.iconColor};
  margin-right: ${theme.spaces.x12};
  display: block;
`;
const StyledGhostTextAreaContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${tokens.Root.gap};

  .${ICON_CLASS} {
    position: absolute;
    right: 0;
    top: ${theme.spaces.x12};
    display: none;
  }

  ${({ loading }) => (loading ? `.${ICON_CLASS} { display: block; }` : '')}

  &:hover {
    .${ICON_CLASS} {
      ${iconStyles}
    }
  }

  &:focus-within {
    .${ICON_CLASS} {
      display: none;
    }
  }
`;
const textAreaStyle = css `
  padding: ${tokens.GhostDescription.padding};
  line-height: ${tokens.GhostDescription.lineHeight};
  font-size: ${tokens.GhostDescription.fontSize};
`;
const StyledGhostTextArea = styled(TextArea) `
  ${StyledTextAreaContainer} {
    inset: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    box-shadow: none;
    border-radius: 0;
    border-bottom-color: ${({ variant }) => tokens.Input[variant].borderColor};
    ${({ $underline, variant }) => $underline &&
    variant !== InputVariants.error &&
    `&:not(:hover) {
        border-bottom-color: transparent;
      }`}
    color: ${tokens.Input.color};
    width: 100%;

    display: inline-grid;
    &[data-value='']:after,
    &:not([data-value]):after {
      content: attr(data-placeholder) ' ';
    }
    &:after {
      content: attr(data-value) ' ';
      visibility: hidden;
      white-space: pre;
    }

    &::after,
    ${StyledTextArea} {
      ${textAreaStyle}
      grid-area: 1 / 1;
      overflow-x: auto;
      overflow-y: ${({ $currentRows, $maxRows }) => ($currentRows > $maxRows ? 'auto' : 'hidden')};
      white-space: pre;
      min-height: ${tokens.GhostDescription.lineHeight} + 2 * ${tokens.GhostInput.padding};
      max-height: calc(
        ${({ $maxRows }) => $maxRows} * ${tokens.GhostDescription.lineHeight} + 2 * ${tokens.GhostInput.padding}
      );
    }
    &:focus-within {
      color: ${tokens.Input.color};
      outline: none;
      box-shadow: inset 0 -1px 0 ${({ variant }) => tokens.Input[variant].focus.borderColor};
      border-bottom: 1px solid ${({ variant }) => tokens.Input[variant].focus.borderColor};
    }

    textarea:not(:focus) {
      resize: none;
    }

    &[disabled],
    textarea[disabled] {
      cursor: not-allowed;
      background: none;
    }
  }
`;
const TextAreaWrapper = styled.div `
  position: relative;
  width: 100%;
  padding-right: ${theme.spaces.x28};
`;
export const GhostTextArea = forwardRef(({ variant = InputVariants.primary, loading, className, value, defaultValue, onChange, rows = 1, maxRows = 6, helpText, resize = ResizeDirection.none, ...restProps }, ref) => {
    const [state, setState] = useControlledState({
        value,
        defaultValue: defaultValue,
        onChange: (value, triggerKind) => onChange?.(value, triggerKind)
    });
    const currentRows = state?.split('\n').length || 0;
    const renderIcon = () => loading ? (_jsx(LoadingDIcon, { spin: true, size: 'xs', type: 'extended', color: tokens.GhostInput.loading.iconColor })) : (_jsx(EditBIcon, { type: 'solid', size: 'xs', color: tokens.GhostInput.iconColor }));
    return (_jsxs(StyledGhostTextAreaContainer, { loading: loading, className: className, children: [_jsxs(TextAreaWrapper, { children: [_jsx(StyledGhostTextArea, { variant: variant, value: state, onChange: setState, "$underline": !!state, required: false, rows: rows, "$maxRows": maxRows, "$currentRows": currentRows, ...restProps, ref: ref, resize: ResizeDirection.none }), renderIcon()] }), helpText && (_jsx(StyledHelpText, { variant: variant, direction: 'horizontal', align: 'space-between', style: { paddingLeft: tokens.GhostInput.padding }, children: helpText }))] }));
});
