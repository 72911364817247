import { jsx as _jsx } from "react/jsx-runtime";
import { LOGO_ICON_CLASS } from "./tokens";
import { ReactComponent as LogoAbinitio } from '../assets/images/Logos/logo-abinitio.svg';
import { ReactComponent as LogoAirflow } from '../assets/images/Logos/logo-airflow.svg';
import { ReactComponent as LogoAlteryx } from '../assets/images/Logos/logo-alteryx.svg';
import { ReactComponent as LogoAmazonS3 } from '../assets/images/Logos/logo-amazon-s3.svg';
import { ReactComponent as LogoAvro } from '../assets/images/Logos/logo-avro.svg';
import { ReactComponent as LogoAws } from '../assets/images/Logos/logo-aws.svg';
import { ReactComponent as LogoAzureAD } from '../assets/images/Logos/logo-azure-AD.svg';
import { ReactComponent as LogoAzureDevops } from '../assets/images/Logos/logo-Azure-Devops.svg';
import { ReactComponent as LogoAzureNew } from '../assets/images/Logos/logo-azure-new.svg';
import { ReactComponent as LogoAzure } from '../assets/images/Logos/logo-azure.svg';
import { ReactComponent as LogoBigquery } from '../assets/images/Logos/logo-bigquery.svg';
import { ReactComponent as LogoCloudComposer } from '../assets/images/Logos/logo-cloud-composer.svg';
import { ReactComponent as LogoCosmodb } from '../assets/images/Logos/logo-cosmodb.svg';
import { ReactComponent as LogoCss } from '../assets/images/Logos/logo-css.svg';
import { ReactComponent as LogoDatabricks } from '../assets/images/Logos/logo-databricks.svg';
import { ReactComponent as LogoDelta } from '../assets/images/Logos/logo-delta.svg';
import { ReactComponent as LogoEntraID } from '../assets/images/Logos/logo-entra-ID.svg';
import { ReactComponent as LogoGit } from '../assets/images/Logos/logo-git.svg';
import { ReactComponent as LogoGithub } from '../assets/images/Logos/logo-github.svg';
import { ReactComponent as LogoGoogleCloud } from '../assets/images/Logos/logo-google-cloud.svg';
import { ReactComponent as LogoGoogle } from '../assets/images/Logos/logo-google.svg';
import { ReactComponent as LogoHadoop } from '../assets/images/Logos/logo-hadoop.svg';
import { ReactComponent as LogoHashicorp } from '../assets/images/Logos/logo-hashicorp.svg';
import { ReactComponent as LogoHive } from '../assets/images/Logos/logo-hive.svg';
import { ReactComponent as LogoHtml } from '../assets/images/Logos/logo-html.svg';
import { ReactComponent as LogoHudi } from '../assets/images/Logos/logo-hudi.svg';
import { ReactComponent as LogoIbm } from '../assets/images/Logos/logo-ibm.svg';
import { ReactComponent as LogoIceberg } from '../assets/images/Logos/logo-iceberg.svg';
import { ReactComponent as LogoInformatica } from '../assets/images/Logos/logo-informatica.svg';
import { ReactComponent as LogoJavaJdbc } from '../assets/images/Logos/logo-java-jdbc.svg';
import { ReactComponent as LogoJquery } from '../assets/images/Logos/logo-jquery.svg';
import { ReactComponent as LogoJs } from '../assets/images/Logos/logo-js.svg';
import { ReactComponent as LogoKafka } from '../assets/images/Logos/logo-kafka.svg';
import { ReactComponent as LogoKubernetes } from '../assets/images/Logos/logo-kubernetes.svg';
import { ReactComponent as LogoLivy } from '../assets/images/Logos/logo-livy.svg';
import { ReactComponent as LogoMicrosoft } from '../assets/images/Logos/logo-microsoft.svg';
import { ReactComponent as LogoMongodb } from '../assets/images/Logos/logo-mongodb.svg';
import { ReactComponent as LogoMssql } from '../assets/images/Logos/logo-mssql.svg';
import { ReactComponent as LogoNpm } from '../assets/images/Logos/logo-npm.svg';
import { ReactComponent as LogoOkta } from '../assets/images/Logos/logo-okta.svg';
import { ReactComponent as LogoOrc } from '../assets/images/Logos/logo-orc.svg';
import { ReactComponent as LogoParquet } from '../assets/images/Logos/logo-parquet.svg';
import { ReactComponent as LogoPinecone } from '../assets/images/Logos/logo-pinecone.svg';
import { ReactComponent as LogoProphecy } from '../assets/images/Logos/logo-prophecy.svg';
import { ReactComponent as LogoPython } from '../assets/images/Logos/logo-python.svg';
import { ReactComponent as LogoRedshift } from '../assets/images/Logos/logo-redshift.svg';
import { ReactComponent as LogoSalesforce } from '../assets/images/Logos/logo-salesforce.svg';
import { ReactComponent as LogoScala } from '../assets/images/Logos/logo-scala.svg';
import { ReactComponent as LogoSharepoint } from '../assets/images/Logos/logo-sharepoint.svg';
import { ReactComponent as LogoSlack } from '../assets/images/Logos/logo-slack.svg';
import { ReactComponent as LogoSmartSheet } from '../assets/images/Logos/logo-SmartSheet.svg';
import { ReactComponent as LogoSnaplogic } from '../assets/images/Logos/logo-snaplogic.svg';
import { ReactComponent as LogoSnowflake } from '../assets/images/Logos/logo-snowflake.svg';
import { ReactComponent as LogoSpark2 } from '../assets/images/Logos/logo-spark-2.svg';
import { ReactComponent as LogoSpark } from '../assets/images/Logos/logo-spark.svg';
import { ReactComponent as LogoSplunk } from '../assets/images/Logos/logo-splunk.svg';
import { ReactComponent as LogoSql } from '../assets/images/Logos/logo-sql.svg';
import { ReactComponent as LogoSynapseDataEngineering } from '../assets/images/Logos/logo-Synapse-Data-Engineering.svg';
import { ReactComponent as LogoSynapseDataWarehouse } from '../assets/images/Logos/logo-Synapse-Data-Warehouse.svg';
import { ReactComponent as LogoSynapse } from '../assets/images/Logos/logo-synapse.svg';
import { ReactComponent as LogoTableau } from '../assets/images/Logos/logo-Tableau.svg';
import { ReactComponent as LogoTrino } from '../assets/images/Logos/logo-trino.svg';
const withLogoClass = (WrappedComponent) => {
    return (props) => (_jsx(WrappedComponent, { ...props, className: `${LOGO_ICON_CLASS} ${props.className || ""}` }));
};
export const LogoAbinitioIcon = withLogoClass(LogoAbinitio);
export const LogoAirflowIcon = withLogoClass(LogoAirflow);
export const LogoAlteryxIcon = withLogoClass(LogoAlteryx);
export const LogoAmazonS3Icon = withLogoClass(LogoAmazonS3);
export const LogoAvroIcon = withLogoClass(LogoAvro);
export const LogoAwsIcon = withLogoClass(LogoAws);
export const LogoAzureADIcon = withLogoClass(LogoAzureAD);
export const LogoAzureDevopsIcon = withLogoClass(LogoAzureDevops);
export const LogoAzureNewIcon = withLogoClass(LogoAzureNew);
export const LogoAzureIcon = withLogoClass(LogoAzure);
export const LogoBigqueryIcon = withLogoClass(LogoBigquery);
export const LogoCloudComposerIcon = withLogoClass(LogoCloudComposer);
export const LogoCosmodbIcon = withLogoClass(LogoCosmodb);
export const LogoCssIcon = withLogoClass(LogoCss);
export const LogoDatabricksIcon = withLogoClass(LogoDatabricks);
export const LogoDeltaIcon = withLogoClass(LogoDelta);
export const LogoEntraIDIcon = withLogoClass(LogoEntraID);
export const LogoGitIcon = withLogoClass(LogoGit);
export const LogoGithubIcon = withLogoClass(LogoGithub);
export const LogoGoogleCloudIcon = withLogoClass(LogoGoogleCloud);
export const LogoGoogleIcon = withLogoClass(LogoGoogle);
export const LogoHadoopIcon = withLogoClass(LogoHadoop);
export const LogoHashicorpIcon = withLogoClass(LogoHashicorp);
export const LogoHiveIcon = withLogoClass(LogoHive);
export const LogoHtmlIcon = withLogoClass(LogoHtml);
export const LogoHudiIcon = withLogoClass(LogoHudi);
export const LogoIbmIcon = withLogoClass(LogoIbm);
export const LogoIcebergIcon = withLogoClass(LogoIceberg);
export const LogoInformaticaIcon = withLogoClass(LogoInformatica);
export const LogoJavaJdbcIcon = withLogoClass(LogoJavaJdbc);
export const LogoJqueryIcon = withLogoClass(LogoJquery);
export const LogoJsIcon = withLogoClass(LogoJs);
export const LogoKafkaIcon = withLogoClass(LogoKafka);
export const LogoKubernetesIcon = withLogoClass(LogoKubernetes);
export const LogoLivyIcon = withLogoClass(LogoLivy);
export const LogoMicrosoftIcon = withLogoClass(LogoMicrosoft);
export const LogoMongodbIcon = withLogoClass(LogoMongodb);
export const LogoMssqlIcon = withLogoClass(LogoMssql);
export const LogoNpmIcon = withLogoClass(LogoNpm);
export const LogoOktaIcon = withLogoClass(LogoOkta);
export const LogoOrcIcon = withLogoClass(LogoOrc);
export const LogoParquetIcon = withLogoClass(LogoParquet);
export const LogoPineconeIcon = withLogoClass(LogoPinecone);
export const LogoProphecyIcon = withLogoClass(LogoProphecy);
export const LogoPythonIcon = withLogoClass(LogoPython);
export const LogoRedshiftIcon = withLogoClass(LogoRedshift);
export const LogoSalesforceIcon = withLogoClass(LogoSalesforce);
export const LogoScalaIcon = withLogoClass(LogoScala);
export const LogoSharepointIcon = withLogoClass(LogoSharepoint);
export const LogoSlackIcon = withLogoClass(LogoSlack);
export const LogoSmartSheetIcon = withLogoClass(LogoSmartSheet);
export const LogoSnaplogicIcon = withLogoClass(LogoSnaplogic);
export const LogoSnowflakeIcon = withLogoClass(LogoSnowflake);
export const LogoSpark2Icon = withLogoClass(LogoSpark2);
export const LogoSparkIcon = withLogoClass(LogoSpark);
export const LogoSplunkIcon = withLogoClass(LogoSplunk);
export const LogoSqlIcon = withLogoClass(LogoSql);
export const LogoSynapseDataEngineeringIcon = withLogoClass(LogoSynapseDataEngineering);
export const LogoSynapseDataWarehouseIcon = withLogoClass(LogoSynapseDataWarehouse);
export const LogoSynapseIcon = withLogoClass(LogoSynapse);
export const LogoTableauIcon = withLogoClass(LogoTableau);
export const LogoTrinoIcon = withLogoClass(LogoTrino);
