import { AIRFLOW_PROVIDER } from '../../constants/airflow';
import { SQL_PROVIDER } from '../../constants/sql';
import { Fabric, FabricAspects } from '../../redux/types';
import { getAspectValue } from '../utils';
import { FabricProviderMap, FabricProviderType } from './types';
import { AirflowFabricPayload, ProphecyManagedAirflowPayload } from './types/airflow';
import { DQProviderInfo, DQ_PROVIDER } from './types/dataQuality';
import { ORCHESTRATION_PROVIDER, OrchestrationProviderInfo } from './types/orchestration';
import { FabricServicePrincipalState, SPARK_PROVIDER, SparkProviderInfo } from './types/spark';
import { SqlBigQueryProvider, SqlProvider, SqlProviderInfo } from './types/sql';

type ProviderInfoTypes =
  | SparkProviderInfo
  | SqlProvider
  | AirflowFabricPayload
  | DQProviderInfo
  | OrchestrationProviderInfo;

type FabricProviderInfo<P extends ProviderInfoTypes> = P extends SparkProviderInfo
  ? {
      fabricProviderType: FabricProviderType.Spark;
      provider: SPARK_PROVIDER;
      providerInfo: SparkProviderInfo;
      fabricServicePrincipalState?: FabricServicePrincipalState;
    }
  : P extends SqlProvider
    ? {
        fabricProviderType: FabricProviderType.SQL;
        provider: SQL_PROVIDER;
        providerInfo: SqlProvider;
      }
    : P extends AirflowFabricPayload
      ? {
          fabricProviderType: FabricProviderType.Airflow;
          provider: AIRFLOW_PROVIDER;
          providerInfo: AirflowFabricPayload;
        }
      : P extends DQProviderInfo
        ? {
            fabricProviderType: FabricProviderType.DataQuality;
            provider: DQ_PROVIDER;
            providerInfo: DQProviderInfo;
          }
        : P extends OrchestrationProviderInfo
          ? {
              fabricProviderType: FabricProviderType.Orchestration;
              provider: ORCHESTRATION_PROVIDER;
              providerInfo: OrchestrationProviderInfo;
            }
          : never;

export function getFabricProviderInfo(fabric: FabricAspects) {
  const fabricServicePrincipalState = getAspectValue<FabricServicePrincipalState>(
    fabric.FabricServicePrincipalStateAspect
  );

  const sparkProviderInfo = getAspectValue<SparkProviderInfo>(
    fabric.SparkProviderInfoAspect || fabric.SparkProviderInfo
  );
  if (sparkProviderInfo) {
    const { providerType } = sparkProviderInfo;
    const cloud = sparkProviderInfo[providerType as 'databricks']?.cloud;
    const provider = cloud === SPARK_PROVIDER.PROPHECY ? SPARK_PROVIDER.PROPHECY : providerType;

    return {
      fabricProviderType: FabricProviderType.Spark,
      provider,
      providerInfo: sparkProviderInfo,
      fabricServicePrincipalState
    } as FabricProviderInfo<SparkProviderInfo>;
  }

  const sqlProviderInfo = getAspectValue<SqlProvider>(fabric.SqlProviderInfoAspect);
  if (sqlProviderInfo) {
    return {
      fabricProviderType: FabricProviderType.SQL,
      provider: sqlProviderInfo.providerInfo.providerType,
      providerInfo: sqlProviderInfo
    } as FabricProviderInfo<SqlProvider>;
  }

  const airflowProviderInfo = getAspectValue<AirflowFabricPayload>(fabric.AirflowProviderInfoAspect);
  if (airflowProviderInfo) {
    return {
      fabricProviderType: FabricProviderType.Airflow,
      provider: airflowProviderInfo.providerType,
      providerInfo: airflowProviderInfo
    } as FabricProviderInfo<AirflowFabricPayload>;
  }

  const dqProviderInfo = getAspectValue<DQProviderInfo>(fabric.DQProviderInfo);
  if (dqProviderInfo) {
    return {
      fabricProviderType: FabricProviderType.DataQuality,
      provider: dqProviderInfo.providerType,
      providerInfo: dqProviderInfo
    } as FabricProviderInfo<DQProviderInfo>;
  }

  const orchestrationProviderInfo = getAspectValue<OrchestrationProviderInfo>(fabric.OrchestrationProviderInfoAspect);
  if (orchestrationProviderInfo) {
    return {
      fabricProviderType: FabricProviderType.Orchestration,
      provider: orchestrationProviderInfo.providerType,
      providerInfo: orchestrationProviderInfo
    } as FabricProviderInfo<OrchestrationProviderInfo>;
  }
}

export function isFabricProviderMatching<P extends { fabricProviderType?: string; provider?: string }>(
  fabricProviderInfo: P,
  checkFabricProviderType: FabricProviderType,
  checkProvider?: string
) {
  const { fabricProviderType, provider } = fabricProviderInfo;
  return checkFabricProviderType === fabricProviderType && (checkProvider === undefined || checkProvider === provider);
}

const fabricProviderTypeMatchCache = new Map<string, boolean>();

export function isFabricMatching(fabric: Fabric, checkFabricProviderType: FabricProviderType, checkProvider?: string) {
  const cacheKey = `${fabric.id}-${checkFabricProviderType || ''}-${checkProvider}`;
  if (fabricProviderTypeMatchCache.has(cacheKey)) {
    return Boolean(fabricProviderTypeMatchCache.get(cacheKey));
  }
  const fabricProviderInfo = getFabricProviderInfo(fabric);

  const result = Boolean(
    fabricProviderInfo && isFabricProviderMatching(fabricProviderInfo, checkFabricProviderType, checkProvider)
  );

  fabricProviderTypeMatchCache.set(cacheKey, result);
  return result;
}

export function isSparkFabricProvider(
  fabricProviderInfo: FabricProviderInfo<ProviderInfoTypes>,
  subProvider?: string
): fabricProviderInfo is FabricProviderInfo<SparkProviderInfo> {
  return isFabricProviderMatching(fabricProviderInfo, FabricProviderType.Spark, subProvider);
}

function isAirflowFabricProvider(
  fabricProviderInfo: FabricProviderInfo<ProviderInfoTypes>,
  subProvider?: string
): fabricProviderInfo is FabricProviderInfo<AirflowFabricPayload> {
  return isFabricProviderMatching(fabricProviderInfo, FabricProviderType.Airflow, subProvider);
}

export function isSQLFabricProvider(
  fabricProviderInfo: FabricProviderInfo<ProviderInfoTypes>,
  subProvider?: string
): fabricProviderInfo is FabricProviderInfo<SqlProvider> {
  return isFabricProviderMatching(fabricProviderInfo, FabricProviderType.SQL, subProvider);
}

export function isSparkFabric(fabric: Fabric, subProvider?: string) {
  return isFabricMatching(fabric, FabricProviderType.Spark, subProvider);
}

export function isOrchestrationFabric(fabric: Fabric) {
  return isFabricMatching(fabric, FabricProviderType.Orchestration);
}

export const isSparkDatabricksFabric = (fabric: Fabric) => {
  const providerInfo = getFabricProviderInfo(fabric);
  return providerInfo
    ? isFabricProviderMatching(providerInfo, FabricProviderType.Spark, SPARK_PROVIDER.DATABRICKS) ||
        isFabricProviderMatching(providerInfo, FabricProviderType.Spark, SPARK_PROVIDER.PROPHECY)
    : false;
};

const getUTCDate = (inputDate?: string | number, time?: [number, number, number]) => {
  var date = inputDate ? new Date(inputDate) : new Date();
  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    time ? time[0] : date.getUTCHours(),
    time ? time[1] : date.getUTCMinutes(),
    time ? time[2] : date.getUTCSeconds()
  );
};

const oneDayInMs = 1000 * 3600 * 24;
const endOfDay = [23, 59, 59] as [number, number, number];
const startOfDay = [0, 0, 0] as [number, number, number];

export function getFabricExpiryInDays(fabric: Fabric, fabricProviderInfo: FabricProviderInfo<ProviderInfoTypes>) {
  if (isSparkFabricProvider(fabricProviderInfo) && fabricProviderInfo.provider !== undefined) {
    const { provider, fabricServicePrincipalState } = fabricProviderInfo;
    if (provider !== SPARK_PROVIDER.PROPHECY) return;

    const expiryDate = fabricServicePrincipalState?.expiry;
    const difference = Math.floor(
      (expiryDate
        ? getUTCDate(expiryDate, endOfDay) - getUTCDate()
        : getUTCDate() - getUTCDate(fabric.created, startOfDay)) / oneDayInMs
    );

    return expiryDate ? difference : 21 - difference;
  }

  if (isAirflowFabricProvider(fabricProviderInfo)) {
    const { providerInfo } = fabricProviderInfo;
    const expiryDate = (providerInfo as ProphecyManagedAirflowPayload).expiry;
    if (!expiryDate) return;

    // Handle never expiry case, expired - created > 5 years
    if (
      Math.floor((getUTCDate(expiryDate, endOfDay) - getUTCDate(fabric.created, startOfDay)) / oneDayInMs) >=
      5 * 365
    ) {
      return;
    }

    const difference = getUTCDate(expiryDate, endOfDay) - getUTCDate();
    return Math.floor(difference / oneDayInMs);
  }
}

export function isFabricExpired(fabric: Fabric, fabricProviderInfo: FabricProviderInfo<ProviderInfoTypes>) {
  return fabricProviderInfo ? (getFabricExpiryInDays(fabric, fabricProviderInfo) ?? 0) < 0 : false;
}

export const getFabricProviderMapValue = <T>(
  fabricMap: { [K in FabricProviderType]: { [key in FabricProviderMap[K]]: T } },
  providerInfo: ReturnType<typeof getFabricProviderInfo>
) => {
  return (
    providerInfo?.fabricProviderType &&
    (fabricMap[providerInfo.fabricProviderType] as { [key: string]: T })?.[providerInfo.provider]
  );
};

export function isSQLBigQueryProvider(provider: SqlProviderInfo): provider is SqlBigQueryProvider {
  return provider.providerType === SQL_PROVIDER.BigQuery;
}
