export const RECENT_FABRIC = 'RECENT_FABRIC_ID';
export const SESSION_FABRIC_MAP = 'SESSION_FABRIC_MAP';
export const LAST_VISITED_IDE_URL = 'LAST_VISITED_IDE_URL';
export const LAST_OBSERVATION_IDE_URL = 'LAST_OBSERVATION_IDE_URL';
export const LAST_JOB_IDE_URL = 'LAST_JOB_IDE_URL';
export const DOMAIN_URL_KEY = 'domain-url';
export const WORKFLOW_DEBUG_MODE_KEY = 'config.debug.enable';
export const EXECUTION_MORE_LOG = 'config.execution.logDetail';
export const WEBSOCKET_COMPRESSION_KEY = 'config.websocketCompression.disable';
export const SPARK_SHELL_COMMANDS_KEY = 'recentSparkShellCommands';
export const CODE_VIEW_OPENED_FILE_INFO = 'codeViewOpenedFile';
export const PROJECT_BUILD_ENABLED_KEY = 'config.project.build.enabled';
export const DBT_MACRO_ENABLED_KEY = 'config.dbt.macro.enabled';
export const DBT_PROJECT_DEPENDENCY_ENABLED_KEY = 'config.dbt.projectDependency.enabled';
export const DBT_SHOW_ALL_PROVIDERS = 'config.dbt.showAllProviders';
export const COPILOT_ENABLED_KEY = 'config.copilot.enabled';
export const PROJECT_BROWSER_OPEN_KEY = 'projectBrowserOpen';
export const isDebugMode = () => localStorage.getItem(WORKFLOW_DEBUG_MODE_KEY) === 'true';
export const isWebsocketCompressionEnabled = localStorage.getItem(WEBSOCKET_COMPRESSION_KEY) !== 'true';
export const OBSERVATION_FABRIC = 'observation.fabric';
export const EXPECTATION_DEFAULT_INTERVAL = 'expectation.interval';
export const ENABLE_FULLSTORY_FOR_PROPHECY_EMAIL = 'fullstory.prophecy.user.enabled';
export const ENABLE_TRANSPILER_LANGUAGE = 'transpiler.language';
export const UNDO_REDO_KEY = 'config.history.enabled';
export const CARRY_FORWARD_SLUG_ENABLED = 'config.carryForwardSlug.enabled';
const REACT_QUERY_DEVTOOLS_KEY = 'config.reactQueryDevtools.enabled';
export const isReactQueryDevtoolsEnabled = localStorage.getItem(REACT_QUERY_DEVTOOLS_KEY) === 'true';
export const ENABLE_SPARK_SORT_FILTER = 'config.sparkSortAndFilter.enabled';
export const SPARK_PROPHECY_MANAGED_TIMEOUT = 'spark.prophecyManaged.timeout.editable';
export const NEW_PORT_ADDED_TOAST_SHOWN = 'config.newPortAdded.shown';
//Note: We store selected algorithm on localStorage, so for next layout that becomes the selected option for that user.
export const SELECTED_LAYOUT_ALGO_KEY = 'graph.layout.algo';

const TRANSPILER_JOB_KEY = 'config.transpiler.job.enabled';
export const isTranspilerJobEnabled = localStorage.getItem(TRANSPILER_JOB_KEY) === 'true';
export const EDIT_DEFAULT_TEMPLATE_ENABLED_KEY = 'config.editDefaultTemplate.enabled';
export const ALLOW_CONFIG_TAGS = 'config.configTags.enabled';
export const ALLOW_SECRET_TYPE_CONFIG = 'config.secretType.enabled';
export const ALLOW_PARAM_CONFIG = 'config.paramConfig.enabled';
export const DEMO_BUSINESS_RULES = 'config.businessRules.demo';
export const isBusinessRulesDemoEnabled = localStorage.getItem(DEMO_BUSINESS_RULES) === 'true';
export const COPILOT_AUDIO_PROMPT_ENABLED = 'config.copilot.audio.enabled';
export const SPEC_FLOW_ENABLED = 'config.specFlow.enabled';
export const ALLOW_FABRIC_ACCESS_CLUSTER_ADMIN = 'config.fabricAccessClusterAdmin.enabled';
export const ALLOW_DATA_PROFILE_ON_INTERIMS = 'config.dataProfile.enabled';
export const PROPHY_CREDENTIALS = 'prophecy-credentials';
