import { Button, Stack, Text, theme } from '@prophecy/ui';
import { Private_Routes } from 'frontend/core/src/common/url';
import styled from 'styled-components';

const Root = styled(Stack)`
  background: ${theme.colors.grey50};
  border: 1px solid ${theme.colors.grey200};
`;

export default function DeploymentHeader({ title }: { title: string }) {
  return (
    <Root padding={`${theme.spaces.x12} ${theme.spaces.x12}`} gap={theme.spaces.x2} align='center' width='100%'>
      <Stack direction='horizontal' alignY='center' align='space-between'>
        <Text weight={theme.fontWeight.medium} level='sm13' tone={theme.colors.grey700}>
          {title}
        </Text>
        <Button
          size='s'
          elementType='link'
          to={Private_Routes.Settings.tab.getUrl({ tab: 'deployment' })}
          variant='linkGray'>
          Cancel
        </Button>
      </Stack>
    </Root>
  );
}
