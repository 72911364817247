import { fetchURL } from './fetchUrl';

export async function makeBeaconCall(url: string, body: unknown, headers?: Headers) {
  return await fetchURL(url, {
    method: 'POST',
    body: body instanceof FormData ? (body as BodyInit) : JSON.stringify(body),
    headers,
    keepalive: true
  });
}
