import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Stack } from '../Layout';
import { theme } from '../theme';
import { useHint } from './Hint';
import { Text } from './Text';
export const levelToIconSizeMap = {
    // Todo: will be replaced with new tokens
    xs: 'xxs',
    sm13: 'xxs',
    sm: 'xs',
    xl: 'xs'
};
export const LabelWithHint = ({ children, hint, level = 'sm', tone = theme.colors.gray500, ...restProps }) => {
    const _hint = useHint({ hint, level, tone });
    return (_jsx(Text, { level: level, ...restProps, children: _jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x8, alignY: 'center', children: [children, _hint] }) }));
};
