import { hostUrl } from '../../utils/getServerUrl';
import { GlobalWindow } from '../../utils/types';
import { makeBeaconCall } from '../rest/makeBeaconCall';

export enum AppLogLevel {
  'warn' = 'warn',
  'error' = 'error',
  'debug' = 'debug'
}

export async function appLogger(payload: {
  entity?: string;
  operation?: string;
  log?: string;
  logLevel?: AppLogLevel;
}) {
  const global = window as unknown as GlobalWindow;
  const uid = global.__current_user_info?.id;
  const _payload = { ...payload, uid };
  return await makeBeaconCall(hostUrl + '/api/app-logger', _payload);
}
