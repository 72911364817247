function isTextNode(node) {
    return 'text' in node;
}
function haveSameFormatting(a, b) {
    return (a.bold === b.bold &&
        a.italic === b.italic &&
        a.underline === b.underline &&
        a.strikethrough === b.strikethrough &&
        a.code === b.code);
}
function serializeNode(node) {
    switch (node.type) {
        case 'h1':
            return '# ' + serializeToMd(node.children || []) + '\n\n';
        case 'h2':
            return '## ' + serializeToMd(node.children || []) + '\n\n';
        case 'h3':
            return '### ' + serializeToMd(node.children || []) + '\n\n';
        case 'p':
            return serializeToMd(node.children || []) + '\n\n';
        case 'ul':
            return serializeList(node.children || [], true);
        case 'ol':
            return serializeList(node.children || [], false);
        case 'li':
            return serializeToMd(node.children || []) + '\n';
        case 'lic':
            return serializeToMd(node.children || []);
        case 'img':
            return `![${node.alt || ''}](${node.url || ''})\n\n`;
        case 'table':
            return serializeTable(node.children || []) + '\n\n';
        case 'tr':
            return serializeTableRow(node.children || []) + '\n';
        case 'th':
            return serializeTableCell(node.children || [], true);
        case 'td':
            return serializeTableCell(node.children || [], false);
        case 'code_block':
            return `\n\n\`\`\`${node.lang ? node.lang : ''}\n` + serializeToMd(node.children || []) + `\n\`\`\`\n\n`;
        case 'code_line':
            return '\n' + serializeToMd(node.children || []);
        default:
            return serializeToMd(node.children || []);
    }
}
function formatTextNode(node) {
    let text = node.text;
    if (!text)
        return '';
    if (node.code) {
        text = '`' + text + '`';
    }
    if (node.bold) {
        text = '**' + text + '**';
    }
    if (node.italic) {
        text = '*' + text + '*';
    }
    if (node.underline) {
        text = '<u>' + text + '</u>';
    }
    if (node.strikethrough) {
        text = '~~' + text + '~~';
    }
    return text;
}
function serializeList(nodes, unordered) {
    let markdown = '';
    nodes.forEach((node, index) => {
        if (isElement(node) && node.type === 'li') {
            let bullet = unordered ? '-' : `${index + 1}.`;
            const content = serializeToMd(node.children || []);
            markdown += `${bullet} ${content}\n`;
        }
    });
    return markdown + '\n';
}
function serializeTable(rows) {
    let markdown = '';
    let headerRow = '';
    let separatorRow = '';
    let bodyRows = '';
    rows.forEach((row, index) => {
        const rowContent = serializeTableRow(row.children || []);
        if (index === 0) {
            headerRow = rowContent.trim();
            const cellCount = row.children?.length || 0;
            separatorRow = Array(cellCount).fill('---').join(' | ');
        }
        else {
            bodyRows += rowContent;
        }
    });
    markdown = `${headerRow}\n${separatorRow}\n${bodyRows}`;
    return markdown;
}
function serializeTableRow(cells) {
    const rowContent = cells
        .map((cell) => {
        return serializeTableCell(cell.children || [], false);
    })
        .join(' | ');
    return rowContent + '\n';
}
function serializeTableCell(nodes, isHeader) {
    const content = serializeToMd(nodes).trim();
    return content;
}
function isElement(node) {
    return 'type' in node;
}
export function serializeToMd(nodes) {
    let markdown = '';
    let i = 0;
    while (i < nodes.length) {
        const node = nodes[i];
        if (isTextNode(node)) {
            // if we get two or more consecutive text nodes with same formatting, we can combine them
            // as we break the text node into multiple node to handle partial highlights
            let combinedTextNode = { ...node };
            while (i + 1 < nodes.length && isTextNode(nodes[i + 1]) && haveSameFormatting(node, nodes[i + 1])) {
                combinedTextNode.text += nodes[i + 1].text;
                i++;
            }
            markdown += formatTextNode(combinedTextNode);
        }
        else {
            markdown += serializeNode(node);
        }
        i++;
    }
    return markdown.trim();
}
