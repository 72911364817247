import { jsx as _jsx } from "react/jsx-runtime";
import gemCategoryCustomIcon from '!url-loader!../assets/new_gems/Category/GemCategoryCustom.svg';
import gemCategoryDatasetIcon from '!url-loader!../assets/new_gems/Category/GemCategoryDataset.svg';
import gemCategoryJoinSplitIcon from '!url-loader!../assets/new_gems/Category/GemCategoryJoinSplit.svg';
import gemCategoryMachineLearningIcon from '!url-loader!../assets/new_gems/Category/GemCategoryMachineLearning.svg';
import gemCategoryModelIcon from '!url-loader!../assets/new_gems/Category/GemCategoryModel.svg';
import gemCategoryParseIcon from '!url-loader!../assets/new_gems/Category/GemCategoryParse.svg';
import gemCategoryPrepareIcon from '!url-loader!../assets/new_gems/Category/GemCategoryPrepare.svg';
import gemCategoryReportIcon from '!url-loader!../assets/new_gems/Category/GemCategoryReport.svg';
import gemCategorySourceTargetIcon from '!url-loader!../assets/new_gems/Category/GemCategorySourceTarget.svg';
import gemCategorySubgraphIcon from '!url-loader!../assets/new_gems/Category/GemCategorySubgraph.svg';
import gemCategoryTransformIcon from '!url-loader!../assets/new_gems/Category/GemCategoryTransform.svg';
import gemCustomMacroIcon from '!url-loader!../assets/new_gems/Custom/GemCustomMacro.svg';
import gemCustomPythonScriptIcon from '!url-loader!../assets/new_gems/Custom/GemCustomPythonScript.svg';
import gemCustomRestAPIIcon from '!url-loader!../assets/new_gems/Custom/GemCustomRestAPI.svg';
import gemCustomSQLStatementIcon from '!url-loader!../assets/new_gems/Custom/GemCustomSQLStatement.svg';
import gemJoinSplitDynamicReplaceIcon from '!url-loader!../assets/new_gems/JoinSplit/GemJoinSplitDynamicReplace.svg';
import gemJoinSplitExceptIcon from '!url-loader!../assets/new_gems/JoinSplit/GemJoinSplitExcept.svg';
import gemJoinSplitFuzzyMatchIcon from '!url-loader!../assets/new_gems/JoinSplit/GemJoinSplitFuzzyMatch.svg';
import gemJoinSplitIntersectIcon from '!url-loader!../assets/new_gems/JoinSplit/GemJoinSplitIntersect.svg';
import gemJoinSplitJoinIcon from '!url-loader!../assets/new_gems/JoinSplit/GemJoinSplitJoin.svg';
import gemJoinSplitUnionIcon from '!url-loader!../assets/new_gems/JoinSplit/GemJoinSplitUnion.svg';
import gemModelModelTableIcon from '!url-loader!../assets/new_gems/Model/GemModelModelTable.svg';
import gemModelPythonIcon from '!url-loader!../assets/new_gems/Model/GemModelPython.svg';
import gemModelRestAPIIcon from '!url-loader!../assets/new_gems/Model/GemModelRestAPI.svg';
import gemParseJSONParseIcon from '!url-loader!../assets/new_gems/Parse/GemParseJSONParse.svg';
import gemParseTexttoColumnsIcon from '!url-loader!../assets/new_gems/Parse/GemParseTexttoColumns.svg';
import gemParseXMLParseIcon from '!url-loader!../assets/new_gems/Parse/GemParseXMLParse.svg';
import gemPrepareDataCleansingIcon from '!url-loader!../assets/new_gems/Prepare/GemPrepareDataCleansing.svg';
import gemPrepareDeduplicateIcon from '!url-loader!../assets/new_gems/Prepare/GemPrepareDeduplicate.svg';
import gemPrepareDynamicSelectIcon from '!url-loader!../assets/new_gems/Prepare/GemPrepareDynamicSelect.svg';
import gemPrepareFilterIcon from '!url-loader!../assets/new_gems/Prepare/GemPrepareFilter.svg';
import gemPrepareFlattenSchemaIcon from '!url-loader!../assets/new_gems/Prepare/GemPrepareFlattenSchema.svg';
import gemPrepareLimitIcon from '!url-loader!../assets/new_gems/Prepare/GemPrepareLimit.svg';
import gemPrepareMultiColumnEditIcon from '!url-loader!../assets/new_gems/Prepare/GemPrepareMulti-columnEdit.svg';
import gemPrepareMultiColumnRenameIcon from '!url-loader!../assets/new_gems/Prepare/GemPrepareMulti-columnRename.svg';
import gemPrepareOrderByIcon from '!url-loader!../assets/new_gems/Prepare/GemPrepareOrderBy.svg';
import gemPrepareReformatIcon from '!url-loader!../assets/new_gems/Prepare/GemPrepareReformat.svg';
import gemReportEmailPerRowIcon from '!url-loader!../assets/new_gems/Report/GemReportEmailPerRow.svg';
import gemReportEmailTableIcon from '!url-loader!../assets/new_gems/Report/GemReportEmailTable.svg';
import gemReportTableauIcon from '!url-loader!../assets/new_gems/Report/GemReportTableau.svg';
import gemSourceTargetAzureBlobStorageIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetAzureBlobStorage.svg';
import gemSourceTargetCatalogTableIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetCatalogTable.svg';
import gemSourceTargetCSVIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetCSV.svg';
import gemSourceTargetDatabricksIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetDatabricks.svg';
import gemSourceTargetDatabricksVolumesIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetDatabricksVolumes.svg';
import gemSourceTargetFIXEDIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetFIXED.svg';
import gemSourceTargetFlatFilesIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetFlatFiles.svg';
import gemSourceTargetFTPIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetFTP.svg';
import gemSourceTargetGoogleAnalyticsIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetGoogleAnalytics.svg';
import gemSourceTargetGoogleCloudIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetGoogleCloud.svg';
import gemSourceTargetGoogleDriveIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetGoogleDrive.svg';
import gemSourceTargetJSONIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetJSON.svg';
import gemSourceTargetListFilePathsIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetListFilePaths.svg';
import gemSourceTargetModelTableIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetModelTable.svg';
import gemSourceTargetMongoDBIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetMongoDB.svg';
import gemSourceTargetMSSQL2Icon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetMSSQL2.svg';
import gemSourceTargetPostgreSQLIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetPostgreSQL.svg';
import gemSourceTargetPowerBIIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetPowerBI.svg';
import gemSourceTargetS3ObjectStoreIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetS3ObjectStore.svg';
import gemSourceTargetSalesforceIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetSalesforce.svg';
import gemSourceTargetSeedsIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetSeeds.svg';
import gemSourceTargetSFTPIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetSFTP.svg';
import gemSourceTargetSharepointIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetSharepoint.svg';
import gemSourceTargetSmartSheetsIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetSmartSheets.svg';
import gemSourceTargetSnowflakeIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetSnowflake.svg';
import gemSourceTargetSQLServerIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetSQLServer.svg';
import gemSourceTargetTableauIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetTableau.svg';
import gemSourceTargetTXTIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetTXT.svg';
import gemSourceTargetUploadfromDesktopIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetUploadfromDesktop.svg';
import gemSourceTargetXLSXIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetXLSX.svg';
import gemSourceTargetXMLIcon from '!url-loader!../assets/new_gems/SourceTarget/GemSourceTargetXML.svg';
import gemSubgraphSubgraphIcon from '!url-loader!../assets/new_gems/Subgraph/GemSubgraphSubgraph.svg';
import gemTransformAggregateIcon from '!url-loader!../assets/new_gems/Transform/GemTransformAggregate.svg';
import gemTransformDataCleansingIcon from '!url-loader!../assets/new_gems/Transform/GemTransformDataCleansing.svg';
import gemTransformPivotIcon from '!url-loader!../assets/new_gems/Transform/GemTransformPivot.svg';
import gemTransformUnpivotIcon from '!url-loader!../assets/new_gems/Transform/GemTransformUnpivot.svg';
import gemTransformWindowFunctionIcon from '!url-loader!../assets/new_gems/Transform/GemTransformWindowFunction.svg';
export const GemCategoryCustom = (props) => {
    return _jsx("img", { ...props, src: gemCategoryCustomIcon, alt: 'GemCategoryCustomImage' });
};
export const GemCategoryDataset = (props) => {
    return _jsx("img", { ...props, src: gemCategoryDatasetIcon, alt: 'GemCategoryDatasetImage' });
};
export const GemCategoryJoinSplit = (props) => {
    return _jsx("img", { ...props, src: gemCategoryJoinSplitIcon, alt: 'GemCategoryJoinSplitImage' });
};
export const GemCategoryMachineLearning = (props) => {
    return _jsx("img", { ...props, src: gemCategoryMachineLearningIcon, alt: 'GemCategoryMachineLearningImage' });
};
export const GemCategoryModel = (props) => {
    return _jsx("img", { ...props, src: gemCategoryModelIcon, alt: 'GemCategoryModelImage' });
};
export const GemCategoryParse = (props) => {
    return _jsx("img", { ...props, src: gemCategoryParseIcon, alt: 'GemCategoryParseImage' });
};
export const GemCategoryPrepare = (props) => {
    return _jsx("img", { ...props, src: gemCategoryPrepareIcon, alt: 'GemCategoryPrepareImage' });
};
export const GemCategoryReport = (props) => {
    return _jsx("img", { ...props, src: gemCategoryReportIcon, alt: 'GemCategoryReportImage' });
};
export const GemCategorySourceTarget = (props) => {
    return _jsx("img", { ...props, src: gemCategorySourceTargetIcon, alt: 'GemCategorySourceTargetImage' });
};
export const GemCategorySubgraph = (props) => {
    return _jsx("img", { ...props, src: gemCategorySubgraphIcon, alt: 'GemCategorySubgraphImage' });
};
export const GemCategoryTransform = (props) => {
    return _jsx("img", { ...props, src: gemCategoryTransformIcon, alt: 'GemCategoryTransformImage' });
};
export const GemCustomMacro = (props) => {
    return _jsx("img", { ...props, src: gemCustomMacroIcon, alt: 'GemCustomMacroImage' });
};
export const GemCustomPythonScript = (props) => {
    return _jsx("img", { ...props, src: gemCustomPythonScriptIcon, alt: 'GemCustomPythonScriptImage' });
};
export const GemCustomRestAPI = (props) => {
    return _jsx("img", { ...props, src: gemCustomRestAPIIcon, alt: 'GemCustomRestAPIImage' });
};
export const GemCustomSQLStatement = (props) => {
    return _jsx("img", { ...props, src: gemCustomSQLStatementIcon, alt: 'GemCustomSQLStatementImage' });
};
export const GemJoinSplitDynamicReplace = (props) => {
    return _jsx("img", { ...props, src: gemJoinSplitDynamicReplaceIcon, alt: 'GemJoinSplitDynamicReplaceImage' });
};
export const GemJoinSplitExcept = (props) => {
    return _jsx("img", { ...props, src: gemJoinSplitExceptIcon, alt: 'GemJoinSplitExceptImage' });
};
export const GemJoinSplitFuzzyMatch = (props) => {
    return _jsx("img", { ...props, src: gemJoinSplitFuzzyMatchIcon, alt: 'GemJoinSplitFuzzyMatchImage' });
};
export const GemJoinSplitIntersect = (props) => {
    return _jsx("img", { ...props, src: gemJoinSplitIntersectIcon, alt: 'GemJoinSplitIntersectImage' });
};
export const GemJoinSplitJoin = (props) => {
    return _jsx("img", { ...props, src: gemJoinSplitJoinIcon, alt: 'GemJoinSplitJoinImage' });
};
export const GemJoinSplitUnion = (props) => {
    return _jsx("img", { ...props, src: gemJoinSplitUnionIcon, alt: 'GemJoinSplitUnionImage' });
};
export const GemModelModelTable = (props) => {
    return _jsx("img", { ...props, src: gemModelModelTableIcon, alt: 'GemModelModelTableImage' });
};
export const GemModelPython = (props) => {
    return _jsx("img", { ...props, src: gemModelPythonIcon, alt: 'GemModelPythonImage' });
};
export const GemModelRestAPI = (props) => {
    return _jsx("img", { ...props, src: gemModelRestAPIIcon, alt: 'GemModelRestAPIImage' });
};
export const GemParseJSONParse = (props) => {
    return _jsx("img", { ...props, src: gemParseJSONParseIcon, alt: 'GemParseJSONParseImage' });
};
export const GemParseTexttoColumns = (props) => {
    return _jsx("img", { ...props, src: gemParseTexttoColumnsIcon, alt: 'GemParseTexttoColumnsImage' });
};
export const GemParseXMLParse = (props) => {
    return _jsx("img", { ...props, src: gemParseXMLParseIcon, alt: 'GemParseXMLParseImage' });
};
export const GemPrepareDataCleansing = (props) => {
    return _jsx("img", { ...props, src: gemPrepareDataCleansingIcon, alt: 'GemPrepareDataCleansingImage' });
};
export const GemPrepareDeduplicate = (props) => {
    return _jsx("img", { ...props, src: gemPrepareDeduplicateIcon, alt: 'GemPrepareDeduplicateImage' });
};
export const GemPrepareDynamicSelect = (props) => {
    return _jsx("img", { ...props, src: gemPrepareDynamicSelectIcon, alt: 'GemPrepareDynamicSelectImage' });
};
export const GemPrepareFilter = (props) => {
    return _jsx("img", { ...props, src: gemPrepareFilterIcon, alt: 'GemPrepareFilterImage' });
};
export const GemPrepareFlattenSchema = (props) => {
    return _jsx("img", { ...props, src: gemPrepareFlattenSchemaIcon, alt: 'GemPrepareFlattenSchemaImage' });
};
export const GemPrepareLimit = (props) => {
    return _jsx("img", { ...props, src: gemPrepareLimitIcon, alt: 'GemPrepareLimitImage' });
};
export const GemPrepareMultiColumnEdit = (props) => {
    return _jsx("img", { ...props, src: gemPrepareMultiColumnEditIcon, alt: 'GemPrepareMultiColumnEditImage' });
};
export const GemPrepareMultiColumnRename = (props) => {
    return _jsx("img", { ...props, src: gemPrepareMultiColumnRenameIcon, alt: 'GemPrepareMultiColumnRenameImage' });
};
export const GemPrepareOrderBy = (props) => {
    return _jsx("img", { ...props, src: gemPrepareOrderByIcon, alt: 'GemPrepareOrderByImage' });
};
export const GemPrepareReformat = (props) => {
    return _jsx("img", { ...props, src: gemPrepareReformatIcon, alt: 'GemPrepareReformatImage' });
};
export const GemReportEmailPerRow = (props) => {
    return _jsx("img", { ...props, src: gemReportEmailPerRowIcon, alt: 'GemReportEmailPerRowImage' });
};
export const GemReportEmailTable = (props) => {
    return _jsx("img", { ...props, src: gemReportEmailTableIcon, alt: 'GemReportEmailTableImage' });
};
export const GemReportTableau = (props) => {
    return _jsx("img", { ...props, src: gemReportTableauIcon, alt: 'GemReportTableauImage' });
};
export const GemSourceTargetAzureBlobStorage = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetAzureBlobStorageIcon, alt: 'GemSourceTargetAzureBlobStorageImage' });
};
export const GemSourceTargetCatalogTable = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetCatalogTableIcon, alt: 'GemSourceTargetCatalogTableImage' });
};
export const GemSourceTargetCSV = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetCSVIcon, alt: 'GemSourceTargetCSVImage' });
};
export const GemSourceTargetDatabricks = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetDatabricksIcon, alt: 'GemSourceTargetDatabricksImage' });
};
export const GemSourceTargetDatabricksVolumes = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetDatabricksVolumesIcon, alt: 'GemSourceTargetDatabricksVolumesImage' });
};
export const GemSourceTargetFIXED = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetFIXEDIcon, alt: 'GemSourceTargetFIXEDImage' });
};
export const GemSourceTargetFlatFiles = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetFlatFilesIcon, alt: 'GemSourceTargetFlatFilesImage' });
};
export const GemSourceTargetFTP = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetFTPIcon, alt: 'GemSourceTargetFTPImage' });
};
export const GemSourceTargetGoogleAnalytics = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetGoogleAnalyticsIcon, alt: 'GemSourceTargetGoogleAnalyticsImage' });
};
export const GemSourceTargetGoogleCloud = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetGoogleCloudIcon, alt: 'GemSourceTargetGoogleCloudImage' });
};
export const GemSourceTargetGoogleDrive = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetGoogleDriveIcon, alt: 'GemSourceTargetGoogleDriveImage' });
};
export const GemSourceTargetJSON = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetJSONIcon, alt: 'GemSourceTargetJSONImage' });
};
export const GemSourceTargetListFilePaths = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetListFilePathsIcon, alt: 'GemSourceTargetListFilePathsImage' });
};
export const GemSourceTargetModelTable = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetModelTableIcon, alt: 'GemSourceTargetModelTableImage' });
};
export const GemSourceTargetMongoDB = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetMongoDBIcon, alt: 'GemSourceTargetMongoDBImage' });
};
export const GemSourceTargetMSSQL2 = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetMSSQL2Icon, alt: 'GemSourceTargetMSSQL2Image' });
};
export const GemSourceTargetPostgreSQL = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetPostgreSQLIcon, alt: 'GemSourceTargetPostgreSQLImage' });
};
export const GemSourceTargetPowerBI = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetPowerBIIcon, alt: 'GemSourceTargetPowerBIImage' });
};
export const GemSourceTargetS3ObjectStore = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetS3ObjectStoreIcon, alt: 'GemSourceTargetS3ObjectStoreImage' });
};
export const GemSourceTargetSalesforce = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetSalesforceIcon, alt: 'GemSourceTargetSalesforceImage' });
};
export const GemSourceTargetSeeds = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetSeedsIcon, alt: 'GemSourceTargetSeedsImage' });
};
export const GemSourceTargetSFTP = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetSFTPIcon, alt: 'GemSourceTargetSFTPImage' });
};
export const GemSourceTargetSharepoint = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetSharepointIcon, alt: 'GemSourceTargetSharepointImage' });
};
export const GemSourceTargetSmartSheets = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetSmartSheetsIcon, alt: 'GemSourceTargetSmartSheetsImage' });
};
export const GemSourceTargetSnowflake = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetSnowflakeIcon, alt: 'GemSourceTargetSnowflakeImage' });
};
export const GemSourceTargetSQLServer = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetSQLServerIcon, alt: 'GemSourceTargetSQLServerImage' });
};
export const GemSourceTargetTableau = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetTableauIcon, alt: 'GemSourceTargetTableauImage' });
};
export const GemSourceTargetTXT = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetTXTIcon, alt: 'GemSourceTargetTXTImage' });
};
export const GemSourceTargetUploadfromDesktop = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetUploadfromDesktopIcon, alt: 'GemSourceTargetUploadfromDesktopImage' });
};
export const GemSourceTargetXLSX = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetXLSXIcon, alt: 'GemSourceTargetXLSXImage' });
};
export const GemSourceTargetXML = (props) => {
    return _jsx("img", { ...props, src: gemSourceTargetXMLIcon, alt: 'GemSourceTargetXMLImage' });
};
export const GemSubgraphSubgraph = (props) => {
    return _jsx("img", { ...props, src: gemSubgraphSubgraphIcon, alt: 'GemSubgraphSubgraphImage' });
};
export const GemTransformAggregate = (props) => {
    return _jsx("img", { ...props, src: gemTransformAggregateIcon, alt: 'GemTransformAggregateImage' });
};
export const GemTransformDataCleansing = (props) => {
    return _jsx("img", { ...props, src: gemTransformDataCleansingIcon, alt: 'GemTransformDataCleansingImage' });
};
export const GemTransformPivot = (props) => {
    return _jsx("img", { ...props, src: gemTransformPivotIcon, alt: 'GemTransformPivotImage' });
};
export const GemTransformUnpivot = (props) => {
    return _jsx("img", { ...props, src: gemTransformUnpivotIcon, alt: 'GemTransformUnpivotImage' });
};
export const GemTransformWindowFunction = (props) => {
    return _jsx("img", { ...props, src: gemTransformWindowFunctionIcon, alt: 'GemTransformWindowFunctionImage' });
};
