import { getColorCode } from '@prophecy/ui/theme';

import { JobCategoryMap } from './constants';
import { JobCategoryTypes } from './types';

export function getJobNodeIcon(category: JobCategoryTypes) {
  const categoryInfo = JobCategoryMap[category];
  const tone = categoryInfo.tone;

  const Icon = categoryInfo.icon;
  return <Icon color={getColorCode(tone, 600)} />;
}
