import { Stack, BaseColor } from '@prophecy/ui';
import {
  ActivityIcon,
  CreatedIconProps,
  GemDodecaIcon,
  HexagonAIcon,
  HexagonBIcon,
  GemCustomIcon,
  PentagonIcon,
  TableIcon,
  GemTransformIcon,
  GemRadiantIcon,
  GemMlIcon,
  GemWingIcon,
  GemCategoryModel,
  GemReportIcon
} from '@prophecy/ui-v3/Icons';
import { GemIconNames } from '@prophecy/ui/Graph/types';
import {
  Hexagon,
  LoosePentagon,
  Pentagon,
  Wing,
  HalfRose,
  DiamondBlue,
  DoubleTerminated,
  TrillionOrange,
  RadiantGreen,
  Dipyramid,
  GemIconProps,
  Dodecahedron,
  HexagonOrange
} from '@prophecy/ui/Icons/GemIcons';
import { ICON_CLASS } from '@prophecy/ui/Icons/tokens';
import { getColorCode, theme } from '@prophecy/ui/theme';
import { withProps } from '@prophecy/utils/react/hoc';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { type GemType, UISpecCategory } from '../Parser/types';
import { useSpecs } from './graph/gem-specs';
import { SqlProcessKind } from './graph/types';
import { gemSpecToGemType, gemTypeToString } from './selectors';
import { Entity } from './types/Entity';

const StyledIconWrap = styled(Stack)<{ tone: BaseColor }>`
  .${ICON_CLASS} {
    font-size: ${theme.sizes.x45};
    color: ${({ tone }) => getColorCode(tone, 600)};
  }
`;

type CategoryUIMeta = {
  iconName: string;
  icon: React.FunctionComponent<GemIconProps>;
  tone: BaseColor;
  monoToneIcon: React.FunctionComponent<Omit<CreatedIconProps<string>, 'type'>>;
};

const CommonCategories: Record<string, CategoryUIMeta> = {
  [UISpecCategory.Subgraph]: {
    iconName: 'Pentagon',
    icon: Pentagon,
    monoToneIcon: withProps(PentagonIcon, { type: 'default' }),
    tone: BaseColor.blue
  }
};

export const SparkCategoryDetails: Record<string, CategoryUIMeta> = {
  ...CommonCategories,
  [UISpecCategory.SourceTarget]: {
    iconName: 'Hexagon',
    icon: Hexagon,
    monoToneIcon: withProps(HexagonBIcon, { type: 'default' }),
    tone: BaseColor.secondary
  },
  [UISpecCategory.Transform]: {
    iconName: 'LoosePentagon',
    icon: LoosePentagon,
    monoToneIcon: withProps(GemTransformIcon, { type: 'extended' }),
    tone: BaseColor.fuchsia
  },
  [UISpecCategory.JoinSplit]: {
    iconName: 'Wing',
    icon: Wing,
    monoToneIcon: withProps(GemWingIcon, { type: 'extended' }),
    tone: BaseColor.purple
  },
  [UISpecCategory.Custom]: {
    iconName: 'DoubleTerminated',
    icon: DoubleTerminated,
    monoToneIcon: withProps(GemCustomIcon, { type: 'extended' }),
    tone: BaseColor.fuchsia
  },
  // [UISpecCategory.MyGems]: { iconName: 'HalfRose', icon: HalfRose, tone: BaseColor.pink },
  [UISpecCategory.MachineLearning]: {
    iconName: 'Dipyramid',
    icon: Dipyramid,
    monoToneIcon: withProps(GemMlIcon, { type: 'extended' }),
    tone: BaseColor.yellow
  }
};

export function CustomCategoryIcon({ icon, tone }: { icon: React.ReactNode; tone: BaseColor }) {
  return (
    <StyledIconWrap width='100%' height='100%' alignY='center' align='center' tone={tone}>
      {icon}
    </StyledIconWrap>
  );
}

export const ActivityNodeIcon = (props: GemIconProps) => {
  return <CustomCategoryIcon icon={<ActivityIcon type='default' />} tone={BaseColor.orange} />;
};

export const TableNodeIcon = (props: GemIconProps & { type?: React.ComponentProps<typeof TableIcon>['type'] }) => {
  return <TableIcon type='default' color={theme.colors.secondary600} {...props} />;
};

export const PredefinedGemCategories = {
  [SqlProcessKind.TargetModel]: Entity.Model
};

const SqlCategoryDetails: Record<string, CategoryUIMeta> = {
  //SQL IDE specific categories
  Model: {
    iconName: 'HalfRose',
    icon: HalfRose,
    monoToneIcon: withProps(HexagonAIcon, { type: 'default' }),
    tone: BaseColor.pink
  },
  Snapshot: {
    iconName: 'HalfRose',
    icon: HalfRose,
    monoToneIcon: withProps(HexagonAIcon, { type: 'default' }),
    tone: BaseColor.pink
  },
  TargetModel: {
    iconName: 'GemCategoryModel',
    icon: GemCategoryModel,
    monoToneIcon: withProps(HexagonAIcon, { type: 'default' }),
    tone: BaseColor.pink
  },
  TargetSnapshot: {
    iconName: 'HalfRose',
    icon: HalfRose,
    monoToneIcon: withProps(HexagonAIcon, { type: 'default' }),
    tone: BaseColor.pink
  },
  Seed: {
    iconName: 'Hexagon',
    icon: Hexagon,
    monoToneIcon: withProps(HexagonBIcon, { type: 'default' }),
    tone: BaseColor.secondary
  },
  Source: {
    iconName: 'Dataset',
    icon: TableNodeIcon,
    monoToneIcon: withProps(TableNodeIcon, { type: 'default' }),
    tone: BaseColor.secondary
  },
  UnreferencedSource: {
    iconName: 'Dataset',
    icon: TableNodeIcon,
    monoToneIcon: withProps(TableNodeIcon, { type: 'default' }),
    tone: BaseColor.secondary
  },
  ExternalSource: {
    iconName: 'Hexagon',
    icon: Hexagon,
    monoToneIcon: withProps(HexagonBIcon, { type: 'default' }),
    tone: BaseColor.secondary
  },
  Table: {
    iconName: 'Hexagon',
    icon: Hexagon,
    monoToneIcon: withProps(HexagonBIcon, { type: 'default' }),
    tone: BaseColor.secondary
  },
  SingularDataTest: {
    iconName: 'Activity',
    icon: ActivityNodeIcon,
    monoToneIcon: withProps(ActivityIcon, { type: 'default' }),
    tone: BaseColor.orange
  },
  Test: {
    iconName: 'Activity',
    icon: ActivityNodeIcon,
    monoToneIcon: withProps(ActivityIcon, { type: 'default' }),
    tone: BaseColor.orange
  },
  TargetSingularDataTest: {
    iconName: 'Activity',
    icon: ActivityNodeIcon,
    monoToneIcon: withProps(ActivityIcon, { type: 'default' }),
    tone: BaseColor.orange
  }
};

const JobCategoryDetails: Record<string, CategoryUIMeta> = {
  SubFlow: {
    iconName: 'Pentagon',
    icon: Pentagon,
    monoToneIcon: withProps(PentagonIcon, { type: 'default' }),
    tone: BaseColor.blue
  },
  Sensor: {
    icon: Dodecahedron,
    iconName: 'Dodecahedron',
    monoToneIcon: withProps(GemDodecaIcon, { type: 'extended' }),
    tone: BaseColor.pink
  },
  Operator: {
    iconName: 'RadiantGreen',
    icon: RadiantGreen,
    monoToneIcon: withProps(GemRadiantIcon, { type: 'extended' }),
    tone: BaseColor.secondary
  },
  'Spark/SQL': {
    icon: RadiantGreen,
    iconName: 'RadiantGreen',
    monoToneIcon: withProps(GemRadiantIcon, { type: 'extended' }),
    tone: BaseColor.greenLight
  },
  'Trigger/Notify': {
    icon: TrillionOrange,
    iconName: 'TrillionOrange',
    monoToneIcon: withProps(GemReportIcon, { type: 'extended' }),
    tone: BaseColor.orange
  },
  'Data Transfer': {
    icon: HexagonOrange,
    iconName: 'HexagonOrange',
    monoToneIcon: withProps(HexagonAIcon, { type: 'default' }),
    tone: BaseColor.orange
  }
};
function getUnknownGem() {
  return {
    icon: DiamondBlue,
    iconName: 'DiamondBlue',
    tone: BaseColor.blue,
    CategoryName: 'UNKNOWN_GEM'
  } as const;
}

const UNKNOWN_GEM = getUnknownGem();
export function getUnknownGemName() {
  return getUnknownGem().CategoryName;
}

export const CategoryDetails = {
  ...CommonCategories,
  ...SparkCategoryDetails,
  ...SqlCategoryDetails,
  ...JobCategoryDetails
};
export function getGemIcon(category: string = '', pickDefault: boolean = true) {
  return CategoryDetails[category]?.icon || (pickDefault ? UNKNOWN_GEM.icon : null);
}

export function getGemIconName(category: string = '') {
  return (CategoryDetails[category]?.iconName || UNKNOWN_GEM.iconName) as GemIconNames;
}

export function getKnownCategoryName(category: string) {
  return CategoryDetails[category] ? category : UNKNOWN_GEM.CategoryName;
}

export function getCategoryMonoToneIcon(category: string) {
  return CategoryDetails[category]?.monoToneIcon;
}

export function getCategoryTone(category: string) {
  return CategoryDetails[category]?.tone || UNKNOWN_GEM.tone;
}

// Utility
export type CategoryMap = Record<string, string>;

export function useCategoryMap() {
  const gemSpecs = useSpecs();
  const categoryMap = useMemo(() => {
    const map: CategoryMap = {};
    gemSpecs.forEach((gem) => {
      const gemType = gemSpecToGemType(gem) as GemType;
      const gemTypeStr = gemTypeToString(gemType);
      map[gemTypeStr] = gem.category;
    });
    return map;
  }, [gemSpecs]);
  return categoryMap;
}
