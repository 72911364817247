import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { someNode } from '@udecode/plate-common';
import { useEditorPlugin, focusEditor, useEditorSelector } from '@udecode/plate-common/react';
import { deleteTable, insertTableRow } from '@udecode/plate-table';
import { TablePlugin, insertTable, deleteRow, deleteColumn } from '@udecode/plate-table/react';
import { Dropdown } from '../Dropdown';
import { MarkdownTableIcon, ColumnsBIcon, RowsBIcon, PlusIcon, TrashBIcon } from '../Icons';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { StyledButton, StyledIconWithLabel, StyledToolbarArrowIcon } from './styled';
function IconWithLabel({ icon, label, disabled }) {
    return (_jsxs(StyledIconWithLabel, { direction: 'horizontal', gap: theme.spaces.x8, alignY: 'center', disabled: disabled, children: [icon, _jsx("span", { children: label })] }));
}
export function TableActions() {
    const { editor, tf } = useEditorPlugin(TablePlugin);
    const tableSelected = useEditorSelector((editor) => someNode(editor, { match: { type: TablePlugin.key } }), []);
    return (_jsx(Dropdown, { overlay: _jsxs(_Fragment, { children: [_jsx(Dropdown.SubMenu, { overlay: _jsxs(_Fragment, { children: [_jsx(Dropdown.Item, { onClick: () => {
                                    insertTable(editor, { header: true, rowCount: 3, colCount: 3 }, { select: true });
                                    focusEditor(editor);
                                }, children: _jsx(IconWithLabel, { icon: _jsx(PlusIcon, { type: 'default' }), label: 'Insert Table' }) }), _jsx(Dropdown.Item, { disabled: !tableSelected, onClick: () => {
                                    deleteTable(editor);
                                    focusEditor(editor);
                                }, children: _jsx(IconWithLabel, { icon: _jsx(TrashBIcon, { type: 'default' }), label: 'Delete Table', disabled: !tableSelected }) })] }), children: _jsx(IconWithLabel, { icon: _jsx(MarkdownTableIcon, { type: 'extended' }), label: 'Table' }) }), _jsx(Dropdown.SubMenu, { disabled: !tableSelected, overlay: _jsxs(_Fragment, { children: [_jsx(Dropdown.Item, { onClick: () => {
                                    tf.insert.tableColumn();
                                    focusEditor(editor);
                                }, children: _jsx(IconWithLabel, { icon: _jsx(PlusIcon, { type: 'default' }), label: 'Insert Column' }) }), _jsx(Dropdown.Item, { onClick: () => {
                                    deleteColumn(editor);
                                    focusEditor(editor);
                                }, children: _jsx(IconWithLabel, { icon: _jsx(TrashBIcon, { type: 'default' }), label: 'Delete Column' }) })] }), children: _jsx(IconWithLabel, { icon: _jsx(ColumnsBIcon, { type: 'default' }), label: 'Column', disabled: !tableSelected }) }), _jsx(Dropdown.SubMenu, { disabled: !tableSelected, overlay: _jsxs(_Fragment, { children: [_jsx(Dropdown.Item, { onClick: () => {
                                    insertTableRow(editor);
                                    focusEditor(editor);
                                }, children: _jsx(IconWithLabel, { icon: _jsx(PlusIcon, { type: 'default' }), label: 'Insert Row' }) }), _jsx(Dropdown.Item, { onClick: () => {
                                    deleteRow(editor);
                                    focusEditor(editor);
                                }, children: _jsx(IconWithLabel, { icon: _jsx(TrashBIcon, { type: 'default' }), label: 'Delete Row' }) })] }), children: _jsx(IconWithLabel, { icon: _jsx(RowsBIcon, { type: 'default' }), label: 'Rows', disabled: !tableSelected }) })] }), children: _jsx(StyledButton, { variant: 'tertiaryGray', width: theme.sizes.x56, size: 'm', icon: _jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x4, alignY: 'center', children: [_jsx(MarkdownTableIcon, { type: 'extended' }), _jsx(StyledToolbarArrowIcon, { type: 'default' })] }) }) }));
}
