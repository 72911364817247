import { jsx as _jsx } from "react/jsx-runtime";
import { getTimezoneOffset } from '@prophecy/utils/date';
import { Select } from '../Select';
import { actualTzNames } from './actualTzNames';
const getTimezoneName = (tzName) => {
    return actualTzNames[tzName] || tzName;
};
const buildTimeZones = () => {
    const zones = Intl.supportedValuesOf('timeZone');
    const timeZoneList = [];
    zones.forEach((tz) => {
        const actualTzName = getTimezoneName(tz);
        // Actual TZ name of Asia/Calcutta is Asia/Kolkata which is not in zones - needs to be added to the new list
        // Actual TZ of Asia/Muscat is Asia/Dubai which exists in zones - Asia/Muscat to be ignored as Asia/Dubai is already in the new list
        const tzExistsInZones = zones.includes(actualTzName);
        // Actual TZ name of Indian/Cocos and Asia/Rangoon is Asia/Yangon which is not in zones - this will be added twice if timeZoneList is not checked
        const tzAlreadyAdded = timeZoneList.some((tz) => tz.value === actualTzName);
        if (actualTzName && actualTzName !== tz && (tzExistsInZones || tzAlreadyAdded)) {
            return;
        }
        const offset = getTimezoneOffset(tz);
        const [hours = 0, mins = 0] = offset.split(':').map(Number);
        const label = `(GMT${offset}) ${actualTzName.replace(/_/g, ' ')}`;
        // converts offset in hours (5) and minutes (30) to minutes (330)
        const offsetInMins = (Math.abs(hours) * 60 + mins) * (hours ? hours / Math.abs(hours) : 1);
        timeZoneList.push({
            label,
            value: actualTzName,
            offsetInMins
        });
    });
    timeZoneList.sort((a, b) => a.offsetInMins - b.offsetInMins);
    return timeZoneList;
};
const timeZoneList = buildTimeZones();
export const getUserTimezone = () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const actualName = getTimezoneName(userTimezone);
    return timeZoneList.find((tz) => tz.value === actualName);
};
export const TimezonePicker = (props) => {
    return (_jsx(Select, { ...props, showSearch: true, children: timeZoneList.map((timezone) => {
            const { value, label } = timezone;
            return (_jsx(Select.Option, { ...timezone, children: label }, value));
        }) }));
};
