import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { produceWithoutFreeze } from '@prophecy/utils/data';
import { getIndexAndParentPath, PATH_KEY, updatePath } from '@prophecy/utils/nestedData';
import { get } from 'lodash-es';
import styled from 'styled-components';
import { Badges } from '../Badges';
import { Dropdown } from '../Dropdown';
import { ExpressionBox } from '../ExpressionBox';
import { EXPRESSION_BOX_CODE_BLOCK_CLS, EXPRESSION_BOX_EDITOR_CLS } from '../ExpressionBox/base/constants';
import { Columns, Stack, StackItem } from '../Layout';
import { theme } from '../theme';
import { TableAddButton } from './styled';
import { tokens } from './tokens';
import { LogicalRows, RowPosition } from './types';
const KeyWord = styled(Badges) `
  width: ${tokens.Keyword.width};
  border-radius: ${tokens.Keyword.borderRadius};
  font-size: ${tokens.Keyword.fontSize};
  padding: 0;
`;
export function betweenConditionGroups(beforeRow, afterRow) {
    return (beforeRow &&
        afterRow &&
        (beforeRow.rowType === LogicalRows.if || beforeRow.rowType === LogicalRows.elseIf) &&
        (afterRow.rowType === LogicalRows.elseIf || afterRow.rowType === LogicalRows.else));
}
function addNewChildrenRow(dataSource, path, buildNewRow, onRowChange) {
    const row = get(dataSource, path);
    const children = row.children || [];
    const newRowPath = `${path}.children[${children.length}]`;
    const newRow = buildNewRow(newRowPath);
    newRow[PATH_KEY] = newRowPath;
    onRowChange({ ...row, children: [...children, newRow] }, path);
}
export function createIfRow(path) {
    return {
        rowType: LogicalRows.if,
        condition: { expression: '' },
        children: [],
        [PATH_KEY]: path
    };
}
export function wrapInIfGroup(rows, path) {
    return {
        rowType: LogicalRows.ifGroup,
        children: rows,
        [PATH_KEY]: path
    };
}
export function createElseIfRow(path) {
    return {
        rowType: LogicalRows.elseIf,
        condition: { expression: '' },
        children: [],
        [PATH_KEY]: path
    };
}
export function createElseRow(path) {
    return {
        rowType: LogicalRows.else,
        children: [],
        [PATH_KEY]: path
    };
}
export function createForInRow(path) {
    return {
        rowType: LogicalRows.for,
        variable: { expression: '' },
        iterable: { expression: '' },
        children: [],
        [PATH_KEY]: path
    };
}
export function correctNestedLogicalRows(data, parent) {
    for (let i = 0, ln = data.length; i < ln; i++) {
        const item = data[i];
        const { rowType } = item;
        if (rowType === LogicalRows.ifGroup) {
            // if ifGroup doesn't have any children, or just an else condition remove it.
            if (item.children?.length === 0 ||
                (item.children?.length === 1 && item.children[0].rowType === LogicalRows.else)) {
                data.splice(i, 1);
                i--;
                ln--;
                continue;
            }
        }
        // if an else is not wrapped in an ifGroup its hanging else, remove them
        if (rowType === LogicalRows.else && parent?.rowType !== LogicalRows.ifGroup) {
            data.splice(i, 1);
            i--;
            ln--;
            continue;
        }
        // if the elseIf isn't followed by if or else if, convert it to if
        if (rowType === LogicalRows.elseIf &&
            data[i - 1]?.rowType !== LogicalRows.if &&
            data[i - 1]?.rowType !== LogicalRows.elseIf) {
            item.rowType = LogicalRows.if;
        }
        // if a if condition is followed by if or elseif inside ifGroup, convert it to elseIf
        if (rowType === LogicalRows.if &&
            parent?.rowType === LogicalRows.ifGroup &&
            [LogicalRows.if, LogicalRows.elseIf].includes(data[i - 1]?.rowType)) {
            item.rowType = LogicalRows.elseIf;
        }
        // if a if/elseif/else not wrapped inside ifGroup, wrap it inside ifGroup
        if ([LogicalRows.if, LogicalRows.elseIf, LogicalRows.else].includes(rowType) &&
            !(parent && parent.rowType === LogicalRows.ifGroup)) {
            const groupStartIndex = i;
            let groupEndIndex = i + 1;
            while ([LogicalRows.elseIf, LogicalRows.else].includes(data[groupEndIndex]?.rowType)) {
                groupEndIndex++;
            }
            const ifGroupRow = wrapInIfGroup(data.slice(groupStartIndex, groupEndIndex), '');
            data.splice(groupStartIndex, groupEndIndex - groupStartIndex, ifGroupRow);
            ln = ln - (groupEndIndex - groupStartIndex) + 1;
            i--;
            continue;
        }
        // if an else condition is not in last position, move it to last.
        if (rowType === LogicalRows.else && i !== ln - 1) {
            data.splice(i, 1);
            data.push(item);
            i--;
            ln--;
            continue;
        }
        if (item.children?.length) {
            correctNestedLogicalRows(item.children, item);
        }
    }
}
const StyledExpressionBox = styled(ExpressionBox) `
  .${EXPRESSION_BOX_CODE_BLOCK_CLS}, .${EXPRESSION_BOX_EDITOR_CLS} {
    && {
      margin: 0 !important;
      width: 100% !important;
    }
  }
`;
export function IfOrElseIfRowComponent({ row, dataSource, language, suggestions, prepareSuggestions, onRowChange, onDataChange, buildNewRow, expandRow, processItem, rowType, rowComponents }) {
    const _item = row;
    const { condition, __path } = _item;
    const onChange = (expression = '') => {
        onRowChange({ ..._item, condition: { ...condition, expression } }, __path);
    };
    const { parentPath, index } = getIndexAndParentPath(__path);
    const parentArray = (parentPath ? get(dataSource, parentPath) : dataSource);
    const lastCondition = parentArray[parentArray.length - 1];
    let hasPrecedingElseBlock = lastCondition?.rowType === LogicalRows.else;
    function addNewRow() {
        addNewChildrenRow(dataSource, __path, buildNewRow, onRowChange);
        expandRow(row);
    }
    function addElseIfBlock() {
        const updatedData = produceWithoutFreeze(dataSource, (draft) => {
            const parentAry = parentPath ? get(draft, parentPath) : draft;
            const newRowPath = `${parentPath}[${index + 1}]`;
            const newRow = processItem(createElseIfRow(newRowPath));
            parentAry.splice(index + 1, 0, newRow);
            updatePath(draft, '');
        });
        onDataChange(updatedData);
    }
    function addElseBlock() {
        const updatedData = produceWithoutFreeze(dataSource, (draft) => {
            const parentAry = parentPath ? get(draft, parentPath) : draft;
            const newRowPath = `${parentPath}[${parentAry.length}]`;
            const newRow = processItem(createElseRow(newRowPath));
            parentAry.push(newRow);
            updatePath(draft, '');
        });
        onDataChange(updatedData);
    }
    const ifOptions = (_jsxs(_Fragment, { children: [_jsx(Dropdown.Item, { onClick: addNewRow, children: "Row" }), _jsx(Dropdown.Item, { onClick: addElseIfBlock, children: "Else if" }), !hasPrecedingElseBlock && _jsx(Dropdown.Item, { onClick: addElseBlock, children: "Else" })] }));
    const ExternalRowComponent = rowComponents?.[rowType];
    return (_jsxs(Columns, { width: '100%', height: '100%', gap: theme.spaces.x4, children: [ExternalRowComponent && (_jsx(Columns.Column, { width: '1fr', children: _jsx(ExternalRowComponent, { value: condition.expression, onChange: onChange, rowType: rowType }) })), !ExternalRowComponent && (_jsx(Columns.Column, { width: 'auto', children: _jsx(Stack, { align: 'center', height: '100%', children: _jsx(KeyWord, { tone: 'grayBlue', children: rowType }) }) })), !ExternalRowComponent && (_jsx(Columns.Column, { width: '1fr', padding: `0 ${theme.spaces.x4}`, children: _jsx(StyledExpressionBox, { value: condition.expression, language: condition.format || language, suggestions: suggestions, prepareSuggestions: prepareSuggestions, onChange: onChange }) })), _jsx(Columns.Column, { width: 'auto', children: _jsx(Dropdown, { overlay: ifOptions, overlayStyle: { minWidth: '100px' }, align: 'end', children: _jsx(TableAddButton, {}) }) })] }));
}
export function IfRowComponent(props) {
    return _jsx(IfOrElseIfRowComponent, { ...props, rowType: LogicalRows.if });
}
export function ElseIfRowComponent(props) {
    return _jsx(IfOrElseIfRowComponent, { ...props, rowType: LogicalRows.elseIf });
}
export function ElseComponent({ row, dataSource, onRowChange, expandRow, buildNewRow, rowComponents }) {
    const _item = row;
    const { __path } = _item;
    function addNewRow() {
        addNewChildrenRow(dataSource, __path, buildNewRow, onRowChange);
        expandRow(row);
    }
    const ExternalRowComponent = rowComponents?.[LogicalRows.else];
    return (_jsxs(Stack, { direction: 'horizontal', alignY: 'center', height: '100%', children: [ExternalRowComponent ? (_jsx(StackItem, { grow: '1', children: _jsx(ExternalRowComponent, {}) })) : (_jsxs(_Fragment, { children: [_jsx(KeyWord, { tone: 'grayBlue', children: "else" }), _jsx(StackItem, { grow: '1' })] })), _jsx(TableAddButton, { onClick: addNewRow })] }));
}
export function ForComponent({ row, dataSource, language, suggestions, prepareSuggestions, onRowChange, buildNewRow, expandRow, rowComponents }) {
    const _item = row;
    const { variable, iterable, __path } = _item;
    const onVariableChange = (expression = '') => {
        onRowChange({ ..._item, variable: { ...variable, expression } }, __path);
    };
    const onIterableChange = (expression = '') => {
        onRowChange({ ..._item, iterable: { ...iterable, expression } }, __path);
    };
    function addNewRow() {
        addNewChildrenRow(dataSource, __path, buildNewRow, onRowChange);
        expandRow(row);
    }
    const ExternalRowComponent = rowComponents?.[LogicalRows.for];
    return (_jsxs(Columns, { width: '100%', height: '100%', gap: theme.spaces.x4, children: [ExternalRowComponent && (_jsx(Columns.Column, { width: '1fr', children: _jsx(ExternalRowComponent, { variable: variable.expression, onVariableChange: onVariableChange, iterable: iterable.expression, onIterableChange: onIterableChange }) })), !ExternalRowComponent && (_jsx(Columns.Column, { width: 'auto', children: _jsx(Stack, { align: 'center', height: '100%', children: _jsx(KeyWord, { tone: 'grayBlue', children: "for" }) }) })), !ExternalRowComponent && (_jsx(Columns.Column, { width: '1fr', padding: `0 ${theme.spaces.x4}`, children: _jsx(StyledExpressionBox, { value: variable.expression, language: variable.format || language, suggestions: suggestions, prepareSuggestions: prepareSuggestions, onChange: onVariableChange }) })), !ExternalRowComponent && (_jsx(Columns.Column, { width: 'auto', children: _jsx(Stack, { align: 'center', height: '100%', children: _jsx(KeyWord, { tone: 'grayBlue', children: "in" }) }) })), !ExternalRowComponent && (_jsx(Columns.Column, { width: '1fr', padding: `0 ${theme.spaces.x4}`, children: _jsx(StyledExpressionBox, { value: iterable.expression, language: iterable.format || language, suggestions: suggestions, prepareSuggestions: prepareSuggestions, onChange: onIterableChange }) })), _jsx(Columns.Column, { width: '35px', children: _jsx(TableAddButton, { onClick: addNewRow }) })] }));
}
function getParentRow(dataSource, row) {
    const { parentPath } = getIndexAndParentPath(row[PATH_KEY]);
    return parentPath ? get(dataSource, parentPath.replace(/\.children$/, '')) : null;
}
/**
 * Independent rows are those rows which doesn't depend on any other rows like if, for and normal row
 * Elseif and else are dependent on other rows, so will have special logic to add them
 */
export function canAddIndependentRows(dataSource, row, position) {
    const parentRow = getParentRow(dataSource, row);
    /**
     * if row is if/elseif/else and we are trying to add after last element or trying to render before first element
     * then we can add normal row relative to if group, else not allowed
     */
    if ([LogicalRows.if, LogicalRows.elseIf, LogicalRows.else].includes(row.rowType)) {
        const { children } = parentRow;
        if ((row[PATH_KEY] === children?.[children.length - 1]?.[PATH_KEY] && position === RowPosition.after) ||
            (row[PATH_KEY] === children?.[0]?.[PATH_KEY] && position === RowPosition.before)) {
            return { referenceRow: parentRow, allowed: true };
        }
        return { referenceRow: row, allowed: false };
    }
    // for all other case we can add after and before the row
    return { referenceRow: row, allowed: true };
}
export function canAddElseIfRow(dataSource, row, position) {
    const parentRow = getParentRow(dataSource, row);
    /**
     * else if can be added anywhere inside if group before else
     */
    if (parentRow &&
        parentRow.rowType === LogicalRows.ifGroup &&
        !(row.rowType === LogicalRows.else && position === RowPosition.after)) {
        return { referenceRow: row, allowed: true };
    }
    return { referenceRow: row, allowed: false };
}
export function canAddElseRow(dataSource, row, position) {
    const parentRow = getParentRow(dataSource, row);
    /**
     * else can be added anywhere inside if group if an else condition is not already present
     */
    if (parentRow &&
        parentRow.rowType === LogicalRows.ifGroup &&
        !parentRow.children?.find((r) => r.rowType === LogicalRows.else)) {
        return { referenceRow: row, allowed: true };
    }
    return { referenceRow: row, allowed: false };
}
