import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { StyledSearchInput } from '../Table/ColumnFilters';
import { theme } from '../theme';
import { Table } from './TableBase';
import { TABLE_CONTAINER, TABLE_THEAD_CLASS } from './tokens';
const StyledTable = styled(Table) `
  .${TABLE_CONTAINER} {
    height: 100%;
    overflow: auto;
  }
  .ui-table-body {
    overflow-y: auto !important;
  }
  .ui-table-cell-row-hover {
    background-color: transparent;
  }
  .${TABLE_CONTAINER} {
    border: none;
    padding-right: ${theme.spaces.x12};
  }
  && .ui-table-row td {
    border-width: 0;
    padding: ${theme.spaces.x12} ${theme.spaces.x8};
  }

  .ui-table-placeholder {
    height: auto;
  }

  .${TABLE_THEAD_CLASS} {
    height: ${theme.sizes.x48};

    * {
      position: static;
    }
  }

  ${StyledSearchInput} {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    padding: ${theme.spaces.x4};
  }
`;
export function List({ data, onReorder, emptyText, renderItem, className, searchFilter, ...rest }) {
    const showSearchInput = !!searchFilter?.searchColumnKey;
    return (_jsx(StyledTable, { className: className, showEmptyMessage: emptyText ? { description: emptyText } : false, dataSource: data, height: '100%', showHeader: showSearchInput, fixedHeader: showSearchInput, placeholderRows: 0, tableLayout: 'fixed', reorder: onReorder ? { onReorder } : undefined, ...rest, children: _jsx(Table.Column, { width: '100%', dataIndex: 'rowData', title: '', 
            // @ts-ignore //TODO type fix for rcTable column to support filter and sort
            filter: searchFilter
                ? {
                    type: 'search',
                    condition: (data, value) => {
                        const searchColumnKey = searchFilter.searchColumnKey;
                        const columnValue = data.rowData?.[searchColumnKey];
                        if (columnValue) {
                            searchFilter?.onChange?.(value);
                            return columnValue?.toLowerCase().indexOf(value?.toLowerCase()) > -1;
                        }
                        return false;
                    },
                    forceUncontrolledFilter: searchFilter.forceUncontrolledFilter,
                    value: searchFilter?.value,
                    onChange: searchFilter?.onChange,
                    showSearchInput: showSearchInput,
                    searchInputProps: {
                        placeholder: 'Search...',
                        inputSize: 'm',
                        className: searchFilter.className
                    }
                }
                : null, render: (value, record, index) => {
                return renderItem(record, index);
            } }, 'rowData') }));
}
