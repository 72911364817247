import { jsx as _jsx } from "react/jsx-runtime";
import { useCodeSyntaxLeaf } from '@udecode/plate-code-block/react';
import { PlateElement, PlateLeaf } from '@udecode/plate/react';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { CodeBlockWrapper } from './styled';
const StyledPre = styled.pre `
  overflow-x: clip;
  overflow-y: auto;
  max-height: 132px;
  border-radius: 0.375rem;
  line-height: normal;
  margin: 1rem 0px;
  &&& {
    padding: 1.5rem 1rem;
    border: 0px;
  }
`;
export const CodeBlockElement = forwardRef(({ children, className, ...props }, ref) => {
    return (_jsx(CodeBlockWrapper, { ...props, className: className, children: _jsx(StyledPre, { children: _jsx("code", { children: children }) }) }));
});
export const CodeLineElement = forwardRef((props, ref) => {
    return _jsx(PlateElement, { ref: ref, ...props });
});
export const CodeSyntaxLeaf = forwardRef(({ children, ...props }, ref) => {
    const { leaf } = props;
    const { tokenProps } = useCodeSyntaxLeaf({ leaf });
    return (_jsx(PlateLeaf, { ref: ref, ...props, children: _jsx("span", { ...tokenProps, children: children }) }));
});
