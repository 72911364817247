import { Centered } from '@prophecy/ui/Layout';
import { Spinner } from '@prophecy/ui/Spinner';
import { parseJSON } from '@prophecy/utils/string';
import { useQuery } from '@tanstack/react-query';
import { noop } from 'lodash-es';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { LocalPreferenceProvider } from '../common/preferences/localPreference';
import { UserPreferenceProvider, useUserPreferenceStore } from '../common/preferences/userPreference';
import { QueryKeys } from '../common/queries/common';
import { migrateEntityFabric, toFabricObject } from '../data/apis/api';
import { errorHandler, makeGraphQlCall, useRestQuery } from '../data/util';
import { useGlobalVariables } from './globalConfig/securedConfigHook';
import { useMixPanel } from './mixpanel/context';
import { getUserQuery, UserQueryResponse, UserFabric, User } from './query';
import { UserFabricStatus, UserFabricStatusKnownStatus } from './types';

export type UserProviderType = {
  user: ReturnType<typeof toUserObject>;
  refetch: ReturnType<typeof useQuery>['refetch'];
};

const UserContext = createContext<UserProviderType | undefined>(undefined);

export function useUser() {
  return useContext(UserContext) as UserProviderType;
}

async function user(userUID: string, isSqlProject: boolean, hasAirflow: boolean) {
  return await fetchUserFabricDocu(userUID, isSqlProject, hasAirflow);
}

export const useUserSessionId = () => {
  return useSelector((state: { sessionId: string }) => state.sessionId);
};

export function UserProvider({ children, userUID }: { children: React.ReactNode; userUID: string }) {
  const globalConf = useGlobalVariables();
  const executionUrl = globalConf.getExecutionURL as string;
  const [migrated, setMigrated] = useState(false);

  useRestQuery(['migrateEntityFabric-user'], () => migrateEntityFabric(executionUrl), {
    onError: noop,
    onSettled: () => {
      setMigrated(true);
    },
    retry: false,
    refetchOnWindowFocus: false
  });

  const { identifyAndSetPeople } = useMixPanel();

  const {
    isInitialLoading: isLoading,
    isError,
    data,
    refetch
  } = useQuery(
    [QueryKeys.User],
    user.bind(null, userUID, globalConf.isSQLProjectEnabled, globalConf.isAirflowEnabled),
    {
      onError: errorHandler,
      enabled: migrated
    }
  );

  const { userPreferences, setPreference, isLoading: isUsePreferenceLoading } = useUserPreferenceStore();

  const value = useMemo(() => ({ user: data, refetch }) as UserProviderType, [data, refetch]);

  if (isLoading || isUsePreferenceLoading || isError || !data) {
    return (
      <Centered>
        <Spinner />
      </Centered>
    );
  }

  if (data?.firstName) {
    const { firstName, lastName, created, company } = data;
    const obj = {
      firstName,
      lastName,
      userId: data.id,
      signup_date: new Date(created).toISOString()
    };
    identifyAndSetPeople(data.email, company ? { ...obj, company } : obj);
  }
  return (
    <UserContext.Provider value={value}>
      <UserPreferenceProvider preferences={userPreferences} setPreference={setPreference}>
        <LocalPreferenceProvider>{children}</LocalPreferenceProvider>
      </UserPreferenceProvider>
    </UserContext.Provider>
  );
}
function toUserObject(user: User) {
  const fabrics = user.fabrics.map(toFabricObject);
  const id = user._id;
  const sqlFabricCredsMap =
    parseJSON<{ fabrics: Record<string, boolean> }>(user.SqlFabricCredsInfoAspect?.[0]?.AspectValue, {
      fabrics: {}
    })?.fabrics || {};

  const sqlFabrics: (UserFabric & { provider: string })[] = [];
  const sqlConfiguredFabrics: string[] = [];
  fabrics.forEach((fabric) => {
    if (fabric.SqlProviderInfoAspect?.length) {
      const aspectValueJson = parseJSON(fabric.SqlProviderInfoAspect?.[0]?.AspectValue, {
        providerInfo: { providerType: '' }
      });
      const providerType = aspectValueJson?.providerInfo?.providerType;
      sqlFabrics.push({ ...fabric, provider: providerType });
      if (sqlFabricCredsMap[fabric.id]) {
        sqlConfiguredFabrics.push(fabric.id);
      }
    }
  });

  let sqlFabricStatus: UserFabricStatus = UserFabricStatusKnownStatus.NoFabric;
  if (sqlFabrics.length === 0) {
    sqlFabricStatus = UserFabricStatusKnownStatus.NoFabric;
  } else if (sqlConfiguredFabrics.length > 0) {
    sqlFabricStatus = UserFabricStatusKnownStatus.AlreadyHaveFabric;
  } else {
    sqlFabricStatus = Number(sqlFabrics[0]._id);
  }

  return {
    ...user,
    id,
    fabrics,
    sqlFabrics,
    sqlFabricStatus
  };
}
async function fetchUserFabricDocu(id: string, isSqlProject: boolean, hasAirflow: boolean) {
  const query = getUserQuery(isSqlProject, hasAirflow);
  const { User: user } = await (makeGraphQlCall(query, { id }) as Promise<UserQueryResponse>);
  if (user) {
    return toUserObject(user);
  }
}
