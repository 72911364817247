import { Label as RadixLabel, Separator as RadixSeparator, Content as RadixContent, SubContent as RadixSubContent, Item as RadixItem, SubTrigger as RadixSubTrigger, Trigger as RadixTrigger } from '@radix-ui/react-dropdown-menu';
import styled, { css } from 'styled-components';
import { disableButtonFocusRing } from '../Button/styled';
import { Stack } from '../Layout';
import { getInterFontWeightCSS, theme } from '../theme';
import { DISABLED_STATE_ATTRIBUTE, SELECTED_STATE_ATTRIBUTE, tokens } from './tokens';
import { Size } from './types';
export const itemStyles = css `
  all: unset;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: ${tokens.Item.fontFamily};
  font-size: ${tokens.Item.fontSize};
  ${getInterFontWeightCSS(tokens.Item.fontWeight)};
  line-height: ${tokens.Item.lineHeight};
  letter-spacing: ${tokens.Item.letterSpacing};
  color: ${tokens.Item.color};
  cursor: pointer;
  padding: ${({ size = Size.md }) => tokens.size[size].padding};
  border-radius: ${tokens.Item.borderRadius};
  &:focus,
  &:hover {
    background: ${tokens.Item.state.hovered.background};
  }

  &[${SELECTED_STATE_ATTRIBUTE}='true'] {
    background: ${tokens.Item.state.selected.background};
  }

  ${({ dragging, draggingStarted }) => dragging || draggingStarted
    ? `
      ${dragging
        ? `
        background: ${tokens.Item.state.dragging.background};
        border: 1px solid ${tokens.Item.state.dragging.borderColor};
        box-shadow: ${tokens.Item.state.dragging.boxShadow};
      `
        : `
        border: 1px dashed ${tokens.Item.state.draggingStarted.borderColor};
        color: ${tokens.Item.state.draggingStarted.color};
      `}
  `
    : ''}

  &[${DISABLED_STATE_ATTRIBUTE}] {
    cursor: not-allowed;
    color: ${tokens.Item.state.disabled.color};
    box-shadow: none;
    &:focus,
    &:hover {
      background: unset;
    }
  }
`;
export const contentStyles = css `
  display: flex;
  flex-direction: column;

  min-width: 220px;
  padding: ${({ size = Size.md }) => tokens.size[size].padding};
  background: ${tokens.Content.background};
  box-shadow: ${tokens.Content.shadow};
  border-radius: ${tokens.Content.borderRadius};
  border: 1px solid ${tokens.Content.borderColor};
  z-index: ${tokens.Content.zIndex};
  gap: ${tokens.Content.gap};
`;
export const Content = styled(RadixContent) `
  ${contentStyles}
`;
export const SubContent = styled(RadixSubContent) `
  ${contentStyles}
`;
export const labelStyles = css `
  font-family: ${tokens.Label.fontFamily};
  font-size: ${tokens.Label.fontSize};
  ${getInterFontWeightCSS(tokens.Label.fontWeight)};
  line-height: ${tokens.Label.lineHeight};
  letter-spacing: ${tokens.Label.letterSpacing};
  padding: ${tokens.Label.padding};
  color: ${tokens.Label.color};
`;
export const Label = styled(RadixLabel) `
  ${labelStyles}
`;
export const Item = styled(RadixItem) `
  ${itemStyles}
`;
export const SubTrigger = styled(RadixSubTrigger) `
  ${itemStyles}
`;
export const Separator = styled(RadixSeparator) `
  margin: ${tokens.Separator.margin} calc(-1 * ${theme.spaces.x8});
  height: ${tokens.Separator.height};
  background-color: ${tokens.Separator.backgroundColor};
  width: calc(100% + ${theme.spaces.x16});
`;
export const StyledTrigger = styled(RadixTrigger) `
  ${disableButtonFocusRing}
  // TODO: We need to implement a consistent focus ring solution that works with focus-visible.
  // Currently disabling focus ring completely in dropdown to avoid inconsistent behavior across browsers.
  &&&:focus-visible {
    box-shadow: none;
  }
`;
export const DropdownFooterContainer = styled(Stack) `
  padding: ${theme.spaces.x8};
  gap: ${theme.spaces.x8};
  border-top: 1px solid ${theme.colors.gray200};
  background: ${theme.colors.gray50};
`;
