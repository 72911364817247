import { jsx as _jsx } from "react/jsx-runtime";
import { Breadcrumb } from '../Breadcrumb';
import { getURIParts } from './utils';
export default function EditorBreadcrumb({ file, ...restProps }) {
    const parts = getURIParts(file);
    let crumbs = [];
    parts.forEach((part, i) => {
        if (i < 6 || i === parts.length - 1) {
            crumbs.push({
                value: part,
                children: part
            });
        }
    });
    return _jsx(Breadcrumb, { ...restProps, active: parts[parts.length - 1], crumbs: crumbs });
}
