import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useScrollableParent } from '@prophecy/utils/hook';
import { useControlledState } from '@prophecy/utils/react/hooks';
import RCCascader from 'rc-cascader';
import RCSelect from 'rc-select';
import { useEffect, useRef } from 'react';
import { ChevronDownIcon } from '../Icons';
import { StyledLabel, StyledHelpText } from '../Input/styled';
import { Centered } from '../Layout';
import { Spinner } from '../Spinner';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { useBoxDimension } from '../ViewBox';
import { StyledSelectRoot, StyledSelectWrapper, StyledDropdownContent, StyledDropdown, StyledIconWrap, StyledRightContent, StyledSelectArrow, StyledSelectFooterWrapper, VirtualScrollCss, StyledLoadingIconWrap, StyledXCircleIcon } from './styled';
import { SELECT_ROOT_CLASS, tokens } from './tokens';
import { SelectVariants, SelectSize } from './types';
export function createCustomSelect(Component) {
    return function Select({ label, helpText, 
    /*
     * Set something to notFoundContent to force dropdown render or
     * set notFoundContent to ' ' (whitespace) to force dropdown render
     * when options are empty but footer is needed
     */
    notFoundContent, hint, variant = SelectVariants.primary, size = SelectSize.m, footer, icon, optionCTA, open, tooltip, defaultOpen, showDownIcon = true, onDropdownVisibleChange, style, className, loading, dropdownMatchSelectWidth, value: _value, defaultValue, onChange: _onChange, dropdownRender: _dropdownRender, ...restProps }) {
        const dropdownContent = useRef(null);
        const selectRoot = useRef(null);
        const [value, onChange] = useControlledState({
            value: _value,
            onChange: (value, ...args) => _onChange?.(value, args[0]),
            defaultValue
        });
        const selectRootDim = useBoxDimension(() => (dropdownMatchSelectWidth === true ? selectRoot.current : undefined));
        // change icon and notFound content if loading
        if (loading) {
            notFoundContent = _jsx(Spinner, {});
        }
        const [_open, toggle] = useControlledState({
            value: open,
            defaultValue: defaultOpen,
            onChange: onDropdownVisibleChange
        });
        useEffect(() => {
            if (!_open) {
                selectRoot?.current?.querySelector('input')?.blur?.();
            }
        }, [_open]);
        useScrollableParent({ rootNode: selectRoot?.current, visible: _open, onParentScroll: () => toggle(!_open) });
        const dropdownRender = (menu) => {
            if (!_open) {
                return _jsx(_Fragment, {});
            }
            let dropdownContentWidth;
            if (typeof dropdownMatchSelectWidth === 'number') {
                dropdownContentWidth = dropdownMatchSelectWidth;
            }
            else if (dropdownMatchSelectWidth) {
                dropdownContentWidth = selectRootDim?.width;
            }
            return (_jsxs(StyledDropdownContent, { ref: dropdownContent, width: dropdownContentWidth, selectSize: size, children: [_dropdownRender ? _dropdownRender(menu) : menu, footer && _jsx(StyledSelectFooterWrapper, { children: footer })] }));
        };
        const handleRightContent = (_jsxs(StyledRightContent, { direction: 'horizontal', alignY: 'center', gap: tokens.Trigger.sizes[size].gap, onClick: () => toggle(!_open), children: [value ? optionCTA : null, _jsx(StyledSelectArrow, { children: _jsx(ChevronDownIcon, { type: 'default' }) })] }));
        let labelUI = label ? _jsx(StyledLabel, { children: label }) : null;
        if (label && hint) {
            labelUI = _jsx(LabelWithHint, { hint: hint, children: labelUI });
        }
        const loadingIcon = (_jsx(StyledLoadingIconWrap, { size: size, children: _jsx(Spinner, {}) }));
        let baseComponent;
        const commonProps = {
            prefixCls: SELECT_ROOT_CLASS,
            suffixIcon: loading ? loadingIcon : handleRightContent,
            clearIcon: _jsx(StyledXCircleIcon, { type: 'solid', color: tokens.Icon.CloseIconColor, "$loading": loading }),
            open: _open,
            onDropdownVisibleChange: toggle,
            dropdownRender,
            notFoundContent: notFoundContent ? _jsx(Centered, { children: notFoundContent }) : null
        };
        if (Component === RCSelect) {
            baseComponent = _jsx(RCSelect, { ...commonProps, ...restProps, value: value, onChange: onChange });
        }
        else {
            baseComponent = (_jsx(RCCascader, { ...commonProps, ...restProps, value: value, onChange: onChange }));
        }
        let selectUI = (_jsxs(StyledSelectWrapper, { size: size, variant: variant, hasIcon: Boolean(icon), children: [icon && (_jsx(StyledIconWrap, { size: size, absolute: true, children: icon })), baseComponent, _jsx(StyledDropdown, {})] }));
        if (tooltip) {
            selectUI = _jsx(_Fragment, { children: renderWithTooltip(selectUI, tooltip) });
        }
        if (hint && !label) {
            selectUI = _jsx(LabelWithHint, { hint: hint, children: selectUI });
        }
        return (_jsxs(StyledSelectRoot, { gap: tokens.Root.gap, ref: selectRoot, style: style, className: className, children: [_jsx(VirtualScrollCss, {}), labelUI, selectUI, helpText && (_jsx(StyledHelpText, { variant: variant, direction: 'horizontal', align: 'flex-start', children: helpText }))] }));
    };
}
