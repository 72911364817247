import { Centered, Stack, theme, Text, Display, Button, Badges, BaseColor } from '@prophecy/ui';
import { CopilotLogoXs16pxIcon } from '@prophecy/ui-v3/Icons';
import { negateVariable } from '@prophecy/ui-v3/theme';
import { BadgesComponentNames } from '@prophecy/ui/Badges/tokens';
import styled from 'styled-components';

import { ReactComponent as SparkLogo } from '../../assets/spark_logo.svg';
import { ReactComponent as SqlLogo } from '../../assets/sql_logo.svg';
import { useOnBoardingSkipFlow } from '../../common/onboarding/hooks';
import { Onboarding } from '../../common/user-analytics/events';

const Card = styled.a`
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 0;
  gap: ${theme.spaces.x4};
  padding: ${theme.spaces.x24};
  width: 440px;
  height: 198px;

  background: ${theme.colors.white};
  border-radius: ${theme.radius.m};
  border: 1px solid ${theme.colors.white};
  box-shadow: ${theme.shadows.sm};
  &:hover,
  &:active {
    border: 1px solid ${theme.colors.primary300};
    box-shadow:
      ${theme.shadows.sm},
      0px 0px 0px 4px ${theme.colors.primary100};
  }
`;
const StyledCopilotLogo = styled(CopilotLogoXs16pxIcon)`
  width: 12px;
  height: 12px;
`;
const StyledBadges = styled(Badges)`
  &&& {
    margin-top: ${theme.spaces.x6};
    border-color: transparent;
    ${StyledCopilotLogo} {
      margin-left: ${negateVariable(theme.spaces.x4)};
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  [data-component='${BadgesComponentNames.container}'] {
    gap: ${theme.spaces.x4};
    svg {
      position: relative;
      bottom: 1px;
    }
  }
`;
export function OnboardingHome() {
  const { track, navigate, onSkip, sqlOnboardingUrl, sparkOnboardingUrl } = useOnBoardingSkipFlow();

  function sqlClick() {
    track(
      Onboarding.onboarding_home.events.onboarding_home_flow_type,
      {
        onboarding_home_flow_type: 'sql'
      },
      Onboarding.onboarding_home.event_category
    );
    navigate(sqlOnboardingUrl);
  }
  function sparkClick() {
    track(
      Onboarding.onboarding_home.events.onboarding_home_flow_type,
      {
        onboarding_home_flow_type: 'spark'
      },
      Onboarding.onboarding_home.event_category
    );
    navigate(sparkOnboardingUrl);
  }
  return (
    <Stack height='100%'>
      <Centered>
        <Stack gap={theme.spaces.x48} alignY='center'>
          <Display level='xs' weight={theme.fontWeight.medium} color={theme.colors.grey900}>
            Welcome to Prophecy
          </Display>
          <Stack direction='horizontal' gap={theme.spaces.x24}>
            <Card onClick={sqlClick}>
              <Stack direction='horizontal'>
                <SqlLogo />
                <StyledBadges size='s' tone={BaseColor.purple} icon={<StyledCopilotLogo />} iconPlacement='left'>
                  <Text level='xs' weight={theme.fontWeight.semiBold} color={theme.colors.grey900}>
                    AI
                  </Text>
                </StyledBadges>
              </Stack>

              <Text level='xl' weight={theme.fontWeight.semiBold} color={theme.colors.grey900}>
                Low-code SQL
              </Text>
              <Text level='sm' weight={theme.fontWeight.normal} color={theme.colors.grey600}>
                Design a robust Pipeline powered by SQL and AI Copilot
              </Text>
            </Card>
            <Card onClick={sparkClick}>
              <SparkLogo />
              <Text level='xl' weight={theme.fontWeight.semiBold} color={theme.colors.grey900}>
                Low-code Spark
              </Text>
              <Text level='sm' weight={theme.fontWeight.normal} color={theme.colors.grey600}>
                Create a Pipeline using visual operators that turns into code
              </Text>
            </Card>
          </Stack>
          <Stack direction='horizontal' width='100%' align='end'>
            <Button variant='secondaryGray' onClick={onSkip}>
              Skip
            </Button>
          </Stack>
        </Stack>
      </Centered>
    </Stack>
  );
}
