import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import { CheckIcon, MinusIcon } from '../Icons';
import { Stack } from '../Layout';
import { theme } from '../theme/index';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { StyledCheckboxRoot, StyledCheckboxIndicator, CheckboxLabel, StyledHelpText } from './styled';
import { Size } from './types';
export const Checkbox = ({ checked, defaultChecked, indeterminate, disabled, onChange, children, size = Size.s, hint, tooltip, helpText, ...restProps }) => {
    const [checkedState, setCheckedState] = useControlledState({
        value: indeterminate ? 'indeterminate' : checked,
        defaultValue: defaultChecked,
        onChange: (value) => onChange?.(value)
    });
    let checkBoxUI = (_jsx(StyledCheckboxRoot, { checked: !!checkedState, size: size, defaultChecked: !!defaultChecked, onCheckedChange: setCheckedState, disabled: Boolean(disabled), ...restProps, children: _jsxs(StyledCheckboxIndicator, { children: [checkedState === 'indeterminate' && _jsx(MinusIcon, { type: 'default', size: size, miniIcon: true }), checkedState === true && _jsx(CheckIcon, { type: 'default', size: size, miniIcon: true })] }) }));
    if (tooltip) {
        checkBoxUI = _jsx(_Fragment, { children: renderWithTooltip(checkBoxUI, tooltip) });
    }
    if (hint && !children) {
        checkBoxUI = _jsx(LabelWithHint, { hint: hint, children: checkBoxUI });
    }
    const childrenUI = hint && children ? _jsx(LabelWithHint, { hint: hint, children: children }) : children;
    return (_jsxs(Stack, { gap: theme.spaces.x2, children: [_jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x8, alignY: 'center', children: [checkBoxUI, childrenUI && (_jsx(CheckboxLabel, { level: 'sm', size: size, disabled: Boolean(disabled), onClick: () => {
                            !disabled && setCheckedState(!checkedState);
                        }, children: childrenUI }))] }), helpText && _jsx(StyledHelpText, { children: helpText })] }));
};
