import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { uniq } from 'lodash-es';
import { useState } from 'react';
import styled from 'styled-components';
import { ChevronRightDoubleIcon } from '../Icons';
import { theme } from '../theme';
import Editor from './BaseEditor';
import Breadcrumb from './Breadcrumb';
import FileTree from './FileTree';
import { FileOperation } from './types';
const border = `1px solid ${theme.colors.gray200}`;
const MultiFileEditorContainer = styled.div `
  height: 100%;
  display: flex;
  align-items: stretch;

  .toggle-pane-left {
    border-right: ${border};
  }

  .toggle-pane-right {
    border-left: ${border};
  }
`;
const StyledTogglePaneIcon = styled.div `
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    transform: rotate(${(p) => (p.action === 'expand' ? '0deg' : '180deg')});
  }
`;
const PaneContainer = styled.div `
  flex: 1;
  overflow: hidden;
`;
const PaneSlider = styled.div `
  width: 150%;
  height: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.showFullWidthPane ? `2fr 1fr` : `1fr 1fr 1fr`)};
  align-items: stretch;
  transform: translateX(${(p) => (p.showCustomPane ? '-33.33%' : '0')});
  transition: transform 0.5s ease;
`;
const Pane = styled.div `
  border-left: ${border};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const PaneHeader = styled.div `
  display: flex;
  min-height: 40px;
  height: 40px;
  align-items: center;
  border-bottom: ${border};
`;
const PaneHeaderContent = styled.div `
  flex: 1;
`;
export const StyledBreadCrumb = styled(Breadcrumb) `
  padding: 0 20px;
`;
export const StyledFileTree = styled(FileTree) `
  flex: 1;
  overflow: auto;
`;
export var EditorPaneType;
(function (EditorPaneType) {
    EditorPaneType["primary"] = "primary";
    EditorPaneType["secondary"] = "secondary";
    EditorPaneType["custom"] = "custom";
})(EditorPaneType || (EditorPaneType = {}));
function TogglePaneIcon({ action, onClick, className }) {
    return (_jsx(StyledTogglePaneIcon, { action: action, onClick: onClick, className: className, children: _jsx(ChevronRightDoubleIcon, { type: 'default', size: 'xs', color: theme.colors.gray500 }) }));
}
export function EditorPane({ paneType, showTogglePaneIcon, onTogglePaneClick = () => { }, renderPaneHeader, file, onFileSelect, prepareSuggestions, readOnly, onChange, onSave, delay = 1000, editorClassName, renderCursorToolbar, getInlineSuggestion, onEditorLoad, onEditorUnload }) {
    const open = (referencePath) => {
        onFileSelect?.(referencePath);
    };
    const addDefinitionAction = (definitions) => {
        return definitions?.map((def) => {
            return {
                ...def,
                action: def.action || open // add default action if its not defined.
            };
        });
    };
    const path = file.path || '';
    return (_jsxs(_Fragment, { children: [_jsxs(PaneHeader, { children: [showTogglePaneIcon && (_jsx(TogglePaneIcon, { action: 'expand', onClick: onTogglePaneClick, className: 'toggle-pane-left' })), _jsx(PaneHeaderContent, { children: renderPaneHeader ? renderPaneHeader(paneType, file) : _jsx(StyledBreadCrumb, { file: path }) })] }), _jsx(Editor, { ariaLabel: `Editor for ${path.split('/').pop()}`, language: file.language, path: `${paneType}-${path}`, value: file.code, decorations: file.decorations, definitions: addDefinitionAction(file.definitions), diagnostics: file.diagnostics, suggestions: file.suggestions, prepareSuggestions: prepareSuggestions, readOnly: file.readOnly ?? readOnly, onChange: onChange, onSave: onSave, delay: delay, className: editorClassName, getInlineSuggestion: getInlineSuggestion, renderCursorToolbar: renderCursorToolbar, onEditorLoad: onEditorLoad, onEditorUnload: onEditorUnload }, path)] }));
}
export function MultiFileEditor({ readOnly, files, onFileSelect, primaryFilePath, secondaryFilePath, renderPaneHeader, showCustomPane = false, toggleCustomPane, customPane, expandedPaths = [], onChange, className, editorClassName, renderCursorToolbar, getInlineSuggestion }) {
    const [primaryEditor, setPrimaryEditor] = useState();
    const [secondaryEditor, setSecondaryEditor] = useState();
    const primaryFile = files[primaryFilePath];
    const secondaryFile = secondaryFilePath && files[secondaryFilePath];
    // add primary file path and secondary file path if not added already
    let _expandedPaths = [...expandedPaths];
    if (primaryFilePath)
        _expandedPaths.push(primaryFilePath);
    if (secondaryFilePath)
        _expandedPaths.push(secondaryFilePath);
    _expandedPaths = uniq(_expandedPaths);
    return (_jsx(MultiFileEditorContainer, { className: className, children: _jsx(PaneContainer, { children: _jsxs(PaneSlider, { showFullWidthPane: !primaryFilePath || !secondaryFilePath, showCustomPane: showCustomPane, children: [primaryFilePath && (_jsx(Pane, { "data-test-id": 'primary-code-pane', children: _jsx(EditorPane, { onEditorLoad: (editor) => {
                                setPrimaryEditor(editor);
                            }, renderCursorToolbar: renderCursorToolbar, paneType: 'primary', showTogglePaneIcon: false, renderPaneHeader: renderPaneHeader
                                ? (...args) => renderPaneHeader(primaryEditor, ...args)
                                : undefined, file: primaryFile || {}, onFileSelect: (path) => onFileSelect?.(path, EditorPaneType.primary), onChange: (value) => onChange?.([{ type: FileOperation.UPDATE, path: primaryFilePath, text: value }], EditorPaneType.primary), readOnly: readOnly, editorClassName: editorClassName, getInlineSuggestion: getInlineSuggestion
                                ? (...args) => {
                                    const [model] = args;
                                    const _files = Object.keys(files).reduce((acc, filepath) => {
                                        acc[filepath] = filepath === primaryFilePath ? model.getValue() : files[filepath].code;
                                        return acc;
                                    }, {});
                                    return getInlineSuggestion(primaryEditor, _files, primaryFilePath, ...args);
                                }
                                : undefined }) })), secondaryFile && (_jsx(Pane, { "data-test-id": 'secondary-code-pane', children: _jsx(EditorPane, { onEditorLoad: (editor) => {
                                setSecondaryEditor(editor);
                            }, renderCursorToolbar: renderCursorToolbar, paneType: 'secondary', showTogglePaneIcon: showCustomPane, onTogglePaneClick: () => toggleCustomPane?.(false), renderPaneHeader: renderPaneHeader
                                ? (...args) => renderPaneHeader(secondaryEditor, ...args)
                                : undefined, file: secondaryFile || {}, onFileSelect: (path) => onFileSelect?.(path, EditorPaneType.primary), onChange: (value) => onChange?.([{ type: FileOperation.UPDATE, path: secondaryFilePath, text: value }], EditorPaneType.primary), readOnly: readOnly, editorClassName: editorClassName, getInlineSuggestion: getInlineSuggestion
                                ? (...args) => {
                                    const [model] = args;
                                    const _files = Object.keys(files).reduce((acc, filepath) => {
                                        acc[filepath] = filepath === secondaryFilePath ? model.getValue() : files[filepath].code;
                                        return acc;
                                    }, {});
                                    return getInlineSuggestion(secondaryEditor, _files, secondaryFilePath, ...args);
                                }
                                : undefined }) })), _jsxs(Pane, { children: [_jsxs(PaneHeader, { children: [_jsx(PaneHeaderContent, { children: renderPaneHeader?.(undefined, 'custom') }), showCustomPane && (_jsx(TogglePaneIcon, { action: 'expand', onClick: () => toggleCustomPane?.(false), className: 'toggle-pane-right' }))] }), customPane] })] }) }) }));
}
