import styled from 'styled-components';
import { Stack } from '../Layout';
import { theme } from '../theme';
export const UploadRootContainer = styled(Stack) `
  font-family: ${theme.fontFamily.text};
  color: ${theme.colors.gray900};
  padding: ${theme.spaces.x12};
  cursor: pointer;
  border-radius: ${theme.radius.x6};
  margin-bottom: ${theme.spaces.x24};
`;
export const StyledUploadContainer = styled(Stack) `
  margin: ${theme.spaces.x12} 0;
  ${({ disabled }) => (disabled ? 'opacity: 0.5; pointer-events: none; cursor: not-allowed;' : '')}
`;
