import { theme } from '../theme/index';
export const tokens = {
    CheckboxRoot: {
        s: {
            size: theme.sizes.x16,
            iconSize: theme.fontSizes.x12,
            fontSize: theme.fontSizes.x14,
            borderRadius: theme.radius.x4
        },
        m: {
            size: theme.sizes.x20,
            iconSize: theme.fontSizes.x14,
            fontSize: theme.fontSizes.x16,
            borderRadius: theme.radius.x6
        },
        borderThickness: '1.5px',
        HelpText: {
            fontSize: theme.fontSizes.x12,
            color: theme.colors.gray500
        }
    },
    normal: {
        backgroundColor: theme.colors.white,
        color: theme.colors.gray700,
        borderColor: theme.colors.gray300,
        focused: {
            borderColor: theme.colors.gray300,
            boxShadow: theme.focusRing.shadow.xs
        }
    },
    selected: {
        iconColor: theme.colors.white,
        borderColor: theme.colors.brand600,
        backgroundColor: theme.colors.brand600,
        hover: {
            borderColor: theme.colors.brand600,
            backgroundColor: theme.colors.brand600
        },
        focused: {
            borderColor: theme.colors.brand600,
            boxShadow: theme.focusRing.shadow.xs
        }
    },
    disabled: {
        backgroundColor: theme.colors.gray100,
        color: theme.colors.gray300
    },
    CheckboxGroup: {
        gap: theme.spaces.x12
    }
};
