import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { tokens } from './tokens';
const Container = styled.div `
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  overflow: hidden;
  display: flex;
`;
const Segment = styled.div `
  width: ${({ $width }) => `${$width}%`};
  height: 100%;
  background-color: ${({ $color }) => $color};
  transition: width 0.3s ease-in-out;
`;
export const SegmentedProgressBar = ({ segments, height = tokens.ProgressBar.height, width = tokens.ProgressBar.width, borderRadius = tokens.ProgressBar.size.borderRadius, backgroundColor = tokens.ProgressBar.Content.backgroundColor, className }) => {
    const total = segments.reduce((sum, segment) => sum + segment.value, 0);
    const normalizedSegments = segments.map((segment) => (Object.assign(Object.assign({}, segment), { percentage: (segment.value / total) * 100 })));
    return (_jsx(Container, { "$height": height, "$width": width, "$borderRadius": borderRadius, "$backgroundColor": backgroundColor, className: className, children: normalizedSegments.map((segment, index) => (_jsx(Segment, { "$width": segment.percentage, "$color": segment.color }, index))) }));
};
