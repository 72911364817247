import { theme } from '../theme/theme';
import { tokens as TextTokens } from './../Typography/tokens';
export const tokens = {
    background: theme.colors.gray50,
    borderColor: theme.colors.gray100,
    text: {
        color: theme.colors.gray600,
        weight: theme.fontWeight.medium
    },
    sizes: {
        xs: {
            dimension: theme.sizes.x24,
            text: TextTokens.Text.level.xs,
            icon: {
                dimension: theme.sizes.x16
            },
            indicator: {
                dimension: theme.sizes.x6
            }
        },
        sm: {
            dimension: theme.sizes.x32,
            text: TextTokens.Text.level.sm,
            icon: {
                dimension: theme.sizes.x20
            },
            indicator: {
                dimension: theme.sizes.x8
            }
        },
        md: {
            dimension: theme.sizes.x40,
            text: TextTokens.Text.level.md,
            icon: {
                dimension: theme.sizes.x24
            },
            indicator: {
                dimension: theme.sizes.x10
            }
        },
        lg: {
            dimension: theme.sizes.x48,
            text: TextTokens.Text.level.lg,
            icon: {
                dimension: theme.sizes.x28
            },
            indicator: {
                dimension: theme.sizes.x12
            }
        },
        xl: {
            dimension: theme.sizes.x56,
            text: TextTokens.Text.level.xl,
            icon: {
                dimension: theme.sizes.x32
            },
            indicator: {
                dimension: theme.sizes.x14
            }
        },
        '2xl': {
            dimension: theme.sizes.x64,
            text: TextTokens.Display.level.xs,
            icon: {
                dimension: theme.sizes.x32
            },
            indicator: {
                dimension: theme.sizes.x16
            }
        }
    }
};
