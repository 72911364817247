import { debounce, isEqual, isNil, noop, throttle } from 'lodash-es';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSearchParams } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { isNodeEvnProduction } from '../env';
import { isDependencySame } from '../memoize';
/**
 * a hook which keeps state in a ref so the ref
 * can be passed around and it will always point to latest value.
 *
 * Why not useRef: useRef doesn't cause re render, which we need in case of state change
 */
export function useStateRef(initialState) {
    const [state, setState] = useState(initialState);
    const stateRef = useRef(state);
    const _setState = useCallback((state) => {
        const newState = typeof state === 'function' ? state(stateRef.current) : state;
        stateRef.current = newState;
        setState(newState);
    }, [setState]);
    return [stateRef, _setState];
}
/**
 * Hook for updating states in render phase, if prop changes
 * Note: this doesn't return a state like getDerivedStateFromProps, but instead, you can set your state inside
 * this hook. This is just useMemo without any return value, added only for semantic
 *
 * Use this hook very sparingly only when your state is dependent to props
 * */
export function useDeriveStateFromProps(cb, dependencies) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(cb, dependencies);
}
/**
 * Hook to simulate controlled/uncontrolled state of input elements for other components.
 */
export function useControlledState({ value, defaultValue, onChange = noop }) {
    const [internalState, setInternalState] = useState(defaultValue);
    const isControlled = value !== undefined;
    const state = isControlled ? value : internalState;
    const setState = useCallback((state, ...args) => {
        // set internal state if component is uncontrolled, or with state update we are making it uncontrolled
        // which would be when we change value to undefined.
        if (!isControlled || state === undefined)
            setInternalState(state);
        onChange(state, ...args);
    }, [isControlled, onChange]);
    return [state, setState];
}
export function usePersistentCallback(cb) {
    const callbackRef = useRef(cb);
    // keep the callback ref upto date
    callbackRef.current = cb;
    /**
     * initialize a persistent callback which never changes
     * through out the component lifecycle
     */
    const persistentCbRef = useRef(function (...args) {
        return callbackRef.current(...args);
    });
    return persistentCbRef.current;
}
export function useMemoizedDependencies(dependencies) {
    const dependencyAry = useRef(dependencies);
    // if dependency array has changed update the current value of it
    if (!isEqual(dependencies, dependencyAry.current)) {
        dependencyAry.current = dependencies;
    }
    return dependencyAry.current;
}
/**
 * useEffect but deeply compares the dependency array, useful when the reference changes frequently
 * but the underlying data doesn't update
 */
export function useDeepCompareEffect(cb, dependencies) {
    const dependencyAry = useMemoizedDependencies(dependencies);
    // eslint not able to non inline callbacks
    // eslint-disable-next-line react-hooks/exhaustive-deps,
    useEffect(cb, dependencyAry);
}
export var WatchStrategy;
(function (WatchStrategy) {
    WatchStrategy["Immediate"] = "Immediate";
    WatchStrategy["AfterRender"] = "AfterRender";
})(WatchStrategy || (WatchStrategy = {}));
/**
 * hook to watch on dependent values and run the callback.
 * It supports two strategies Immediate and AfterRender
 * Immediate: runs the callback immediately when dependencies change in same render cycle
 * AfterRender: runs the callback after the changes are committed
 */
export function useWatch(callback, dependencies, strategy = WatchStrategy.Immediate) {
    const persistentCallback = usePersistentCallback(callback);
    const lastStrategy = useRef(strategy);
    if (lastStrategy.current !== strategy) {
        throw new Error('Watch strategy cannot be changed');
    }
    const lastDependencies = useRef(dependencies);
    const hook = strategy === WatchStrategy.Immediate ? useMemo : useEffect;
    hook(() => {
        const _lastDependencies = lastDependencies.current;
        lastDependencies.current = dependencies;
        persistentCallback(_lastDependencies, dependencies);
    }, dependencies);
}
/**
 * useMemo but deeply compares the dependency array, useful when the reference changes frequently
 * but the underlying data doesn't update
 */
export function useDeepCompareMemo(cb, dependencies) {
    const dependencyAry = useMemoizedDependencies(dependencies);
    // eslint not able to non inline callbacks
    // eslint-disable-next-line react-hooks/exhaustive-deps,
    return useMemo(cb, dependencyAry);
}
export function useThrottle(cb, delay, options) {
    const persistentCallback = usePersistentCallback(cb);
    const throttledFunc = useRef();
    return useMemo(() => {
        let lastThrottledFunc = throttledFunc.current;
        throttledFunc.current = throttle((...args) => {
            // if there is existing throttledFunc cancel it
            if (lastThrottledFunc) {
                lastThrottledFunc.cancel();
                lastThrottledFunc = undefined;
            }
            persistentCallback(...args);
        }, delay, options);
        return throttledFunc.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [persistentCallback, delay, JSON.stringify(options)]);
}
export function useDebounce(cb, delay, options) {
    const persistentCallback = usePersistentCallback(cb);
    const debouncedFunc = useRef();
    return useMemo(() => {
        let lastDebouncedFunc = debouncedFunc.current;
        debouncedFunc.current = debounce((...args) => {
            // if there is existing debouncedFunc cancel it
            if (lastDebouncedFunc) {
                lastDebouncedFunc.cancel();
                lastDebouncedFunc = undefined;
            }
            persistentCallback(...args);
        }, delay, options);
        return debouncedFunc.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [persistentCallback, delay, JSON.stringify(options)]);
}
// a hook to batch the calls for functions and send all the payload at once
export function useBatchedFunction(cb, delay = 1000, options) {
    const payloads = useRef([]);
    const callBatchedPayload = () => {
        cb(...payloads.current);
        payloads.current = [];
    };
    const throttleFn = useThrottle(callBatchedPayload, delay, options);
    const batchedFn = usePersistentCallback((...payload) => {
        payloads.current.push(payload);
        throttleFn();
    });
    batchedFn.cancel = throttleFn.cancel;
    batchedFn.flush = throttleFn.flush;
    return batchedFn;
}
function useTimedFlagCore(timeout, setFlag) {
    const timeoutRef = useRef();
    const _setFlag = useCallback((_flag = true) => {
        setFlag(_flag);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        // set the reset flag only if flag is true
        if (_flag) {
            timeoutRef.current = setTimeout(() => {
                setFlag(false);
            }, timeout);
        }
    }, [setFlag, timeout]);
    return _setFlag;
}
export function useTimedFlag(timeout = 500) {
    const [flag, setFlag] = useState(false);
    const _setFlag = useTimedFlagCore(timeout, setFlag);
    return [flag, _setFlag];
}
export function useTimedFlagRef(timeout = 500) {
    const flagRef = useRef(false);
    const _setFlag = useTimedFlagCore(timeout, (flag) => (flagRef.current = flag));
    return [flagRef, _setFlag];
}
export function useInterval(callback, delay) {
    const savedCallback = useRef(callback);
    // Remember the latest callback if it changes.
    savedCallback.current = callback;
    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return;
        }
        const id = setInterval(() => savedCallback.current(), delay);
        return () => clearInterval(id);
    }, [delay]);
}
/*
 * this hook will tell whenever a dependancy is changed in the same render
 * in the render when dependency changed this will return true and it will return false in next render
 */
export const useDidChange = (deps, equalityFunction) => {
    const lastDependencyRef = useRef();
    // get the last dependency
    const lastDependency = lastDependencyRef.current;
    //set the new deps as last dependency
    lastDependencyRef.current = deps;
    // for the first time mark deps as changed, and the check with last deps
    return !lastDependency || !isDependencySame(deps, lastDependency, equalityFunction);
};
/* will not execute the callback on component load, will execute everytime deps changes after first load
     or once lazy conditon matches the first time, useful for cases where value is initialized
        with undefined and set in different renders and we want to track only changes after the initial value */
export const useLazyEffect = (callback, deps, lazyCondition = () => true) => {
    const ignoreRender = useRef(true);
    const persistentLazyCondition = usePersistentCallback(lazyCondition);
    useEffect(() => {
        if (ignoreRender.current && persistentLazyCondition()) {
            ignoreRender.current = false;
        }
        else {
            return callback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
// This should only be used in development
/*
  //sample usage
  const useRemount = makeRemountCheck();
  useRemount('componentName');
*/
export const makeRemountCheck = () => {
    let counter = 0;
    return (name) => {
        const renderCounter = useRef(0);
        if (!renderCounter.current && counter) {
            if (!isNodeEvnProduction()) {
                console.log('re-mounted', name);
            }
            counter = 0;
        }
        renderCounter.current++;
        counter++;
    };
};
/**
 * React's useEffect are not guranteed to be async, but sometime we
 * need to do after previous render and paint is done, this hook can be used there
 * note: with this hook we can't have cleanup function
 */
export function useAsyncEffect(effect, dependencies) {
    return useEffect(() => {
        requestAnimationFrame(() => {
            effect();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}
// this hook debounces the value change and keep returning the old value while debounced
export function useDebouncedValue(value, delay = 500) {
    const [debouncedValue, setValue] = useState(value);
    const debouncedSetValue = useDebounce(setValue, delay);
    useEffect(() => {
        debouncedSetValue(value);
    }, [debouncedSetValue, value]);
    return [debouncedValue, debouncedSetValue];
}
export function useBatchedOnAnimationFrame(callback) {
    const raf = useRef();
    const callRefs = useRef([]);
    const rafDebouncedCallback = usePersistentCallback((...args) => {
        callRefs.current.push(() => callback(...args));
        if (!raf.current) {
            raf.current = requestAnimationFrame(() => {
                //TODO: remove manual batching once react 18 is added, as react 18 automatically does it
                ReactDOM.unstable_batchedUpdates(() => {
                    callRefs.current.forEach((cb) => cb());
                });
                callRefs.current = [];
                raf.current = undefined;
            });
        }
    });
    rafDebouncedCallback.cancel = () => {
        if (raf.current) {
            cancelAnimationFrame(raf.current);
            callRefs.current = [];
        }
    };
    rafDebouncedCallback.flush = () => {
        callRefs.current.forEach((cb) => cb());
        rafDebouncedCallback.cancel();
        return undefined;
    };
    return rafDebouncedCallback;
}
export const useLatestState = (value) => {
    const [state, setState] = useState(value);
    useMemo(() => {
        setState(value);
    }, [value]);
    return [state, setState];
};
export const useDependantState = (getter, deps) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const externalState = useMemo(() => getter(...deps), deps);
    return useLatestState(externalState);
};
/**
 * a hook which keeps input state in a ref so the ref
 * can be passed around and it will always point to latest value.
 *
 * Why not useRef: useRef doesn't cause re render, which we need in case of state change
 */
export function useLatestRef(state) {
    const stateRef = useRef(state);
    stateRef.current = state;
    return stateRef;
}
export const useDependantRef = (getter, deps) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const externalState = useMemo(() => getter(...deps), deps);
    return useLatestRef(externalState);
};
export const useOnce = (callback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(callback, []);
};
// a flag which tells if an app is mounted. Useful for cases where some async func is running and responds after long
export function useIsMounted() {
    // during first render cycle as well, keep the value as mounted.
    const mounted = useRef(true);
    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);
    return mounted;
}
// this flag check if react update is pending as result of setState
export function useIsReactUpdatePending(setState) {
    const reactUpdatePending = useRef();
    useEffect(() => {
        reactUpdatePending.current = false;
    });
    const _setState = usePersistentCallback((...args) => {
        reactUpdatePending.current = true;
        return setState(...args);
    });
    return [reactUpdatePending.current, _setState];
}
export function useWindowEvent(event, handler, captureMode) {
    const persistentHandler = usePersistentCallback(handler);
    useEffect(() => {
        window.addEventListener(event, persistentHandler, captureMode);
        return () => {
            window.removeEventListener(event, persistentHandler, captureMode);
        };
    }, [captureMode, event, persistentHandler]);
}
export function useOnMount(cb) {
    const _cb = usePersistentCallback(cb);
    useEffect(_cb, [_cb]);
}
export function useOnUnmount(cb) {
    const _cb = usePersistentCallback(cb);
    useEffect(() => {
        return () => {
            _cb();
        };
    }, [_cb]);
}
// Click away listener
export const useClickAwayListener = (handler, ignore, options, eventType) => {
    const ref = useRef(null);
    const persistentHandler = usePersistentCallback(handler);
    const persistentIgnore = usePersistentCallback(ignore || (() => false));
    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current &&
                !ref.current.contains(event.target) &&
                !persistentIgnore(event.target)) {
                persistentHandler(event);
            }
        };
        document.addEventListener(eventType || 'click', handleClick, options);
        return () => {
            document.removeEventListener(eventType || 'click', handleClick, options);
        };
    }, [ref, eventType, persistentHandler, persistentIgnore, options]);
    return ref;
};
//use this very sparingly or else, it will trigger multiple rerenders instead of one
export const useImmerWithCallback = (initialStateValue) => {
    const [state, setCurrentState] = useImmer(initialStateValue);
    const immerCallbackRefList = useRef([]);
    const setState = useCallback((updatedState, callback) => {
        if (callback) {
            immerCallbackRefList.current = [...immerCallbackRefList.current, callback];
        }
        setCurrentState(updatedState);
    }, [immerCallbackRefList, setCurrentState]);
    useEffect(() => {
        const callbackList = immerCallbackRefList.current;
        if (callbackList.length > 0) {
            immerCallbackRefList.current = [];
            callbackList.forEach((callback) => {
                if (typeof callback === 'function') {
                    callback(state);
                }
            });
        }
    }, [state]);
    return [state, setState];
};
export function useThenable(thenable) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [value, setValue] = useState();
    useEffect(() => {
        setIsLoading(true);
        thenable
            .then((value) => setValue(value))
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false));
    }, [error, thenable]);
    return { isLoading, value, error };
}
// throttle the function calls and execute once per requestAnimationFrame
export const useFrameThrottle = (cb, trailing) => {
    const inQueueRef = useRef(undefined);
    const persistentCallback = usePersistentCallback((isTrailing, ...args) => {
        if (!isTrailing)
            cb(...args);
        requestAnimationFrame(() => {
            // if another callback came in while waiting execute it
            if (!isNil(inQueueRef.current)) {
                persistentCallback(false, ...inQueueRef.current);
            }
            inQueueRef.current = undefined;
        });
    });
    return usePersistentCallback((...args) => {
        // if waiting for next tick, update the args
        if (inQueueRef.current !== undefined) {
            inQueueRef.current = args;
            return;
        }
        // if trailing, store args and start tick, else execute immediately and start tick
        inQueueRef.current = trailing ? args : null;
        persistentCallback(trailing, ...args);
    });
};
export const useForceUpdate = () => {
    const [, setToggle] = useState(false);
    return usePersistentCallback(() => setToggle((toggle) => !toggle));
};
export const useConsole = (...deps) => {
    if (useDidChange(deps)) {
        console.log(...deps);
    }
};
/**
 * Function to call a callback after one render cycle of react.
 * You can increase the cycle by nesting the afterNextRenderCalls
 */
export function useAfterNextRender() {
    const [counter, setCounter] = useState(0);
    const callbackRef = useRef();
    const isMounted = useIsMounted();
    useEffect(() => {
        if (callbackRef.current) {
            const cbs = callbackRef.current;
            callbackRef.current = undefined;
            cbs.forEach((cb) => cb());
        }
    }, [counter]);
    return usePersistentCallback((callback) => {
        if (!isMounted.current)
            return;
        callbackRef.current = callbackRef.current ? [...callbackRef.current, callback] : [callback];
        setCounter((c) => c + 1);
    });
}
export const useConditionalHook = (callbackWithHook, propCondition, elseValue) => {
    // useOnce is triggered once per component, so the condition never changes
    if (useOnce(() => propCondition)) {
        return callbackWithHook();
    }
    return elseValue;
};
export const useIsFirstRender = () => {
    const isFirstRender = useRef(true);
    useEffect(() => {
        isFirstRender.current = false;
    }, []);
    return isFirstRender.current;
};
export const useUUID = () => {
    const keyRef = useRef(nanoid());
    const resetKey = usePersistentCallback(() => {
        keyRef.current = nanoid();
    });
    return [keyRef.current, resetKey];
};
export const useReactiveUUID = () => {
    const [key, setKey] = useState(nanoid());
    const resetKey = usePersistentCallback(() => {
        setKey(nanoid());
    });
    return [key, resetKey];
};
export const getNextState = (prevState, nextState) => {
    return typeof nextState === 'function' ? nextState(prevState) : nextState;
};
export const useReactiveState = (propState) => {
    const [state, setState] = useLatestState(typeof propState === 'function' ? propState() : propState);
    const currentStateRef = useLatestRef(state);
    return [
        state,
        usePersistentCallback((nextState, callback) => {
            currentStateRef.current = getNextState(currentStateRef.current, nextState);
            setState(currentStateRef.current);
            callback === null || callback === void 0 ? void 0 : callback(currentStateRef.current);
        }),
        currentStateRef
    ];
};
export const useSearchParamState = (key, initialValue) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const setState = usePersistentCallback((value) => {
        searchParams.set(key, value || '');
        setSearchParams(searchParams);
    });
    const value = searchParams.get(key);
    const normalizedValue = value && value.length > 0 ? value : initialValue;
    return [normalizedValue, setState];
};
