import { BaseColor, Stack } from '@prophecy/ui';
import {
  JobIcon,
  GlobeAIcon,
  GemDatasetIcon,
  ColumnIcon,
  GemCustomIcon,
  GemTransformIcon,
  GemWingIcon,
  GemIcon,
  FunctionIcon,
  PipelineIcon,
  DatabaseIcon,
  BuildingBIcon,
  GemModelIcon,
  DownloadDIcon,
  LineageIcon,
  ProjectIcon,
  GemSubgraphIcon,
  TableIcon,
  UserAIcon,
  UsersAIcon,
  GemAlertIcon,
  SettingsBIcon,
  FileBIcon,
  SettingsDIcon,
  ActivityIcon,
  GemRadiantIcon,
  GemDodecaIcon,
  LogoProphecyIcon,
  NewIconVariant,
  LinkDIcon,
  ClockIcon
} from '@prophecy/ui-v3/Icons';
import { EntityIcon as EntityIconBase } from '@prophecy/ui-v3/Misc/EntityIcon';
import {
  VariantType as EntityIconVariantType,
  SizeType as EntityIconSizeType
} from '@prophecy/ui-v3/Misc/EntityIcon/types';
import { getColorCode } from '@prophecy/ui-v3/theme/utils';
import { GridAIcon } from '@prophecy/ui/Icons';
import { MarkdownIcon } from '@prophecy/ui/Icons/FileIcons';
import styled, { css } from 'styled-components';

import { UISpecCategory } from '../../Parser/types';
import { getUnknownGemName } from '../categoryMap';
import { Entity } from '../types/Entity';
const UNKNOWN_GEM = getUnknownGemName();
export const EntityIconMap = {
  [Entity.SqlOrchestratorPipeline]: { icon: PipelineIcon, type: NewIconVariant.extended },
  [Entity.Pipeline]: { icon: PipelineIcon, type: NewIconVariant.extended },
  [Entity.Configuration]: { icon: PipelineIcon, type: NewIconVariant.extended },
  [Entity.Dataset]: { icon: GemDatasetIcon, type: NewIconVariant.extended },
  [Entity.Subgraph]: { icon: GemSubgraphIcon, type: NewIconVariant.extended },
  [Entity.Job]: { icon: JobIcon, type: NewIconVariant.extended },
  [Entity.Model]: { icon: GemModelIcon, type: NewIconVariant.extended },
  [Entity.Snapshot]: { icon: GemModelIcon, type: NewIconVariant.extended },
  [Entity.Team]: { icon: UsersAIcon, type: NewIconVariant.default },
  [Entity.User]: { icon: UserAIcon, type: NewIconVariant.default },
  [Entity.Project]: { icon: ProjectIcon, type: NewIconVariant.extended },
  [Entity.Gem]: { icon: GemIcon, type: NewIconVariant.extended },
  [Entity.Package]: { icon: BuildingBIcon, type: NewIconVariant.default },
  [Entity.Schedule]: { icon: ClockIcon, type: NewIconVariant.default },

  [Entity.Fabric]: { icon: GlobeAIcon, type: NewIconVariant.default },
  [Entity.Lineage]: { icon: LineageIcon, type: NewIconVariant.extended },
  [Entity.UDF]: { icon: FunctionIcon, type: NewIconVariant.extended },
  [Entity.Source]: { icon: GemDatasetIcon, type: NewIconVariant.extended },
  [Entity.UnreferencedSource]: { icon: GemDatasetIcon, type: NewIconVariant.extended },
  [Entity.Seed]: { icon: GemDatasetIcon, type: NewIconVariant.extended },
  [Entity.Macro]: { icon: FunctionIcon, type: NewIconVariant.extended },
  PipelineConfiguration: { icon: PipelineIcon, type: NewIconVariant.extended },
  Pset: { icon: SettingsDIcon, type: NewIconVariant.default },
  PipelineConfigurations: { icon: PipelineIcon, type: NewIconVariant.extended },
  Expression: { icon: FunctionIcon, type: NewIconVariant.extended },
  Column: { icon: ColumnIcon, type: NewIconVariant.extended },
  [Entity.Function]: { icon: FunctionIcon, type: NewIconVariant.extended },
  Environment: { icon: GlobeAIcon, type: NewIconVariant.default },
  Database: { icon: DatabaseIcon, type: NewIconVariant.extended },
  [Entity.Table]: { icon: TableIcon, type: NewIconVariant.default },
  [Entity.View]: { icon: TableIcon, type: NewIconVariant.default },
  Transform: { icon: GemTransformIcon, type: NewIconVariant.extended },
  Custom: { icon: GemCustomIcon, type: NewIconVariant.extended },
  JoinSplit: { icon: GemWingIcon, type: NewIconVariant.extended },
  [UISpecCategory.JoinSplit]: { icon: GemWingIcon, type: NewIconVariant.extended },
  Trillion: { icon: GemAlertIcon, type: NewIconVariant.extended },
  MachineLearning: { icon: GemCustomIcon, type: NewIconVariant.extended },
  System: { icon: LogoProphecyIcon, type: NewIconVariant.default },
  [Entity.Template]: { icon: FileBIcon, type: NewIconVariant.default },
  [Entity.Transpiler]: { icon: DownloadDIcon, type: NewIconVariant.default },
  TranspilerConfig: { icon: SettingsBIcon, type: NewIconVariant.default },
  SingularDataTest: { icon: ActivityIcon, type: NewIconVariant.default },
  GenericDataTest: { icon: ActivityIcon, type: NewIconVariant.default },
  Test: { icon: ActivityIcon, type: NewIconVariant.default },
  [Entity.Requirement]: { icon: MarkdownIcon, type: NewIconVariant.default },
  [UNKNOWN_GEM]: { icon: GemIcon, type: NewIconVariant.extended },
  Operator: { icon: GemRadiantIcon, type: NewIconVariant.extended },
  Sensor: { icon: GemDodecaIcon, type: NewIconVariant.extended },
  SparkSQL: { icon: GemRadiantIcon, type: NewIconVariant.extended },
  TriggerNotify: { icon: GemAlertIcon, type: NewIconVariant.extended },
  Connection: { icon: LinkDIcon, type: NewIconVariant.default },
  DataTransfer: { icon: GemModelIcon, type: NewIconVariant.extended },
  [Entity.App]: { icon: GridAIcon, type: NewIconVariant.default }
} as const;

export type EntityIconType = keyof typeof EntityIconMap;

type Tone = `${BaseColor}`;
export function getTokens(tone: Tone) {
  return {
    color: getColorCode(tone, 600),
    backgroundType: {
      default: {
        color: getColorCode(tone, 600),
        border: getColorCode(tone, 200),
        background: getColorCode(tone, 50)
      },
      solid: {
        color: getColorCode(tone, 100),
        border: getColorCode(tone, 200),
        background: getColorCode(tone, 600)
      }
    }
  };
}

export const EntityToneMap = {
  [Entity.SqlOrchestratorPipeline]: BaseColor.primary,
  [Entity.Pipeline]: BaseColor.primary,
  [Entity.Transpiler]: BaseColor.grayBlue,
  [Entity.Configuration]: BaseColor.primary,
  [Entity.Dataset]: BaseColor.secondary,
  [Entity.Subgraph]: BaseColor.blue,
  [Entity.Job]: BaseColor.yellow,
  [Entity.Model]: BaseColor.pink,
  [Entity.Snapshot]: BaseColor.pink,
  [Entity.Team]: BaseColor.grayBlue,
  [Entity.Project]: BaseColor.grayBlue,
  [Entity.Gem]: BaseColor.blue,
  [Entity.Package]: BaseColor.orange,

  [Entity.Fabric]: BaseColor.greenLight,
  [Entity.Lineage]: BaseColor.orange,
  [Entity.Source]: BaseColor.secondary,
  [Entity.UnreferencedSource]: BaseColor.secondary,
  [Entity.Seed]: BaseColor.secondary,
  [Entity.Macro]: BaseColor.purple,
  PipelineConfiguration: BaseColor.primary,
  PipelineConfigurations: BaseColor.primary,
  Expression: BaseColor.purple,
  Column: BaseColor.blueLight,
  [Entity.Function]: BaseColor.purple,
  [Entity.UDF]: BaseColor.teal,
  Environment: BaseColor.greenLight,
  Database: BaseColor.yellow,
  [Entity.Table]: BaseColor.purple,
  [Entity.View]: BaseColor.greenLight,
  Transform: BaseColor.fuchsia,
  Custom: BaseColor.fuchsia,
  JoinSplit: BaseColor.purple,
  [UISpecCategory.JoinSplit]: BaseColor.purple,
  Trillion: BaseColor.orange,
  MachineLearning: BaseColor.yellow,
  TranspilerConfig: BaseColor.greenLight,
  [Entity.Template]: BaseColor.grayBlue,
  [Entity.User]: BaseColor.grayBlue,
  System: BaseColor.grayBlue,
  Pset: BaseColor.greenLight,
  SingularDataTest: BaseColor.orange,
  GenericDataTest: BaseColor.orange,
  Test: BaseColor.orange,
  Operator: BaseColor.secondary,
  Sensor: BaseColor.pink,
  SparkSQL: BaseColor.secondary,
  TriggerNotify: BaseColor.orange,
  DataTransfer: BaseColor.orange,
  [UNKNOWN_GEM]: BaseColor.blue,
  Connection: BaseColor.teal,
  [Entity.Requirement]: BaseColor.transparent,
  [Entity.Schedule]: BaseColor.yellow,
  [Entity.App]: BaseColor.green
};

export const IconWrapper = styled(Stack)<{ $radius: string; $background: string; $border?: string }>`
  background: ${(props) => props.$background};
  border-radius: ${(props) => props.$radius};
  ${(props) =>
    props.$border &&
    css`
      border: 1px solid ${props.$border};
    `};
  flex-shrink: 0;
`;
export type EntityIconKey = keyof typeof EntityIconMap & keyof typeof EntityToneMap;

export interface EntityIconProps {
  entity: EntityIconKey;
  tooltip?: React.ReactNode;
  size?: EntityIconSizeType;
  className?: string;
  hideTooltip?: boolean;
  variant?: EntityIconVariantType;
}
export function EntityIcon({ entity, tooltip, size = 'm', className, variant, hideTooltip }: EntityIconProps) {
  const iconObj = EntityIconMap[entity as EntityIconKey];

  if (!iconObj) {
    console.error(`No icon found for entity: ${entity}`);
    return null;
  }

  const { icon: Icon, type: iconType } = iconObj;
  const tokens = getTokens(EntityToneMap[entity as EntityIconKey]);
  const title = tooltip ?? entity;

  return (
    <EntityIconBase
      variant={variant}
      size={size}
      icon={
        // @ts-ignore
        <Icon type={iconType} color={tokens.color} />
      }
      color={EntityToneMap[entity as EntityIconKey]}
      tooltip={title}
      hideTooltip={hideTooltip}
      className={className}
    />
  );
}
