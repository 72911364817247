import { StackItem } from '@prophecy/ui/Layout';
import { Text } from '@prophecy/ui/Typography';
import { ComponentProps, PropsWithChildren } from 'react';
import styled from 'styled-components';

const RelativeStackItem = styled(StackItem).attrs({
  grow: '1'
})`
  position: relative;
`;

const ScrollableFlexWrapper = styled.div`
  position: absolute;
  inset: 0;
  overflow: auto;
`;

const BlockFlexWrapper = styled.div`
  position: absolute;
  inset: 0;
  overflow: hidden;
`;

const EllipsisFlexWrapper = styled.div`
  height: 100%;
`;

export const ScrollableFlexGrow = ({
  children,
  ...props
}: Omit<PropsWithChildren<ComponentProps<typeof StackItem>>, 'ref'>) => {
  return (
    <RelativeStackItem {...props}>
      <ScrollableFlexWrapper>{children}</ScrollableFlexWrapper>
    </RelativeStackItem>
  );
};

export const BlockFlexGrow = ({
  children,
  ...props
}: Omit<PropsWithChildren<ComponentProps<typeof StackItem>>, 'ref'>) => {
  return (
    <>
      <RelativeStackItem {...props}>
        <BlockFlexWrapper>{children}</BlockFlexWrapper>
      </RelativeStackItem>
      <Text level='sm'>
        <>&#8203;</>
        {/* BlockFlexGrow will depend on the height of sibling,
                if no siblings, BlockFlexGrow will take 0 height, this will ensure min height */}
      </Text>
    </>
  );
};

export const EllipsisFlexGrow = ({ children, ...props }: PropsWithChildren<ComponentProps<typeof StackItem>>) => {
  return (
    <StackItem grow='1' height='100%' {...props}>
      <EllipsisFlexWrapper>{children}</EllipsisFlexWrapper>
    </StackItem>
  );
};
