import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

import { GemSpec } from '../../Parser/types';
import { CommonState } from '../types';

type SpecGetter<S extends CommonState> = (state: S) => GemSpec[];

const GemSpecContext = createContext<<S extends CommonState>(state: S) => GemSpec[]>((s) => s.gemSpecs);

export function useSpecs<S extends CommonState = CommonState>(): GemSpec[] {
  const getter = useContext(GemSpecContext);
  return useSelector<S, GemSpec[]>((state) => getter(state));
}
export function GemSpecProvider<S extends CommonState>({
  children,
  getGemSpec
}: {
  children: React.ReactNode;
  getGemSpec: SpecGetter<S>;
}) {
  return <GemSpecContext.Provider value={getGemSpec as SpecGetter<CommonState>}>{children}</GemSpecContext.Provider>;
}
