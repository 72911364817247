import { jsx as _jsx } from "react/jsx-runtime";
import { useClickAwayListener, usePersistentCallback } from '@prophecy/utils/react/hooks';
import { format } from 'date-fns';
import { CalendarIcon, XCircleIcon } from '../../Icons';
import { Popover } from '../../Popover';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from '../constant';
import { DatePicker } from '../DatePicker';
import { useInternalValue, usePickerInput, useValNotFinalizedYet } from '../hooks';
import { StyledInput } from '../styled';
import { DATA_ATTR_CLEAR, DATA_ATTR_TOGGLE } from '../tokens';
import { PICKER_VARIANT } from '../types';
import { isDateValid } from '../utils/utils';
export const DefaultDateInput = function ({ dateFormat = DEFAULT_DATE_FORMAT, popoverProps, showActionFooter = false, pickerProps, value: propValue, onChange, ...props }) {
    const getDateString = usePersistentCallback((date) => {
        if (!date) {
            return '';
        }
        if (pickerProps?.showTime) {
            return format(date, pickerProps.showTime ? `${dateFormat}, ${pickerProps.timeFormat || DEFAULT_TIME_FORMAT}` : dateFormat);
        }
        return format(date, dateFormat);
    });
    const { value: val, onChange: setVal, sync, reset, valSynced } = useInternalValue({
        value: propValue,
        onChange: onChange,
        autoSync: !showActionFooter
    });
    const { popoverOpen, containerRef, iconRef: calendarIconRef, handleInputChange, handleSelect, handlePopupOutsideInterraction, onApply, onCancel, inputString, resetDateToNull } = usePickerInput({
        format: pickerProps?.showTime ? `${dateFormat}, ${pickerProps?.timeFormat || DEFAULT_TIME_FORMAT}` : dateFormat,
        onChange: setVal,
        value: val,
        isValid: (val, format) => isDateValid(val, format),
        sync,
        reset,
        getValueString: getDateString,
        hasTimePicker: pickerProps?.showTime
    });
    const valNotFinalizedYet = useValNotFinalizedYet({
        showActionFooter,
        valSynced,
        popoverOpen
    });
    const clearDate = (e) => {
        resetDateToNull();
    };
    const onInterractionOutsideRef = useClickAwayListener(handlePopupOutsideInterraction);
    const popover = (_jsx(Popover, { overlay: _jsx("div", { ref: onInterractionOutsideRef, children: _jsx(DatePicker, { ...pickerProps, variant: PICKER_VARIANT.DATE, value: val, dateFormat: dateFormat, onChange: handleSelect, onApply: onApply, onCancel: onCancel, showActionFooter: showActionFooter }) }), overlayStyle: { border: 'none', boxShadow: 'none', backgroundColor: 'transparent' }, visible: popoverOpen, ...popoverProps, children: _jsx("div", { ref: calendarIconRef, "data-action": popoverOpen ? DATA_ATTR_CLEAR : DATA_ATTR_TOGGLE, children: popoverOpen ? _jsx(XCircleIcon, { type: 'solid', onClickCapture: clearDate }) : _jsx(CalendarIcon, { type: 'default' }) }) }));
    const placeholder = props.placeholder ||
        (pickerProps?.showTime
            ? `${dateFormat.toLocaleLowerCase()}, ${(pickerProps?.timeFormat || DEFAULT_TIME_FORMAT).toLocaleLowerCase()}`
            : dateFormat.toLocaleLowerCase());
    return (_jsx("div", { ref: containerRef, children: _jsx(StyledInput, { ...props, value: inputString, onChange: handleInputChange, placeholder: placeholder, icon: popover, iconPlacement: 'right', delay: 0, "$valNotFinalizedYet": valNotFinalizedYet }) }));
};
