import { tokens as iconTokens } from '../Icons/tokens';
import { theme } from '../theme';
const commonInputStates = {
    backgroundColor: theme.colors.white,
    color: theme.colors.gray900,
    hover: {
        color: theme.colors.gray900
    }
};
const commonSizeProperties = {
    fontSize: theme.text.md.fontSize,
    lineHeight: theme.text.md.lineHeight,
    padding: theme.spaces.x12,
    borderRadius: theme.radius.x6,
    gap: theme.spaces.x6
};
export const tokens = {
    Input: {
        padding: theme.spaces.x12,
        color: theme.colors.gray900,
        boxShadow: theme.shadows.xs,
        iconColor: theme.colors.gray500,
        hintIconSize: theme.text.sm.fontSize,
        Title: {
            color: theme.colors.gray500
        },
        Label: {
            fontSize: theme.text.sm.fontSize,
            lineHeight: theme.text.sm.lineHeight,
            color: theme.colors.gray700,
            iconColor: theme.colors.gray500,
            iconSize: theme.sizes.x14,
            underlineColor: theme.colors.gray500
        },
        HelpText: {
            fontSize: theme.text.xs.fontSize,
            lineHeight: theme.text.xs.lineHeight,
            fontWeight: theme.fontWeight.normal,
            primary: {
                color: theme.colors.gray500
            },
            error: {
                color: theme.colors.error500
            },
            warning: {
                color: theme.colors.warning600
            }
        },
        AddOn: {
            backgroundColor: theme.colors.gray50,
            color: theme.colors.gray600
        },
        primary: {
            ...commonInputStates,
            borderColor: theme.colors.gray300,
            focus: {
                borderColor: theme.colors.brand500
            }
        },
        error: {
            ...commonInputStates,
            borderColor: theme.colors.error300,
            focus: {
                borderColor: theme.colors.error500
            }
        },
        disabled: {
            backgroundColor: theme.colors.gray50,
            borderColor: theme.colors.gray300,
            color: theme.colors.gray500
        },
        size: {
            s: {
                ...commonSizeProperties,
                fontSize: theme.text.sm.fontSize,
                lineHeight: theme.text.sm.lineHeight,
                height: theme.sizes.x28,
                padding: theme.spaces.x8,
                paddingVertical: '0px',
                borderRadius: theme.radius.x6,
                iconSize: iconTokens.Icon.xs.size,
                gap: theme.spaces.x4
            },
            m: {
                ...commonSizeProperties,
                fontWeight: theme.fontWeight.medium,
                height: theme.sizes.x40,
                paddingVertical: '0px',
                borderRadius: theme.radius.x8,
                iconSize: iconTokens.Icon.s.size,
                gap: theme.spaces.x6
            },
            l: {
                ...commonSizeProperties,
                height: theme.sizes.x48,
                paddingVertical: theme.spaces.x6,
                borderRadius: theme.radius.x8,
                iconSize: iconTokens.Icon.m.size,
                gap: theme.spaces.x6
            }
        }
    },
    InlineInput: {
        padding: theme.spaces.x2,
        iconSize: iconTokens.Icon.xs.size,
        focus: {
            borderColor: theme.colors.brand500
        }
    },
    GhostInput: {
        fontWeight: theme.fontWeight.medium,
        borderRadius: 0,
        iconSize: iconTokens.Icon.xs.size,
        iconColor: theme.colors.gray400,
        padding: theme.spaces.x4,
        loading: {
            iconColor: theme.colors.gray700
        },
        size: {
            m: {
                iconSize: theme.sizes.x10,
                fontSize: theme.text.lg.fontSize,
                lineHeight: theme.text.lg.lineHeight,
                height: theme.sizes.x32,
                gap: theme.spaces.x12
            },
            l: {
                iconSize: theme.sizes.x12,
                fontSize: theme.display.xs.fontSize,
                lineHeight: theme.display.xs.lineHeight,
                height: theme.sizes.x36,
                gap: theme.spaces.x12
            }
        }
    },
    GhostDescription: {
        iconSize: theme.sizes.x10,
        fontSize: theme.text.sm.fontSize,
        lineHeight: theme.text.sm.lineHeight,
        padding: `0 ${iconTokens.Icon.xs.size} ${theme.spaces.x8} ${theme.spaces.x4}`
    },
    Root: {
        gap: theme.spaces.x6,
        Asterisk: {
            color: theme.colors.brand600,
            fontSize: theme.text.sm.fontSize,
            fontWeight: theme.fontWeight.medium,
            lineHeight: theme.text.sm.lineHeight
        }
    }
};
