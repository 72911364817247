import styled from 'styled-components';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { Text } from '../Typography/Text';
import { tokens } from './tokens';
export const StyledEmptyWrap = styled(Stack) `
  display: inline-flex;
  font-family: ${theme.fontFamily.text};
`;
export const StyledImageWrap = styled.div `
  display: inline-block;

  svg,
  img {
    width: ${({ size }) => tokens.Empty.size[size].imageSize};
  }
`;
export const StyledEmptyDescription = styled(Text) `
  color: ${tokens.Description.color};
  margin: 0;
  white-space: nowrap;
`;
