import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import isEqual from 'lodash-es/isEqual';
import styled, { createGlobalStyle } from 'styled-components';
import { RefreshCcwEIcon } from '../Icons';
import { Stack } from '../Layout';
import { SELECT_ITEM_CLASS, SELECT_ITEM_OPTION_CLASS, SELECT_ITEM_OPTION_SELECTED_CLASS, SELECT_VIRTUAL_LIST_HOLDER, SELECT_VIRTUAL_LIST_HOLDER_INNER } from '../Select/tokens';
import { theme } from '../theme';
import { RangeSelector } from './styled';
import { tokens } from './tokens';
import { DaysInMonth, renderRangeTag } from './utils';
const DatePickerPopupStyles = createGlobalStyle `
  .ui-cron-date-picker-popup {
    .${SELECT_VIRTUAL_LIST_HOLDER} {
      max-height: none !important;
    }

    .${SELECT_VIRTUAL_LIST_HOLDER_INNER} {
      display: grid !important; // to override inline style
      grid-template-columns: repeat(7, 1fr);
      gap: ${tokens.DatePicker.Option.gap};
      
    }

    .${SELECT_ITEM_CLASS} {
      width: 32px;
    }

    .${SELECT_ITEM_OPTION_CLASS}.${SELECT_ITEM_OPTION_SELECTED_CLASS} {
    &, &:hover {
        background-color: ${theme.colors.gray100};
      }
    }
  }
`;
const DatePickerResetIcon = styled(RefreshCcwEIcon) `
  padding-right: ${theme.sizes.x6};
`;
export function DatePicker({ mode, value, ...restProps }) {
    const firstOption = DaysInMonth[0];
    const isValueEqualToFirstOption = isEqual(value, [firstOption]);
    return (_jsxs(_Fragment, { children: [_jsx(RangeSelector, { mode: mode, value: value, ...restProps, options: DaysInMonth.map((date) => ({ label: date, value: date })), dropdownClassName: 'ui-cron-date-picker-popup ui-cron-center-aligned-content', virtual: false, showSearch: false, dropdownMatchSelectWidth: false, tagRender: ({ value: currentItem }) => renderRangeTag(currentItem, value, DaysInMonth), suffixIcon: !isValueEqualToFirstOption ? (_jsx(DatePickerResetIcon, { onClick: () => restProps.onChange?.([firstOption], { label: firstOption, value: firstOption }), size: 'm', type: 'default' })) : undefined, optionRender: (option) => (_jsx(Stack, { direction: 'horizontal', align: 'center', alignY: 'center', children: option.label })) }), _jsx(DatePickerPopupStyles, {})] }));
}
