import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { StyledIndicator, StyledProgressBarContainer } from './styled';
import { tokens } from './tokens';
import { Variant } from './types';
export const ProgressBar = (_a) => {
    var { variant = Variant.loading, value, height = tokens.ProgressBar.height, width = tokens.ProgressBar.width, color } = _a, rest = __rest(_a, ["variant", "value", "height", "width", "color"]);
    return (_jsx(StyledProgressBarContainer, Object.assign({ height: height, width: width }, rest, { children: _jsx(StyledIndicator, { variant: variant, "$color": color, style: { width: `${value}%` } }) })));
};
export * from './SegmentedProgressBar';
