import { Handle, Position } from 'reactflow';
import styled, { css } from 'styled-components';
import { Button } from '../Button';
import { CheckIcon, LoadingDIcon } from '../Icons';
import { ICON_CLASS } from '../Icons/tokens';
import { Input } from '../Input';
import { Stack } from '../Layout';
import { MarkdownEditor } from '../MarkdownEditor/MarkdownEditor';
import { StyledDropdownHandle, StyledFixedToolbar } from '../MarkdownEditor/styled';
import { tokens as markdownEditorTokens } from '../MarkdownEditor/tokens';
import { Hint } from '../Misc/Hint';
import { getInterFontWeightCSS, negateVariable, theme } from '../theme';
import { Text } from '../Typography/Text';
import { VALID_GEM_CONNECTION, INVALID_GEM_CONNECTION, ADD_NEW_PORT_HANDLE, LEFT_HANDLE_CLASS, REACT_FLOW_CLASS, REACT_FLOW_CONNECTION_LINE, RIGHT_HANDLE_CLASS, tokens } from './tokens';
import { NodeStateTypes } from './types';
const showAddNewPortHandleStyles = css `
  .${REACT_FLOW_CLASS} .${REACT_FLOW_CONNECTION_LINE} + &:hover .${ADD_NEW_PORT_HANDLE} {
    display: flex !important;
  }
`;
const buttonResetStyles = css `
  background: transparent;
  border: none;
  &:focus-visible {
    outline: none;
  }
`;
const placeCenterStyles = css `
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledGemLabel = styled.div `
  max-width: 180px;
  ${getInterFontWeightCSS(theme.fontWeight.medium)};
`;
export const StyledGemType = styled.div `
  color: ${({ status }) => tokens.Status[status].color};
  font-size: ${theme.fontSizes.x12};
  max-width: 180px;
`;
export const StyledStatus = styled(Stack) `
  position: absolute;
  top: ${theme.spaces.x12};
  right: ${theme.spaces.x12};
  width: ${theme.spaces.x16};
`;
export const TileWrapper = styled.div `
  height: ${({ height }) => (height ? height + 'px' : tokens.Tile.height)};
  width: ${tokens.Tile.width};
  position: relative;
  padding: ${theme.spaces.x12};
  display: flex;
  place-items: center;

  background: ${tokens.Node.background};
  border-radius: ${tokens.Node.borderRadius};
  &:hover {
    box-shadow: ${tokens.Node.hover.shadow};
  }

  &&& > .${LEFT_HANDLE_CLASS} {
    background: ${({ status }) => tokens.Status[status].borderColor};
    left: -11px;
  }
  &&& > .${RIGHT_HANDLE_CLASS} {
    border-left-color: ${({ status }) => tokens.Status[status].borderColor};
    right: -11px;
  }

  ${({ status }) => `border: 1px solid ${tokens.Status[status].borderColor};`};
  ${({ state }) => state === NodeStateTypes.error || state === NodeStateTypes.warning
    ? `border-color: ${tokens.Node.state[state].borderColor};`
    : ''};

  ${({ selected }) => selected
    ? `
      box-shadow: ${tokens.Node.state.selected.shadow};
      border-width: 3px;
      transition: ${tokens.Tile.transition};
  `
    : ''}
  > svg {
    width: 100%;
    height: 100%;
  }

  ${({ disabled }) => disabled &&
    `
    filter: grayscale(1);
  `}
`;
export const StyledGemNode = styled.div `
  ${showAddNewPortHandleStyles}

  &.${VALID_GEM_CONNECTION} {
    cursor: crosshair !important;
  }

  &.${INVALID_GEM_CONNECTION} {
    cursor: no-drop !important;
  }
`;
export const NodeWrapper = styled.div `
  height: ${({ size }) => (size ? size + 'px' : tokens.Node.height)};
  width: ${({ size }) => (size ? size + 'px' : tokens.Node.width)};
  position: relative;
  display: flex;
  place-items: center;
  ${({ virtual, isGemBroken }) => (virtual || isGemBroken) &&
    `
    filter: grayscale(1);
    opacity: .6;
  `}

  background: ${tokens.Node.background};
  border-radius: ${tokens.Node.borderRadius};

  border-width: ${({ selected, modified, isDragging }) => selected || isDragging
    ? tokens.Node.state.selected.borderWidth
    : modified
        ? tokens.Node.state.modified.borderWidth
        : tokens.Node.borderWidth};
  border-color: ${({ selected, state, modified, isDragging }) => modified
    ? tokens.Node.state.modified.borderColor
    : state === NodeStateTypes.error || state === NodeStateTypes.warning
        ? tokens.Node.state[state].borderColor
        : selected || isDragging
            ? tokens.Node.state.selected.borderColor
            : tokens.Node.borderColor};
  border-style: ${({ isDragging }) => (isDragging ? tokens.Node.state.dragging.borderStyle : tokens.Node.borderStyle)};

  &:hover {
    box-shadow: ${tokens.Node.hover.shadow};
  }

  .${LEFT_HANDLE_CLASS} {
    left: ${({ selected, isDragging }) => (selected || isDragging ? '-12px' : '-11px')};
  }
  .${RIGHT_HANDLE_CLASS} {
    right: ${({ selected, isDragging }) => (selected || isDragging ? '-12px' : '-11px')};
  }
  ${({ status }) => status
    ? css `
          border-color: ${tokens.Status[status].borderColor};
          && > .${LEFT_HANDLE_CLASS} {
            background: ${tokens.Status[status].borderColor};
          }
          && > .${RIGHT_HANDLE_CLASS} {
            border-left-color: ${tokens.Status[status].borderColor};
          }
        `
    : ``}
  > svg, img {
    width: 100%;
    height: 100%;
  }
`;
export const NodeLabel = styled(Stack) `
  position: absolute;
  ${({ fullWidth }) => (fullWidth ? '' : `max-width: calc(100% + ${theme.spaces.x16});`)}
  transform: translateX(-50%);
  bottom: calc(100% + ${theme.spaces.x6});
  left: 50%;
`;
export const NodeDescriptionHint = styled(Hint) `
  max-width: 400px;
`;
export const NodeType = styled(Text) `
  position: absolute;
  transform: translateX(-50%);
  top: calc(100% + ${theme.spaces.x6});
  left: 50%;
  max-width: calc(100% + ${theme.spaces.x20});
  display: flex;
  gap: ${theme.spaces.x4};
  place-items: center;
  white-space: nowrap;
`;
export const SuccessIcon = styled(CheckIcon) `
  &&& {
    color: ${theme.colors.success500};
  }
`;
export const LoadingIcon = styled(LoadingDIcon) `
  &&& {
    color: ${theme.colors.gray700};
  }
`;
export const VerticalSeparator = styled.div `
  height: 20px;
  margin: ${theme.spaces.x4} 0;
  border-left: 1px solid ${theme.colors.gray200};
`;
export const HorizontalSeparator = styled.div `
  margin: 0 ${theme.spaces.x4};
  border-top: 1px solid ${theme.colors.gray200};
`;
export const NodeContainer = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 9px;
  overflow: hidden;
`;
export const OpenButton = styled.button `
  ${buttonResetStyles}
  ${placeCenterStyles}
  flex-grow: 1;
  cursor: pointer;
  &:hover {
    background: ${theme.colors.gray100};
  }
`;
export const StyledInput = styled(Input) `
  min-width: 132px;
  svg {
    color: ${theme.colors.gray400};
  }
`;
export const ToolSection = styled.div `
  display: flex;
  justify-content: space-around;
  align-items: center;

  && .${ICON_CLASS} {
    font-size: ${theme.fontSizes.x16};
  }
`;
export const ToolIconButton = styled(Button) `
  flex: 1;
  height: 100%;
  &[data-state='open'],
  &:hover {
    background: ${theme.colors.gray100};
  }
`;
export const Phase = styled(Text) `
  position: absolute;
  left: ${theme.spaces.x8};
  bottom: ${theme.spaces.x4};
  color: ${theme.colors.gray500};
`;
export const disableUserSelectClass = 'disable-user-select-for-graph-selection';
export const disableUserSelect = css `
  .${disableUserSelectClass} & {
    user-select: none;
  }
`;
export const SubMenuItem = styled.span `
  padding-left: ${theme.spaces.x4};
  font-size: ${theme.fontSizes.x12};
`;
export const PortLabel = styled(Text) `
  position: absolute;
  ${({ position }) => (position === Position.Left ? `right: ${theme.spaces.x10};` : `left: ${theme.spaces.x6};`)}
  top: 50%;
  transform: translateY(-50%);
`;
export const NodeTooltipWrapper = styled.p `
  word-wrap: break-word;
`;
export const StyledMark = styled.mark `
  border-radius: ${theme.spaces.x4};
  background-color: ${theme.colors.yellow300};
  padding: ${theme.spaces.x2} 0;
`;
export const AddNewPortHandleContainer = styled.div `
  position: relative;
  &::after {
    display: block;
    content: '';
    width: ${theme.spaces.x48};
    height: calc(${(props) => `${props.nodeSize}px`} + ${theme.spaces.x40});
    bottom: ${negateVariable(theme.spaces.x20)};
    position: absolute;
    transform: translate(-100%);
    z-index: ${theme.zLayer.s};
  }
  ${showAddNewPortHandleStyles}
`;
export const AddNewPortHandle = styled(Handle) `
  display: none !important;
  z-index: ${theme.zLayer.m};
`;
export const StyledMarkdownEditor = styled(MarkdownEditor) `
  border: none;
  box-shadow: none;
  min-height: 100%;
  min-width: 100%;
  position: relative;

  .${markdownEditorTokens.classNames.slateEditor} {
    margin: ${theme.spaces.x8} ${theme.spaces.x12};
    padding: 0;
  }

  h1,
  h2 {
    background: ${theme.backgroundColor.brandPrimary};
    padding: ${theme.spaces.x8} ${theme.spaces.x12};

    [data-slate-placeholder='true'] {
      top: ${theme.spaces.x8} !important;
    }
  }

  h2 {
    background: ${theme.backgroundColor.primaryHover};
  }
`;
export const CommentNodeContainer = styled(StyledGemNode) `
  display: flex;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;
export const CommentNodeWrapper = styled.div `
  background: ${theme.backgroundColor.primary};
  border: ${({ $selected: selected, $isDragging: isDragging }) => selected || isDragging
    ? `${tokens.Node.state.modified.borderWidth} solid ${tokens.Node.state.selected.borderColor}`
    : 'none'};
  border-radius: ${theme.radius.x2};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;

  .${LEFT_HANDLE_CLASS} {
    left: ${({ $selected: selected, $isDragging: isDragging }) => selected || isDragging ? `${negateVariable(theme.spaces.x12)}` : '-11px'};
  }
  .${RIGHT_HANDLE_CLASS} {
    right: ${({ $selected: selected, $isDragging: isDragging }) => selected || isDragging ? `${negateVariable(theme.spaces.x12)}` : '-11px'};
  }

  &:before,
  &:after {
    background: ${theme.backgroundColor.primary};
    border: ${tokens.Node.state.modified.borderWidth} solid ${tokens.Node.state.selected.borderColor};
    border-radius: ${theme.radius.x10};
    content: '';
    display: ${({ $selected: selected }) => (selected ? 'block' : 'none')};
    height: ${theme.sizes.x22};
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${theme.sizes.x8};
  }

  &:after {
    left: auto;
    right: 0;
    transform: translate(50%, -50%);
  }

  * {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${StyledFixedToolbar} {
    background: ${theme.backgroundColor.primary};
    border: ${theme.radius.x2} solid ${theme.borderColor.tertiary};
    border-radius: ${theme.radius.x8};
    box-shadow: ${theme.shadows.lg};
    display: none;
    left: 50%;
    position: absolute;
    top: ${negateVariable(theme.sizes.x56)};
    transform: translateX(-50%);
  }

  &:focus-within {
    ${StyledFixedToolbar} {
      display: flex;
    }
  }

  ${StyledFixedToolbar}:has(${StyledDropdownHandle}[data-state='open']) {
    display: flex;
  }

  ${StyledMarkdownEditor} {
    cursor: ${({ $selected: selected }) => (selected ? 'text' : 'grab')};
  }
`;
