import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isNil } from 'lodash-es';
import { useState } from 'react';
import { IconPlacement } from '../Button/types';
import { Stack, StackItem } from '../Layout';
import { BaseColor, theme } from '../theme';
import { StyledButton, StyledClearIcon } from './styled';
import { BadgesComponentNames } from './tokens';
import { Size, Variant } from './types';
export function Badges({ closable = false, onClose, variant = Variant.pillLight, children, size = Size.m, rounded = false, icon, iconPlacement = IconPlacement.right, filled = true, tone = BaseColor.primary, isBadge = true, ...restProps }) {
    const [visible, toggle] = useState(true);
    if (!visible) {
        return null;
    }
    const _onClose = () => {
        toggle(false);
        onClose?.();
    };
    return (_jsx(StyledButton, { size: size, filled: filled, "$variant": variant, tone: tone, rounded: rounded, isBadge: true, icon: icon, iconPlacement: iconPlacement, "$closable": closable, "$iconPlacement": iconPlacement, "$isChildrenPresent": !!children, ...restProps, children: !isNil(children) ? (_jsxs(Stack, { "data-component": BadgesComponentNames.container, direction: 'horizontal', gap: theme.spaces.x2, alignY: 'center', children: [_jsx(StackItem, { children: children }), closable ? _jsx(StyledClearIcon, { size: 's', type: 'default', onClick: _onClose }) : null] })) : null }));
}
export function IconBadge(props) {
    return _jsx(Badges, { ...props, rounded: true });
}
Badges.IconBadge = IconBadge;
