import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isRenderableNode } from '@prophecy/utils/react/nodes';
import { useEffect, useState, forwardRef } from 'react';
import { useTriggerContext } from '../Dropdown';
import { renderWithTooltip } from '../Tooltip';
import { StyledButton, StyledButtonLink, StyledButtonAnchor, StyledLoadingIcon, StyledIconWrapper, StyledButtonContent, ButtonWrapperForTooltip } from './styled';
import { ButtonVariants, ButtonSize, ButtonAs, IconPlacement, ButtonShape, ButtonCategory } from './types';
function useLoading(loading) {
    const [isLoading, setIsLoading] = useState(!!loading);
    useEffect(() => {
        if (typeof loading === 'boolean') {
            setIsLoading(loading);
        }
        else if (typeof loading === 'object') {
            const timeout = setTimeout(() => {
                setIsLoading(true);
            }, loading.delay);
            return () => clearTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(loading)]);
    return isLoading;
}
const ButtonComponentMap = {
    anchor: StyledButtonAnchor,
    button: StyledButton,
    link: StyledButtonLink
};
function _Button({ variant = ButtonVariants.primary, icon, iconPlacement = IconPlacement.right, size = ButtonSize.m, shape = ButtonShape.default, loading, disabled, disableWhileLoading = true, elementType, children, block, danger, tooltip, onClick, disablePointerFocus, ...restProps }, forwardRef) {
    const _as = (elementType ?? ButtonAs.button);
    const isLoading = useLoading(loading);
    const _disabled = disabled || (isLoading && disableWhileLoading);
    const hasIcon = isRenderableNode(icon);
    const hasChildren = isRenderableNode(children);
    const hasOnlyIcon = hasIcon && !hasChildren;
    const Component = ButtonComponentMap[_as];
    const insideTrigger = useTriggerContext();
    /**
     * if a button is inside a dropdown trigger, map the onClick to the trigger's onPointerDown
     * As trigger prevent defaults on pointerDown, blocking click event for child components
     */
    const clickProp = _disabled ? undefined : insideTrigger ? { onPointerDown: onClick } : { onClick };
    let _variant = variant;
    // for danger button, there is no tertiary grey and link grey, so change that.
    if (danger && variant === ButtonVariants.tertiaryGray) {
        _variant = ButtonVariants.tertiary;
    }
    else if (danger && variant === ButtonVariants.linkGray) {
        _variant = ButtonVariants.link;
    }
    let button = (_jsxs(Component, { type: 'button', ref: forwardRef, variant: _variant, size: size, disabled: _disabled, shape: shape, hasChildren: hasChildren, "data-loading": isLoading, loading: isLoading, category: danger ? ButtonCategory.danger : ButtonCategory.default, block: block, disablePointerFocus: _variant === ButtonVariants.link ||
            _variant === ButtonVariants.linkGray ||
            _disabled ||
            block ||
            disablePointerFocus, ...clickProp, ...restProps, children: [isLoading && _jsx(StyledLoadingIcon, { type: 'extended', "$iconPlacement": iconPlacement, spin: true }), hasChildren && _jsx(StyledButtonContent, { children: children }), hasOnlyIcon && isLoading
                ? null
                : hasIcon && _jsx(StyledIconWrapper, { "$iconPlacement": iconPlacement, children: icon })] }));
    if (tooltip) {
        /**
         * if elementType is link, it renders a Link Component, which doesn't have forward ref.
         * So if tooltip prop is added it doesn't have effect. To solve such case we can wrap it
         * inside span.
         */
        const _button = elementType === ButtonAs.link ? _jsx(ButtonWrapperForTooltip, { children: button }) : button;
        button = _jsx(_Fragment, { children: renderWithTooltip(_button, tooltip) });
    }
    return button;
}
// NOTE: Sadly forwardRef doesn't allow generic types, so we have to typecast it
export const Button = forwardRef(_Button);
