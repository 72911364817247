import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFocusTrap } from '@prophecy/utils/react/focus';
import { useControlledState } from '@prophecy/utils/react/hooks';
import { FocusScope } from '@radix-ui/react-focus-scope';
import { Provider, Root, Portal, Trigger } from '@radix-ui/react-tooltip';
import React, { isValidElement } from 'react';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { StyledContent, StyledCopyIcon, StyledDetail, StyledMarkDown } from './styled';
import { UseMarkdownFor } from './types';
export * from './types';
export function Tooltip({ title, detail, visible, onVisibleChange, children, overlayClassName, overlayStyle, placement: side = 'top', placementOffset: sideOffset = 8, align, hasCopy = false, alignOffset, delay = 100, disabled, isMarkdown = UseMarkdownFor.none, forceTooltipContentColor = true, ...contentProps }) {
    const [_visible, toggleVisible] = useControlledState({
        value: (!title && !detail) || disabled ? false : visible,
        onChange: onVisibleChange
    });
    const titleRef = React.createRef();
    const detailsRef = React.createRef();
    const container = useFocusTrap(Boolean(_visible));
    const onCopy = async (e) => {
        e.stopPropagation();
        const text = titleRef?.current?.innerText || '';
        const details = detailsRef?.current?.innerText || '';
        await navigator.clipboard.writeText(`${text}\n${details}`);
    };
    const content = (_jsx(StyledContent, { className: overlayClassName, "$forceTooltipContentColor": forceTooltipContentColor, style: overlayStyle, side: side, align: align, sideOffset: sideOffset, alignOffset: alignOffset, ref: container, tabIndex: -1, ...contentProps, children: _jsxs(Stack, { direction: 'vertical', children: [title && (_jsxs(Stack, { direction: 'horizontal', width: '100%', align: 'space-between', gap: theme.spaces.x4, children: [_jsx("span", { ref: titleRef, children: (isMarkdown === UseMarkdownFor.title || isMarkdown === UseMarkdownFor.both) &&
                                typeof title === 'string' ? (_jsx(StyledMarkDown, { children: title })) : (title) }), hasCopy && _jsx(StyledCopyIcon, { size: 'xs', type: 'default', onClick: onCopy })] })), detail && (_jsxs(Stack, { direction: 'horizontal', width: '100%', align: 'left', children: [_jsx(StyledDetail, { ref: detailsRef, style: { paddingTop: 'none' }, children: (isMarkdown === UseMarkdownFor.detail || isMarkdown === UseMarkdownFor.both) &&
                                typeof detail === 'string' ? (_jsx(StyledMarkDown, { children: detail })) : (detail) }), hasCopy && !title && _jsx(StyledCopyIcon, { size: 'xs', type: 'default', onClick: onCopy })] }))] }) }));
    return (_jsx(Provider, { children: _jsxs(Root, { open: _visible, delayDuration: delay, onOpenChange: toggleVisible, children: [_jsx(Trigger, { asChild: true, children: children }), _jsx(Portal, { children: _jsx(FocusScope, { asChild: true, trapped: false, onUnmountAutoFocus: (e) => e.preventDefault(), onMountAutoFocus: (e) => e.preventDefault(), children: content }) })] }) }));
}
export const renderWithTooltip = (content, tooltip) => {
    if (!tooltip)
        return content;
    const tooltipProps = isValidElement(tooltip) || typeof tooltip === 'string'
        ? {
            title: tooltip,
            placement: 'top',
            isMarkdown: 'title',
            overlayStyle: {
                maxWidth: '500px',
                maxHeight: '300px',
                overflow: 'auto',
                padding: theme.spaces.x12
            }
        }
        : tooltip;
    return _jsx(Tooltip, { ...tooltipProps, children: content });
};
