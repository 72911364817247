import { theme } from '../theme';
export const tokens = {
    ResizeHandle: {
        zIndex: theme.zLayer.s,
        fontSize: theme.fontSizes.x18,
        offset: theme.spaces.x4,
        backgroundColor: theme.colors.gray200
    }
};
export const RESIZE_HANDLE_CLASS = 'ui-resize-handle';
