const FabricFields = `
          _id
          name
          created
          cluster: aspects(aspect: ClusterInfo) {
            AspectValue
          }
          team{
            _id
            name
          }
          FabricServicePrincipalStateAspect: aspects(aspect: FabricServicePrincipalState) {
            AspectName
            AspectValue
          }
          SparkProviderInfo: aspects(aspect: SparkProviderInfo) {
            AspectName
            AspectValue
          }
          SqlProviderInfoAspect: aspects(aspect: SqlProviderInfo) {
              AspectValue
              AspectName
            }
          AirflowProviderInfoAspect: aspects(aspect: AirflowProviderInfo){ 
              AspectValue
              AspectName
            }
          aspects(aspect: [JobSize]) {
            AspectValue
            AspectName
          }
  `;
export function getUserFabricFields(isSqlProject: boolean, hasAirflow: boolean) {
  return `
          _id
          name
          created
          cluster: aspects(aspect: ClusterInfo) {
            AspectValue
          }
          team{
            _id
            name
          }
          FabricServicePrincipalStateAspect: aspects(aspect: FabricServicePrincipalState) {
            AspectName
            AspectValue
          }
          SparkProviderInfo: aspects(aspect: SparkProviderInfo) {
            AspectName
            AspectValue
          }
          ${
            isSqlProject
              ? `SqlProviderInfoAspect: aspects(aspect: SqlProviderInfo) {
              AspectValue
              AspectName
            }
            OrchestrationProviderInfoAspect: aspects(aspect: OrchestrationProviderInfo) {
              AspectValue
              AspectName
            }  
            `
              : ''
          }
          ${
            hasAirflow
              ? `AirflowProviderInfoAspect: aspects(aspect: AirflowProviderInfo){ 
              AspectValue
              AspectName
            }`
              : ''
          }
          aspects(aspect: [JobSize]) {
            AspectValue
            AspectName
          }
  ` as typeof FabricFields;
}
