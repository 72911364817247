import { jsx as _jsx } from "react/jsx-runtime";
import { useDebounce, useDependantState } from '@prophecy/utils/react/hooks';
import { noop } from 'lodash-es';
import RCSelect from 'rc-select';
import { useState } from 'react';
import { createCustomSelect } from './BaseSelect';
import { defaultFilterOption, defaultOptionRender, useValidateDropdownWidth } from './Select';
import { AUTOCOMPLETE_CLASS, tokens } from './tokens';
import { SelectSize } from './types';
const CustomSelect = createCustomSelect(RCSelect);
export function AutoComplete({ value, defaultValue = '', className = '', options, onChange, onFocus, onBlur, icon, delay = 0, listItemHeight, virtual, dropdownMatchSelectWidth = true, optionRender, ...restProps }) {
    useValidateDropdownWidth(dropdownMatchSelectWidth, virtual);
    const [state, setState] = useDependantState((value) => value, [value]);
    const [applyFilter, toggleFilter] = useState(true);
    const currentListItemHeight = listItemHeight || tokens.Menu.size[restProps.size || SelectSize.m].listItemHeight;
    const handleFocus = (event) => {
        onFocus?.(event);
        toggleFilter(false);
    };
    const slowOnChange = useDebounce(onChange || noop, delay);
    const handleChange = (value, option) => {
        setState(value);
        const selectedOption = option?.value ? option : undefined;
        slowOnChange(value, selectedOption);
        toggleFilter(true);
    };
    const handleBlur = (e) => {
        // cancel debounce
        slowOnChange.cancel();
        // trigger change only if value is not up to date
        if (state !== value) {
            onChange?.(state);
        }
        onBlur?.(e);
    };
    let _options = options;
    if (applyFilter && options) {
        _options = options.filter((option) => {
            return defaultFilterOption(state || '', option);
        });
    }
    return (_jsx(CustomSelect, { ...restProps, virtual: virtual, listItemHeight: currentListItemHeight, className: `${className} ${AUTOCOMPLETE_CLASS}`, mode: 'combobox', value: state, onChange: handleChange, options: _options, onFocus: handleFocus, onBlur: handleBlur, optionRender: optionRender ?? ((option) => defaultOptionRender(option, dropdownMatchSelectWidth)), dropdownMatchSelectWidth: dropdownMatchSelectWidth }));
}
AutoComplete.Option = RCSelect.Option;
AutoComplete.OptGroup = RCSelect.OptGroup;
