import { Root, Thumb } from '@radix-ui/react-switch';
import styled, { css } from 'styled-components';
import { Stack } from '../Layout';
import { resolveCssVariable, theme } from '../theme/index';
import { tokens } from './tokens';
export const StyledSwitchContainer = styled(Stack) `
  ${(props) => (props.disabled ? `cursor: not-allowed;` : `cursor: pointer;`)};
  position: relative;
`;
export const StyledSwitchRoot = styled(Root) `
  all: unset;
  align-items: center;
  ${({ size }) => `
    width: ${tokens.size[size].width};
    height: ${tokens.size[size].height};
  `};
  padding: ${tokens.SwitchRoot.padding};
  background-color: ${tokens.SwitchRoot.state.normal.backgroundColor};
  border-radius: ${tokens.SwitchRoot.borderRadius};
  position: relative;
  flex-shrink: 0;
  ${({ disabled }) => disabled
    ? css `
          cursor: not-allowed;
          background-color: ${tokens.SwitchRoot.state.normal.backgroundColor};
          &[data-state='checked'] {
            background-color: ${tokens.SwitchRoot.state.selected.backgroundColor};
          }
        `
    : css `
          &:hover,
          &:focus {
            background-color: ${tokens.SwitchRoot.state.normal.hover.backgroundColor};
          }
          &:focus {
            box-shadow: ${tokens.SwitchRoot.boxShadow};
          }
          &[data-state='checked'] {
            background-color: ${tokens.SwitchRoot.state.selected.backgroundColor};
            &:hover {
              background-color: ${tokens.SwitchRoot.state.selected.hover.backgroundColor};
            }
            &:focus {
              box-shadow: ${tokens.SwitchRoot.boxShadow};
              background-color: ${tokens.SwitchRoot.state.selected.focus.backgroundColor};
            }
          }
        `}

  ${(props) => props.hasHelpText && `top: ${theme.spaces.x4};`}
`;
export const StyledSwitchThumb = styled(Thumb) `
  display: block;
  ${({ size }) => `
    width: ${tokens.size[size].SwitchThumb.size};
    height: ${tokens.size[size].SwitchThumb.size};
  `};
  background-color: ${({ disabled }) => disabled ? tokens.SwitchThumb.disabled.backgroundColor : tokens.SwitchThumb.backgroundColor};
  border-radius: 100%;
  transition: transform ${theme.transition.s};
  transform: translateX(${tokens.SwitchRoot.padding});
  will-change: transform;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1));
  &[data-state='checked'] {
    transform: translateX(${({ size }) => (resolveCssVariable(tokens.size[size].height) === '20px' ? '18px' : '22px')});
  }
`;
