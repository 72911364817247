import { Item, Root } from '@radix-ui/react-radio-group';
import styled, { css } from 'styled-components';
import { ContentIcon } from '../Icons';
import { Stack } from '../Layout';
import { getInterFontWeightCSS, theme } from '../theme';
import { Text } from '../Typography/Text';
import { tokens } from './tokens';
import { Placement } from './types';
export const StyledRoot = styled(Root) `
  display: flex;
`;
export const StyledGroupIconSection = styled(Stack) `
  width: 100%;
  height: 60px;
  border-radius: ${tokens.RadioGroup.borderRadius} ${tokens.RadioGroup.borderRadius} 0px 0px;
`;
const baseRadioStyles = css `
  border-radius: 50%;
  border: 1.5px solid ${theme.colors.gray300};
  transition: all 0.2s ease;
`;
const checkedRadioStyles = css `
  border: none;
  background: ${({ disabled }) => disabled
    ? `linear-gradient(to bottom, ${theme.colors.gray300} 0%, ${theme.colors.gray300} 100%) content-box,
         linear-gradient(to bottom, ${theme.colors.gray100} 0%, ${theme.colors.gray100} 100%) padding-box`
    : `linear-gradient(to bottom, ${theme.colors.white} 0%, ${theme.colors.white} 100%) content-box,
         linear-gradient(to bottom, ${theme.colors.brand600} 0%, ${theme.colors.brand600} 100%) padding-box`};

  ${({ disabled }) => disabled && `box-shadow: 0px 0px 0px 1.5px ${theme.colors.gray300}`}
`;
export const StyledRadio = styled.div `
  width: ${({ size }) => tokens.RadioItem[size].size};
  height: ${({ size }) => tokens.RadioItem[size].size};
  min-width: ${({ size }) => tokens.RadioItem[size].size};
  padding: ${({ size }) => tokens.RadioItem[size].padding};
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${baseRadioStyles}
  background: ${({ disabled }) => (disabled ? tokens.RadioItem.disabled.Icon.backgroundColor : 'transparent')};
  ${({ checked }) => checked && checkedRadioStyles}
`;
// Split common styles into smaller, focused mixins
const cardStyles = css `
  cursor: pointer;
  width: 140px;
  border-radius: ${tokens.RadioGroup.borderRadius};

  &&& {
    height: 113px;
  }

  ${StyledGroupIconSection} {
    background: ${({ disabled }) => disabled ? tokens.RadioItem.disabled.cardIconBackground : tokens.RadioItem.selected.cardIconBackground};
  }
`;
const stateStyles = css `
  background: ${({ checked, disabled }) => disabled
    ? tokens.RadioItem.disabled.backgroundColor
    : checked
        ? tokens.RadioItem.selected.backgroundColor
        : tokens.RadioItem.normal.backgroundColor};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
`;
/**
 * For RadioItem's RadioIconBase
 */
export const StyledContentIcon = styled(ContentIcon) `
  width: ${({ $size }) => tokens.RadioItem[$size].size};
  height: ${({ $size }) => tokens.RadioItem[$size].size};
  border: 2px solid ${theme.colors.gray200};
  border-radius: ${theme.radius.full};
  color: ${theme.colors.white};
`;
export const RadioIconBase = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: ${({ $size }) => tokens.RadioItem[$size].size};
  min-height: ${({ $size }) => tokens.RadioItem[$size].size};
  background-color: ${theme.colors.brand100};
  border-radius: ${theme.radius.full};

  ${StyledContentIcon} {
    ${({ $checked, $disabled }) => $checked &&
    `
        border: ${$disabled ? `2px solid ${theme.colors.gray200}` : 'none'};
        color: ${$disabled ? tokens.RadioItem.disabled.Icon.backgroundColor : tokens.RadioItem.iconColor};
        circle {
          fill: ${$disabled ? tokens.RadioItem.disabled.Icon.borderColor : theme.colors.white};
        }
        
      `}
  }
`;
export const commonCss = css `
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: flex-start;
  align-items: ${({ alignY }) => alignY || 'center'};

  &,
  ${StyledRadio}, ${RadioIconBase} {
    transition:
      box-shadow ${theme.transition.s},
      border-color ${theme.transition.s};
  }

  ${({ isCard }) => isCard && cardStyles}
  ${stateStyles}

  ${({ disabled }) => `
    border: 1px solid ${tokens.RadioItem.normal.borderColor};
    &:focus {
      &[data-state='unchecked'] {
        &, ${StyledRadio}, ${RadioIconBase} {
          box-shadow: ${disabled ? 'none' : tokens.RadioItem.focus.boxShadow};
        }
      }
    }

    &:focus-visible {
      &[data-state='checked'] {
        &, ${StyledRadio}, ${RadioIconBase} {
          box-shadow: ${disabled ? 'none' : tokens.RadioItem.focus.boxShadow};
        }
      }
    }

     &[data-state='checked'] {
        border-width: ${tokens.RadioItem.selected.borderWidth};
        &,&:hover{
          border-color: ${disabled ? tokens.RadioItem.normal.borderColor : tokens.RadioItem.selected.borderColor};
        }
     }
  `}
`;
export const GroupItemCss = css `
  width: ${({ width }) => width};
  border-radius: ${tokens.RadioGroup.borderRadius};
  padding: ${tokens.RadioGroup.padding};
  &:not([data-state='checked']) {
    padding: calc(${tokens.RadioGroup.padding} + 1px); // 1px for border - to insure no flickering
  }
`;
export const StyledRadioItem = styled(Item) `
  ${commonCss}

  ${({ headingWithBorder, disabled, disableBorder, checkboxPlacement }) => headingWithBorder
    ? `
    &&&, &&&:hover, &&&[data-state='checked']{
      padding: 0;
      flex-direction: column;
      display: flex;
      ${disabled ? '' : 'background: transparent;'}
    }
    // 1px padding for unchecked to prevent border flicker
    &&&:not([data-state='checked']){
      padding: 1px;
    }
  `
    : disableBorder
        ? `&&&&, &&&&:hover, &&&&[data-state='checked']{
        border: none;
        ${checkboxPlacement === Placement.left ? `gap: ${tokens.RadioGroup.gap};` : ''}
        box-shadow: none;
        background: none;
      }`
        : ''}
`;
export const StyledRadioLabel = styled(Text) `
  flex: 1;
  font-size: ${({ size }) => tokens.RadioItem[size].fontSize};
  min-width: 0;
  ${getInterFontWeightCSS(tokens.RadioItem.fontWeight)};
  color: ${({ disabled }) => (disabled ? tokens.RadioItem.disabled.color : tokens.RadioItem.color)};
`;
export const StyledOptionsWrap = styled(Stack) `
  ${({ wrap }) => (wrap ? 'flex-wrap: wrap;' : '')}
  ${StyledRadioItem} {
    ${({ direction }) => (direction === 'vertical' ? `` : 'height: 100%;')}
  }
`;
export const StyledContentWrap = styled(Stack) `
  width: 100%;
  height: 100%;
  position: relative;

  ${({ headingWithBorder, disabled, checked }) => headingWithBorder
    ? `
    padding: ${checked ? tokens.RadioGroup.padding : `${tokens.RadioGroup.padding} ${tokens.RadioGroup.padding} calc(${tokens.RadioGroup.padding} + 1px)`}; // 1px for border - to insure no flickering
    border-bottom: ${tokens.RadioItem.normal.borderWidth} solid ${tokens.RadioItem.normal.borderColor};
    background: ${disabled ? tokens.RadioItem.disabled.backgroundColor : 'transparent'};
    border-radius: ${tokens.RadioGroup.borderRadius} ${tokens.RadioGroup.borderRadius} 0 0;
    &:hover{
      background: ${disabled ? tokens.RadioItem.disabled.backgroundColor : tokens.RadioItem.selected.Icon.backgroundColor};
      border-color: ${disabled ? tokens.RadioItem.disabled.Icon.borderColor : tokens.RadioItem.selected.Icon.borderColor};
    }
    ${checked
        ? `
     border-width: ${disabled ? tokens.RadioItem.normal.borderWidth : tokens.RadioItem.selected.borderWidth};
      &,&:hover{
        background: ${disabled ? tokens.RadioItem.disabled.backgroundColor : tokens.RadioItem.selected.backgroundColor};
        border-color: ${disabled ? tokens.RadioItem.disabled.Icon.borderColor : tokens.RadioItem.selected.borderColor};
      }
    `
        : ''}
  `
    : ''}
`;
export const StyledCaption = styled(Stack) `
  margin-left: ${theme.spaces.x24};
  color: ${tokens.RadioItem.Caption.color};
  font-size: ${tokens.RadioItem.Caption.fontSize};
  line-height: ${tokens.RadioItem.Caption.lineHeight};
`;
