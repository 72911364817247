import { Content as _HoverCardContent } from '@radix-ui/react-hover-card';
import { Content as _PopoverContent } from '@radix-ui/react-popover';
import styled, { css } from 'styled-components';
import { theme } from '../theme';
const contentStyle = css `
  display: flex;
  flex-direction: column;
  font-family: ${theme.fontFamily.text};
  min-width: 220px;
  padding: ${theme.spaces.x8} ${theme.spaces.x12};
  background: ${theme.colors.white};
  box-shadow: ${theme.shadows.lg};
  border-radius: ${theme.radius.x12};
  z-index: ${theme.zLayer.xxl};
  border: 1px solid ${theme.colors.gray200};
  pointer-events: all;
`;
export const PopoverContent = styled(_PopoverContent) `
  ${contentStyle}
`;
export const HoverCardContent = styled(_HoverCardContent) `
  ${contentStyle}
`;
