export var TabTone;
(function (TabTone) {
    TabTone["brand"] = "brand";
    TabTone["gray"] = "gray";
    TabTone["underline"] = "underline";
    TabTone["white"] = "white";
    TabTone["minimal"] = "minimal";
})(TabTone || (TabTone = {}));
export var Direction;
(function (Direction) {
    Direction["vertical"] = "vertical";
    Direction["horizontal"] = "horizontal";
})(Direction || (Direction = {}));
export var TabSize;
(function (TabSize) {
    TabSize["s"] = "s";
    TabSize["m"] = "m";
})(TabSize || (TabSize = {}));
export var TriggerType;
(function (TriggerType) {
    TriggerType["trigger"] = "trigger";
    TriggerType["group"] = "group";
})(TriggerType || (TriggerType = {}));
