import styled, { css } from 'styled-components';
import { ICON_CLASS } from '../Icons/tokens';
import { Stack } from '../Layout';
import { getInterFontWeightCSS, negateVariable, theme } from '../theme';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { tokens } from './tokens';
import { Placement } from './types';
export const StyledAsteriskIcon = styled.span `
  color: ${tokens.Root.Asterisk.color};
  font-size: ${tokens.Root.Asterisk.fontSize};
  ${getInterFontWeightCSS(tokens.Root.Asterisk.fontWeight)};
  line-height: ${tokens.Root.Asterisk.lineHeight};
`;
export const StyledIconWrapper = styled.span `
  user-select: none;
  display: inline-block;
  line-height: 1;
  order: ${({ addOnPlacement, iconPlacement }) => iconPlacement === Placement.left && addOnPlacement !== Placement.left
    ? '1'
    : iconPlacement === Placement.right
        ? '3'
        : '2'};
`;
export const StyledAddOnWrapper = styled.span `
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.fontSizes.x14};
  height: 100%;
  order: ${({ addOnPlacement, iconPlacement }) => addOnPlacement === Placement.left && iconPlacement !== Placement.left
    ? '1'
    : addOnPlacement === Placement.right
        ? '3'
        : '2'};

  padding: 0 ${({ size }) => tokens.Input.size[size].padding};
  ${({ addOnPlacement, size, $variant, addOnSeparator }) => addOnPlacement === Placement.left
    ? `
        ${addOnSeparator && `border-right: 1px solid ${tokens.Input[$variant].borderColor};`}
        margin-left: ${negateVariable(tokens.Input.size[size].padding)};
        margin-right: calc(${tokens.Input.size[size].padding} - ${tokens.Input.size[size].gap});
        border-top-left-radius: ${tokens.Input.size[size].borderRadius};
        border-bottom-left-radius: ${tokens.Input.size[size].borderRadius};
  `
    : `
        ${addOnSeparator && `border-left: 1px solid ${tokens.Input[$variant].borderColor};`}
        margin-left: calc(${tokens.Input.size[size].padding} - ${tokens.Input.size[size].gap});
        margin-right: ${negateVariable(tokens.Input.size[size].padding)};
        border-top-right-radius: ${tokens.Input.size[size].borderRadius};
        border-bottom-right-radius: ${tokens.Input.size[size].borderRadius};
  `}

  background: ${({ addOnSeparator }) => (addOnSeparator ? tokens.Input.AddOn.backgroundColor : 'transparent')};
  color: ${tokens.Input.AddOn.color};
  line-height: ${({ size }) => tokens.Input.size[size].height};
`;
export const StyledInputRoot = styled(Stack) `
  font-family: ${theme.fontFamily.text};
  position: relative;
  color: ${tokens.Input.color};

  /** Style icon base on size */
  .${ICON_CLASS} {
    color: ${(props) => tokens.Input[props.variant].iconColor ?? tokens.Input.iconColor};
    font-size: ${(props) => tokens.Input.size[props.size].iconSize};
  }
`;
export const StyledLabelWithHint = styled(LabelWithHint) `
  .${ICON_CLASS} {
    color: ${tokens.Input.iconColor};
    font-size: ${tokens.Input.hintIconSize};
  }
`;
export const inputColorStyles = css `
  background-color: ${(props) => tokens.Input[props.variant].backgroundColor};
  border-radius: ${(props) => tokens.Input.size[props.inputSize].borderRadius};
  border: 1px solid ${({ variant }) => tokens.Input[variant].borderColor};
  color: ${(props) => tokens.Input[props.variant].color};
  box-shadow: ${(props) => tokens.Input.boxShadow};
  cursor: pointer;
  &:hover {
    color: ${(props) => tokens.Input[props.variant].hover.color};
  }
  &:focus-within,
  &[data-active='true'] {
    border-color: ${(props) => tokens.Input[props.variant].focus.borderColor};
    outline: 1px solid ${({ variant }) => tokens.Input[variant].focus.borderColor};
    outline-offset: -2px;
  }
  &[disabled],
  input[disabled] {
    color: ${tokens.Input.disabled.color};
    background-color: ${tokens.Input.disabled.backgroundColor};
    border-color: ${tokens.Input.disabled.borderColor};
    cursor: not-allowed;
  }
`;
export const inputContainerStyles = css `
  all: unset;
  box-sizing: border-box;
  padding: ${(props) => `${tokens.Input.size[props.inputSize].paddingVertical} ${tokens.Input.size[props.inputSize].padding}`};
  ${(props) => props.type === 'number' && `padding-right: 0;`}
  font-size: ${(props) => tokens.Input.size[props.inputSize].fontSize};
  height: ${(props) => tokens.Input.size[props.inputSize].height};
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${(props) => tokens.Input.size[props.inputSize].gap};

  [type='number']::-webkit-inner-spin-button {
    height: ${(props) => tokens.Input.size[props.inputSize].height};
  }
`;
export const StyledInputContainer = styled(Stack) `
  ${inputContainerStyles}
  ${inputColorStyles}
`;
export const InputWrap = styled(Stack) `
  width: 100%;
  height: 100%;
  order: ${({ addOnPlacement, iconPlacement }) => (addOnPlacement || iconPlacement ? '2' : '1')};
`;
export const StyledInput = styled.input `
  padding: 0;
  display: inline-block;
  line-height: 1;
  height: 100%;
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  font-size: 1em;
  color: ${tokens.Input.color};
  font-family: ${theme.fontFamily.text};
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:-webkit-autofill {
    -webkit-background-clip: text;
  }
`;
export const StyledLabelWrapper = styled.div `
  display: flex;
  align-items: flex-start;
  gap: ${theme.spaces.x2};
`;
export const StyledLabel = styled.label `
  ${({ underlined }) => underlined && `border-bottom: 1px dotted ${tokens.Input.Label.underlineColor};`}
  line-height: ${tokens.Input.Label.lineHeight};
  font-size: ${tokens.Input.Label.fontSize};
  color: ${tokens.Input.Label.color};
  .${ICON_CLASS} {
    color: ${tokens.Input.Label.iconColor};
    font-size: ${tokens.Input.Label.iconSize};
  }
`;
export const StyledHelpText = styled(Stack) `
  font-size: ${tokens.Input.HelpText.fontSize};
  line-height: ${tokens.Input.HelpText.lineHeight};
  ${getInterFontWeightCSS(tokens.Input.HelpText.fontWeight)};
  color: ${(props) => tokens.Input.HelpText[props.variant].color};
`;
