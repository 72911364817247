import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { rotate } from '../theme/animation';
import { tokens, ICON_CLASS } from './tokens';
export var IconVariant;
(function (IconVariant) {
    IconVariant["light"] = "light";
    IconVariant["bold"] = "bold";
    IconVariant["custom"] = "custom";
})(IconVariant || (IconVariant = {}));
export var NewIconVariant;
(function (NewIconVariant) {
    NewIconVariant["default"] = "default";
    NewIconVariant["solid"] = "solid";
    NewIconVariant["extended"] = "extended";
    NewIconVariant["gem"] = "gem";
})(NewIconVariant || (NewIconVariant = {}));
export var IconSize;
(function (IconSize) {
    IconSize["xxs"] = "xxs";
    IconSize["xs"] = "xs";
    IconSize["s"] = "s";
    IconSize["m"] = "m";
    IconSize["l"] = "l";
    IconSize["xl"] = "xl";
})(IconSize || (IconSize = {}));
export function IconBase({ iconVariants, type, rotate, name, size = IconSize.m, strokeWidth, miniIcon = false, ...restProps }) {
    const Component = iconVariants[type];
    if (!Component)
        throw new Error(`Icon type "${type}" is not defined for "${name}"`);
    return _jsx(Component, { rotate: rotate, ...restProps, "data-icon-name": name });
}
export const Icon = styled(IconBase) `
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  font-size: ${({ size }) => tokens.Icon[size].size};
  ${(props) => props.rotate && `transform: rotate(${props.rotate}deg);`}
  transform-origin: center center;
  ${(props) => props.spin &&
    css `
      animation: 1s ${rotate} linear infinite;
    `}
  ${(props) => props.color && `color: ${props.color};`}
  path, rect {
    ${({ type }) => type === NewIconVariant.gem
    ? `&[fill] {
            fill: currentColor;
          }`
    : `fill: currentColor;`}
    &[stroke] {
      stroke: currentColor;
      &:not([fill]) {
        fill: none;
      }
      ${({ strokeWidth, size, miniIcon }) => `stroke-width: ${strokeWidth || (miniIcon ? tokens.Icon[size].mini.strokeWidth : tokens.Icon[size].strokeWidth)};`}
    }
  }
`;
export function createIcons(name, iconVariants) {
    const CreatedIcon = forwardRef((props, ref) => {
        const { type, size = IconSize.s } = props;
        return (_jsx(Icon, { ...props, className: `${ICON_CLASS} ${props.className}`, ref: ref, type: type, size: size, iconVariants: iconVariants, name: name }));
    });
    // added this line for typing support
    CreatedIcon.supportedTypes = [];
    return CreatedIcon;
}
export const IconWithUrl = styled.div `
  background-color: ${({ color }) => color};
  mask: url(${({ icon }) => icon});
  mask-size: ${({ size }) => `${size} ${size}`};
  mask-position: center;
  width: ${({ size }) => `${size}`};
  height: ${({ size }) => `${size}`};
`;
