import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
class CursorToolbar {
    constructor({ position, editor, allowEditorOverflow, renderCursorToolbar }) {
        this.id = 'cursor_toolbar';
        this.position = position;
        this.domNode = document.createElement('div');
        this.domRoot = ReactDOM.createRoot(this.domNode);
        this.allowEditorOverflow = Boolean(allowEditorOverflow);
        this.domRoot.render(renderCursorToolbar(editor));
    }
    getId() {
        return this.id;
    }
    getDomNode() {
        return this.domNode;
    }
    getPosition() {
        return this.position;
    }
    destroy() {
        this.domRoot.unmount();
    }
}
export function useCursorToolbar({ editor, renderCursorToolbar }) {
    const widgetRef = useRef(null);
    const positionRef = useRef(null);
    const closeWidget = usePersistentCallback(() => {
        if (editor && widgetRef.current) {
            widgetRef.current.destroy();
            editor.removeContentWidget(widgetRef.current);
            widgetRef.current = null;
        }
    });
    const showWidget = usePersistentCallback(() => {
        const currentPosition = positionRef.current;
        if (editor && currentPosition && renderCursorToolbar) {
            const position = {
                position: {
                    lineNumber: currentPosition.lineNumber,
                    column: currentPosition.column
                },
                preference: [1, 2]
            };
            widgetRef.current = new CursorToolbar({
                editor,
                position: position,
                allowEditorOverflow: false,
                renderCursorToolbar: renderCursorToolbar
            });
            editor.addContentWidget(widgetRef.current);
        }
    });
    const listenCursorChange = usePersistentCallback((event) => {
        const newPosition = event.position;
        const oldPosition = positionRef.current;
        if (oldPosition?.column === newPosition.column && oldPosition?.lineNumber === newPosition.lineNumber) {
            return;
        }
        else {
            positionRef.current = newPosition;
            closeWidget();
            showWidget();
        }
    });
    useEffect(() => {
        if (!renderCursorToolbar || !editor) {
            return;
        }
        editor.onDidChangeCursorPosition(listenCursorChange);
        editor.onDidBlurEditorWidget(closeWidget);
    }, [editor, closeWidget, showWidget, renderCursorToolbar, listenCursorChange]);
}
