export var ButtonCategory;
(function (ButtonCategory) {
    ButtonCategory["default"] = "default";
    ButtonCategory["danger"] = "danger";
})(ButtonCategory || (ButtonCategory = {}));
export var ButtonState;
(function (ButtonState) {
    ButtonState["hover"] = "hover";
    ButtonState["focused"] = "focused";
    ButtonState["disabled"] = "disabled";
})(ButtonState || (ButtonState = {}));
export var ButtonVariants;
(function (ButtonVariants) {
    ButtonVariants["primary"] = "primary";
    ButtonVariants["secondary"] = "secondary";
    ButtonVariants["secondaryGray"] = "secondaryGray";
    ButtonVariants["tertiary"] = "tertiary";
    ButtonVariants["tertiaryGray"] = "tertiaryGray";
    ButtonVariants["link"] = "link";
    ButtonVariants["linkGray"] = "linkGray";
    ButtonVariants["plain"] = "plain";
})(ButtonVariants || (ButtonVariants = {}));
export const ButtonVariantsWithDanger = [
    `${ButtonVariants.primary}`,
    `${ButtonVariants.secondary}`,
    `${ButtonVariants.tertiary}`,
    `${ButtonVariants.link}`
];
export var IconPlacement;
(function (IconPlacement) {
    IconPlacement["left"] = "left";
    IconPlacement["right"] = "right";
})(IconPlacement || (IconPlacement = {}));
export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["xs"] = "xs";
    ButtonSize["s"] = "s";
    ButtonSize["m"] = "m";
    ButtonSize["l"] = "l";
    ButtonSize["xl"] = "xl";
    ButtonSize["xxl"] = "xxl";
})(ButtonSize || (ButtonSize = {}));
export var ButtonShape;
(function (ButtonShape) {
    ButtonShape["default"] = "default";
    ButtonShape["circle"] = "circle";
})(ButtonShape || (ButtonShape = {}));
export var ButtonAs;
(function (ButtonAs) {
    ButtonAs["button"] = "button";
    ButtonAs["link"] = "link";
    ButtonAs["anchor"] = "anchor";
})(ButtonAs || (ButtonAs = {}));
