import dagre from 'dagre';
import ELK from 'elkjs/lib/elk-api';
import { getRectOfNodes } from 'reactflow';
import { LABEL_HEIGHT, NODE_DIMENSION, NODE_SPACING, SUBFLOW_MARGIN } from './tokens';
import { NodeType, LayoutAlgo } from './types';
import { checkRectangleOverlap, getNodeSize, isNodeNotPositioned, snapPosition } from './utils';
// with web worker
const elk = new ELK({
    workerFactory: function () {
        /*
         * we  are loading elk-worker as worker file, but the ts understand it as a module, so we are ignoring the type
         */
        // @ts-ignore
        const WorkerFactory = new Worker(new URL('elkjs/lib/elk-worker.js', import.meta.url));
        return WorkerFactory;
    }
});
function getSubFlowRelations(nodes) {
    const subFlowNodes = nodes.filter((node) => node.type === NodeType.subFlow);
    const subFlowRelations = Object.fromEntries(subFlowNodes.map((node) => [node.id, { node, children: [] }]));
    nodes.forEach((node) => {
        if (node.parentNode) {
            subFlowRelations[node.parentNode].children.push(node);
        }
    });
    return subFlowRelations;
}
function getDagreSubFlowLayoutedElements(nodes) {
    const subFlowNodes = nodes.filter((node) => node.type === NodeType.subFlow);
    const subFlowRelations = Object.fromEntries(subFlowNodes.map((node) => [node.id, { node, children: [] }]));
    nodes.forEach((node) => {
        if (node.parentNode) {
            subFlowRelations[node.parentNode].children.push(node);
        }
    });
    const nodeMaps = Object.fromEntries(nodes.map((node) => [node.id, { ...node }]));
    Object.entries(subFlowRelations).forEach(([subFlowId, { node, children }]) => {
        const bounds = getRectOfNodes(children);
        const nodeData = node.data;
        const width = Math.max(bounds.width + SUBFLOW_MARGIN * 2, nodeData.minWidth || 0);
        const height = Math.max(bounds.height + SUBFLOW_MARGIN * 2 + LABEL_HEIGHT, nodeData.minHeight || 0);
        const subFlowPosition = snapPosition({
            x: bounds.x - (width - bounds.width) / 2,
            y: bounds.y - (height - bounds.height) / 2 - LABEL_HEIGHT
        });
        // update subflow
        nodeMaps[subFlowId].position = subFlowPosition;
        nodeMaps[subFlowId].width = width;
        nodeMaps[subFlowId].height = height;
        // update children
        children.forEach((child) => {
            nodeMaps[child.id].position.x = child.position.x - subFlowPosition.x;
            nodeMaps[child.id].position.y = child.position.y - subFlowPosition.y;
        });
    });
    return Object.values(nodeMaps);
}
async function getLayoutedElementsWitElk(nodes, edges, subFlowRelations, horizontalSpacing = NODE_SPACING, verticalSpacing = NODE_SPACING) {
    const nodeLayoutOption = {
        alignment: 'CENTER',
        portConstraints: 'FIXED_ORDER',
        algorithm: 'org.eclipse.elk.layered',
        edgeRouting: 'SPLINES',
        hierarchyHandling: 'INCLUDE_CHILDREN',
        'spacing.nodeNodeBetweenLayers': `${horizontalSpacing}`,
        'spacing.baseValue': `${verticalSpacing}`
    };
    const getNodeConfiguration = (node) => {
        const getSize = node?.data?.getSize || getNodeSize;
        const sourceHandleLength = node?.data?.sourceHandles.length || 0;
        const targetHandleLength = node?.data?.targetHandles.length || 0;
        const maxPorts = Math.max(sourceHandleLength, targetHandleLength);
        const { width, height } = getSize(maxPorts);
        let _height = height;
        let _width = width;
        if (node.type === NodeType.comment) {
            _height = node.height ?? height;
            _width = node.width ?? width;
        }
        return {
            id: node.id,
            width: _width,
            height: _height,
            layoutOptions: nodeLayoutOption,
            ports: [
                ...node.data.targetHandles.map((handle, index) => ({
                    id: handle.id,
                    layoutOptions: {
                        'port.index': `${index}`,
                        'port.side': 'WEST'
                    }
                })),
                ...node.data.sourceHandles.map((handle, index) => ({
                    id: handle.id,
                    layoutOptions: {
                        // elk layout x,y 0 starts from bottom left, similar to coordination system, so we need to vertically mirror the port and nodes
                        'port.index': `${sourceHandleLength - 1 - index}`,
                        'port.side': 'EAST'
                    }
                }))
            ]
        };
    };
    const graph = {
        id: 'root',
        layoutOptions: nodeLayoutOption,
        children: [
            ...nodes.filter((node) => node.type !== NodeType.subFlow && !node.parentNode).map(getNodeConfiguration),
            ...Object.values(subFlowRelations).map(({ node, children }) => {
                const nodeData = node.data;
                return {
                    id: node.id,
                    width: nodeData.width,
                    height: nodeData.height,
                    layoutOptions: {
                        ...nodeLayoutOption,
                        // not elk works on x,y cordinate coming from bottom to top, so top applies to bottom and vice versa
                        'elk.padding': `[top=${SUBFLOW_MARGIN}, left=${SUBFLOW_MARGIN}, bottom=${SUBFLOW_MARGIN * 2}, right=${SUBFLOW_MARGIN}]`
                    },
                    children: children.map(getNodeConfiguration)
                };
            })
        ],
        edges: edges.map((edge) => {
            return {
                id: edge.id,
                sources: [edge.sourceHandle],
                targets: [edge.targetHandle]
            };
        })
    };
    const updatedGraph = await elk.layout(graph);
    const getRelativeY = (parentNode, currentNode) => {
        const { height: parentHeight } = parentNode;
        let { y, height } = currentNode;
        /**
         * elk layout x,y 0 starts from bottom left, similar to coordination system, so we need to vertically mirror the port and nodes
         * on top of it adding graph height so, y position remains in positive number, and subtracting node height as the y position is now calculated from bottom
         */
        y = parentHeight + y * -1 - height;
        return y;
    };
    return nodes.map((node) => {
        let updatedNode;
        let x, y, width, height;
        if (node.parentNode) {
            const subflow = updatedGraph.children.find((el) => el.id === node.parentNode);
            updatedNode = subflow.children.find((el) => el.id === node.id);
            ({ x, y, width, height } = updatedNode);
            y = getRelativeY(subflow, updatedNode);
            // if subflow have single children, they are not center aligned due to min width constraint, so we manually move them
            if (subflow.children?.length === 1) {
                const subflowNode = subFlowRelations[subflow.id];
                const nodeData = subflowNode.node.data;
                x = (Math.max(subflow.width, nodeData.minWidth || 0) - width) / 2;
            }
        }
        else {
            updatedNode = updatedGraph.children.find((el) => el.id === node.id);
            ({ x, y, width, height } = updatedNode);
            y = getRelativeY(updatedGraph, updatedNode);
        }
        const position = snapPosition({
            x,
            y
        });
        return {
            ...node,
            position,
            positionAbsolute: position,
            width: width,
            height: height
        };
    });
}
function sortNodesAndEdges(nodes, edges) {
    const nodeMap = Object.fromEntries(nodes.map((node) => [node.id, node]));
    const edgeMap = {};
    const sourceEdgeMap = {};
    const targetEdgeMap = {};
    edges.forEach((edge) => {
        const { source, target } = edge;
        edgeMap[edge.id] = edge;
        sourceEdgeMap[source] = sourceEdgeMap[source] || [];
        sourceEdgeMap[source].push(edge);
        targetEdgeMap[target] = targetEdgeMap[target] || [];
        targetEdgeMap[target].push(edge);
    });
    const sortedNodesIds = new Set();
    const sortedEdgesIds = new Set();
    // start with upstream nodes
    let nodesToProcess = Object.keys(sourceEdgeMap).filter((nodeId) => !targetEdgeMap[nodeId]);
    // start with top most stream nodes and start pushing the nodes and edges to sort
    while ((sortedNodesIds.size < nodes.length || sortedEdgesIds.size < edges.length) && nodesToProcess.length) {
        const newNodesToProcess = new Set();
        const nodeIds = [];
        const reshuffledParents = [];
        const nodeToFirstParentMap = {};
        /**
         * first scan trough current nodes to process to figure out if we need to
         * rearrange the parent nodes, based on how the edges are connected to this nodes
         * then sort the current set of nodes to process based on their parent order
         * and then add the downstream nodes to process
         *
         * In the process add the edges on same order as nodes are added
         */
        nodesToProcess.forEach((nodeId) => {
            const node = nodeMap[nodeId];
            // if we found a cycle, in which sorted node is already present return
            if (sortedNodesIds.has(nodeId))
                return;
            nodeIds.push(nodeId);
            // if node has more then one connected upstream nodes sort them based on input ports
            // if its already added remove it and add it again, same do for the edges
            const upstreamEdges = targetEdgeMap[nodeId];
            if (upstreamEdges?.length > 1) {
                const handleIndexMap = Object.fromEntries(node.data.targetHandles.map((handle, index) => [handle.id, index]));
                // sort edges based on node handles
                upstreamEdges.sort((a, b) => {
                    const indexA = handleIndexMap[a.targetHandle];
                    const indexB = handleIndexMap[b.targetHandle];
                    return indexA - indexB;
                });
                upstreamEdges.forEach((edge, index) => {
                    const { id, source } = edge;
                    if (sortedEdgesIds.has(id)) {
                        sortedEdgesIds.delete(id);
                        sortedEdgesIds.add(id);
                    }
                    if (sortedNodesIds.has(source)) {
                        sortedNodesIds.delete(source);
                        sortedNodesIds.add(source);
                        reshuffledParents.push(source);
                    }
                });
            }
            nodeToFirstParentMap[nodeId] = upstreamEdges?.[0]?.source;
        });
        // sort node and edges ids based on their parent order, as reshuffling can happen on parent
        if (reshuffledParents.length) {
            const reshuffledParentOrder = Object.fromEntries(reshuffledParents.map((nodeId, index) => [nodeId, index]));
            // sort new nodes
            nodeIds.sort((node1, node2) => {
                const upstreamNode1 = nodeToFirstParentMap[node1];
                const upstreamNode2 = nodeToFirstParentMap[node2];
                // if we don't have upstream node no need to reorder them
                if (reshuffledParentOrder[upstreamNode1] === undefined || reshuffledParentOrder[upstreamNode2] === undefined) {
                    return 0;
                }
                return reshuffledParentOrder[upstreamNode1] - reshuffledParentOrder[upstreamNode2];
            });
            // sort parent edges
            reshuffledParents.forEach((nodeId) => {
                const downStreamEdges = sourceEdgeMap[nodeId];
                downStreamEdges?.forEach((edge) => {
                    const { id } = edge;
                    if (sortedEdgesIds.has(id)) {
                        sortedEdgesIds.delete(id);
                        sortedEdgesIds.add(id);
                    }
                });
            });
        }
        // add the sorted nodes in sortedNode id list, and then find the downstream nodes to process
        nodeIds.forEach((nodeId) => {
            sortedNodesIds.add(nodeId);
            // add next set of nodes to process
            const downStreamEdges = sourceEdgeMap[nodeId];
            if (downStreamEdges) {
                downStreamEdges.forEach((edge) => {
                    sortedEdgesIds.add(edge.id);
                    newNodesToProcess.add(edge.target);
                });
            }
        });
        nodesToProcess = [...newNodesToProcess];
    }
    // add remaining unconnected nodes at the end
    nodes.forEach((node) => {
        sortedNodesIds.add(node.id);
    });
    const sortedNodes = [...sortedNodesIds].map((id) => nodeMap[id]);
    const sortedEdges = [...sortedEdgesIds].map((id) => edgeMap[id]);
    return { sortedNodes, sortedEdges };
}
function getLayoutedElementsWithDagre(nodes, edges, layoutConfig = {}) {
    const nodeInputPortIndexMap = new Map();
    nodes.forEach((node) => {
        node.data.sourceHandles.forEach((handle, ind) => {
            nodeInputPortIndexMap.set(node.id + '::' + handle.id, ind);
        });
    });
    const nodeOutputPortIndexMap = new Map();
    nodes.forEach((node) => {
        node.data.targetHandles.forEach((handle, ind) => {
            nodeOutputPortIndexMap.set(node.id + '::' + handle.id, ind);
        });
    });
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    const _layoutConfig = Object.assign({
        rankdir: 'LR',
        marginx: NODE_SPACING,
        marginy: NODE_SPACING,
        nodesep: NODE_SPACING
    }, layoutConfig);
    let maxHandles = 0;
    const { sortedNodes, sortedEdges } = sortNodesAndEdges(nodes, edges);
    // layout non subflow nodes first and then layout subflow on top of it.
    sortedNodes
        .filter((node) => node.type !== NodeType.subFlow)
        .forEach((el) => {
        const getSize = el?.data?.getSize || getNodeSize;
        const maxPorts = Math.max(el?.data?.sourceHandles.length, el?.data?.targetHandles.length);
        if (maxPorts > maxHandles)
            maxHandles = maxPorts;
        const { width, height } = el.type === NodeType.comment ? { width: el.width, height: el.height } : getSize(maxPorts);
        dagreGraph.setNode(el.id, { width, height });
    });
    sortedEdges.forEach((el) => {
        dagreGraph.setEdge(el.source, el.target, undefined, undefined, nodeInputPortIndexMap.get(el.source + '::' + el.sourceHandle), nodeOutputPortIndexMap.get(el.target + '::' + el.targetHandle));
    });
    dagreGraph.setGraph({ ..._layoutConfig, ranksep: 120 * Math.max(1, Math.log(maxHandles)) });
    dagre.layout(dagreGraph);
    return nodes.map((el) => {
        if (el.type === NodeType.subFlow)
            return el;
        const { x, y, width, height } = dagreGraph.node(el.id);
        const position = snapPosition({
            x: x - width / 2 + Math.random() / 1000,
            y: y - height / 2
        });
        return {
            ...el,
            id: el.id,
            position,
            positionAbsolute: position,
            width,
            height
        };
    });
}
export async function getLayoutedElements(nodes, edges, layoutConfig = {}) {
    const subFlowRelations = getSubFlowRelations(nodes);
    const { layoutAlgo = LayoutAlgo.elk } = layoutConfig;
    let updatedNodes;
    // filter edges which doesn't have corresponding nodes, case like subgraph
    const nodesMap = Object.fromEntries(nodes.map((node) => [node.id, node]));
    const _edges = edges.filter((edge) => nodesMap[edge.source] && nodesMap[edge.target]);
    if (layoutAlgo === LayoutAlgo.dagre) {
        updatedNodes = getLayoutedElementsWithDagre(nodes, _edges, layoutConfig);
        // handle subflows
        if (Object.keys(subFlowRelations).length) {
            updatedNodes = getDagreSubFlowLayoutedElements(updatedNodes);
        }
    }
    else {
        updatedNodes = await getLayoutedElementsWitElk(nodes, _edges, subFlowRelations, layoutConfig.marginx ?? layoutConfig.nodesep, layoutConfig.marginy ?? layoutConfig.nodesep);
    }
    return updatedNodes;
}
function getReferencePoint(nodes) {
    // as the nodes are always added after, for x we consider the end of the node + some spacing
    const xPoints = nodes.map((node) => {
        return (node.positionAbsolute?.x || node.position.x) + (node.width || NODE_DIMENSION) + NODE_SPACING;
    });
    // for y we take the center
    const yPoints = nodes.map((node) => {
        return (node.positionAbsolute?.y || node.position.y) + (node.height || NODE_DIMENSION) / 2;
    });
    // for x and y  take the center of min position and max position, this should work if there is single node as well
    const minX = Math.min(...xPoints);
    const maxX = Math.max(...xPoints);
    const minY = Math.min(...yPoints);
    const maxY = Math.max(...yPoints);
    return {
        x: minX + (maxX - minX) / 2,
        y: minY + (maxY - minY) / 2
    };
}
function hasCollisionPoints(nodes, partialLayoutedNodes, referencePoint) {
    /**
     * in reference point x is where the partial graph starts, and y is the center of partial graph
     * so we need to shift all the nodes based on reference point
     */
    const bound = getRectOfNodes(partialLayoutedNodes);
    const refX = referencePoint.x - bound.x;
    const refY = referencePoint.y - bound.y - bound.height / 2;
    const _partialLayoutedNodes = partialLayoutedNodes.map((node) => {
        const position = {
            x: refX + node.position.x,
            y: refY + node.position.y
        };
        return {
            ...node,
            position: position,
            positionAbsolute: { ...position }
        };
    });
    const updatedBound = getRectOfNodes(_partialLayoutedNodes);
    // check if any of the node is inside this rect.
    const hasCollision = nodes.some((node) => {
        const nodePadding = NODE_SPACING / 2;
        const width = (node.width || NODE_DIMENSION) + 2 * nodePadding;
        const height = (node.height || NODE_DIMENSION) + 2 * nodePadding;
        const x = node.position.x - nodePadding;
        const y = node.position.y - nodePadding;
        return checkRectangleOverlap(updatedBound, { x, y, width, height });
    });
    return {
        hasCollision,
        partialUpdatedNodes: _partialLayoutedNodes
    };
}
export async function getPartialLayoutedElement(nodes, edges, referenceNodes, layoutConfig = {}) {
    // TODO: Handle subflow here, currently as the partial layout will happen with mostly IDE where subflow is not there
    // filter nodes which doesn't have position set
    const unPositionedNodes = nodes.filter(isNodeNotPositioned);
    const unPositionedNodesMaps = Object.fromEntries(nodes.map((node) => [node.id, node]));
    const filteredEdges = edges.filter((edge) => unPositionedNodesMaps[edge.source] && unPositionedNodesMaps[edge.target]);
    // layout the partial graph
    const updatedNodes = await getLayoutedElements(unPositionedNodes, filteredEdges, layoutConfig);
    // get the reference point from where we have to place the updated nodes
    const { x, y } = getReferencePoint(referenceNodes);
    let { hasCollision, partialUpdatedNodes } = hasCollisionPoints(nodes, updatedNodes, { x, y });
    if (!hasCollision)
        return partialUpdatedNodes;
    // the change in y, as y is in center we add up that with the node spacing
    const refDelta = NODE_SPACING + NODE_DIMENSION / 2;
    // if collision check one level up
    ({ hasCollision, partialUpdatedNodes } = hasCollisionPoints(nodes, updatedNodes, {
        x,
        y: y - refDelta
    }));
    if (!hasCollision)
        return partialUpdatedNodes;
    // if still a collision check one level down
    ({ hasCollision, partialUpdatedNodes } = hasCollisionPoints(nodes, updatedNodes, { x, y: y + refDelta }));
    if (!hasCollision)
        return partialUpdatedNodes;
    // if the collision on all the three place, layout whole graph
    return getLayoutedElements(nodes, edges, layoutConfig);
}
export function hideLayoutedNodes(nodes, edges, layoutedNodes) {
    const layoutedNodesMap = Object.fromEntries(layoutedNodes.map((node) => [node.id, true]));
    const _nodes = nodes.map((node) => {
        return {
            ...node,
            data: {
                ...node.data,
                hidden: Boolean(layoutedNodesMap[node.id])
            }
        };
    });
    const _edges = edges.map((edge) => {
        return {
            ...edge,
            data: {
                ...edge.data,
                hidden: Boolean(layoutedNodesMap[edge.source] || layoutedNodesMap[edge.target])
            }
        };
    });
    return {
        nodes: _nodes,
        edges: _edges
    };
}
export function filterLayoutedNodes(nodes, edges) {
    const layoutedNodesMap = Object.fromEntries(nodes.map((node) => [node.id, !isNodeNotPositioned(node)]));
    const layoutedNodes = nodes.filter((node) => layoutedNodesMap[node.id]);
    /**
     * Note, here we specifically want to check if a node layouted flag is false or not. there can be a case where
     * the connection can point to the node which is not part of layoutedNodesMap list (like subgraph edge connections)
     * in such case we want to include those connections, if non subgraph port is layouted
     */
    const layoutedEdges = edges.filter((edge) => layoutedNodesMap[edge.source] !== false && layoutedNodesMap[edge.target] !== false);
    return {
        nodes: layoutedNodes,
        edges: layoutedEdges
    };
}
