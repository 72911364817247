import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFocusHandleOnClose, useFocusTrap } from '@prophecy/utils/react/focus';
import { useControlledState } from '@prophecy/utils/react/hooks';
import { Arrow, CheckboxItem, Group, ItemIndicator, Portal, RadioGroup, RadioItem, Sub, Root } from '@radix-ui/react-dropdown-menu';
import { createContext, useContext } from 'react';
import { ChevronRightIcon } from '../Icons';
import { Stack, StackItem } from '../Layout';
import { Content, Item, Label, Separator, StyledTrigger, SubContent, SubTrigger } from './styled';
import { tokens } from './tokens';
import { Size } from './types';
export const TriggerContext = createContext(false);
export function useTriggerContext() {
    return useContext(TriggerContext);
}
export function Dropdown({ arrow, disabled, overlay, visible, onVisibleChange, children, overlayClassName, overlayStyle, placement: side = 'bottom', placementOffset: sideOffset = 8, align = 'start', alignOffset, portalled = true, size = Size.md, onCloseAutoFocus, onInteractOutside, modal = true }) {
    const [_visible, toggleVisible] = useControlledState({
        value: visible,
        onChange: onVisibleChange
    });
    const container = useFocusTrap(Boolean(_visible));
    const defaultOnCloseAutoFocus = useFocusHandleOnClose(Boolean(_visible));
    const content = (_jsx(Content, { className: overlayClassName, style: overlayStyle, side: side, size: size, align: align, sideOffset: sideOffset, asChild: true, alignOffset: alignOffset, ref: container, tabIndex: -1, onCloseAutoFocus: onCloseAutoFocus ?? defaultOnCloseAutoFocus, onInteractOutside: onInteractOutside, children: _jsxs("div", { children: [arrow && _jsx(Arrow, {}), overlay] }) }));
    return (_jsxs(Root, { open: _visible, dir: 'ltr', modal: modal, onOpenChange: toggleVisible, children: [_jsx(TriggerContext.Provider, { value: true, children: _jsx(StyledTrigger, { disabled: disabled, asChild: true, children: children }) }), portalled ? _jsx(Portal, { children: content }) : content] }));
}
function SubMenu({ children, overlay, triggerArrow = true, overlayArrow, placementOffset: sideOffset = 8, alignOffset, open, defaultOpen, onOpenChange, size = Size.md, ...rest }) {
    return (_jsxs(Sub, { open: open, defaultOpen: defaultOpen, onOpenChange: onOpenChange, children: [_jsx(SubTrigger, { ...rest, size: size, children: _jsxs(Stack, { direction: 'horizontal', width: '100%', gap: tokens.SubMenu.Arrow.gap, alignY: 'center', align: 'space-between', children: [_jsx(StackItem, { children: children }), _jsx(StackItem, { shrink: '0', children: triggerArrow && _jsx(ChevronRightIcon, { type: 'default', size: 'xs' }) })] }) }), _jsxs(SubContent, { size: size, sideOffset: sideOffset, alignOffset: alignOffset, children: [overlay, overlayArrow && _jsx(Arrow, {})] })] }));
}
Dropdown.Label = Label;
Dropdown.Item = Item;
Dropdown.Group = Group;
Dropdown.CheckboxItem = CheckboxItem;
Dropdown.RadioGroup = RadioGroup;
Dropdown.RadioItem = RadioItem;
Dropdown.ItemIndicator = ItemIndicator;
Dropdown.Separator = Separator;
Dropdown.SubMenu = SubMenu;
export { DropdownFooterContainer } from './styled';
export * from './types';
