import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { EditBIcon } from '../Icons';
import { ICON_CLASS } from '../Icons/tokens';
import { theme } from '../theme';
import { Input } from './Input';
import { InputWrap, StyledInput, StyledInputContainer } from './styled';
import { tokens } from './tokens';
import { InputSize } from './types';
export const growInputCss = css `
  // auto grow element based on input content
  ${InputWrap} {
    display: inline-grid;
    overflow: hidden;

    &[data-value='']:after,
    &:not([data-value]):after {
      content: attr(data-placeholder) ' ';
    }
    &:after {
      content: attr(data-value) ' ';
      visibility: hidden;
      white-space: pre;
    }

    &::after,
    ${StyledInput} {
      width: auto;
      min-width: 50px;
      grid-area: 1 / 2;
    }
  }
  .${ICON_CLASS} {
    font-size: ${tokens.InlineInput.iconSize};
  }
`;
const StyledInlineInput = styled(Input) `
  ${StyledInputContainer} {
    height: auto;
    padding: 0;
    padding-bottom: ${tokens.InlineInput.padding};
    box-shadow: none;
    border-color: transparent;

    &:focus-within {
      border-color: ${tokens.InlineInput.focus.borderColor};
      padding: 0 ${(props) => tokens.Input.size[props.inputSize].padding};
      outline: 1px solid ${tokens.InlineInput.focus.borderColor};
    }

    .${ICON_CLASS} {
      visibility: hidden;
    }

    &:hover:not(:focus-within) {
      .${ICON_CLASS} {
        visibility: visible;
      }
    }
  }
  ${growInputCss}
  ${InputWrap} {
    &:after {
      padding-left: ${(props) => tokens.Input.size[props.inputSize].padding};
    }
  }
`;
export const InlineInput = forwardRef(({ inputSize = InputSize.m, onFocus, onPressEnter, onBlur, disabled, ...restProps }, ref) => {
    const [focused, toggleFocused] = useState(false);
    return (_jsx(StyledInlineInput, { size: 1, inputSize: inputSize, icon: focused || disabled ? undefined : _jsx(EditBIcon, { type: 'solid', color: theme.colors.gray400 }), iconPlacement: 'right', disabled: disabled, required: false, onPressEnter: (e) => {
            e.preventDefault();
            e.currentTarget.blur();
            toggleFocused(false);
            onPressEnter?.(e);
        }, onFocus: (e) => {
            toggleFocused(true);
            onFocus?.(e);
        }, onBlur: (e) => {
            toggleFocused(false);
            onBlur?.(e);
        }, ref: ref, ...restProps }));
});
