import { gql } from 'graphql-request';

import { AspectKindType, EntityKind, EntityKindType } from '../../graphqlTypes/enums';
import { graphql } from '../../graphqlTypes/graphql';
import { Entity } from '../types/Entity';

export const QueryKeys = {
  ProjectDetail: 'ProjectDetail',
  User: EntityKind.User,
  Pipeline: EntityKind.Pipeline,
  SqlPipeline: EntityKind.SQLOrchestratorPipeline,
  PipelineConfiguration: EntityKind.PipelineConfigurations,
  Dataset: EntityKind.Dataset,
  Job: EntityKind.Job,
  Team: EntityKind.Team,
  Project: EntityKind.Project,
  Expectation: Entity.Expectation,
  Fabric: EntityKind.Fabric,
  Subgraph: EntityKind.Subgraph,
  Package: Entity.Package,
  CopilotLLMConfig: 'CopilotLLMConfig',
  Gem: EntityKind.Gem,
  CreateEntity: 'CreateEntity',
  UpdateFabric: 'UpdateFabric',
  ProjectList: 'ProjectList',
  TeamList: 'TeamList',
  LineageSearch: 'LineageSearch',
  Git: 'git',
  Model: EntityKind.SQLModel,
  Monitoring: 'Monitoring',
  ProjectCreationTemplate: 'ProjectCreationTemplate',
  MonitoringAlert: 'MonitoringAlert',
  FeatureConfig: 'FeatureConfig',
  ServiceLogsTimeFrame: 'ServiceLogsTimeFrame',
  ServiceLogs: 'ServiceLogs',
  GitDiff: 'GitDiff',
  ProjectDeployments: 'ProjectDeployments',
  ProjectDeploymentHistory: 'ProjectDeploymentHistory',
  ProjectReleases: 'ProjectReleases',
  ProjectDeploymentSettings: 'ProjectDeploymentSettings',
  ProjectGeneralSettings: 'ProjectGeneralSettings',
  FabricSessionList: 'fabric-session-list',
  ClusterProjectCreationDefaults: 'ClusterProjectCreationDefaults',
  TeamProjectCreationDefaults: 'TeamProjectCreationDefaults',
  EnvironmentConnections: 'EnvironmentConnections',
  TeamOrchestrationConnections: 'TeamOrchestrationConnections',
  OrchestrationSettings: 'OrchestrationSettings',
  SQLSecretSpecs: 'SQLSecretSpecs',
  SQLSecretProviders: 'SQLSecretProviders',
  SQLSecretList: 'SQLSecretList',
  ExampleSQLProject: 'ExampleSQLProject',
  List: 'List',
  NameList: 'NameList',
  EntityList: 'EntityList',
  FullDetailList: 'FullDetailList'
};

export type DeleteEntityQueryVariables = {
  uid: string;
  entityKind: EntityKindType;
};

export const deleteEntityQuery = graphql(`
  mutation removeEntity($uid: String!, $entityKind: EntityKind!) {
    removeEntity(uid: $uid, entityKind: $entityKind) {
      _id
    }
  }
`);

export const updateEntityAspectQuery = graphql(`
  mutation upsertAspect($uid: String!, $entityKind: EntityKind!, $aspectKind: AspectKind!, $aspectValue: String!) {
    upsertAspect(uid: $uid, entityKind: $entityKind, aspectKind: $aspectKind, aspectValue: $aspectValue)
  }
`);

export const updateEntityVersionedAspectQuery = graphql(`
  mutation upsertVersionedAspect(
    $uid: String!
    $entityKind: EntityKind!
    $aspectKind: AspectKind!
    $aspectValue: String!
  ) {
    upsertAspect(uid: $uid, entityKind: $entityKind, aspectKind: $aspectKind, aspectValue: $aspectValue)
  }
`);

export const updateEntityFieldQuery = graphql(`
  mutation updateEntity(
    $uid: String!
    $entityKind: EntityKind!
    $entityFieldName: String!
    $entityFieldValue: String!
  ) {
    updateEntity(
      uid: $uid
      entityKind: $entityKind
      entityFieldName: $entityFieldName
      entityFieldValue: $entityFieldValue
    )
  }
`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type Aspect<T = unknown> = {
  AspectValue: string;
  AspectName: AspectKindType;
};

export type VersionedAspect = {
  VersionedAspectValue: string;
  VersionedAspectName: AspectKindType;
};

export type DetailType = {
  _id: string;
  name: string;
  path: string;
};

export type DetailTypeWithProject = DetailType & {
  project: {
    _id: string;
  };
};

const ProjectQuery = ` project {
    _id
  }`;

export const EntityDetailQuery = (entity: string, isTeam?: boolean) => gql`
  query GqlQuery_Page${entity}($uid: String!) {
    ${entity}(uid: $uid) {
      _id
      name
      ${isTeam ? '' : 'path'}
      ${isTeam ? '' : ProjectQuery}
    }
  }
`;
