import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { stopPropagation } from '@prophecy/utils/dom';
import { useState, forwardRef } from 'react';
import { ButtonVariants } from '../Button/types';
import { Stack } from '../Layout';
import { StyledButton, StyledClearIcon, StyledNumberContainer, StyledIconWrapper } from './styled';
import { tokens } from './tokens';
import { Size, Placement } from './types';
function _Tags({ closable = false, onClose, icon, children, size = Size.m, count, iconPlacement = Placement.left, prefix, disabled, countCircle = false, ...restProps }, forwardRef) {
    const [visible, toggle] = useState(true);
    if (!visible) {
        return null;
    }
    const _onClose = (e) => {
        toggle(false);
        onClose?.();
    };
    const closeIcon = closable ? (_jsx(StyledClearIcon, { type: 'default', size: size, miniIcon: true, onClick: !disabled ? _onClose : undefined, onPointerDown: stopPropagation })) : null;
    return (_jsx(StyledButton, { ref: forwardRef, variant: ButtonVariants.secondaryGray, size: size, "$closable": closable, "$isIcon": !!icon, disablePointerFocus: true, disabled: disabled, ...restProps, iconPlacement: iconPlacement, children: _jsxs(Stack, { direction: 'horizontal', alignY: 'center', gap: tokens.TagsRoot.size[size].gap, children: [iconPlacement === Placement.left && closeIcon, iconPlacement === Placement.left && icon && (_jsx(StyledIconWrapper, { "$size": size, "$disabled": disabled, "$iconPlacement": iconPlacement, children: icon })), prefix, children, count !== undefined && (_jsx(StyledNumberContainer, { disabled: disabled, countCircle: countCircle, size: size, children: count })), iconPlacement === Placement.right && icon && (_jsx(StyledIconWrapper, { "$size": size, "$disabled": disabled, "$iconPlacement": iconPlacement, children: icon })), iconPlacement === Placement.right && closeIcon] }) }));
}
// NOTE: Sadly forwardRef doesn't allow generic types, so we have to typecast it
export const Tags = forwardRef(_Tags);
