import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Fuse from 'fuse.js';
import { noop } from 'lodash-es';
import styled from 'styled-components';
import { CopilotButton } from '../Button';
import { Button } from '../Button/Button';
import { FileDevelopmentDefaultCSSIcon, FileDevelopmentDefaultJSONIcon, FileDevelopmentDefaultSQLIcon, FileDevelopmentDefaultXMLIcon, FileDevelopmentIconHTMLIcon, FileDevelopmentIconJSIcon, FileDevelopmentIconPythonIcon, FileDocumentDefaultCSVIcon, FileDocumentDefaultTXTIcon, FileGenericDefaultDocumentIcon, FileImageDefaultIMGIcon, FileImageDefaultJPEGIcon, FileImageDefaultJPGIcon, FileImageDefaultPNGIcon, FileImageDefaultSVGIcon } from '../Icons';
import { Stack } from '../Layout/Stack';
import { theme } from '../theme';
import { toast } from '../Toast';
import { Text } from '../Typography';
import { CompletionItemInsertTextRule, CompletionItemKind, MarkerSeverity, SpecFunctionType, SuggestionLanguages } from './types';
export const FILE_ICONS = {
    json: FileDevelopmentDefaultJSONIcon,
    img: FileImageDefaultIMGIcon,
    jpg: FileImageDefaultJPGIcon,
    jpeg: FileImageDefaultJPEGIcon,
    png: FileImageDefaultPNGIcon,
    svg: FileImageDefaultSVGIcon,
    html: FileDevelopmentIconHTMLIcon,
    py: FileDevelopmentIconPythonIcon,
    txt: FileDocumentDefaultTXTIcon,
    xml: FileDevelopmentDefaultXMLIcon,
    js: FileDevelopmentIconJSIcon,
    css: FileDevelopmentDefaultCSSIcon,
    sql: FileDevelopmentDefaultSQLIcon,
    license: FileGenericDefaultDocumentIcon,
    csv: FileDocumentDefaultCSVIcon,
    // TODO: add icons for the following (after figma is updated)
    jsx: FileGenericDefaultDocumentIcon,
    ts: FileGenericDefaultDocumentIcon,
    tsx: FileGenericDefaultDocumentIcon,
    scala: FileGenericDefaultDocumentIcon,
    npmrc: FileGenericDefaultDocumentIcon,
    md: FileGenericDefaultDocumentIcon,
    favicon: FileGenericDefaultDocumentIcon,
    yml: FileGenericDefaultDocumentIcon
};
export const MarkerSeverityClassName = {
    [MarkerSeverity.Error]: 'error',
    [MarkerSeverity.Warning]: 'warning',
    [MarkerSeverity.Info]: 'info',
    [MarkerSeverity.Hint]: 'hint'
};
export const MonacoEditorClass = 'monaco-editor';
export function getURIParts(uri = '') {
    // remove leading slash and file prefix
    const pathname = uri.replace(/^file:\/*/, '').replace(/^\//, '');
    return pathname.split('/');
}
export const trimSlash = (path) => {
    return path.replace(/^\/|\/$/g, '');
};
export const addLeadingSlash = (path) => {
    return path.replace(/(^\/?)/, '/');
};
export function getFileIcon(fileName) {
    const extension = fileName.split('.').pop();
    if (extension) {
        const Icon = FILE_ICONS[extension.toLowerCase()] ?? FileGenericDefaultDocumentIcon;
        return _jsx(Icon, {});
    }
    return null;
}
export function getBaseName(path) {
    return path.split('/').pop();
}
export function fileNameWithoutExtension(fileName) {
    return fileName.split('.').slice(0, -1).join('.');
}
export function splitByExtension(fileName) {
    const [, name, extension] = fileName.match(/^(.*)\.([a-zA-Z0-9]*)$/) ?? ['', fileName, ''];
    return { name, extension };
}
export function setupModelForSuggestion(_model, suggestions, getInlineSuggestion) {
    const model = _model;
    model._$customCompletionItems = true;
    model._$suggestions = suggestions;
    model._$getInlineSuggestion = getInlineSuggestion;
}
export const prepareArguments = (args) => {
    return args
        ? args
            ?.map((arg, i) => {
            return arg.repeat ? ` ...${arg.name}` : ` ${arg.name}${i === args.length - 1 ? ' ' : ''}`;
        })
            .filter((val) => val)
        : [];
};
function replaceBrackets(inputString, args) {
    const squareBracketsRegex = /\[[^[\]]*\]/g;
    let outputString = inputString;
    const _removeBracket = (bracket) => {
        outputString = outputString.replace(bracket, '');
    };
    outputString = outputString.replace(squareBracketsRegex, '').trim();
    let brackets = outputString.match(squareBracketsRegex);
    while (brackets) {
        const bracketCount = brackets.length;
        for (let i = 0; i < bracketCount; i++) {
            const bracket = brackets[i];
            _removeBracket(bracket);
        }
        brackets = outputString.match(squareBracketsRegex);
    }
    outputString = outputString.replace(/\s+({|\})\s+/g, '$1');
    return outputString;
}
function updateInsertText(insertText, data) {
    const finalInsertText = replaceBrackets(insertText, data.apiArguments)
        .replace(/\[[^\]]*\]\s*/g, '') // Remove everything between square brackets
        .replace(/__([\w\d]+)__/g, (match, p1) => {
        const matchedItem = data?.apiArguments?.find((item) => item.name === p1);
        if (!matchedItem) {
            return p1;
        }
        const isRepeat = matchedItem.repeat;
        const isOptional = matchedItem.optional;
        let returnString = isOptional ? `?${p1}` : p1;
        returnString = isRepeat ? `...${returnString}` : returnString;
        return returnString;
    });
    return finalInsertText.replace(',  )', ')');
}
function returnfinalPrepatedString(functionName, insertText, isFunctionType) {
    const delimiter = !isFunctionType ? ' ' : '';
    return `${functionName}${delimiter}${insertText}`;
}
export const processInsertText = (data) => {
    const functionName = data.functionName;
    const currentSelectedInsertText = data.insertText ? data.insertText[0] : null;
    if (data.functionType === SpecFunctionType.udf) {
        // this is a placeholder for now. waiting for the backend to provide the correct insert text for UDFs
        return ``;
    }
    if (currentSelectedInsertText) {
        const updatedInsertText = updateInsertText(currentSelectedInsertText, data);
        const trimmedFinalString = updatedInsertText.replace(/\s+/g, ' '); //replace multiple spaces with single space
        const functionIndex = trimmedFinalString.toLowerCase().indexOf(functionName.toLowerCase());
        if (functionIndex !== -1) {
            // Return the substring starting from the function name and including any text that follows it
            return trimmedFinalString.substring(functionIndex);
        }
        return trimmedFinalString;
    }
    const isFunction = data.functionType === SpecFunctionType.function;
    const args = prepareArguments(isFunction ? data?.apiArguments : data.apiArguments.slice(1));
    const argsStr = isFunction ? `(${args?.join(', ')})` : args?.join(' ');
    return returnfinalPrepatedString(functionName, argsStr, isFunction);
};
function prepareExplanation(insertText, spec) {
    const argsString = insertText.split(spec.functionName)[1];
    const isFunction = argsString?.startsWith('(');
    if (isFunction) {
        let argsString = '';
        if (spec?.apiArguments?.length) {
            argsString = `(${spec.apiArguments
                .map((arg) => {
                let returnString = arg.optional ? `?${arg.name}` : arg.name;
                returnString = arg.repeat ? `...${returnString}` : returnString;
                if (arg?.type?.length) {
                    returnString = `${returnString}: ${arg.type.join(' | ')}`;
                }
                return returnString;
            })
                .join(', ')})`;
        }
        argsString = `${argsString} ${spec.description?.explanation}`;
        if (spec?.returnType) {
            argsString = `${argsString} - ${spec.returnType}`;
        }
        return argsString;
    }
    else {
        return spec.description?.explanation;
    }
}
function createFuse(language, extraSuggestions) {
    const functions = [];
    const operations = [];
    language.forEach((item) => {
        if (item?.functionType === 'function') {
            const insertText = processInsertText(item);
            functions.push({
                label: item.functionName,
                insertText: insertText,
                kind: CompletionItemKind.Function,
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: prepareExplanation(insertText, item),
                documentation: item.description?.examples
            });
        }
        else if (item?.functionType === 'operation') {
            const insertText = processInsertText(item);
            operations.push({
                label: item.functionName,
                insertText: insertText,
                kind: CompletionItemKind.Operator,
                detail: prepareExplanation(insertText, item),
                documentation: item.description?.examples
            });
        }
    });
    const options = {
        includeScore: true,
        keys: ['label']
    };
    const fuse = new Fuse([...functions, ...operations, ...extraSuggestions], options);
    return fuse;
}
function toCompletionItems(results, range) {
    return results.map((result) => {
        return {
            ...result.item,
            range
        };
    });
}
const InlineSuggestionWidgetRoot = styled.div `
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  padding: ${theme.spaces.x8} ${theme.spaces.x12};
  justify-content: space-between;
  align-items: center;
  border-radius: ${theme.radius.x4};
  background: linear-gradient(77deg, rgba(231, 244, 255, 0.5) -15.46%, rgba(235, 233, 254, 0.5) 60.56%);
  backdrop-filter: blur(2px);
`;
export function registerCompletionItemProvider(_monaco, getLanguageSpec, prepareSuggestions) {
    const monaco = _monaco;
    monaco._$prepareSuggestions = prepareSuggestions;
    monaco._$fetchLanguageSpec = getLanguageSpec;
    if (monaco && !monaco._$registeredCompletionItemProvider) {
        // monaco._$registeredCompletionItemProvider is a private(custom) property
        const createProvideCompletionItems = (language) => async (_model, position) => {
            // use the current/latest active language spec and prepare suggestion method
            /*
             * `update`: we are no longer passing whole language spec data at once instead we are using language connector to fetch it on demand.
             * - `getLanguageSpec` is a function that returns a promise of language spec data.
             */
            const _fetchLanguageSpec = monaco._$fetchLanguageSpec;
            const _prepareSuggestions = monaco._$prepareSuggestions;
            const model = _model;
            const spec = (await _fetchLanguageSpec?.(language)) ?? [];
            if (model._$customCompletionItems) {
                // monaco._$customCompletionItems is a private(custom) property
                const _suggestions = _prepareSuggestions
                    ? _prepareSuggestions({ suggestions: model._$suggestions, model, position, language })
                    : model._$suggestions;
                const fuse = createFuse(spec, _suggestions);
                // monaco._$suggestions is a private(custom) property
                const word = model.getWordUntilPosition(position);
                const results = fuse.search(word.word);
                const range = {
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.startColumn,
                    endColumn: word.endColumn
                };
                const suggestions = toCompletionItems(results, range);
                return { suggestions };
            }
        };
        const allSupportedLanguages = Object.values(SuggestionLanguages);
        allSupportedLanguages.forEach((language) => {
            monaco.languages.registerCompletionItemProvider(language, {
                provideCompletionItems: createProvideCompletionItems(language)
            });
        });
        allSupportedLanguages.forEach((language) => {
            let toastRef;
            function hideToast() {
                toastRef?.close();
                toastRef = undefined;
            }
            monaco.languages.registerInlineCompletionsProvider(language, {
                provideInlineCompletions: async (_model, ...args) => {
                    hideToast();
                    const model = _model;
                    return model._$getInlineSuggestion?.(_model, ...args);
                },
                handlePartialAccept: noop,
                handleItemDidShow: function didShow(_compilation) {
                    const compilation = _compilation;
                    hideToast();
                    if (compilation.triggeredFromPrompt) {
                        toastRef = toast.plain({
                            style: {
                                padding: 0
                            },
                            content: (_jsxs(InlineSuggestionWidgetRoot, { children: [_jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x8, alignY: 'center', children: [_jsx(CopilotButton, { size: theme.sizes.x24 }), _jsx(Text, { level: 'sm', tone: theme.colors.gray900, children: "AI generated code" })] }), _jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x8, alignY: 'center', children: [_jsx(Button, { variant: 'secondaryGray', size: 's', onPointerDown: (event) => {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                    const editors = monaco.editor.getEditors();
                                                    editors.forEach((editor) => editor.trigger('editor', 'editor.action.inlineSuggest.hide', null));
                                                }, children: "Reject" }), _jsx(Button, { variant: 'secondaryGray', size: 's', onPointerDown: (event) => {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                    const editors = monaco.editor.getEditors();
                                                    editors.forEach((editor) => editor.trigger('editor', 'editor.action.inlineSuggest.commit', null));
                                                }, children: "Accept" })] })] })),
                            closeable: false,
                            width: 'auto'
                        });
                    }
                },
                freeInlineCompletions: () => {
                    hideToast();
                }
            });
        });
        monaco._$registeredCompletionItemProvider = true;
    }
}
export function diagnosticsToDecorations(diagnostics) {
    return diagnostics.map((diagnostic) => ({
        type: 'custom',
        range: diagnostic.range,
        options: {
            isWholeLine: true,
            marginClassName: `diagnostic-decoration-${MarkerSeverityClassName[diagnostic.severity]}`
        }
    }));
}
export const remeasureFontOnLoad = (() => {
    const loadedFonts = {};
    return (monaco, fontSize = 14) => {
        const fontKey = `${fontSize}px ${theme.fontFamily.code}`;
        // if font is already loaded just return
        if (loadedFonts[fontKey])
            return;
        document.fonts
            ?.load(`${fontSize}px ${theme.fontFamily.code}`)
            .then(() => {
            loadedFonts[fontKey] = true;
            monaco.editor.remeasureFonts();
        })
            .catch(() => {
            // ignore error
        });
    };
})();
