import { Stack, theme } from '@prophecy/ui';
import { Button } from '@prophecy/ui/Button';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import React from 'react';
import styled from 'styled-components';

import { checkForNewVersion } from '../common/appVersion';
import { ReactComponent as SadIcon } from './icons/Sad.svg';

type ErrorBoundaryProps = {
  onError?: (error: Error, componentStack: string) => void;
  fallback?: React.ReactElement;
  children: React.ReactNode;
};

const BrokenMessage = styled.p`
  font-size: ${theme.fontSizes.x18};
  text-align: center;
  margin-top: -60px;
`;

const StyledButton = styled(Button)`
  padding: 0;
  font-size: ${theme.fontSizes.x18};
  height: auto;
`;

export default function ErrorBoundary({ fallback, children, onError }: ErrorBoundaryProps) {
  const _fallback: React.ReactElement = fallback || (
    <Stack alignY='center' width='100%'>
      <SadIcon style={{ fontSize: '24rem', color: 'gray' }} />
      <BrokenMessage>
        Something went wrong! Please try{' '}
        <StyledButton variant='link' onClick={() => window.location.reload()}>
          reloading
        </StyledButton>{' '}
        the page.
      </BrokenMessage>
    </Stack>
  );

  return (
    <SentryErrorBoundary
      fallback={_fallback}
      onError={(a, b) => {
        checkForNewVersion();
        onError?.(a, b);
      }}>
      {children}
    </SentryErrorBoundary>
  );
}
export function withErrorBoundary<T extends object>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name;

  const ComponentWithErrorBoundary = (props: T) => {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };

  ComponentWithErrorBoundary.displayName = `withErrorBoundary(${displayName})`;

  return ComponentWithErrorBoundary;
}
