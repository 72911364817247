import { Stack, TypographyGlobalStyle } from '@prophecy/ui';
import { BASE_PATH, history, useLocationPathName } from '@prophecy/utils/history';
import DocumentTitle from '@prophecy/utils/react/DocumentTitle';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Navigate, Outlet, Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import {
  DatabrickSignUp,
  EmbeddedSignup,
  LoginUsingToken,
  Maintenance,
  OAuthCallback,
  OAuthSamlCallback,
  PublicLayout,
  ResetPasswordForm,
  ServiceDown,
  SignInForm,
  SignUpForm
} from '../App';
import { Public_Routes } from '../common/url';
import { AcceptCookieBanner } from '../components/AcceptCookie';
import { MonitoringNotification } from '../components/Monitoring/MonitoringNotification';
import { useAppMetadata } from '../context/appMetadata';
import { useUnsecuredGlobalVariables } from '../context/globalConfig/unsecuredConfigHook';
import { useTabFocusEvents } from '../context/hooks/useTabFocusEvents';
import { useQualifiedChatBox } from '../pages/auth/common';
import { MaintenanceUpgradeStatusList } from '../pages/Maintenance/constants';
import { MaintenanceNotification } from '../pages/Maintenance/MaintenanceNotification';
import { useDownServiceStatus } from '../pages/Maintenance/util';
import { PageContainer, StyledRoot } from './common';

const queryClient = new QueryClient();

const RouteTemplate = () => {
  useQualifiedChatBox();
  useTabFocusEvents();
  const preLoginData = useAppMetadata();
  const updateStatus = preLoginData?.maintenanceUpdateInfo?.upgrade_status?.status;
  const isUnderMaintenance = updateStatus && MaintenanceUpgradeStatusList.indexOf(updateStatus) !== -1;
  const pathname = useLocationPathName();
  let OutletComponent = Outlet;
  const isProphecyDown = useDownServiceStatus()?.isProphecyDown;
  //Maintenance page should get the priority than prophecy down
  if (isUnderMaintenance && pathname !== Public_Routes.Maintenance.getUrl()) {
    OutletComponent = () => <Navigate replace to={Public_Routes.Maintenance.getUrl()} />;
  } else if (isProphecyDown && !isUnderMaintenance && pathname !== Public_Routes.ProphecyDown.getUrl()) {
    OutletComponent = () => <Navigate replace to={Public_Routes.ProphecyDown.getUrl()} />;
  }

  return (
    <>
      <DocumentTitle title='Prophecy.io' />
      {!isUnderMaintenance && (
        <>
          <MaintenanceNotification />
          <MonitoringNotification />
        </>
      )}
      <StyledRoot>
        <Stack width='100%' direction='horizontal'>
          <PageContainer>
            <OutletComponent />
          </PageContainer>
        </Stack>
      </StyledRoot>
    </>
  );
};

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export const PublicRoutes = function () {
  const isSMTPEnabled = useUnsecuredGlobalVariables().isSMTPEnabled;
  return (
    <QueryClientProvider client={queryClient}>
      <TypographyGlobalStyle />
      <AcceptCookieBanner />
      <HistoryRouter basename={BASE_PATH} history={history}>
        <SentryRoutes>
          <Route
            key={Public_Routes.EmbeddedSignup.url}
            path={Public_Routes.EmbeddedSignup.url}
            element={
              <PublicLayout>
                <EmbeddedSignup />
              </PublicLayout>
            }
          />
          <Route element={<RouteTemplate />}>
            <Route
              key={Public_Routes.SignIn.url}
              path={Public_Routes.SignIn.url}
              element={
                <PublicLayout>
                  <SignInForm />
                </PublicLayout>
              }
            />
            <Route
              key={Public_Routes.SignUp.url}
              path={Public_Routes.SignUp.url}
              element={
                <PublicLayout>
                  <SignUpForm />
                </PublicLayout>
              }
            />
            {isSMTPEnabled && (
              <Route
                key={Public_Routes.ResetPassword.url}
                path={Public_Routes.ResetPassword.url}
                element={
                  <PublicLayout>
                    <ResetPasswordForm />
                  </PublicLayout>
                }
              />
            )}
            <Route
              key={Public_Routes.ProphecyDown.url}
              path={Public_Routes.ProphecyDown.url}
              element={
                <PublicLayout>
                  <ServiceDown isPublic={true} />
                </PublicLayout>
              }
            />
            <Route
              key={Public_Routes.LoginUsingToken.url}
              path={Public_Routes.LoginUsingToken.url}
              element={
                <PublicLayout>
                  <LoginUsingToken />
                </PublicLayout>
              }
            />
            <Route
              key={Public_Routes.DatabricksSignUp.url}
              path={Public_Routes.DatabricksSignUp.url}
              element={
                <PublicLayout>
                  <DatabrickSignUp />
                </PublicLayout>
              }
            />

            <Route
              key={Public_Routes.Maintenance.url}
              path={Public_Routes.Maintenance.url}
              element={
                <PublicLayout>
                  <Maintenance />
                </PublicLayout>
              }
            />
          </Route>
          <Route
            key={Public_Routes.OauthCallback.url}
            path={Public_Routes.OauthCallback.url}
            element={
              <PublicLayout>
                <OAuthCallback />
              </PublicLayout>
            }
          />
          <Route
            key={Public_Routes.OauthSamlCallback.url}
            path={Public_Routes.OauthSamlCallback.url}
            element={
              <PublicLayout>
                <OAuthSamlCallback />
              </PublicLayout>
            }
          />
        </SentryRoutes>
      </HistoryRouter>
    </QueryClientProvider>
  );
};
