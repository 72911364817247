import { ReactComponent as CrossJoin } from '../assets/color/color-cross-join.svg';
import { ReactComponent as FullOuterJoin } from '../assets/color/color-full-outer-join.svg';
import { ReactComponent as GenericJoin } from '../assets/color/color-generic-join.svg';
import { ReactComponent as InnerJoin } from '../assets/color/color-inner-join.svg';
import { ReactComponent as LeftOuterJoin } from '../assets/color/color-left-outer-join.svg';
import { ReactComponent as NaturalJoinA } from '../assets/color/color-natural-join-a.svg';
import { ReactComponent as NaturalJoinB } from '../assets/color/color-natural-join-b.svg';
import { ReactComponent as RightOuterJoin } from '../assets/color/color-right-outer-join.svg';
import { ReactComponent as Content } from '../assets/color/Content.svg';
import { ReactComponent as InterimA } from '../assets/color/InterimAIcon.svg';
import { ReactComponent as InterimB } from '../assets/color/InterimBIcon.svg';
import { createIcons } from './Icon';
export const CrossJoinIcon = createIcons('CrossJoin', {
    default: CrossJoin
});
export const FullOuterJoinIcon = createIcons('FullOuterJoin', {
    default: FullOuterJoin
});
export const GenericJoinIcon = createIcons('GenericJoin', {
    default: GenericJoin
});
export const InnerJoinIcon = createIcons('InnerJoin', {
    default: InnerJoin
});
export const LeftOuterJoinIcon = createIcons('LeftOuterJoin', {
    default: LeftOuterJoin
});
export const NaturalJoinAIcon = createIcons('NaturalJoinA', {
    default: NaturalJoinA
});
export const NaturalJoinBIcon = createIcons('NaturalJoinB', {
    default: NaturalJoinB
});
export const RightOuterJoinIcon = createIcons('RightOuterJoin', {
    default: RightOuterJoin
});
export const ContentIcon = createIcons('Content', {
    default: Content
});
export const InterimAIcon = InterimA;
export const InterimBIcon = InterimB;
