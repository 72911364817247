import { theme } from '../../theme';
export const tokens = {
    OTPInput: {
        Container: {
            gap: theme.spaces.x6
        },
        Label: {
            fontSize: theme.text.sm.fontSize,
            lineHeight: theme.text.sm.lineHeight,
            fontWeight: theme.fontWeight.medium,
            color: theme.colors.gray700
        },
        Hint: {
            fontSize: theme.text.sm.fontSize,
            lineHeight: theme.text.sm.lineHeight,
            fontWeight: theme.fontWeight.normal,
            color: theme.colors.gray600
        },
        Separator: {
            color: theme.colors.gray300,
            fontSize: theme.display.lg.fontSize,
            lineHeight: theme.display.lg.lineHeight,
            fontWeight: theme.fontWeight.medium
        },
        input: {
            fontFamily: theme.fontFamily.text,
            color: theme.colors.brand600,
            backgroundColor: theme.colors.white
        },
        state: {
            default: {
                borderColor: theme.colors.gray300,
                boxShadow: theme.shadows.xs
            },
            focus: {
                borderColor: theme.colors.brand500,
                boxShadow: theme.focusRing.color
            }
        },
        size: {
            s: {
                input: {
                    fontSize: theme.display.lg.fontSize,
                    lineHeight: theme.display.lg.lineHeight,
                    fontWeight: theme.fontWeight.medium,
                    height: theme.spaces.x64,
                    minWidth: theme.spaces.x64,
                    borderRadius: theme.radius.x10,
                    padding: `${theme.spaces.x2} ${theme.spaces.x8}`
                },
                gap: theme.spaces.x8
            },
            m: {
                input: {
                    fontSize: theme.display.lg.fontSize,
                    lineHeight: theme.display.lg.lineHeight,
                    fontWeight: theme.fontWeight.medium,
                    height: theme.spaces.x80,
                    minWidth: theme.spaces.x80,
                    borderRadius: theme.radius.x12,
                    padding: `${theme.spaces.x8} ${theme.spaces.x8}`
                },
                gap: theme.spaces.x12
            },
            l: {
                input: {
                    fontSize: theme.display.xl.fontSize,
                    lineHeight: theme.display.xl.lineHeight,
                    fontWeight: theme.fontWeight.medium,
                    height: theme.spaces.x96,
                    minWidth: theme.spaces.x96,
                    borderRadius: theme.radius.x12,
                    padding: `${theme.spaces.x12} ${theme.spaces.x12}`
                },
                gap: theme.spaces.x12
            }
        }
    }
};
