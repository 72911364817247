import { Badges, Select, SelectProps, Stack, theme } from '@prophecy/ui';
import { Icon } from '@prophecy/ui/Icons';
import { sortBy } from 'lodash-es';
import { useMemo } from 'react';

import { Fabric } from '../../redux/types';
import { useWithFabricProviderInfo } from './hooks';
import { FabricSVGIcons } from './icons';
import { getFabricProviderMapValue } from './utils';

export const FabricSelect = ({
  fabrics,
  showExpiry,
  disableExpired,
  ...props
}: SelectProps & { fabrics?: Fabric[]; showExpiry?: boolean; disableExpired?: boolean }) => {
  const fabricsWithProviderInfo = useWithFabricProviderInfo(fabrics);
  const fabricOptions = useMemo(() => {
    return sortBy(fabricsWithProviderInfo, 'fabric.name');
  }, [fabricsWithProviderInfo]);

  return (
    <Select placeholder={'Choose a fabric'} {...props}>
      {fabricOptions.map(({ fabricProviderInfo, fabric, isExpired }) => {
        const FabricProviderIcon = getFabricProviderMapValue(FabricSVGIcons, fabricProviderInfo);

        return (
          <Select.Option value={fabric.id} key={fabric.id} label={fabric.name} disabled={isExpired && disableExpired}>
            <Stack direction='horizontal' gap={theme.spaces.x6} alignY='center'>
              {FabricProviderIcon && (
                <Icon
                  name={fabricProviderInfo.provider}
                  type='custom'
                  size='s'
                  iconVariants={{
                    custom: FabricProviderIcon
                  }}
                />
              )}
              {fabric.name}
              {(showExpiry || disableExpired) && isExpired && (
                <Badges size='xs' tone='error'>
                  Expired
                </Badges>
              )}
            </Stack>
          </Select.Option>
        );
      })}
    </Select>
  );
};
