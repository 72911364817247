import styled from 'styled-components';
import { Stack, StackItem } from '../Layout';
import { theme } from '../theme';
import { TREE_CHECKBOX_CLASS, TREE_DRAGGABLE_ICON_CLASS, TREE_ICON_ELEMENT_CLASS, TREE_INDENT_UNIT_CLASS, TREE_LIST_SCROLLBAR_SHOW_CLASS, TREE_NODE_CLASS, TREE_NODE_CONTENT_WRAPPER_CLASS, TREE_NODE_SELECTED_CLASS, TREE_SWITCHER_CLASS, TREE_TITLE_CLASS, tokens, TREE_SCROLLBAR_CLASS, TREE_LIST_SCROLLBAR_THUMB_CLASS, TREE_NODE_ACTIVE_CLASS, TREE_CLASS_FOCUSSED } from './tokens';
export const StyledTree = styled.div `
  color: ${tokens.Tree.color};
  font-family: ${theme.fontFamily.text};
  font-size: ${tokens.Tree.fontSize};

  .${TREE_INDENT_UNIT_CLASS} {
    width: ${tokens.Tree.iconSize};
    display: inline-block;
  }
  &&&& .${TREE_SCROLLBAR_CLASS} {
    width: ${tokens.Tree.scrollBarWidth} !important;
  }
  &&&& .${TREE_LIST_SCROLLBAR_THUMB_CLASS} {
    border-radius: ${tokens.Tree.scrollBarRadius}!important;
    background: ${tokens.Tree.scrollBarBackgroundColor}!important;
    &:hover {
      background: ${tokens.Tree.scrollBarBackgroundHoverColor}!important;
    }
  }

  &:has(.${TREE_CLASS_FOCUSSED}) {
    border-radius: ${theme.radius.x4};
  }

  .${TREE_NODE_CLASS} {
    display: flex;
    gap: ${tokens.Node.gap};
    height: ${tokens.Node.height};
    padding: ${tokens.Node.paddingVertical} 0;
    align-items: center;
    border-radius: ${tokens.Node.borderRadius};
    background-clip: content-box;
    -webkit-background-clip: content-box;

    &:hover {
      background-color: ${tokens.Node.hover.backgroundColor};
    }

    &.${TREE_NODE_SELECTED_CLASS}, &.${TREE_NODE_SELECTED_CLASS}.${TREE_NODE_ACTIVE_CLASS} {
      background-color: ${theme.colors.brand50};
    }
  }

  ${(props) => props.selectable &&
    `
    .${TREE_NODE_CLASS} {
      cursor: pointer;
    }
  `}

  .${TREE_NODE_CONTENT_WRAPPER_CLASS} {
    min-width: 0;
    flex: 1;
    display: flex;
    gap: ${tokens.Node.gap};
    align-items: center;
  }

  .${TREE_TITLE_CLASS} {
    min-width: 0;
    flex: 1;
    padding-right: ${tokens.Node.gap};
  }

  .${TREE_CHECKBOX_CLASS} {
    display: inline-flex;
    align-items: center;
  }

  .${TREE_SWITCHER_CLASS} {
    width: ${(props) => (props.checkable ? tokens.Tree.iconSize : tokens.ExpandIcon.width)};
  }

  .${TREE_ICON_ELEMENT_CLASS} {
    svg {
      width: ${tokens.Tree.iconSize};
      height: ${tokens.Tree.iconSize};
      vertical-align: middle;
    }
    &:empty {
      display: none;
    }
  }
  /** Keep tree scrollbar always visible. */
  .${TREE_LIST_SCROLLBAR_SHOW_CLASS} {
    display: block !important;
  }

  .${TREE_DRAGGABLE_ICON_CLASS} {
    position: absolute;
  }

  ${TREE_NODE_CLASS}[draggable='false'] .${TREE_DRAGGABLE_ICON_CLASS} {
    display: none;
  }
`;
export const StyledTitleWrap = styled(Stack) ``;
export const StyledTitle = styled(StackItem) ``;
export const StyledTitleInfo = styled.span `
  color: ${tokens.Node.Info.color};
`;
