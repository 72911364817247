import { camelCase, upperFirst } from 'lodash-es';
import unraw from 'unraw';
export function pascalCase(str) {
    return upperFirst(camelCase(str));
}
export const downloadDataAsFile = (fileStr, exportName) => {
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', fileStr);
    downloadAnchorNode.setAttribute('download', exportName);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
};
export function escapeSequenceChar(str) {
    /**
     * Use JSON.stringify to escape the escape sequence
     * Remove double quote padding and escaping from double quotes
     * Also remove double escaping of slash
     */
    const val = JSON.stringify(str)
        .replace(/^"(.*)"$/, '$1')
        .replace(/\\"/g, '"')
        .replace(/\\\\/g, '\\');
    return val;
}
export function unescapeSequenceChar(str) {
    try {
        return unraw(str, true);
    }
    catch (e) {
        // in case of string as malformed unicode just return the whole string
        return str;
    }
}
export function pluralize(num, word, suffix = 's') {
    return `${word}${Math.abs(num) === 1 ? '' : suffix}`;
}
export function pluralizeWithNumber(num, word, suffix = 's') {
    return `${new Intl.NumberFormat().format(num)} ${pluralize(num, word, suffix)}`;
}
export function pluralVerb(num, singular, plural) {
    return num === 1 ? singular : plural;
}
export function parseJSON(json, fallbackValue) {
    try {
        return JSON.parse(json);
    }
    catch (e) {
        return fallbackValue;
    }
}
function initials(fullName, delimiter = ' ') {
    let [first, ...rest] = fullName.trim().split(delimiter);
    let last = rest[rest.length - 1];
    first = first || '';
    last = last || '';
    return `${first.charAt(0).toUpperCase()}${last.charAt(0).toUpperCase()}`;
}
export function nameInitials(fullName) {
    if (fullName.includes('.')) {
        return initials(fullName, '.');
    }
    else if (fullName.includes('_')) {
        return initials(fullName, '_');
    }
    else if (fullName.includes('-')) {
        return initials(fullName, '-');
    }
    return initials(fullName);
}
export function emailInitials(email) {
    const [userName] = email.split('@');
    return nameInitials(userName);
}
export function removeTrailingSlash(path) {
    return path.replace(/\/$/, '');
}
export function stringFormatter(str, ...args) {
    return str.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined' ? args[number] : match;
    });
}
export function isCaseInsensitiveEqual(a, b) {
    return (a === null || a === void 0 ? void 0 : a.toLowerCase()) === (b === null || b === void 0 ? void 0 : b.toLowerCase());
}
export function caseInsensitiveInclude(a, b) {
    return a === null || a === void 0 ? void 0 : a.toLowerCase().includes((b === null || b === void 0 ? void 0 : b.toLowerCase()) || '');
}
export function capitalizeString(str) {
    var _a;
    return `${(_a = str === null || str === void 0 ? void 0 : str.charAt(0)) === null || _a === void 0 ? void 0 : _a.toUpperCase()}${str.slice(1, str.length)}`;
}
export function quoteString(str = '', quote = `"`) {
    // Replace all quotes with escaped quotes
    return quote + str.replaceAll(quote, '\\' + quote) + quote;
}
export function unQuoteString(str = '') {
    // Remove the surrounding quotes if they are double or single quotes
    if ((str.startsWith('"') && str.endsWith('"')) ||
        (str.startsWith("'") && str.endsWith("'")) ||
        (str.startsWith('`') && str.endsWith('`'))) {
        const wrappingQuote = str[0];
        str = str.slice(1, -1);
        // Replace escaped quotes to quotes
        return str.replaceAll(`\\${wrappingQuote}`, wrappingQuote);
    }
    return str;
}
export function getPrefixTruncatedText({ text = '', matchText = '', minPrefixLen = 0, symbol = '...' }) {
    if (!text || !matchText)
        return text;
    const lowerText = text.toLowerCase();
    const matchIndex = lowerText.indexOf(matchText.toLowerCase());
    if (matchIndex === -1 || matchIndex <= minPrefixLen)
        return text;
    const beforeMatch = text.slice(0, matchIndex).trim();
    const truncateAt = Math.max(beforeMatch.lastIndexOf(' ') + 1, 0);
    return truncateAt <= minPrefixLen ? text : symbol + text.slice(truncateAt).trim();
}
