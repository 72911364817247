import { useEffect } from 'react';
import { useVirtualizedTableContext } from './VirtualizedTableComponents';
export function FocusableCells({ children, containerRef, focusedCellId }) {
    // scrollToCell will be available in context only in case of virtualise, else it'll be undefined
    const scrollToCell = useVirtualizedTableContext((context) => context.scrollToCell);
    useEffect(() => {
        if (!focusedCellId || !containerRef.current)
            return;
        const { rowIndex, colIndex } = focusedCellId;
        if (scrollToCell) {
            scrollToCell(rowIndex, colIndex);
        }
        else {
            // Manual scroll calculation for non-virtualized table
            const targetRow = containerRef.current.querySelector(`.ui-table-row[data-row-index="${rowIndex}"]`);
            const cells = targetRow?.querySelectorAll('.ui-table-cell:not(.selection-cell, .reorder-cell)');
            const targetCell = cells?.[colIndex];
            if (targetCell) {
                targetCell.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }, [containerRef, focusedCellId, scrollToCell]);
    return children;
}
