import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Lottie from 'lottie-react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Button } from '../Button';
import { ReactComponent as CopilotOrb } from './copilot_orb.svg';
import loadingData from './loading';
const StyledCopilotIconWrap = styled.div `
  display: inline-block;
  vertical-align: middle;
  width: ${({ dimension }) => dimension};
  height: ${({ dimension }) => dimension};
  position: relative;
`;
const StyledCopilotOrb = styled(CopilotOrb) `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  ${({ disabled }) => disabled &&
    `
    cursor: not-allowed;
  `}

  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  ${({ loading }) => loading && `opacity: 0;`}
`;
const StyledCopilotLoadingIcon = styled(Lottie) `
  position: absolute;
  width: 250%;
  height: 250%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
  opacity: 0;
  ${({ loading }) => loading &&
    `
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  `}
  transition: opacity 0.3s ease-in-out, transform 0.6s ease-in-out;
`;
const GlobalIconStyles = createGlobalStyle `
  [style="mix-blend-mode: color-dodge; display: block;"] {
    		mix-blend-mode: plus-lighter !important;    	
  }
`;
export const CopilotIcon = forwardRef(({ size, disabled, loading, active, ...rest }, ref) => {
    const stillCopilotRef = useRef(null);
    const animatingCopilotRef = useRef(null);
    const [hovered, toggleHover] = useState(false);
    useEffect(() => {
        stillCopilotRef.current?.play();
        animatingCopilotRef.current?.play();
        if ((!active && !hovered) || disabled) {
            stillCopilotRef.current?.pause();
        }
        if (!loading) {
            animatingCopilotRef.current?.stop();
        }
    }, [active, hovered, loading, disabled]);
    return (_jsxs(StyledCopilotIconWrap, { ...rest, dimension: size, ref: ref, onMouseEnter: () => toggleHover(true), onMouseLeave: () => toggleHover(false), children: [_jsx(GlobalIconStyles, {}), _jsx(StyledCopilotOrb, { disabled: disabled, loading: loading }), !disabled && (_jsx(StyledCopilotLoadingIcon, { animationData: loadingData, loading: loading, lottieRef: animatingCopilotRef }))] }));
});
export const CopilotButton = forwardRef(({ size, disabled, loading, active, ...rest }, ref) => {
    return (_jsx(Button, { variant: 'plain', ...rest, ref: ref, children: _jsx(CopilotIcon, { size: size, loading: loading, active: active }) }));
});
