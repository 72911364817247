import { theme } from '../theme';
export const PREFIX_CLASS = 'ui-picker';
export const TIME_PANEL_CLASS = `${PREFIX_CLASS}-time-panel`;
export const HEADER_CLASS = `${PREFIX_CLASS}-header`;
export const HEADER_VIEW_CLASS = `${PREFIX_CLASS}-header-view`;
export const CONTENT_CLASS = `${PREFIX_CLASS}-content`;
export const TIME_PANEL_COLUMN_CLASS = `${PREFIX_CLASS}-time-panel-column`;
export const TIME_PANEL_CELL_INNER_CLASS = `${PREFIX_CLASS}-time-panel-cell-inner`;
export const TIME_PANEL_CELL_SELECTED_CLASS = `${PREFIX_CLASS}-time-panel-cell-selected`;
export const RANGES_CLASS = `${PREFIX_CLASS}-ranges`;
export const PANEL_LAYOUT_CLASS = `${PREFIX_CLASS}-panel-layout`;
export const PANELS_CLASS = `${PREFIX_CLASS}-panels`;
export const PANEL_CLASS = `${PREFIX_CLASS}-panel`;
export const HEADER_SUPER_NEXT_BTN_CLASS = `${PREFIX_CLASS}-header-super-next-btn`;
export const HEADER_SUPER_PREV_BTN_CLASS = `${PREFIX_CLASS}-header-super-prev-btn`;
export const HEADER_PREV_BTN_CLASS = `${PREFIX_CLASS}-header-prev-btn`;
export const HEADER_NEXT_BTN_CLASS = `${PREFIX_CLASS}-header-next-btn`;
export const DATE_PANEL_CLASS = `${PREFIX_CLASS}-date-panel`;
export const BODY_CLASS = `${PREFIX_CLASS}-body`;
export const CELL_RANGE_START_CLASS = `${PREFIX_CLASS}-cell-range-start`;
export const CELL_RANGE_END_CLASS = `${PREFIX_CLASS}-cell-range-end`;
export const CELL_IN_RANGE_CLASS = `${PREFIX_CLASS}-cell-in-range`;
export const CELL_TODAY_CLASS = `${PREFIX_CLASS}-cell-today`;
export const PRESETS_CLASS = `${PREFIX_CLASS}-presets`;
export const FOOTER_CLASS = `${PREFIX_CLASS}-footer`;
export const DROPDOWN_CLASS = `${PREFIX_CLASS}-dropdown`;
export const RANGE_ARROW_CLASS = `${PREFIX_CLASS}-range-arrow`;
export const CELL_IN_VIEW_CLASS = `${PREFIX_CLASS}-cell-in-view`;
export const DATE_RANGE_INPUT_CHILD_INPUT_CLASS = 'date-range-input-child-input';
export const DECADE_BTN_CLASS = `${PREFIX_CLASS}-decade-btn`;
export const DATE_TIME_PANEL_CLASS = `${PREFIX_CLASS}-datetime-panel`;
export const tokens = {
    Container: {
        border: `1px solid ${theme.colors.gray200}`,
        boxShadow: theme.shadows.xl,
        borderRadius: theme.radius.x12,
        background: theme.colors.white
    },
    Calendar: {
        width: '328px',
        padding: `${theme.spaces.x20} ${theme.spaces.x24}`,
        panel: { width: 'auto' },
        Range: {
            Seperator: {
                height: `calc(100% + calc(2 * ${theme.spaces.x20}))`, // adding some extra height to cover whole div
                color: theme.colors.gray200
            }
        },
        Header: {
            height: theme.sizes.x32,
            NavBtn: {
                height: theme.sizes.x32,
                width: theme.sizes.x32,
                padding: theme.spaces.x6,
                borderRadius: theme.radius.x6
            },
            View: {
                width: '130px',
                height: theme.sizes.x24,
                MonthYearContainer: {
                    fontFamily: theme.fontFamily.text,
                    fontSize: theme.text.md.fontSize,
                    fontWeight: theme.fontWeight.medium,
                    lineHeight: theme.text.md.lineHeight,
                    color: theme.colors.gray700
                }
            }
        },
        body: {
            width: '280px',
            height: '304px',
            ContentTable: {
                Cells: {
                    // both header and body cells
                    height: theme.sizes.x40,
                    width: theme.sizes.x40,
                    SelectedRange: {
                        backgroundColor: theme.colors.gray100
                    }
                },
                HeaderCells: {
                    fontWeight: theme.fontWeight.medium,
                    color: theme.colors.gray700,
                    fontFamily: theme.fontFamily.text,
                    fontSize: theme.text.sm.fontSize,
                    lineHeight: theme.text.sm.lineHeight
                }
            }
        }
    },
    DateCell: {
        height: theme.sizes.x40,
        width: theme.sizes.x40,
        fontWeight: theme.fontWeight.medium,
        color: theme.colors.gray700,
        fontFamily: theme.fontFamily.text,
        fontSize: theme.text.sm.fontSize,
        lineHeight: theme.text.sm.lineHeight,
        Selected: {
            backgroundColor: theme.colors.brand600,
            color: theme.colors.white
        },
        HasEvents: {
            backgroundColor: theme.colors.brand600,
            color: theme.colors.gray100,
            notInViewColor: theme.colors.gray500,
            Dot: {
                backgroundColor: theme.colors.brand600,
                notInViewBackgroundColor: theme.colors.gray500
            },
            Selected: {
                backgroundColor: theme.colors.brand600,
                color: theme.colors.white,
                Dot: {
                    color: theme.colors.white
                }
            }
        },
        States: {
            Hovered: {
                backgroundColor: theme.colors.brand100,
                color: theme.colors.gray800
            }
        },
        NotInView: {
            color: theme.colors.gray500,
            fontWeight: theme.fontWeight.normal,
            HasEvents: {
                backgroundColor: theme.colors.white,
                Dot: {
                    backgroundColor: theme.colors.gray400
                }
            }
        },
        Range: {
            color: theme.colors.gray200
        }
    },
    ShowIncludeTime: {
        padding: `${theme.spaces.x16} ${theme.spaces.x24}`,
        gap: theme.spaces.x16,
        borderTop: `1px solid ${theme.colors.gray200}`,
        fontFamily: theme.fontFamily.text,
        fontSize: theme.text.md.fontSize,
        fontWeight: theme.fontWeight.medium,
        lineHeight: theme.text.md.lineHeight,
        color: theme.colors.gray700
    },
    PickerAction: {
        padding: theme.spaces.x16,
        gap: theme.spaces.x12,
        borderTop: `1px solid ${theme.colors.gray200}`,
        Button: {
            padding: `${theme.spaces.x10} ${theme.spaces.x14}`,
            gap: theme.spaces.x4,
            borderRadius: theme.radius.x8,
            fontFamily: theme.fontFamily.text,
            fontSize: theme.text.sm.fontSize,
            fontWeight: theme.fontWeight.semiBold,
            lineHeight: theme.text.sm.lineHeight,
            boxShadow: theme.shadows.skeumorphicXs,
            Cancel: {
                color: theme.colors.gray700,
                border: `1px solid ${theme.colors.gray300}`
            },
            Apply: {
                backgroundColor: theme.colors.brand600,
                borderImageSource: 'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);'
            }
        }
    },
    StyledDateRangePickerContainer: {
        border: `1px solid ${theme.colors.gray200}`,
        boxShadow: theme.shadows.xl,
        borderRadius: theme.radius.x12,
        background: theme.colors.white,
        height: '376px',
        ActionContainer: {
            borderTop: `1px solid ${theme.colors.gray200}`
        }
    },
    RangeCalendarContainer: {
        borderTop: `1px solid ${theme.colors.gray200}`
    },
    Presets: {
        width: 192,
        height: '448px',
        padding: `${theme.spaces.x12} ${theme.spaces.x16}`,
        gap: theme.spaces.x4,
        borderRight: `.5px solid ${theme.colors.gray200}`,
        Items: {
            padding: `${theme.spaces.x8} ${theme.spaces.x16}`,
            borderRadius: theme.radius.x6,
            fontFamily: theme.fontFamily.text,
            fontSize: theme.text.sm.fontSize,
            fontWeight: theme.fontWeight.medium,
            lineHeight: theme.text.sm.lineHeight,
            color: theme.colors.gray700,
            States: {
                Hover: {
                    backgroundColor: theme.colors.gray100
                }
            }
        }
    },
    TimePickerContainer: {
        Panel: {
            border: `.5px solid ${theme.colors.gray200}`,
            boxShadow: theme.shadows.xl,
            borderRadius: theme.radius.x12,
            background: theme.colors.white,
            padding: `${theme.spaces.x20} ${theme.spaces.x24}`,
            Header: {
                marginBottom: theme.spaces.x8,
                fontFamily: theme.fontFamily.text,
                fontSize: theme.text.md.fontSize,
                fontWeight: theme.fontWeight.medium,
                lineHeight: theme.text.md.lineHeight,
                color: theme.colors.gray700
            },
            Cells: {
                height: theme.sizes.x40,
                width: theme.sizes.x40,
                fontFamily: theme.fontFamily.text,
                fontSize: theme.text.sm.fontSize,
                fontWeight: theme.fontWeight.medium,
                lineHeight: theme.text.sm.lineHeight,
                color: theme.colors.gray700,
                States: {
                    Hovered: {
                        backgroundColor: theme.colors.brand100,
                        color: theme.colors.gray800
                    }
                }
            },
            SelectedCells: {
                backgroundColor: theme.colors.brand600,
                color: theme.colors.white
            }
        }
    },
    Input: {
        Placeholder: {
            color: theme.colors.gray500
        }
    }
};
export const DATA_ATTR_CLEAR = 'CLEAR_VALUE';
export const DATA_ATTR_TOGGLE = 'TOGGLE_POPOVER';
