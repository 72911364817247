import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Stack } from '../Layout';
import { Text } from '../Typography/Text';
import { TimePicker } from './TimePicker';
import { tokens } from './tokens';
import { useTimeValues } from './utils';
const StyledTimePicker = styled(TimePicker) `
  width: 200px;
`;
export function DayTab({ cronValue, setCronValue, cronType, disabled }) {
    const { hours, minutes, handleTimeChange } = useTimeValues(cronValue, setCronValue, [], cronType);
    return (_jsxs(Stack, { direction: 'horizontal', alignY: 'center', gap: tokens.CronGenerator.Fields.gap, children: [_jsx(Text, { level: 'sm', children: "Repeat at" }), _jsx(StyledTimePicker, { value: [hours, minutes], onChange: handleTimeChange, disabled: disabled })] }));
}
