import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import styled from 'styled-components';
import { Ellipsis } from '../Ellipsis';
import { Stack, StackItem } from '../Layout';
import { theme } from '../theme';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { Text } from '../Typography/Text';
import { RadioGroup } from './RadioGroup';
import { GroupItemCss, RadioIconBase, StyledContentIcon, StyledContentWrap, StyledRadioLabel } from './styled';
import { tokens } from './tokens';
import { Placement, Size } from './types';
export const RadioButtonIconContainerClassName = 'radio-icon-container';
export const StyledRadioGroupItem = styled(RadioGroup.Item) `
  ${GroupItemCss}

  ${({ disabled, headingWithBorder, checked }) => disabled || !headingWithBorder
    ? ''
    : `
    &:hover ${StyledContentWrap}{
     border-color: ${checked ? tokens.RadioItem.selected.borderColor : tokens.RadioItem.normal.borderColor};
     background: ${disabled ? tokens.RadioItem.disabled.backgroundColor : tokens.RadioItem.selected.backgroundColor};
     &[data-state='checked'] ${StyledContentWrap}{
      background: ${disabled ? tokens.RadioItem.disabled.backgroundColor : tokens.RadioItem.selected.backgroundColor};
     }
      
    }
  `}
`;
// TODO: After migration, Add CheckboxGroup Variant Similer to RadioButtonGroup
const RadioButtonGroupItem = ({ value, hint, headingWithBorder, tooltip, description, label, icon, width, size = Size.s, activeKey, disabled, checkboxPlacement = Placement.right, variant }) => {
    const checked = activeKey === value;
    const isSmall = size === Size.s;
    const renderIcon = () => icon && (_jsx(Stack, { padding: `0 ${theme.spaces.x8} 0 0`, className: RadioButtonIconContainerClassName, children: icon }));
    const renderCheckbox = () => (_jsx(RadioIconBase, { "$checked": checked, "$disabled": disabled, "$size": size, children: _jsx(StyledContentIcon, { type: 'default', "$size": size }) }));
    const renderDescription = () => description && (_jsx(Text, { tone: disabled ? tokens.RadioItem.disabled.color : tokens.RadioItem.subTextColor, elementType: 'p', level: isSmall ? 'sm' : 'md', children: description }));
    let itemUI = (_jsxs(StyledRadioGroupItem, { checked: checked, headingWithBorder: headingWithBorder, size: size, value: value, width: width, disabled: disabled, children: [_jsxs(StyledContentWrap, { checked: checked, disabled: disabled, checkboxPlacement: checkboxPlacement, direction: 'horizontal', headingWithBorder: headingWithBorder, gap: tokens.RadioButtonGroupItem.Title.gap, alignY: icon || description ? 'start' : 'center', children: [checkboxPlacement === Placement.left ? (_jsxs(Stack, { direction: 'horizontal', alignY: 'center', children: [renderCheckbox(), renderIcon()] })) : (renderIcon()), _jsx(StackItem, { grow: '1', children: _jsxs(Stack, { width: '100%', gap: theme.spaces.x4, direction: 'vertical', alignY: 'start', children: [_jsx(Stack, { width: '100%', direction: 'horizontal', gap: theme.spaces.x6, alignY: 'center', children: _jsx(Ellipsis, { tooltip: true, children: _jsx(StyledRadioLabel, { size: size, disabled: disabled, level: 'sm', children: label }) }) }), headingWithBorder ? null : renderDescription()] }) }), checkboxPlacement === Placement.right && renderCheckbox()] }), renderDescription() && headingWithBorder ? (_jsx(Stack, { width: '100%', padding: theme.spaces.x16, children: renderDescription() })) : null] }, value));
    if (tooltip) {
        itemUI = _jsx(_Fragment, { children: renderWithTooltip(_jsx(Stack, { width: '100%', children: itemUI }), tooltip) });
    }
    if (hint) {
        itemUI = (_jsx(LabelWithHint, { hint: hint, children: _jsx(Stack, { width: '100%', gap: theme.spaces.x16, children: itemUI }) }));
    }
    return itemUI;
};
export function RadioButtonGroup({ options, value, defaultValue, onChange, size = Size.s, disabled, checkboxPlacement = Placement.right, wrap = true, children, headingWithBorder, variant = 'primary', ...restProps }) {
    const [selection, setSelection] = useControlledState({
        value,
        defaultValue,
        onChange
    });
    const renderOptions = () => options?.map((option) => (_jsx(RadioButtonGroupItem, { headingWithBorder: headingWithBorder, hint: option.hint, tooltip: option.tooltip, disabled: option.disabled || disabled, size: size, width: restProps.width, label: option.label, value: option.value, activeKey: selection, description: option.description, checkboxPlacement: checkboxPlacement, icon: option.icon, variant: variant }, option.key || option.value)));
    return (_jsx(RadioGroup, { value: selection, gap: theme.spaces.x16, onChange: setSelection, wrap: wrap, ...restProps, children: options && options.length > 0 ? renderOptions() : children }));
}
RadioButtonGroup.Item = RadioButtonGroupItem;
