import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import styled from 'styled-components';
import { Button } from '../Button/Button';
import { negateVariable } from '../theme';
import { tokens } from './tokens';
export const TableIconButton = forwardRef((props, ref) => (_jsx(StyledIconButton, { ...props, ref: ref, size: 's', variant: 'linkGray', block: true, danger: false })));
export const StyledExpandIcon = styled(TableIconButton) `
  // We need to adjust padding from the cell which has expand icon.
  margin-left: ${negateVariable(tokens.Cell.padding)};

  &,
  &:active,
  &:focus {
    &,
    &:hover {
      box-shadow: none;
      border: 0;
    }
  }
`;
// remove 1px from height for border-bottom of table cell
export const StyledIconButton = styled(Button) `
  height: ${parseInt(tokens.Row.height) - 1}px;
  width: ${tokens.Row.height};
  color: ${tokens.Icon.color};

  &&:hover:not([disabled]) {
    color: ${tokens.Icon.hover.color};
  }
`;
