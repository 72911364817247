import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useClickAwayListener, usePersistentCallback } from '@prophecy/utils/react/hooks';
import { format } from 'date-fns';
import { useRef } from 'react';
import { CalendarIcon, MinusIcon, XCircleIcon } from '../../Icons/index';
import { StyledInputRoot, StyledInputContainer } from '../../Input/styled';
import { Popover } from '../../Popover/index';
import { DEFAULT_DATE_FORMAT } from '../constant';
import { useInternalValue, usePickerInput, useValNotFinalizedYet } from '../hooks';
import { DateRangePicker } from '../pickerVariants/DateRangePicker';
import { StyledDateRangeInput, StyledInput } from '../styled';
import { DATE_RANGE_INPUT_CHILD_INPUT_CLASS } from '../tokens';
import { PICKER_VARIANT } from '../types';
import { isDateValid } from '../utils/utils';
export const DateRangeInput = function ({ dateFormat = DEFAULT_DATE_FORMAT, popoverProps, showActionFooter = false, pickerProps, timeFormat, inputContainerSize = 'l', inputSize = 'm', ...props }) {
    const { value: val, onChange: setVal, sync, reset, valSynced } = useInternalValue({
        value: props.value,
        onChange: props.onChange,
        autoSync: !showActionFooter,
        isRangePicker: true
    });
    const getRangeString = usePersistentCallback(function (dateRange) {
        return [dateRange[0] ? format(dateRange[0], dateFormat) : '', dateRange[1] ? format(dateRange[1], dateFormat) : ''];
    });
    const { popoverOpen, containerRef, iconRef: calendarIconContainerRef, handleSelect, handlePopupOutsideInterraction, onApply, onCancel, inputString, handleRangeInputChange: handleChange, resetDateToNull } = usePickerInput({
        format: dateFormat,
        onChange: setVal,
        isValid: (val, format) => isDateValid(val, format),
        sync,
        reset,
        value: val,
        getValueString: getRangeString,
        isRangePicker: true
    });
    const startInputRef = useRef(null);
    const endInputRef = useRef(null);
    const pickerRef = useRef(null);
    function getNativePickerInputElements() {
        return pickerRef.current?.getInputElements() ?? [null, null];
    }
    function handleInputClick(isStart = false) {
        const nativePickerInputs = getNativePickerInputElements();
        const clickIndex = isStart ? 0 : 1;
        nativePickerInputs[clickIndex]?.focus();
        isStart ? startInputRef.current?.focus() : endInputRef.current?.focus();
    }
    const valNotFinalizedYet = useValNotFinalizedYet({
        showActionFooter,
        valSynced,
        popoverOpen
    });
    const onInterractionOutsideRef = useClickAwayListener(handlePopupOutsideInterraction);
    return (_jsx(StyledDateRangeInput, { ref: containerRef, children: _jsx(StyledInputRoot, { variant: 'primary', size: inputContainerSize, children: _jsxs(StyledInputContainer, { type: '', variant: 'primary', inputSize: inputContainerSize, children: [_jsx(StyledInput, { ...props, value: inputString[0], onChange: (val) => handleChange(val, true), placeholder: dateFormat.toLocaleLowerCase(), iconPlacement: 'right', className: DATE_RANGE_INPUT_CHILD_INPUT_CLASS, size: 10, inputSize: inputSize, ref: startInputRef, onClick: () => handleInputClick(true), "$valNotFinalizedYet": valNotFinalizedYet }), _jsx(MinusIcon, { type: 'default' }), _jsx(StyledInput, { ...props, value: inputString[1], onChange: (val) => handleChange(val), placeholder: dateFormat.toLocaleLowerCase(), iconPlacement: 'right', className: DATE_RANGE_INPUT_CHILD_INPUT_CLASS, size: 10, inputSize: inputSize, ref: endInputRef, onClick: () => handleInputClick(), "$valNotFinalizedYet": valNotFinalizedYet }), _jsx(Popover, { visible: popoverOpen, overlay: _jsx("div", { ref: onInterractionOutsideRef, children: _jsx(DateRangePicker, { ...pickerProps, value: val, onChange: (val) => handleSelect(val), dateFormat: dateFormat, timeFormat: timeFormat, variant: PICKER_VARIANT.DATE_RANGE, showActionFooter: showActionFooter, onApply: onApply, onCancel: onCancel, ref: pickerRef }) }), overlayStyle: { border: 'none', boxShadow: 'none', backgroundColor: 'transparent' }, ...popoverProps, onInteractOutside: handlePopupOutsideInterraction, children: _jsx("div", { ref: calendarIconContainerRef, children: popoverOpen ? (_jsx(XCircleIcon, { type: 'solid', onClickCapture: () => resetDateToNull() })) : (_jsx(CalendarIcon, { type: 'default' })) }) })] }) }) }));
};
