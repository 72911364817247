import styled, { css } from 'styled-components';
import { Bar } from '../Bar';
import { tokens as BarTokens } from '../Bar/tokens';
import { ICON_CLASS, tokens as iconTokens } from '../Icons/tokens';
import { Stack } from '../Layout';
import { getColorCode } from '../theme/utils';
import { StackItem } from './../Layout/Stack';
const iconOverrideCss = css `
  .${ICON_CLASS} {
    color: ${({ tone }) => getColorCode(tone, 500)};
    font-size: ${iconTokens.Icon.m.size};
  }
`;
export const StyleIconWrap = styled('div') `
  border-radius: ${BarTokens.Bar.borderRadius};
  height: ${BarTokens.Bar.height};
  width: ${BarTokens.Bar.height};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${({ tone }) => getColorCode(tone, 50)};
  border: 1px solid ${({ tone }) => getColorCode(tone, 200)};

  ${iconOverrideCss}
`;
export const CollapsedBar = styled(Bar) `
  cursor: pointer;
  flex: 1;
  min-height: 0;
  height: auto;
`;
export const StyledExpendedItem = styled(Stack) `
  flex: 1;
  min-height: 0;
`;
export const StyledExpandedIconWrap = styled(StackItem) `
  ${iconOverrideCss}
`;
