export var PICKER_VARIANT;
(function (PICKER_VARIANT) {
    PICKER_VARIANT["DATE"] = "DATE";
    PICKER_VARIANT["TIME"] = "TIME";
    PICKER_VARIANT["DATE_RANGE"] = "DATE_RANGE";
})(PICKER_VARIANT || (PICKER_VARIANT = {}));
export var DATE_FORMAT;
(function (DATE_FORMAT) {
    DATE_FORMAT["DD_MM_YY"] = "dd/MM/yy";
    DATE_FORMAT["DD_MM_YYYY"] = "dd/MM/yyyy";
    DATE_FORMAT["MM_DD_YY"] = "MM/dd/yy";
    DATE_FORMAT["MM_DD_YYYY"] = "MM/dd/yyyy";
    DATE_FORMAT["YYYY_MM_DD"] = "yyyy/MM/dd";
    DATE_FORMAT["DD_MMM_YYYY"] = "dd/MMM/yyyy";
    DATE_FORMAT["MMM_DD_YYYY"] = "MMM/dd/yyyy";
    DATE_FORMAT["MMM_D_YYYY"] = "MMM d, yyyy";
    DATE_FORMAT["MMMM_D_YYYY"] = "MMMM d, yyyy";
    DATE_FORMAT["D_MMM_YYYY"] = "d MMM yyyy"; // e.g., "1 Jan 2024"
})(DATE_FORMAT || (DATE_FORMAT = {}));
export var TIME_FORMAT;
(function (TIME_FORMAT) {
    TIME_FORMAT["HH_MM_SS"] = "HH:mm:ss";
    TIME_FORMAT["hh_MM_AMPM"] = "hh:mm a";
    TIME_FORMAT["hh_MM_SS_AMPM"] = "hh:mm:ss a";
})(TIME_FORMAT || (TIME_FORMAT = {}));
