import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RangePicker } from 'rc-picker';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useControlledState } from '../../../../utils/src/react/hooks';
import { ChevronLeftIcon, ChevronRightIcon } from '../../Icons';
import 'rc-picker/assets/index.css';
import { CellRenderer } from '../components/CellRenderer';
import { PanelRenderer } from '../components/DatePanelRenderer';
import { DEFAULT_DATE_FORMAT, getDefaultPresets } from '../constant';
import { StyledPickerContainer, PickerDropdownBorderEraser } from '../styled';
import { PREFIX_CLASS } from '../tokens';
import { generateConfig, getLocale } from '../utils/utils';
export const DateRangePicker = forwardRef(function ({ showActionFooter, onApply, onCancel, events, dateFormat = DEFAULT_DATE_FORMAT, preset, showPresets, ...props }, ref) {
    const [val, setVal] = useControlledState({
        value: props.value,
        onChange: props.onChange,
        defaultValue: [undefined, undefined]
    });
    const containerElement = useRef(null);
    const pickerRef = useRef(null);
    const inputElements = useRef([null, null]);
    const [mode, setMode] = useState(['date', 'date']);
    useEffect(function () {
        const inputs = pickerRef.current?.nativeElement?.querySelectorAll('input');
        inputElements.current[0] = inputs[0];
        inputElements.current[1] = inputs[1];
    }, []);
    useImperativeHandle(ref, () => ({
        getInputElements() {
            return inputElements.current;
        }
    }), []);
    return (_jsxs(StyledPickerContainer, { renderAsPanel: true, ref: containerElement, children: [_jsx(PickerDropdownBorderEraser, {}), _jsx(RangePicker, { prefixCls: PREFIX_CLASS, ref: pickerRef, value: val, onChange: (val) => {
                    setVal(val);
                }, generateConfig: generateConfig, locale: getLocale(), open: true, format: dateFormat, presets: showPresets ? preset || getDefaultPresets() : [], prevIcon: _jsx(ChevronLeftIcon, { type: 'solid', height: 20, width: 20 }), nextIcon: _jsx(ChevronRightIcon, { type: 'solid', height: 20, width: 20 }), superPrevIcon: _jsx(ChevronLeftIcon, { type: 'solid', height: 20, width: 20 }), superNextIcon: _jsx(ChevronRightIcon, { type: 'solid', height: 20, width: 20 }), cellRender: (date, info) => (_jsx(CellRenderer, { date: date, info: info, events: events, isRangePicker: true, selectedRange: val })), panelRender: (node) => {
                    const panel = (_jsx(PanelRenderer, { originalPanelRenderer: node, isRangePicker: true, showPresets: showPresets, showActionFooter: showActionFooter, onApply: () => onApply?.(val), onCancel: () => onCancel?.(val), mode: mode }));
                    if (containerElement.current) {
                        return createPortal(panel, containerElement.current);
                    }
                    return panel;
                }, mode: mode, onPanelChange: (_, newMode) => {
                    setMode(newMode);
                } })] }));
});
