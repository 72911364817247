import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Picker } from 'rc-picker';
import { useRef } from 'react';
import 'rc-picker/assets/index.css';
import { createPortal } from 'react-dom';
import { useControlledState } from '../../../../utils/src/react/hooks';
import { CellRenderer } from '../components/CellRenderer';
import { TimePanelRenderer } from '../components/TimePanelRenderer';
import { DEFAULT_TIME_FORMAT } from '../constant';
import { StyledPickerContainer, PickerDropdownBorderEraser } from '../styled';
import { PREFIX_CLASS } from '../tokens';
import { generateConfig, getDefaultTime, getLocale } from '../utils/utils';
export const TimePicker = function ({ events, showActionFooter, onApply, onCancel, showTime, timeFormat = DEFAULT_TIME_FORMAT, ...props }) {
    const [val, setVal] = useControlledState({
        value: props.value,
        onChange: props.onChange,
        defaultValue: undefined
    });
    const containerElement = useRef(null);
    return (_jsxs(StyledPickerContainer, { renderAsPanel: true, ref: containerElement, children: [_jsx(PickerDropdownBorderEraser, {}), _jsx(Picker, { prefixCls: PREFIX_CLASS, value: val || getDefaultTime(), onPickerValueChange: (date) => setVal(date), generateConfig: generateConfig, locale: getLocale(), picker: 'time', open: true, cellRender: (date, info) => (_jsx(CellRenderer, { date: date, info: info, selectedDate: val, events: events, isRangePicker: false })), panelRender: (node) => {
                    const panel = (_jsx(TimePanelRenderer, { originalPanelRenderer: node, isRangePicker: false, showActionFooter: showActionFooter, onCancel: () => onCancel?.(val), onApply: () => onApply?.(val), showTimePicker: showTime, mode: 'time' }));
                    if (containerElement.current) {
                        return createPortal(panel, containerElement.current);
                    }
                    return panel;
                }, format: timeFormat })] }));
};
