import styled, { css } from 'styled-components';
import { LoadingDIcon } from '../Icons';
import { ICON_CLASS, LOGO_ICON_CLASS } from '../Icons/tokens';
import { getInterFontWeightCSS, theme } from '../theme';
import { LinkComponent } from './LinkComponent';
import { tokens } from './tokens';
import { ButtonShape, ButtonState, ButtonVariants, ButtonCategory, IconPlacement } from './types';
import { getBackground, getBorder, getVariantProperty } from './utils';
const PlainButtonStyle = css `
  all: unset;

  && {
    -webkit-appearance: none;
  }

  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
`;
const BaseButtonStyle = css `
  &&:focus-visible {
    outline: none;
  }
  cursor: pointer;
  box-sizing: border-box;
  ${getInterFontWeightCSS(tokens.Button.fontWeight)};
  font-family: ${theme.fontFamily.text};
  border-radius: ${(props) => props.shape === ButtonShape.circle ? '50%' : tokens.Button.size[props.size].borderRadius};
  font-size: ${(props) => tokens.Button.size[props.size].fontSize};
  line-height: ${({ size }) => tokens.Button.size[size].lineHeight};
  height: ${(props) => tokens.Button.size[props.size].height};
  word-break: normal;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'auto')};
  ${(props) => props.hasChildren
    ? `padding: ${props.variant === ButtonVariants.linkGray || props.variant === ButtonVariants.link ? '0' : tokens.Button.size[props.size].padding};`
    : `width: ${tokens.Button.size[props.size].height};`};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${({ size }) => tokens.Button.size[size].gap};

  ${(props) => getBackground({
    props,
    defaultValue: 'transparent'
})};

  color: ${(props) => getVariantProperty(props, 'color')};
  border: ${(props) => getBorder({ props })};
  box-shadow: ${(props) => getVariantProperty(props, 'boxShadow', undefined, 'none')};
`;
const FocusedButton = css `
  &:focus,
  &:active,
  &[data-active='true'] {
    ${(props) => getBackground({
    props,
    state: ButtonState.focused,
    defaultValue: 'transparent'
})}
    color: ${(props) => getVariantProperty(props, 'color', ButtonState.focused)};
    border: ${(props) => getBorder({ props, state: ButtonState.focused })};
    box-shadow: ${(props) => props.disablePointerFocus ? 'none' : getVariantProperty(props, 'boxShadow', ButtonState.focused, 'none')};
  }

  &:focus-visible {
    box-shadow: ${(props) => getVariantProperty(props, 'boxShadow', ButtonState.focused, 'none')};
  }
`;
const HoverButton = css `
  &:hover {
    ${(props) => `
      ${getBackground({
    props,
    state: ButtonState.hover,
    defaultValue: 'transparent'
})};
    `}
    color: ${(props) => getVariantProperty(props, 'color', ButtonState.hover)};
    border: ${(props) => getBorder({ props, state: ButtonState.hover })};
    box-shadow: ${(props) => props.disabled ? 'none' : getVariantProperty(props, 'boxShadow', ButtonState.hover, 'none')};

    &:focus,
    &:active,
    &[data-active='true'] {
      border: ${(props) => getBorder({ props, state: ButtonState.focused })};

      box-shadow: ${(props) => props.disablePointerFocus ? 'none' : getVariantProperty(props, 'boxShadow', ButtonState.focused, 'none')};
    }

    &:focus-visible {
      box-shadow: ${(props) => getVariantProperty(props, 'boxShadow', ButtonState.focused, 'none')};
    }
  }
`;
const DisableButton = css `
  &:disabled {
    ${(props) => getBackground({
    props,
    state: ButtonState.disabled,
    defaultValue: 'transparent'
})}
    color: ${(props) => getVariantProperty(props, 'color', ButtonState.disabled)};
    &,
    &:hover,
    &:focus,
    &:active {
      border: ${(props) => getBorder({ props, state: ButtonState.disabled, borderWidth: '1px' })};
    }
    box-shadow: none;
    cursor: not-allowed;
  }
`;
const OverrideForButtons = css `
  /** Override for block button */
  ${(props) => props.block &&
    `
    border-radius: 0;
    height: 100%;
    width: 100%;
    padding-top: ${theme.spaces.x6};
    padding-bottom: ${theme.spaces.x6};
  `}

  /** Override for plain button */
  ${(props) => props.variant === ButtonVariants.plain &&
    `
    border-radius: 0;
    height: auto;
    width: auto;
    padding: 0;
    font-size: inherit;
  `}

  /** Style icon base on size */
  .${ICON_CLASS} {
    font-size: ${(props) => tokens.Button.size[props.size].iconSize};
  }
  .${LOGO_ICON_CLASS} {
    width: ${(props) => tokens.Button.size[props.size].logoIconSize};
    height: ${(props) => tokens.Button.size[props.size].logoIconSize};
  }
`;
export const ButtonStyle = css `
  ${BaseButtonStyle}
  ${FocusedButton}
  ${HoverButton}
  ${DisableButton}
  ${OverrideForButtons}
`;
/** Gradient border pseudo element for primary button */
export const PrimaryButtonStyle = css `
  ${ButtonStyle}
  ${(props) => !props.isBadge &&
    !props.disabled &&
    !props.isGroupItem &&
    `
      position: relative;
      box-shadow: none;

      &::before {
        box-sizing: border-box;
        content: '';
        position: absolute;
        inset: -2px;
        border-radius: inherit;
        background: transparent;
        box-shadow: ${!props.disabled
        ? props.category === ButtonCategory.danger
            ? tokens.Button.danger.primary.boxShadow
            : tokens.Button.default.primary.boxShadow
        : 'none'};
        z-index: ${theme.zLayer.s};
      }

      &:hover::before {
        box-shadow: ${!props.disabled
        ? props.category === ButtonCategory.danger
            ? tokens.Button.danger.primary.hover.boxShadow
            : tokens.Button.default.primary.hover.boxShadow
        : 'none'};
      }

      &:hover:focus::before,
      &:hover:active::before,
      &:hover[data-active='true']::before {
        box-shadow: ${!props.disablePointerFocus
        ? props.category === ButtonCategory.danger
            ? tokens.Button.danger.primary.focused.boxShadow
            : tokens.Button.default.primary.focused.boxShadow
        : 'none'};
      }

      &:hover:focus-visible::before {
        box-shadow: ${getVariantProperty(props, 'boxShadow', ButtonState.focused, 'none')};
      }
    `}
`;
export const StyledButton = styled.button `
  ${(props) => props.variant === ButtonVariants.plain
    ? PlainButtonStyle
    : props.variant === ButtonVariants.primary
        ? PrimaryButtonStyle
        : ButtonStyle}
`;
export const StyledButtonLink = styled(LinkComponent) `
  ${(props) => props.variant === ButtonVariants.plain
    ? PlainButtonStyle
    : props.variant === ButtonVariants.primary
        ? PrimaryButtonStyle
        : ButtonStyle}
`;
export const StyledButtonAnchor = styled.a `
  ${(props) => props.variant === ButtonVariants.plain
    ? PlainButtonStyle
    : props.variant === ButtonVariants.primary
        ? PrimaryButtonStyle
        : ButtonStyle}
`;
export const StyledIconWrapper = styled.span `
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  order: ${(props) => (props.$iconPlacement === IconPlacement.left ? '1' : '3')};
`;
export const StyledButtonContent = styled.span `
  order: 2;
`;
export const StyledLoadingIcon = styled(LoadingDIcon) `
  order: ${(props) => (props.$iconPlacement === IconPlacement.left ? '3' : '1')};
  display: inline-block;
  vertical-align: middle;
`;
export const ButtonWrapperForTooltip = styled.span `
  display: inline-block;
`;
export const ButtonContentWrapper = styled.div `
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
// To disable focus ring on buttons when focus ring is rendered only on focus-visible (e.g., dropdown)
export const disableButtonFocusRing = css `
  &&& {
    &:focus:not(:focus-visible),
    &:hover:focus:not(:focus-visible),
    &:active:focus:not(:focus-visible),
    &:hover:active:not(:focus-visible),
    &:active:hover:not(:focus-visible),
    &[data-active='true']:not(:focus-visible),
    &[data-state='open']:not(:focus-visible) {
      box-shadow: none;
    }
  }
`;
