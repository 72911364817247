export enum ORCHESTRATION_PROVIDER {
  Databricks = 'databricks',
  Snowflake = 'snowflake'
}

export type OrchestrationFabricInfo = {
  url: string;
};

export type OrchestrationDataBricksProvider = OrchestrationFabricInfo & {
  providerType: ORCHESTRATION_PROVIDER.Databricks;
};

export type OrchestrationProviderInfo = OrchestrationDataBricksProvider;

export type OrchestrationProvider<T = OrchestrationDataBricksProvider> = {
  providerInfo: T;
};
