import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isRenderableNode } from '@prophecy/utils/react/nodes';
import { forwardRef, useEffect, useState } from 'react';
import { theme } from '../theme';
import { StyledLoaderWrap, StyledTip, StyledSpinnerWrap, StyledOverlay, StyledLoader, StyledCircleIcon, StyledLoadingIcon } from './styled';
import { SpinnerSize } from './types';
export const Spinner = forwardRef(({ spinning = true, tip, size = SpinnerSize.m, showBackgroundCircle, delay, children, overlayClassName, centered, showOverlayBlur = true, ...restProps }, ref) => {
    const [_spinning, setSpinning] = useState(!!spinning);
    useEffect(() => {
        // for spinning false, updating the state immediately
        if (!spinning || delay === 0) {
            setSpinning(spinning);
            return;
        }
        const timeoutId = setTimeout(() => {
            setSpinning(true);
        }, delay);
        return () => clearTimeout(timeoutId);
    }, [spinning, delay]);
    const spinner = (_jsxs(StyledLoaderWrap, { alignY: 'center', children: [_jsxs(StyledLoader, { "$size": size, children: [_jsx(StyledLoadingIcon, { type: 'extended', size: size, spin: true, color: theme.colors.brand600 }), showBackgroundCircle && _jsx(StyledCircleIcon, { type: 'default', size: size, spin: true, color: theme.colors.white })] }), isRenderableNode(tip) && _jsx(StyledTip, { "$size": size, children: tip })] }));
    let content = spinner;
    if (children) {
        content = (_jsxs(_Fragment, { children: [children, _spinning && (_jsx(StyledOverlay, { "$showBlur": showOverlayBlur, className: overlayClassName, children: spinner }))] }));
    }
    else if (centered) {
        content = (_jsx(StyledOverlay, { "$showBlur": showOverlayBlur, className: overlayClassName, children: spinner }));
    }
    return (_jsx(StyledSpinnerWrap, { "$centered": centered, ...restProps, ref: ref, children: content }));
});
