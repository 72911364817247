import { theme } from '../theme';
export const tokens = {
    ArrowIcon: {
        color: theme.colors.gray400,
        fontSize: theme.fontSizes.x16,
        margin: theme.spaces.x6
    },
    Input: {
        background: theme.colors.gray50,
        radius: theme.radius.x6,
        iconSize: theme.sizes.x10
    },
    EditIcon: {
        color: {
            gray: theme.colors.gray500,
            brand: theme.colors.brand700
        },
        width: theme.sizes.x10
    },
    label: {
        fontSize: '13px' // its one-off adjustment size as metioned in figma design
    },
    Crumb: {
        fontWeight: theme.fontWeight.medium,
        gap: theme.spaces.x8,
        color: theme.colors.gray500,
        iconColor: theme.colors.gray400,
        fontFamily: theme.fontFamily.text,
        background: theme.colors.white,
        hover: {
            color: theme.colors.gray700
        },
        active: {
            color: theme.colors.brand700,
            iconColor: theme.colors.brand700
        },
        focused: {
            boxShadow: theme.focusRing.shadow.xs
        }
    },
    text: {
        background: theme.colors.white,
        borderRadius: theme.radius.x4,
        active: {
            background: theme.colors.white,
            color: theme.colors.gray700
        }
    },
    brand: {
        background: theme.colors.white,
        borderRadius: theme.radius.x6,
        active: {
            background: theme.colors.brand50,
            color: theme.colors.brand700
        }
    },
    gray: {
        background: theme.colors.white,
        borderRadius: theme.radius.x6,
        active: {
            background: theme.colors.gray50,
            color: theme.colors.gray700
        }
    }
};
