import { NestedData } from '@prophecy/interfaces/generic';
import { ProphecyError } from '@prophecy/utils/error';

import { captureException } from '../../common/sentry';
import { ErrorTags, SentryTags } from '../../common/sentry/SentryTags';
import { csrf } from '../../utils/csrf';

export async function fetchURL(
  url: URL | RequestInfo,
  requestConfig: RequestInit | undefined,
  handleUnAuthorizedCall: (status: number, url: string) => boolean = () => true
) {
  const body = requestConfig?.body;
  const isFormData = body instanceof FormData;
  const requestHeaders: NestedData = {
    accept: 'application/json;',
    'Content-Type': 'application/json;charset=utf-8',
    [csrf.key]: csrf.get(),
    ...requestConfig?.headers
  };
  if (isFormData && body) {
    delete requestHeaders['Content-Type'];
  }
  // add csrf token and credentials
  const modifiedOptions: RequestInit = {
    credentials: 'include',
    ...requestConfig,
    headers: { [csrf.key]: csrf.get(), ...requestHeaders }
  };

  let response: Response | undefined;

  try {
    response = await fetch(url, modifiedOptions);
  } catch (e) {
    captureException({
      exception: e,
      errorTags: { [SentryTags.ProphecyErrorType]: ErrorTags.JavascriptError }
    });
    throw new ProphecyError('Something went wrong in an API');
  }

  const isAuthorized = handleUnAuthorizedCall(response?.status, url.toString?.());

  if (!response?.ok && isAuthorized) {
    // anything outside 200 range
    let errorResponse;
    const clonedResponse = response?.clone();
    try {
      errorResponse = await response?.json();
    } catch (e) {
      const message = (await clonedResponse?.text()) || 'Something went wrong';
      // Putting a check if html response is returned the ui is breaking with toast/message
      throw new ProphecyError(message.length > 100 ? message.slice(0, 100) : message);
    }
    throw errorResponse;
  }
  // TODO: remove below logic once backend starts sending right content-type header
  const headers: [string, string][] = [];
  for (const [key, value] of response.headers.entries()) {
    if (key === 'content-type') {
      headers.push([key, 'application/json']);
    } else {
      headers.push([key, value]);
    }
  }
  return new Response(response.body, {
    status: response.status,
    statusText: response.statusText,
    headers
  });
}
