import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { getBlocks, withoutNormalizing } from '@udecode/plate-common';
import { ParagraphPlugin, focusEditor, useEditorRef, useSelectionFragmentProp } from '@udecode/plate-common/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { BulletedListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';
import { useMemo } from 'react';
import { Dropdown } from '../Dropdown';
import { MarkdownBulletListIcon, MarkdownH1Icon, MarkdownH2Icon, MarkdownH3Icon, MarkdownNumberedListIcon, MarkdownParagraphIcon, MarkdownQuoteIcon } from '../Icons';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { StyledDropdownHandle, StyledToolbarArrowIcon } from './styled';
const typeMap = [
    {
        icon: _jsx(MarkdownParagraphIcon, { type: 'extended' }),
        keywords: ['paragraph'],
        label: 'Text',
        value: ParagraphPlugin.key
    },
    {
        icon: _jsx(MarkdownH1Icon, { type: 'extended' }),
        keywords: ['title', 'h1'],
        label: 'Heading 1',
        value: HEADING_KEYS.h1
    },
    {
        icon: _jsx(MarkdownH2Icon, { type: 'extended' }),
        keywords: ['subtitle', 'h2'],
        label: 'Heading 2',
        value: HEADING_KEYS.h2
    },
    {
        icon: _jsx(MarkdownH3Icon, { type: 'extended' }),
        keywords: ['subtitle', 'h3'],
        label: 'Heading 3',
        value: HEADING_KEYS.h3
    },
    {
        icon: _jsx(MarkdownBulletListIcon, { type: 'extended' }),
        keywords: ['unordered', 'ul', '-'],
        label: 'Bulleted list',
        value: BulletedListPlugin.key
    },
    {
        icon: _jsx(MarkdownNumberedListIcon, { type: 'extended' }),
        keywords: ['ordered', 'ol', '1'],
        label: 'Numbered list',
        value: NumberedListPlugin.key
    },
    {
        icon: _jsx(MarkdownQuoteIcon, { type: 'extended' }),
        keywords: ['citation', 'blockquote', '>'],
        label: 'Quote',
        value: BlockquotePlugin.key
    }
];
export const setBlockType = (editor, type) => {
    withoutNormalizing(editor, () => {
        const setEntry = (entry) => {
            const [node, path] = entry;
            if (node.type !== type) {
                editor.setNodes({ type }, { at: path });
            }
        };
        const entries = getBlocks(editor);
        entries.forEach((entry) => setEntry(entry));
    });
};
export function TextTypeSelect() {
    const editor = useEditorRef();
    const value = useSelectionFragmentProp({
        defaultValue: ParagraphPlugin.key,
        getProp: (node) => node.type
    });
    const selectedItem = useMemo(() => typeMap.find((item) => item.value === (value ?? ParagraphPlugin.key)) ?? typeMap[0], [value]);
    const onSelect = (type) => {
        setBlockType(editor, type);
        focusEditor(editor);
    };
    return (_jsx(Dropdown, { overlay: typeMap.map(({ icon, label, value: itemValue }) => (_jsx(Dropdown.Item, { onSelect: () => onSelect(itemValue), children: _jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x12, children: [icon, label] }) }, itemValue))), children: _jsx(StyledDropdownHandle, { variant: 'tertiaryGray', size: 'm', icon: _jsx(StyledToolbarArrowIcon, { type: 'default', size: 'xxs' }), children: selectedItem.label }) }));
}
