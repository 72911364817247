//TODO: Remove this once migration is completed
import { theme } from '@prophecy/ui-v3/theme';
import { css } from 'styled-components';
export function focusedShadow(color) {
    return `${theme.shadows.xs}, 0px 0px 0px ${theme.outlineWidth} ${color}`;
}
export function getColorCode(baseColor, shade) {
    return theme.colors[`${baseColor}${shade}`];
}
export function getBoxShadow(baseColor, shade, size = 'xs') {
    return `${theme.shadows[size]}, 0 0 0 4px ${theme.colors[`${baseColor}${shade}`]}`;
}
export function getGradientBorder(gradient, background, borderSize) {
    return css `
    background:
      linear-gradient(${background}, ${background}) padding-box,
      ${gradient} border-box;
    border: ${borderSize} solid transparent;
  `;
}
export function hexToRGBA(hexCode, alpha = 1) {
    // Remove the '#' symbol if present
    const hex = hexCode.replace('#', '');
    // Convert the hexadecimal code to decimal values
    const red = parseInt(hex.substr(0, 2), 16);
    const green = parseInt(hex.substr(2, 2), 16);
    const blue = parseInt(hex.substr(4, 2), 16);
    // Check if the hex code includes alpha value (8 characters)
    if (hex.length === 8) {
        alpha = parseInt(hex.substr(6, 2), 16) / 255;
    }
    // Construct the RGBA string
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}
export function negateAllSpacing(spacing) {
    return spacing
        .split(' ')
        .map((space) => {
        return space.startsWith('-') ? space : `-${space}`;
    })
        .join(' ');
}
export function hexToTransparent(hex, opacity) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');
    // Parse the r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    // Adjust the color based on the opacity and white background
    let newR = Math.round((r - 255 * (1 - opacity)) / opacity);
    let newG = Math.round((g - 255 * (1 - opacity)) / opacity);
    let newB = Math.round((b - 255 * (1 - opacity)) / opacity);
    // Return the rgba color
    return `rgba(${newR},${newG},${newB},${opacity})`;
}
export const negateVariable = (variable) => {
    return `calc(${variable}  * -1)`;
};
