import { theme } from '../theme';
export const tokens = {
    Icon: {
        xxs: {
            size: theme.sizes.x12,
            strokeWidth: 3,
            mini: {
                strokeWidth: 3.5
            }
        },
        xs: {
            size: theme.sizes.x16,
            strokeWidth: 3,
            mini: {
                strokeWidth: 3.5
            }
        },
        s: {
            size: theme.sizes.x20,
            strokeWidth: 2,
            mini: {
                strokeWidth: 3.5
            }
        },
        m: {
            size: theme.sizes.x24,
            strokeWidth: 2,
            mini: {
                strokeWidth: 3
            }
        },
        l: {
            size: theme.sizes.x28,
            strokeWidth: 2,
            mini: {
                strokeWidth: 3
            }
        },
        xl: {
            size: theme.sizes.x32,
            strokeWidth: 2,
            mini: {
                strokeWidth: 3
            }
        }
    }
};
export const ICON_CLASS = 'ui-icon';
export const LOGO_ICON_CLASS = 'ui-logo-icon';
