import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAfterNextRender, useControlledState, usePersistentCallback } from '@prophecy/utils/react/hooks';
import { Children, useContext, createContext, isValidElement, useRef, forwardRef, useEffect } from 'react';
import { Button } from '../Button';
import { StackItem } from '../Layout';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { StyledTabsRoot, StyledTabListContainer, StyledTabsList, StyledTabsTrigger, StyledTabsContentWrap, StyledTabsContent, StyledTabsTriggerGroup, StyledTriggerGroupLabel, StyledBox } from './styled';
import { tokens } from './tokens';
import { TriggerType, TabTone, TabSize, Direction } from './types';
const tabContextDefaults = {
    size: TabSize.m,
    tone: TabTone.brand,
    orientation: Direction.horizontal
};
export const TAB_CONTENT_WRAPPER_CLASS_NAME = 'tab-content-wrapper';
export const TAB_LIST_CONTAINER_CLASS_NAME = 'tab-list-container';
export const TabPaneAttrs = {
    key: 'data-tab-pane-key'
};
const TabsContext = createContext(tabContextDefaults);
export const TabsRoot = forwardRef(({ size, tone, orientation, stretchTabs, bordered, ...restProps }) => {
    const _orientation = orientation || tabContextDefaults.orientation;
    return (_jsx(TabsContext.Provider, { value: {
            size: size || tabContextDefaults.size,
            tone: tone || tabContextDefaults.tone,
            orientation: _orientation,
            // for vertical orientation ignore stretchTabs props
            stretchTabs: _orientation === Direction.horizontal ? stretchTabs : undefined
        }, children: _jsx(StyledTabsRoot, { orientation: _orientation, bordered: bordered, ...restProps }) }));
});
export const TabsList = forwardRef((props, ref) => {
    const { tone, orientation, stretchTabs } = useContext(TabsContext);
    return _jsx(StyledTabsList, { ...props, tone: tone, orientation: orientation, stretchTabs: stretchTabs });
});
export const TabsTrigger = (props) => {
    const { size, tone, orientation, stretchTabs } = useContext(TabsContext);
    return _jsx(StyledTabsTrigger, { size: size, tone: tone, orientation: orientation, stretchTabs: stretchTabs, ...props });
};
export const TabsContent = StyledTabsContent;
export function TabsTriggerGroup({ label, children, selected }) {
    const { size, tone } = useContext(TabsContext);
    return (_jsxs(StyledTabsTriggerGroup, { selected: Boolean(selected), size: size, tone: tone, children: [_jsx(StyledTriggerGroupLabel, { size: size, children: label }), children] }));
}
function getKeyFromTrigger(trigger) {
    if (!trigger) {
        return;
    }
    else if (trigger.type === TriggerType.trigger) {
        return trigger.props.value;
    }
    else if (trigger.type === TriggerType.group) {
        return getKeyFromTrigger(trigger.props.childTriggers[0]);
    }
}
function extractTabsFromChildren(children) {
    const triggers = [];
    const contents = [];
    Children.map(children, (child) => {
        if (!isValidElement(child))
            return;
        const { key, props } = child;
        const { tab, children, ...restProps } = props;
        if (tab) {
            triggers.push({
                type: TriggerType.trigger,
                props: { ...restProps, children: tab, value: key }
            });
            contents.push({ value: key, children });
        }
        else if (children) {
            const { triggers: childTriggers, contents: childContents } = extractTabsFromChildren(children);
            // add only if has nested triggers
            if (!childTriggers.length)
                return;
            triggers.push({ type: TriggerType.group, props: { ...restProps, childTriggers } });
            contents.push(...childContents);
        }
    });
    return { triggers, contents };
}
function TriggerWrap({ value, hint, ...restProps }) {
    let btnUI = _jsx(Button, { variant: 'plain', "data-value": value, ...restProps });
    if (hint) {
        btnUI = _jsx(LabelWithHint, { hint: hint, children: btnUI });
    }
    return (_jsx(TabsTrigger, { value: value, disabled: restProps.disabled, asChild: true, children: btnUI }, value));
}
export function Tabs({ children, activeKey, defaultActiveKey, onChange, tabBarExtraContent = {}, tone, size = tabContextDefaults.size, orientation = tabContextDefaults.orientation, stretchTabs, bordered, scrollableTabs = false, ...restProps }) {
    const { triggers, contents } = extractTabsFromChildren(children);
    const [selectedTab, onTabChange] = useControlledState({
        value: activeKey,
        defaultValue: defaultActiveKey ?? getKeyFromTrigger(triggers[0]),
        onChange: onChange
    });
    const tabRef = useRef(null);
    const tabListRef = useRef(null);
    const afterNextRender = useAfterNextRender();
    const scrollTabIntoView = usePersistentCallback(() => {
        const tabListElement = tabListRef.current;
        if (!tabListElement)
            return;
        const selectedTabElement = tabListElement.querySelector(`[data-value='${selectedTab}']`);
        if (!selectedTabElement)
            return;
        afterNextRender(() => {
            if (orientation === 'horizontal') {
                tabListElement.scrollTo({
                    left: selectedTabElement.offsetLeft - tabListElement.offsetLeft
                });
            }
            else {
                tabListElement.scrollTo({
                    top: selectedTabElement.offsetTop - tabListElement.offsetTop
                });
            }
        });
    });
    // if the tab has scroll bar then we need to scroll to the selected tab on mount
    useEffect(() => {
        if (scrollableTabs)
            scrollTabIntoView();
    }, [scrollableTabs, scrollTabIntoView]);
    return (_jsxs(TabsRoot, { ...restProps, dir: 'ltr', ref: tabRef, value: selectedTab, onValueChange: onTabChange, tone: tone, size: size, bordered: bordered, stretchTabs: stretchTabs, activationMode: 'manual', orientation: orientation, children: [_jsxs(StyledTabListContainer, { direction: orientation, className: TAB_LIST_CONTAINER_CLASS_NAME, gap: tokens.TabsTrigger.size[size].padding, alignY: orientation === 'horizontal' ? 'center' : 'stretch', children: [tabBarExtraContent.left, _jsx(StackItem, { grow: '1', shrink: scrollableTabs ? '1' : '0', children: _jsx(TabsList, { ref: tabListRef, children: triggers.map(({ type, props }, index) => {
                                if (type === TriggerType.group) {
                                    const { label, childTriggers } = props;
                                    return (_jsx(TabsTriggerGroup, { label: label, selected: childTriggers.some((trigger) => trigger.props.value === selectedTab), children: childTriggers.map((trigger, index) => (_jsx(TriggerWrap, { ...trigger.props }, index))) }));
                                }
                                const _props = props;
                                return _jsx(TriggerWrap, { ..._props }, index);
                            }) }) }), tabBarExtraContent.right] }), _jsx(StyledTabsContentWrap, { grow: '1', className: TAB_CONTENT_WRAPPER_CLASS_NAME, children: contents.map(({ value, children }) => {
                    const attrPros = { [TabPaneAttrs.key]: value };
                    return (_createElement(TabsContent, { ...attrPros, value: value, key: value },
                        _jsx(StyledBox, { children: children })));
                }) })] }));
}
export function TabPane(props) {
    return null;
}
export function TabPaneGroup(props) {
    return null;
}
Tabs.TabPane = TabPane;
Tabs.TabPaneGroup = TabPaneGroup;
