export enum ConnectionRunStatus {
  Running = 'Running',
  CompletedSuccessfully = 'CompletedSuccessfully'
}
export enum AWSAccessProvider {
  USER_PROVIDED_CREDENTIALS = 'USER_PROVIDED_CREDENTIALS',
  FABRIC_PROVIDED_CREDENTIALS = 'FABRIC_PROVIDED_CREDENTIALS',
  ENVIRONMENT_PROVIDED_CREDENTIALS = 'ENVIRONMENT_PROVIDED_CREDENTIALS',
  ARN_PROVIDED_CREDENTIALS = 'ARN_PROVIDED_CREDENTIALS'
}
export enum ConnectorType {
  DatabricksConnectorType = 'DatabricksConnectorType',
  GlueConnectorType = 'GlueConnectorType'
}
export enum ConnectorStatusTypes {
  PENDING = 'Pending',
  RUNNING = 'Running',
  COMPLETED_SUCCESSFULLY = 'CompletedSuccessfully',
  RETRY_WITH_ERROR = 'RetryWithError',
  FINISHED_WITH_ERROR = 'FinishedWithError'
}
export enum CodeGenStatus {
  InProgress = 'InProgress',
  InQueue = 'InQueue',
  Default = 'Default'
}
export enum WorkflowMode {
  batch = 'batch',
  stream = 'stream'
}
enum CloudProvider {
  AWS = 'AWS',
  Azure = 'Azure',
  GCP = 'GCP'
}
enum MergeStatus {
  Success = 'Success',
  Conflicted = 'Conflicted'
}

export enum ResolutionStrategy {
  LeftBranch = 'LeftBranch',
  RightBranch = 'RightBranch'
}

export enum UserAttributes {
  DefaultAttr = 'DefaultAttr',
  DeploymentAttr = 'DeploymentAttr'
}
export enum RemoteType {
  Local = 'Local',
  Upstream = 'Upstream',
  Origin = 'Origin'
}
export enum Status {
  Pending = 'Pending',
  Running = 'Running',
  Succeeded = 'Succeeded',
  Retrying = 'Retrying',
  Failed = 'Failed'
}
export enum ItemSelectionType {
  AtMostOne = 'AtMostOne',
  ExactlyOne = 'ExactlyOne',
  Many = 'Many'
}
export enum SectionType {
  EntityAndAspects = 'EntityAndAspects',
  Author = 'Author',
  Team = 'Team',
  Project = 'Project',
  Recency = 'Recency'
}
export enum ForkMode {
  Multi = 'Multi',
  Single = 'Single'
}

export enum GitCombinedStorageModel {
  NormalWithForks = 'NormalWithForks',
  Normal = 'Normal',
  Simple = 'Simple'
}

export enum ForkType {
  Master = 'Master',
  User = 'User'
}
export enum SyncMode {
  External = 'External',
  Internal = 'Internal'
}
export enum BuildSystem {
  maven = 'maven',
  wheel = 'wheel',
  sql = 'sql'
}
export enum DeploymentEntityStatus {
  Added = 'Added',
  Refreshed = 'Refreshed',
  Deleted = 'Deleted'
}
export enum DeploymentStatus {
  NotStated = 'NotStated',
  Running = 'Running',
  Succeeded = 'Succeeded',
  Failed = 'Failed'
}
export enum Scheduler {
  MWAA = 'MWAA',
  Astronomer = 'Astronomer',
  Databricks = 'Databricks',
  Composer = 'Composer',
  Prophecy = 'Prophecy',
  OpenSource = 'OpenSource'
}
export enum CommitLogEntityMutationType {
  New = 'New',
  Modified = 'Modified',
  Deleted = 'Deleted',
  Unmodified = 'Unmodified'
}
export enum Role {
  owner = 'owner',
  admin = 'admin',
  member = 'member'
}
export enum TeamKind {
  ProphecyManaged = 'ProphecyManaged',
  ProphecyManagedPersonal = 'ProphecyManagedPersonal',
  IdpOrganizationWide = 'IdpOrganizationWide',
  IdpGroup = 'IdpGroup'
}
export enum SortOrder {
  Ascending = 'Ascending',
  Descending = 'Descending'
}

export enum SortColumn {
  Created = 'Created',
  Modified = 'Modified'
}
export enum AspectKind {
  PipelinesInJob = 'PipelinesInJob',
  DataQualityExpectation = 'DataQualityExpectation',
  Dependency = 'Dependency',
  Version = 'Version',
  Configuration = 'Configuration',
  Storage = 'Storage',
  AirflowConnectionInfo = 'AirflowConnectionInfo',
  TestResult = 'TestResult',
  DagInformation = 'DagInformation',
  JobSize = 'JobSize',
  SparkProviderInfo = 'SparkProviderInfo',
  AirflowProviderInfo = 'AirflowProviderInfo',
  SqlProviderInfo = 'SqlProviderInfo',
  DQProviderInfo = 'DQProviderInfo',
  SqlFabricCredsInfo = 'SqlFabricCredsInfo',
  Code = 'Code',
  TestVisual = 'TestVisual',
  Schema = 'Schema',
  MigrationInfo = 'MigrationInfo',
  ListCommits = 'ListCommits',
  GitStorage = 'GitStorage',
  GitConfig = 'GitConfig',
  Info = 'Info',
  CodeDeps = 'CodeDeps',
  LineageBranch = 'LineageBranch',
  Lineage = 'Lineage',
  ProjectInitialConfig = 'ProjectInitialConfig',
  LineageRelease = 'LineageRelease',
  LineageCommit = 'LineageCommit',
  ClusterInfo = 'ClusterInfo',
  UserAllFabricInfo = 'UserAllFabricInfo',
  OnboardingInfo = 'OnboardingInfo',
  JobDeploymentAirflowInfo = 'JobDeploymentAirflowInfo',
  ExpressionsInfo = 'ExpressionsInfo',
  DatabricksDeployment = 'DatabricksDeployment',
  RunInfo = 'RunInfo',
  InputInfo = 'InputInfo',
  OutputInfo = 'OutputInfo',
  Snapshot = 'Snapshot',
  DatasetDetails = 'DatasetDetails',
  Interim = 'Interim',
  PartnerInfo = 'PartnerInfo',
  ResolutionModeInfo = 'ResolutionModeInfo',
  SQLInfo = 'SQLInfo',
  AdminInfo = 'AdminInfo',
  NonVersionedCodeBranch = 'NonVersionedCodeBranch',
  NonVersionedCodeCommit = 'NonVersionedCodeCommit',
  FabricServicePrincipalInfo = 'FabricServicePrincipalInfo',
  OIDCInfo = 'OIDCInfo',
  JobFilesSHA = 'JobFilesSHA',
  AirflowConnections = 'AirflowConnections',
  DeploymentState = 'DeploymentState',
  DeploymentSettings = 'DeploymentSettings',
  FabricServicePrincipalState = 'FabricServicePrincipalState',
  OrchestrationProviderInfo = 'OrchestrationProviderInfo'
}

export enum ExternalGitProviderType {
  Github = 'Github',
  GithubEnterprise = 'GithubEnterprise',
  AzureDevOps = 'AzureDevOps',
  BitBucket = 'BitBucket',
  GitLab = 'GitLab',
  GitLabEnterprise = 'GitLabEnterprise',
  Other = 'Other',
  ProphecyManaged = 'ProphecyManaged'
}
export enum SecretsProviderType {
  Databricks = 'Databricks',
  HashiCorp = 'HashiCorp',
  Environment = 'Environment'
}

export enum EntityKind {
  Deployment = 'Deployment',
  EntityDeployment = 'EntityDeployment',
  Repository = 'Repository',
  Team = 'Team',
  Dataset = 'Dataset',
  Schedule = 'Schedule',
  Job = 'Job',
  PhysicalDataset = 'PhysicalDataset',
  Workflow = 'Workflow',
  Pipeline = 'Pipeline',
  Project = 'Project',
  Subgraph = 'Subgraph',
  SQLModel = 'SQLModel',
  SQLMacro = 'SQLMacro',
  SQLSeed = 'SQLSeed',
  SQLSource = 'SQLSource',
  SQLTable = 'SQLTable',
  SQLUnreferencedSource = 'SQLUnreferencedSource',
  SQLOrchestratorPipeline = 'SQLOrchestratorPipeline',
  Configuration = 'Configuration',
  PipelineConfigurations = 'PipelineConfigurations',
  User = 'User',
  Fabric = 'Fabric',
  Functions = 'Functions',
  Gem = 'Gem',
  UDF = 'UDF',
  UDAF = 'UDAF',
  Expression = 'Expression',
  AirflowFunction = 'AirflowFunction',
  Dependency = 'Dependency',
  ProjectRelease = 'ProjectRelease',
  JobRelease = 'JobRelease',
  TranspilerImport = 'TranspilerImport',
  Template = 'Template',
  BulkTranspilerImport = 'BulkTranspilerImport',
  Connector = 'Connector',
  ConnectorRunHistory = 'ConnectorRunHistory',
  InteractiveSparkSession = 'InteractiveSparkSession',
  Secrets = 'Secrets'
}
export enum BackEndCodeLanguage {
  scala = 'scala',
  python = 'python',
  sql = 'sql'
}

export enum VersionControlMode {
  Simple = 'Simple',
  Advanced = 'Advanced'
}
export type ConnectionRunStatusType = `${ConnectionRunStatus}`;
export type AWSAccessProviderType = `${AWSAccessProvider}`;
export type ConnectorTypeType = `${ConnectorType}`;
export type ConnectorStatusTypesType = `${ConnectorStatusTypes}`;
export type CodeGenStatusType = `${CodeGenStatus}`;
export type WorkflowModeType = `${WorkflowMode}`;
export type ItemSelectionTypeType = `${ItemSelectionType}`;
export type CloudProviderType = `${CloudProvider}`;
export type MergeStatusType = `${MergeStatus}`;
export type ResolutionStrategyType = `${ResolutionStrategy}`;
export type UserAttributesType = `${UserAttributes}`;
export type RemoteTypeType = `${RemoteType}`;
export type StatusType = `${Status}`;
export type SectionTypeType = `${SectionType}`;
export type ForkModeType = `${ForkMode}`;
export type GitCombinedStorageModelType = `${GitCombinedStorageModel}`;
export type ForkTypeType = `${ForkType}`;
export type SyncModeType = `${SyncMode}`;
export type BuildSystemType = `${BuildSystem}`;
export type DeploymentEntityStatusType = `${DeploymentEntityStatus}`;
export type DeploymentStatusType = `${DeploymentStatus}`;
export type SchedulerType = `${Scheduler}`;
export type CommitLogEntityMutationTypeType = `${CommitLogEntityMutationType}`;
export type RoleType = `${Role}`;
export type TeamKindType = `${TeamKind}`;
export type SortOrderType = `${SortOrder}`;
export type SortColumnType = `${SortColumn}`;
export type AspectKindType = `${AspectKind}`;
export type ExternalGitProviderTypeType = `${ExternalGitProviderType}`;
export type SecretsProviderTypeType = `${SecretsProviderType}`;
export type EntityKindType = `${EntityKind}`;
export type BackEndCodeLanguageType = `${BackEndCodeLanguage}`;
export type VersionControlModeType = `${VersionControlMode}` | undefined;
