import { jsx as _jsx } from "react/jsx-runtime";
import { useLatestRef } from '@prophecy/utils/react/hooks';
import { useEffect } from 'react';
import { Controller as ReactController, useController as useReactController } from 'react-hook-form';
import { WithHelpText } from './utils';
const getRenderProps = (fieldProps, props) => {
    const { field, fieldState } = fieldProps;
    const updatedField = {
        ...field,
        variant: fieldState.error ? 'error' : 'primary',
        helpText: fieldState.error?.message,
        required: Boolean(props.rules?.required),
        tooltip: props.tooltip,
        onChange: (value) => {
            return field.onChange(value === undefined ? null : value);
        }
    };
    if (props.disabled)
        updatedField.disabled = props.disabled;
    return { ...fieldProps, field: updatedField };
};
export const useController = (props) => {
    return getRenderProps(useReactController(props), props);
};
export const Controller = (props) => {
    const { render, helpText, unregisterOnUnmount, unregisterState = {} } = props;
    let getContent = (fieldProps) => {
        let content = render(getRenderProps(fieldProps, props));
        if (helpText) {
            content = (_jsx(WithHelpText, { helpText: helpText, variant: 'primary', children: content }));
        }
        return content;
    };
    const unregisterStateRef = useLatestRef(unregisterState);
    useEffect(() => () => {
        if (unregisterOnUnmount) {
            props.control?.unregister(props.name, {
                keepValue: true,
                keepDefaultValue: true,
                keepDirty: true,
                keepTouched: true,
                ...unregisterStateRef.current
            });
        }
    }, [props.control, props.name, unregisterOnUnmount, unregisterStateRef]);
    return _jsx(ReactController, { ...props, render: getContent });
};
