import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useComposedRef, useEditorId, useEditorRef, useEventEditorSelectors, createPlatePlugin } from '@udecode/plate-common/react';
import { flip, offset, useFloatingToolbar, useFloatingToolbarState } from '@udecode/plate-floating';
import { forwardRef } from 'react';
import { theme } from '../theme';
import { useMarkdownProps } from './context';
import { LinkButton } from './LinkPlugin';
import { StyledFloatingToolbar } from './styled';
import { TextTypeSelect } from './TextTypeSelect';
import { BoldButton, CodeBlockButton, ItalicButton, UnderlineButton } from './ToolbarComponents';
import { PluginType } from './types';
const FloatingToolbar = forwardRef(({ state }, componentRef) => {
    const { supportedPluginTypes } = useMarkdownProps();
    const editor = useEditorRef();
    const editorId = useEditorId();
    const focusedEditorId = useEventEditorSelectors.focus();
    const isFloatingLinkOpen = !!editor.useOption({ key: 'a' }, 'mode');
    const isAIChatOpen = editor.useOption({ key: 'aiChat' }, 'open');
    const floatingToolbarState = useFloatingToolbarState({
        editorId,
        focusedEditorId,
        hideToolbar: isFloatingLinkOpen || isAIChatOpen,
        ...state,
        floatingOptions: {
            middleware: [
                offset(12),
                flip({
                    fallbackPlacements: ['top-start', 'top-end', 'bottom-start', 'bottom-end'],
                    padding: 12
                })
            ],
            placement: 'top',
            ...state?.floatingOptions
        }
    });
    const { clickOutsideRef, hidden, props: rootProps, ref: floatingRef } = useFloatingToolbar(floatingToolbarState);
    const ref = useComposedRef(componentRef, floatingRef);
    if (hidden)
        return null;
    return (_jsx("div", { ref: clickOutsideRef, children: _jsxs(StyledFloatingToolbar, { direction: 'horizontal', gap: theme.spaces.x8, ref: ref, ...rootProps, alignY: 'center', children: [supportedPluginTypes.has(PluginType.TextType) && _jsx(TextTypeSelect, {}), supportedPluginTypes.has(PluginType.Bold) && _jsx(BoldButton, {}), supportedPluginTypes.has(PluginType.Italic) && _jsx(ItalicButton, {}), supportedPluginTypes.has(PluginType.Underline) && _jsx(UnderlineButton, {}), supportedPluginTypes.has(PluginType.Link) && _jsx(LinkButton, {}), supportedPluginTypes.has(PluginType.Code) && _jsx(CodeBlockButton, {})] }) }));
});
export const FloatingToolbarPlugin = createPlatePlugin({
    key: 'floating-toolbar',
    render: {
        afterEditable: () => _jsx(FloatingToolbar, {})
    }
});
