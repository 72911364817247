import { jsx as _jsx } from "react/jsx-runtime";
import { Hint } from '../Misc/Hint';
const levelToIconSizeMap = {
    //Todo: will be replaced with new tokens
    xs: 'xxs',
    sm13: 'xxs',
    sm: 'xs',
    xl: 'xs'
};
export const useHint = ({ level = 'sm', tone, hint }) => {
    let _hint;
    if (typeof hint === 'string') {
        _hint = { title: hint, overlayStyle: { maxWidth: '350px', maxHeight: '300px', overflow: 'auto' } };
    }
    else {
        _hint = hint;
    }
    return _hint ? _jsx(Hint, { ..._hint, color: tone, size: levelToIconSizeMap[level] }) : null;
};
