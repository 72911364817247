import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFocusHandleOnClose, useFocusTrap } from '@prophecy/utils/react/focus';
import { useControlledState } from '@prophecy/utils/react/hooks';
import { Arrow, Portal, Root, Trigger } from '@radix-ui/react-popover';
import { resolveCssVariable, theme } from '../theme';
import { PopoverContent } from './styled';
export const POPOVER_CONTENT_WRAPPER = 'data-radix-popper-content-wrapper';
export function Popover({ overlay, visible, onVisibleChange, children, overlayClassName, overlayStyle, arrow, placement: side = 'bottom', placementOffset: sideOffset = parseInt(resolveCssVariable(theme.spaces.x8), 10), align, alignOffset, onOpenAutoFocus, onCloseAutoFocus, portalled = true, avoidCollisions, hideWhenDetached, onInteractOutside, onPointerDownOutside }) {
    const [_visible, toggleVisible] = useControlledState({
        value: visible,
        onChange: onVisibleChange
    });
    const container = useFocusTrap(Boolean(_visible));
    const defaultOnCloseAutoFocus = useFocusHandleOnClose(Boolean(_visible));
    const content = (_jsx(PopoverContent, { asChild: true, className: overlayClassName, style: overlayStyle, side: side, align: align, sideOffset: sideOffset, alignOffset: alignOffset, ref: container, tabIndex: -1, onOpenAutoFocus: onOpenAutoFocus, hideWhenDetached: hideWhenDetached, onCloseAutoFocus: onCloseAutoFocus ?? defaultOnCloseAutoFocus, onInteractOutside: onInteractOutside, onPointerDownOutside: onPointerDownOutside, avoidCollisions: avoidCollisions, children: _jsxs("div", { children: [arrow && _jsx(Arrow, {}), overlay] }) }));
    return (_jsxs(Root, { open: _visible, onOpenChange: toggleVisible, children: [_jsx(Trigger, { asChild: true, children: children }), portalled ? _jsx(Portal, { children: content }) : content] }));
}
