import type {
  InkeepAIChatSettings,
  InkeepSearchSettings,
  InkeepBaseSettings,
  InkeepModalSettings
} from '@inkeep/cxkit-react';
import { theme } from '@prophecy/ui';

import { useUser } from '../context/user';
import { INKEEP_API_KEY } from './secrets';

type InkeepSharedSettings = {
  baseSettings: InkeepBaseSettings;
  aiChatSettings: InkeepAIChatSettings;
  searchSettings: InkeepSearchSettings;
  modalSettings: InkeepModalSettings;
};

const useInkeepSettings = (userAvatar?: string): InkeepSharedSettings => {
  const {
    user: { email, id, firstName, lastName }
  } = useUser();

  const baseSettings: InkeepBaseSettings = {
    apiKey: INKEEP_API_KEY,
    // integrationId: INKEEP_INTEGRATION_ID,
    // organizationId: INKEEP_ORGANIZATION_ID,
    primaryBrandColor: theme.colors.primary600,
    organizationDisplayName: 'Prophecy.io',
    env: 'production',

    userProperties: {
      id,
      email,
      name: firstName + lastName
    }
    // ...optional settings
  };

  const modalSettings: InkeepModalSettings = {
    // optional settings
  };

  const searchSettings: InkeepSearchSettings = {
    // optional settings
  };

  const aiChatSettings: InkeepAIChatSettings = {
    // optional settings
    chatSubjectName: 'Prophecy',
    aiAssistantAvatar: 'https://docs.prophecy.io/img/favicon2.png',
    shouldOpenLinksInNewTab: true,
    userAvatar: userAvatar,
    getHelpOptions: [
      {
        name: 'Help',
        action: {
          type: 'open_link',
          url: 'https://docs.prophecy.io/getting-help'
        },
        icon: {
          builtIn: 'IoHelpBuoyOutline'
        }
      },
      {
        name: 'Slack',
        action: {
          type: 'open_link',
          url: 'https://prophecy-io-support.slack.com'
        },
        icon: {
          builtIn: 'FaSlack'
        }
      },
      {
        name: 'Customer Support Portal',
        action: {
          type: 'open_link',
          url: 'https://prophecy.zendesk.com'
        },
        icon: {
          builtIn: 'IoHelpBuoyOutline'
        }
      }
    ],
    exampleQuestions: [
      'How to connect Prophecy and Snowflake?',
      'Which datasets are accessible to Prophecy Data Copilot?',
      'How to monitor pipelines?',
      'How to create a Kafka Source gem?'
    ]
  };

  return { baseSettings, aiChatSettings, searchSettings, modalSettings };
};

export default useInkeepSettings;
