import { Breadcrumb, Button, ButtonProps, Stack, Text, theme } from '@prophecy/ui';
import { CrumbTypes } from '@prophecy/ui/Breadcrumb/types';
import { EntityIcon, EntityIconMap } from 'frontend/core/src/common/Entity/EntityIcons';
import styled from 'styled-components';

type Crumbs = {
  category: string;
  label: string;
  url?: string;
}[];

const StyledBreadcrumbSeparator = styled(Breadcrumb.Icon)`
  color: ${theme.colors.grey400};
  height: ${theme.sizes.x16};
  width: ${theme.sizes.x16};
`;

const Root = styled(Stack)`
  background: ${theme.colors.grey50};
  border: 1px solid ${theme.colors.grey200};
`;

const StyleButton = styled(Button)`
  display: inline-flex;
  & > span {
    display: inline-flex;
    height: ${theme.spaces.x32};
  }
` as typeof Button;

const StyleIconContainer = styled(Stack)`
  flex-shrink: 0;
  width: ${theme.spaces.x32};
  height: ${theme.spaces.x32};
  border-radius: ${theme.radius.xs};
  background: ${theme.colors.blueish_grey100};
`;

const ProjectBreadcrumbSeparator = styled(Breadcrumb.Icon)`
  color: ${theme.colors.grey400};
  height: ${theme.sizes.x12};
  width: ${theme.sizes.x12};
  margin: 0;
`;

export type PageHeaderProps = {
  crumbs: Crumbs;
  children?: React.ReactNode;
  showCategroy?: boolean;
  className?: string;
};

export function PageHeader({ crumbs, children, showCategroy = true, className }: PageHeaderProps) {
  const crumbsLength = crumbs.length;
  return (
    <Root
      padding={`${theme.spaces.x6} ${theme.spaces.x12}`}
      gap={theme.spaces.x2}
      align='center'
      width='100%'
      className={className}>
      <Stack direction='horizontal' alignY='center' align='space-between'>
        <Stack direction='horizontal' alignY={showCategroy ? 'end' : 'center'} gap={theme.spaces.x12}>
          {crumbs.map(({ category, label, url }, index) => {
            return (
              <Button key={url} variant='plain' elementType={url ? 'link' : 'button'} to={url as string}>
                <Stack direction='horizontal' alignY='center' gap={theme.spaces.x6} key={index}>
                  <EntityIcon size='s' entity={category as keyof typeof EntityIconMap} />
                  <Stack align='space-between'>
                    {showCategroy && (
                      <Text level='xxs' tone={theme.colors.grey500}>
                        {category}
                      </Text>
                    )}
                    <Text level='xs' weight={theme.fontWeight.medium} tone={theme.colors.grey700}>
                      {label}
                    </Text>
                  </Stack>
                  {index < crumbsLength - 1 && <StyledBreadcrumbSeparator />}
                </Stack>
              </Button>
            );
          })}
        </Stack>
        {children}
      </Stack>
    </Root>
  );
}

export function ProjectHeader<T extends CrumbTypes>({
  crumbs,
  children
}: {
  crumbs: ButtonProps<T>[];
  children?: React.ReactNode;
}) {
  const crumbsLength = crumbs.length;
  return (
    <>
      <Stack direction='horizontal' alignY='center' gap={theme.spaces.x8}>
        {crumbs.map(({ children, icon, ...rest }, index) => {
          const isLast = index < crumbsLength - 1;
          const color = isLast ? theme.colors.grey700 : theme.colors.grey500;

          return (
            <>
              {/* @ts-ignore FIXME: fix type of crumbs */}
              <StyleButton variant='plain' {...rest}>
                <Stack direction='horizontal' alignY='center' gap={theme.spaces.x8} key={index}>
                  {icon ? (
                    <StyleIconContainer padding={theme.spaces.x4} direction='horizontal' alignY='center' align='center'>
                      {icon}
                    </StyleIconContainer>
                  ) : null}

                  <Text level='xs' weight={theme.fontWeight.medium} tone={color}>
                    {children}
                  </Text>
                </Stack>
              </StyleButton>
              {isLast && <ProjectBreadcrumbSeparator />}
            </>
          );
        })}
      </Stack>
      {children}
    </>
  );
}
