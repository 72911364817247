import { Indicator, Root } from '@radix-ui/react-progress';
import styled from 'styled-components';
import { theme } from '../theme';
import { tokens } from './tokens';
export const StyledProgressBarContainer = styled(Root) `
  position: relative;
  z-index: ${theme.zLayer.l};
  overflow: hidden;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${tokens.ProgressBar.size.borderRadius};
  background: ${tokens.ProgressBar.Content.backgroundColor};
`;
export const StyledIndicator = styled(Indicator) `
  background: ${({ variant, $color }) => $color || tokens.ProgressBar[variant].backgroundColor};
  height: 100%;
  transition: ${tokens.ProgressBar.Content.transition};
`;
