export var Variant;
(function (Variant) {
    Variant["gray"] = "gray";
    Variant["singleColor"] = "singleColor";
    Variant["dualColor"] = "dualColor";
    Variant["boxLight"] = "boxLight";
    Variant["boxDark"] = "boxDark";
})(Variant || (Variant = {}));
export var Size;
(function (Size) {
    Size["xs"] = "xs";
    Size["s"] = "s";
    Size["m"] = "m";
    Size["l"] = "l";
    Size["xl"] = "xl";
    Size["xxl"] = "xxl";
    Size["xxxl"] = "xxxl";
})(Size || (Size = {}));
