import { DialogComponentNames } from '@prophecy/ui/Dialog/tokens';
import { noop } from 'lodash-es';

import {
  COPILOT_BUTTON_LABEL_ATTR,
  DATA_DRAWER_BUTTON_LABEL_ATTR,
  DIALOG_OVERLAY_COMPONENT_NAME_ATTR,
  EXECUTION_TOAST_ATTR,
  FX_BUTTON_LABEL_ATTR
} from '../constants';
import { DataDrawerOverlayAttr } from '../DataExplorerDrawer/constants';
import { INTERIM_DIALOG_TITLE_ATTR } from '../interims/constants';
import { Selector } from './dom-util';

export const PIPELINE_FLOW_ID = `PIPELINE_FLOW_ID`;
export const NEW_PIPELINE_FLOW_ID = `NEW_PIPELINE_FLOW_ID`;
export const SQL_PIPELINE_FLOW_ID = `SQL_PIPELINE_FLOW_ID`;
export const SQL_COPILOT_FLOW_ID = `SQL_COPILOT_FLOW_ID`;
export const InputStyles: React.CSSProperties = {
  transform: `translateX(-40px)`
};
export const TableInputStyles: React.CSSProperties = {
  transform: `translateX(-30px)`
};
export const TreeNodeColumnStyles: React.CSSProperties = {
  transform: `translateX(-30px)`
};
export const onboardingGraphProps = {
  selectionKeyCode: '-1',
  nodesDraggable: false,
  deleteKeyCode: [],
  onPaste: noop,
  onCut: noop,
  hideLayout: true
};
export const Layers = {
  canvas: 100,
  dialog: 1001,
  dialogInDialog: 100001
};

export const Connecting = 'connecting';
export const Connected = 'connected';
export const ErrorStatus = 'error';
export const Detached = 'detached';
export const AttachClusterButtonSelector = 'div[type="button"][aria-label="attach-cluster"]';
export const ConnectionSuccessSelector = `${AttachClusterButtonSelector} svg[data-cluster-status="${Connected}"]`;
export const ConnectionLoadingSelector = `${AttachClusterButtonSelector} svg[data-cluster-status="${Connecting}"]`;
export const ConnectionErrorSelector = `${AttachClusterButtonSelector} svg[data-cluster-status="${ErrorStatus}"]`;
export const ConnectionDetachedSelector = `${AttachClusterButtonSelector} svg[data-cluster-status="${Detached}"]`;
export const DropDownOverlaySelector = '[data-label="attach-cluster-overlay"]';
export const CREATE_NEW_CLUSTER_TEXT = 'Create New Cluster';
export const CreateNewClusterDialogTitleSelector: Selector = [
  `span[data-component="${DialogComponentNames.title}"]`,
  CREATE_NEW_CLUSTER_TEXT
];
export const ClusterRestart_TEXT = `Cluster will be restarted`;
export const ClusterRestartRequired_TEXT = `Cluster Restart is required`;
export const MissingDependencies_TEXT = 'Few missing dependencies';
export const ClusterRestartDialogSelector: Selector = [
  `div[data-component="${DialogComponentNames.content}"] h2`,
  ClusterRestart_TEXT
];
export const ClusterRestartRequiredDialogSelector: Selector = [
  `div[data-component="${DialogComponentNames.content}"] h2`,
  ClusterRestartRequired_TEXT
];
export const MissingDependenciesDialogSelector: Selector = [
  `div[data-component="${DialogComponentNames.content}"] h2`,
  MissingDependencies_TEXT
];
export const ClusterLogsSectionSelector = `[aria-label="cluster information"]`;
export const ARROW_XY_ATTACH_CLUSTER_CANVAS: React.CSSProperties = {
  zIndex: Layers.canvas,
  transform: 'translateX(-12px)'
};
export const ARROW_XY_ATTACH_CLUSTER_DIALOG: React.CSSProperties = {
  zIndex: Layers.canvas
};
export const CLUSTER_DETACHED = 'CLUSTER_DETACHED';
export const InterimDialogTitleSelector = `[${INTERIM_DIALOG_TITLE_ATTR.name}="${INTERIM_DIALOG_TITLE_ATTR.value}"]`;
export const ExecutionToastSelector = `[${EXECUTION_TOAST_ATTR.name}="${EXECUTION_TOAST_ATTR.value}"]`;
export const CloseIconSelector = `[data-icon-name="XClose"]`;
export const InterimIconSelector = `button[data-icon="chart"]`;
export const GhostInterimIconSelector = `[data-handlepos="right"] button[data-icon="chart"]`;

export const ExpressionBuilderOverlaySelector = `[${DIALOG_OVERLAY_COMPONENT_NAME_ATTR.name}="${DIALOG_OVERLAY_COMPONENT_NAME_ATTR.value}"]`;
export const CopilotButtonSelector = `[type="button"][${COPILOT_BUTTON_LABEL_ATTR.name}="${COPILOT_BUTTON_LABEL_ATTR.value}"]`;
export const ExpressionBoxFakeActionSelector = `[data-component="fake-action-container"]`;
export const PortAddAllColumnsSelector = `[data-component="port-add-all-columns"]`;

export const FXButtonSelector = `[type="button"][${FX_BUTTON_LABEL_ATTR.name}="${FX_BUTTON_LABEL_ATTR.value}"]`;
export const DataDrawerButtonSelector = `button:not([disabled])[${DATA_DRAWER_BUTTON_LABEL_ATTR.name}="${DATA_DRAWER_BUTTON_LABEL_ATTR.value}"]`;
export const DataDrawerOverlaySelector = `[${DataDrawerOverlayAttr.name}="${DataDrawerOverlayAttr.value}"]`;

export const EB_TARGET_COLUMN_EDIT_BTN_SELECTOR = `svg[data-icon-name="EditB"]`;
export const EB_TARGET_COLUMN_INPUT_SELECTOR = `input[placeholder="target column"]`;
export const EB_COPILOT_SUGGESTION_SELECTOR = `[data-component="SuggestionCard"]`;
export const EB_COPILOT_ENTER_BTN_SELECTOR = `svg[data-icon-name="CmdEnter"]`;
export const EB_COPILOT_INPUT_SELECTOR = `input[placeholder="What would you like to do?"]`;
export const EB_COPILOT_PANEL_TITLE_SELECTOR: Selector = ['span', 'Data Copilot'];
export const EB_EXIT_BTN_SELECTOR: Selector = [`button`, `Save`];
