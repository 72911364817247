import styled from 'styled-components';
import { Stack } from '../Layout';
import { getInterFontWeightCSS, theme } from '../theme';
import { tokens } from './tokens';
export const TextAreaRoot = styled(Stack) `
  font-family: ${theme.fontFamily.text};
  gap: ${tokens.Root.gap};
`;
export const StyledTextAreaContainer = styled.div `
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  background-color: ${({ variant }) => tokens.Input[variant].backgroundColor};
  border-radius: ${tokens.Input.size.m.borderRadius};
  font-size: ${tokens.Input.size.m.fontSize};
  ${getInterFontWeightCSS(tokens.Input.size.m.fontWeight)};
  line-height: ${tokens.Input.size.m.lineHeight};
  box-shadow: ${tokens.Input.boxShadow};
  border: 1px solid ${({ variant }) => tokens.Input[variant].borderColor};

  &:hover {
    color: ${({ variant }) => tokens.Input[variant].hover.color};
  }

  &:focus-within {
    outline: 1px solid ${({ variant }) => tokens.Input[variant].focus.borderColor};
    border: 1px solid ${({ variant }) => tokens.Input[variant].focus.borderColor};
    outline-offset: -2px;
  }

  &[disabled] {
    color: ${tokens.Input.disabled.color};
    background-color: ${tokens.Input.disabled.backgroundColor};
    border-color: ${tokens.Input.disabled.borderColor};
    cursor: not-allowed;
  }
`;
export const StyledTextArea = styled.textarea `
  font-family: ${theme.fontFamily.text};
  width: 100%;
  height: 100%;
  min-height: ${tokens.Input.size.m.height};
  border: none;
  background: transparent;
  font-size: ${tokens.Input.size.m.fontSize};
  line-height: ${tokens.Input.size.m.lineHeight};
  padding: ${tokens.Input.size.m.padding};
  outline: none;
  color: ${tokens.Input.color};
  resize: ${({ resize }) => resize};
`;
