import styled from 'styled-components';
import { getInterFontWeightCSS, theme } from '../theme';
import { tokens } from './tokens';
export const Root = styled.div `
  background: ${tokens.background};
  color: ${tokens.text.color};
  ${getInterFontWeightCSS(tokens.text.weight)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  ${({ size }) => `
  height: ${tokens.sizes[size].dimension};
  width: ${tokens.sizes[size].dimension};
  `}
  ${({ withBorder }) => withBorder
    ? `
  border: 1px solid ${theme.colors.gray300};
  `
    : ''}
`;
export const Text = styled.span `
  ${({ size }) => `
  font-size: ${tokens.sizes[size].text.fontSize};
  line-height: ${tokens.sizes[size].text.lineHeight};
  `}
`;
export const Image = styled.img `
  object-fit: cover;
`;
