export enum actionTypes {
  updateFabric = 'updateFabric',

  loadDataExplorerInterimData = 'loadDataExplorerInterimData',
  loadInterimData = 'loadInterimData',
  loadMoreInterimData = 'loadMoreInterimData',
  appendInterimData = 'appendInterimData',
  appendDataProfile = 'appendDataProfile',
  updateLazyInterims = 'updateLazyInterims',
  markInterimComplete = 'markInterimComplete',
  updateIsDataExplorerRun = 'updateIsDataExplorerRun',
  clearInterimData = 'clearInterimData',
  updateAppliedFilterAndSort = 'updateAppliedFilterAndSort',
  resetInterim = 'resetInterim',
  updateInterimRunId = 'updateInterimRunIds',
  initialInterimRunIds = 'initialInterimRunIds',
  loadHistoricInterimData = 'loadHistoricInterimData',
  staleInterimData = 'staleInterimData',
  staleDownStreamData = 'staleDownStreamData',
  updateCanvasOptions = 'updateCanvasOptions',
  compileProgress = 'compile/progress',

  // UT update
  componentTestExecutionResultNotification = 'componentTestExecutionResultNotification',
  executeComponentTestStarted = 'executeComponentTestStarted',

  // reusable subgraph
  subgraphsDidOpen = 'subgraph/didOpen',
  subgraphsDidUpdate = 'subgraph/didUpdate',

  // action for clearing state before initializing
  clearStateBeforeInit = 'clearStateBeforeInit',

  // Datasets list
  DatasetsResult = 'DatasetsResult',
  SetDatasetColumns = 'SetDatasetColumns',

  // Component progress
  updateComponentProgress = 'updateComponentProgress',
  resetComponentsProgress = 'resetComponentsProgress',

  // Data Diff
  updateDataDiff = 'updateDataDiff',
  updateDataDiffSample = 'updateDataDiffSample'
}
