import { jsx as _jsx } from "react/jsx-runtime";
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { uniqueId } from 'lodash-es';
import React, { createContext, useContext, forwardRef, useLayoutEffect, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { Button } from './Button';
import { tokens } from './tokens';
import { ButtonVariants, ButtonSize } from './types';
export const ButtonGroupContext = createContext({ size: ButtonSize.m, register: () => () => undefined, groupItems: [] });
const StyledButtonGroup = styled.div `
  display: inline-flex;
  overflow: hidden;
  box-shadow: ${tokens.ButtonGroupItem.secondaryGray.boxShadow};
  ${(props) => `
        border-radius: ${tokens.Button.size[props.size].borderRadius};
      `}
  &[disabled] {
    box-shadow: none;
    border-color: ${tokens.Button.default.secondaryGray.disabled.borderColor};
  }
`;
const StyledButtonGroupItem = styled(Button) `
  border-radius: 0;
  position: relative;

  ${(props) => props.alternate &&
    `
    background: ${tokens.Button.default.secondaryGray.backgroundColor};
    color: ${tokens.ButtonGroupItem.color};
  `}

  &&,
  &&:hover,
  &&:focus,
  &&:active,
  &&:hover:focus {
    box-shadow: none;
    border: ${(props) => props.alternate || props.variant === ButtonVariants.secondaryGray
    ? `1px solid ${props.disabled ? tokens.Button.default.secondaryGray.disabled.borderColor : tokens.Button.default.secondaryGray.borderColor};`
    : 'none'};

    ${(props) => !props.isFirstItem && `border-left: none;`}

    ${(props) => props.isFirstItem &&
    `
        border-top-left-radius: ${tokens.Button.size[props.size].borderRadius};
        border-bottom-left-radius: ${tokens.Button.size[props.size].borderRadius};
    `}

    ${(props) => props.variant === ButtonVariants.primary &&
    !props.disabled &&
    `
      &:hover {
        background: ${props.danger ? tokens.ButtonGroupItem.danger.primary.hover.backgroundColor : tokens.ButtonGroupItem.default.primary.hover.backgroundColor};
      }
    `}

    ${({ disabled, danger, variant }) => disabled &&
    variant === ButtonVariants.primary &&
    `
      border: none;
      color: ${tokens.ButtonGroupItem.default.primary.disabled.color};
      background: ${tokens.ButtonGroupItem[danger ? 'danger' : 'default'].primary.disabled.backgroundColor};
    `}

    ${(props) => props.isLastItem &&
    `
        border-top-right-radius: ${tokens.Button.size[props.size].borderRadius};
        border-bottom-right-radius: ${tokens.Button.size[props.size].borderRadius};
      `}
  }

  &:disabled {
    * {
      opacity: 0.7;
    }
    box-shadow: none;
  }

  ${(props) => props.variant === ButtonVariants.secondaryGray &&
    `
      &:active {
        background: ${tokens.ButtonGroupItem.secondaryGray.focused.backgroundColor};
      }
    `}
`;
const _ButtonGroup = forwardRef(function ButtonGroup({ children, disabled, size = ButtonSize.m, variant = ButtonVariants.secondaryGray, ...restProps }, forwardedRef) {
    const [groupItems, setGroupItems] = useImmer([]);
    const [buttonCount, setButtonCount] = useState(0);
    useEffect(() => {
        let count = 0;
        React.Children.forEach(children, (child) => {
            if (React.isValidElement(child) && child.type === ButtonGroupItem)
                count++;
        });
        setButtonCount(count);
    }, [children]);
    useEffect(() => {
        setGroupItems([]);
    }, [buttonCount, setGroupItems]);
    const register = usePersistentCallback((buttonKey) => {
        setGroupItems((items) => {
            // if an item key is getting registered again, it means a new item is introduced,
            // in such case reset current items
            if (items.includes(buttonKey))
                return [buttonKey];
            return [...items, buttonKey];
        });
        return () => {
            setGroupItems((items) => {
                items.splice(items.indexOf(buttonKey), 1);
            });
        };
    });
    return (_jsx(ButtonGroupContext.Provider, { value: { size, disabled, register, groupItems }, children: _jsx(StyledButtonGroup, { size: size, disabled: !!disabled, variant: variant, ...restProps, ref: forwardedRef, children: children }) }));
});
export const ButtonGroupItem = forwardRef(({ variant = ButtonVariants.secondaryGray, danger = false, alternate, ...restProps }, forwardedRef) => {
    const { size, disabled, register, groupItems } = useContext(ButtonGroupContext);
    const itemKey = useMemo(() => uniqueId('buttonItem'), []);
    useLayoutEffect(() => {
        return register(itemKey);
    }, [itemKey, groupItems.length, register]);
    const isFirstItem = groupItems[0] === itemKey;
    const isLastItem = groupItems[groupItems.length - 1] === itemKey;
    return (_jsx(StyledButtonGroupItem, { size: size, variant: variant, alternate: alternate, disabled: disabled, isFirstItem: isFirstItem, isLastItem: isLastItem, isGroupItem: true, ...restProps, ref: forwardedRef }));
});
// Ts cry on compound pattern with forward refs
export const ButtonGroup = Object.assign(_ButtonGroup, { Item: ButtonGroupItem });
