import { ReactComponent as AirflowOpenSourceIcon } from '@prophecy/ui/assets/images/airflow-opensource.svg';
import { ReactComponent as BigQueryIcon } from '@prophecy/ui/assets/images/bigquery.svg';
import { ReactComponent as CloudComposerIcon } from '@prophecy/ui/assets/images/cloud_composer.svg';
import { ReactComponent as DatabricksIcon } from '@prophecy/ui/assets/images/databricks_outline.svg';
import { ReactComponent as DataprocIcon } from '@prophecy/ui/assets/images/dataproc.svg';
import { ReactComponent as EMRIcon } from '@prophecy/ui/assets/images/emr.svg';
import { ReactComponent as LivyIcon } from '@prophecy/ui/assets/images/livy.svg';
import { ReactComponent as ProphecyIcon } from '@prophecy/ui/assets/images/logo.svg';
import { ReactComponent as MWAAIcon } from '@prophecy/ui/assets/images/mwaa.svg';
import { ReactComponent as RedshiftIcon } from '@prophecy/ui/assets/images/redshift.svg';
import { ReactComponent as SnowflakeIcon } from '@prophecy/ui/assets/images/snowflake.svg';
import { ReactComponent as SynapseIcon } from '@prophecy/ui/assets/images/synapse.svg';
import { ReactComponent as TrinoIcon } from '@prophecy/ui/assets/images/trino.svg';
import { mapValues } from 'lodash-es';
import React, { ReactNode } from 'react';

import { AIRFLOW_PROVIDER } from '../../constants/airflow';
import { SQL_PROVIDER } from '../../constants/sql';
import { FabricProviderMap, FabricProviderType } from './types';
import { DQ_PROVIDER } from './types/dataQuality';
import { ORCHESTRATION_PROVIDER } from './types/orchestration';
import { SPARK_PROVIDER } from './types/spark';

type SVGIcon = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

export const FabricSVGIcons: {
  [K in FabricProviderType]: {
    [key in FabricProviderMap[K]]: SVGIcon;
  };
} = {
  [FabricProviderType.SQL]: {
    [SQL_PROVIDER.BigQuery]: BigQueryIcon,
    [SQL_PROVIDER.DataBricks]: DatabricksIcon,
    [SQL_PROVIDER.Snowflake]: SnowflakeIcon,
    [SQL_PROVIDER.Trino]: TrinoIcon,
    [SQL_PROVIDER.Redshift]: RedshiftIcon
  },
  [FabricProviderType.Spark]: {
    [SPARK_PROVIDER.DATABRICKS]: DatabricksIcon,
    [SPARK_PROVIDER.PROPHECY]: ProphecyIcon,
    [SPARK_PROVIDER.LIVY]: LivyIcon,
    [SPARK_PROVIDER.EMR]: EMRIcon,
    [SPARK_PROVIDER.SYNAPSE]: SynapseIcon,
    [SPARK_PROVIDER.DATAPROC]: DataprocIcon
  },
  [FabricProviderType.Airflow]: {
    [AIRFLOW_PROVIDER.MWAA]: MWAAIcon,
    [AIRFLOW_PROVIDER.CLOUD_COMPOSER]: CloudComposerIcon,
    [AIRFLOW_PROVIDER.PROPHECY_MANAGED_TRIAL]: ProphecyIcon,
    [AIRFLOW_PROVIDER.OPEN_SOURCE]: AirflowOpenSourceIcon
  },
  [FabricProviderType.DataQuality]: {
    [DQ_PROVIDER.DATABRICKS]: DatabricksIcon
  },
  [FabricProviderType.Orchestration]: {
    [ORCHESTRATION_PROVIDER.Databricks]: DatabricksIcon,
    [ORCHESTRATION_PROVIDER.Snowflake]: SnowflakeIcon
  }
};

export const FabricIcons = mapValues(FabricSVGIcons, (providerIcons) =>
  mapValues(providerIcons as { [key: string]: SVGIcon }, (Icon) => <Icon />)
) as { [K in FabricProviderType]: { [key in FabricProviderMap[K]]: ReactNode } };
