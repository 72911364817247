import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { castArray, isArray, property } from 'lodash-es';
import RCSelect from 'rc-select';
import { Children, useEffect, useMemo, useRef, useState } from 'react';
import { Ellipsis } from '../Ellipsis';
import { CheckPenIcon } from '../Icons';
import { Stack, StackItem } from '../Layout';
import { theme } from '../theme';
import { createCustomSelect } from './BaseSelect';
import { SelectHandle } from './SelectHandle';
import { StyledTag } from './styled';
import { tokens } from './tokens';
import { SelectSize } from './types';
import { useGetSelectedOptions } from './useSelectedOptions';
const CustomSelect = createCustomSelect(RCSelect);
const propertiesToCheck = ['value', 'label', 'children'];
const findGroupNameInOptions = (value, options, groupName = '', isFound = false, isOptGroup = false) => {
    //Option can be a string as well for Select.Option in case of optGroup
    if (isArray(options)) {
        options?.some((option) => {
            if (!isFound) {
                const currentOption = isOptGroup ? option.props : option;
                const childOptions = currentOption.children;
                if (currentOption.value === value) {
                    isFound = true;
                }
                else if (typeof childOptions === 'object' || currentOption.options?.length) {
                    const childGroup = groupName ? groupName + '.' + currentOption.value : currentOption.value;
                    const childOptions = findGroupNameInOptions(value, currentOption.children || currentOption.options, childGroup, isFound, isOptGroup);
                    isFound = childOptions.isFound;
                    if (isFound) {
                        groupName = childOptions.groupName;
                    }
                }
            }
            return isFound;
        });
    }
    return { isFound, groupName };
};
export const renderSelectInput = ({ groupName, value, options, hasOptGroup, variant = 'primary', className }) => {
    const group = groupName
        ? groupName
        : typeof options === 'object'
            ? findGroupNameInOptions(value, options, groupName, false, hasOptGroup).groupName
            : '';
    return (_jsx(SelectHandle, { size: 'm', variant: variant, className: className, children: _jsxs(Ellipsis, { tooltip: true, children: [value, " ", group ? `(${group})` : ''] }) }));
};
export const defaultFilterOption = (inputValue, option) => {
    if (!option)
        return false;
    if (!inputValue)
        return true;
    return propertiesToCheck.some((property) => {
        const value = option[property];
        if (typeof value === 'string' || typeof value === 'number') {
            return value.toString().toLowerCase().includes(inputValue.toLowerCase());
        }
        return false;
    });
};
export function useValidateDropdownWidth(dropdownMatchSelectWidth, virtual) {
    useEffect(() => {
        if (virtual && !dropdownMatchSelectWidth) {
            console.error(`Virtualization is enabled, but dropdownMatchSelectWidth is not set to true. 
        In virtualization as only few options are rendered at a time, this will cause width of dropdown getting change while scroll as different items might have different width.
        `);
        }
    }, [virtual, dropdownMatchSelectWidth]);
}
export const defaultOptionRender = (option, dropdownMatchSelectWidth, isSelected) => {
    return (_jsxs(Stack, { direction: 'horizontal', align: 'space-between', alignY: 'center', gap: theme.spaces.x8, children: [_jsx(StackItem, { grow: '1', children: typeof option.label === 'string' && dropdownMatchSelectWidth ? (_jsx(Ellipsis, { tooltip: true, children: option.label })) : (option.label) }), isSelected && (_jsx(StackItem, { shrink: '0', children: _jsx(CheckPenIcon, { type: 'extended', color: theme.colors.success600 }) }))] }));
};
export function Select({ menuItemSelectedIcon, filterOption = defaultFilterOption, value, onChange, onInputKeyDown, tagRender, renderInput, listItemHeight, dropdownMatchSelectWidth = true, virtual = true, mode, allowUnknown = true, showSearch = true, optionRender, creatable, onSearch, children: _children, options: _options, onCreateOption, ...restProps }) {
    const [searchValue, setSearchValue] = useState('');
    const selectedValueMap = useMemo(() => {
        return Object.fromEntries(castArray(value).map((value) => [value, true]));
    }, [value]);
    const { options, children } = useMemo(() => {
        let options = _options, children = _children;
        if (creatable && searchValue !== '' && !selectedValueMap[searchValue]) {
            const searchOptionToAdd = { label: searchValue, value: searchValue, isNewOption: true };
            if (children && Children.toArray(children).length > 0) {
                children = [
                    ...Children.toArray(children),
                    _jsx(Select.Option, { ...searchOptionToAdd, children: searchOptionToAdd.label }, searchOptionToAdd.value)
                ];
            }
            else {
                // show search option in dropdown even if options is undefined
                options = [...(options || []), searchOptionToAdd];
            }
        }
        return { options, children };
    }, [creatable, _options, _children, searchValue, selectedValueMap]);
    const getSelectedOptions = useGetSelectedOptions({ options, children });
    useValidateDropdownWidth(dropdownMatchSelectWidth, virtual);
    const listItemCurrentHeight = listItemHeight || tokens.Menu.size[restProps.size || SelectSize.m].listItemHeight;
    // `allowUnknown: false` will reset the value if selected value doesnt exist in options,
    // useful when select options change dynamically
    useEffect(() => {
        if (allowUnknown)
            return;
        const selectedOptions = getSelectedOptions(value);
        const options = castArray(selectedOptions);
        const validOptions = options.filter(Boolean);
        if (validOptions.length !== options.length) {
            if (Array.isArray(selectedOptions)) {
                onChange?.(validOptions.map(property('value')), validOptions);
            }
            else if (value) {
                // RC select also sends undefined as option when clearing
                onChange?.(undefined, selectedOptions);
            }
        }
    }, [value, allowUnknown, getSelectedOptions, onChange]);
    //Putting a logic for duplicate tag added to show the warning if user is typing the value and pressing enter to add a new tag which already exists in values
    let isDuplicateKeyAdded = useRef(false);
    const handleInputKeyDown = (e) => {
        onInputKeyDown?.(e);
        const currentValue = e?.currentTarget?.value;
        if ((e.key === 'Enter' || (e.code === 'Enter' && typeof value === 'object')) && isArray(value)) {
            isDuplicateKeyAdded.current = value.some((v) => v === currentValue);
        }
    };
    const handleChange = (v, option) => {
        if (!isDuplicateKeyAdded.current) {
            onChange?.(v, option);
            if (creatable) {
                const selectedOptions = castArray(option);
                const optionAddedUsingSearch = selectedOptions.find((currentOption) => currentOption.isNewOption);
                if (optionAddedUsingSearch) {
                    const newlyAddedOption = { label: optionAddedUsingSearch.label, value: optionAddedUsingSearch.value };
                    onCreateOption?.(newlyAddedOption);
                }
                // reset search value after an option is selected
                setSearchValue('');
            }
        }
        else {
            // set value false if user stop typing and choose other option from list then onChange should work
            isDuplicateKeyAdded.current = false;
        }
    };
    const handleSearch = (value) => {
        onSearch?.(value);
        if (creatable) {
            setSearchValue(value);
        }
    };
    const selectProps = {
        tagRender: tagRender
            ? tagRender
            : ({ label, value, onClose, closable, disabled }) => {
                return (_jsx(StyledTag, { icon: null, disabled: disabled, closable: closable, onClose: onClose, children: label }, value));
            },
        onInputKeyDown: handleInputKeyDown,
        value,
        virtual,
        dropdownMatchSelectWidth,
        ...restProps,
        listItemHeight: listItemCurrentHeight,
        onChange: handleChange,
        optionRender: optionRender ??
            ((option) => defaultOptionRender(option, dropdownMatchSelectWidth, !!option.value && selectedValueMap[option.value])),
        menuItemSelectedIcon: menuItemSelectedIcon ?? null,
        filterOption,
        showSearch,
        mode,
        onSearch: handleSearch,
        options,
        children
    };
    if (renderInput) {
        const _children = (children || []);
        const _options = _children.length ? _children : options;
        // eslint-disable-next-line react-compiler/react-compiler
        selectProps.getRawInputElement = () => (_jsx(Stack, { className: restProps.className, children: renderInput({
                value: value || restProps.defaultValue,
                variant: restProps.variant,
                options: _options,
                groupName: '',
                hasOptGroup: !!_children.length
            }) }));
    }
    return _jsx(CustomSelect, { ...selectProps });
}
Select.Option = RCSelect.Option;
Select.OptGroup = RCSelect.OptGroup;
