import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Truncate from 'react-truncate';
import styled from 'styled-components';
import { Button } from '../Button';
import { Stack } from '../Layout';
import { theme } from '../theme';
const StyledButton = styled(Button) `
  padding: 0;
  height: auto;
  word-break: normal;
  &&&:focus,
  &&&:active,
  &&&[data-active='true'],
  &&&:focus-visible {
    border: 0;
  }
`;
export function MultilineEllipsis(_a) {
    var { children, expandable, collapsible, symbol = '...', collapseSymbol = 'Less', lines } = _a, rest = __rest(_a, ["children", "expandable", "collapsible", "symbol", "collapseSymbol", "lines"]);
    const [expanded, setExpanded] = useState(false);
    const stopPropagationAndDefault = (e) => {
        e.stopPropagation();
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
    };
    let ellipsis = symbol;
    if (collapsible) {
        ellipsis = (_jsx(StyledButton, { variant: 'link', onPointerUp: (e) => {
                stopPropagationAndDefault(e);
                setExpanded(true);
            }, children: symbol }));
    }
    return (_jsxs(Stack, { gap: theme.spaces.x8, direction: 'horizontal', alignY: 'end', children: [_jsx(Truncate, Object.assign({}, rest, { lines: expanded ? false : lines, ellipsis: ellipsis, children: children })), collapsible && expanded && (_jsx(StyledButton, { variant: 'link', onPointerUp: (e) => {
                    stopPropagationAndDefault(e);
                    setExpanded(false);
                }, children: collapseSymbol }))] }));
}
