import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactOtpInput from 'react-otp-input';
import { InputSize } from '../types';
import { StyledHint, StyledInputContainer, VerificationCodeInputContainer, StyledSeparator, StyledInput, StyledLabel } from './styled';
import { tokens } from './tokens';
export var OTPLength;
(function (OTPLength) {
    OTPLength["Four"] = "4";
    OTPLength["Six"] = "6";
})(OTPLength || (OTPLength = {}));
const containerStyle = (size) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: tokens.OTPInput.size[size].gap
});
const renderSeparator = (index, digits) => {
    return digits === OTPLength.Six && index === 2 ? _jsx(StyledSeparator, { children: "-" }) : null;
};
const renderInput = (inputProps, index, size) => {
    return _jsx(StyledInput, { "$size": size, ...inputProps, placeholder: '0' });
};
export const OtpInput = ({ label, value, onChange, size = InputSize.s, digits = OTPLength.Six, hint, className, ...restProps }) => {
    return (_jsxs(VerificationCodeInputContainer, { className: className, children: [_jsxs(StyledInputContainer, { children: [label && _jsx(StyledLabel, { level: 'sm', children: label }), _jsx(ReactOtpInput, { containerStyle: containerStyle(size), renderSeparator: (index) => renderSeparator(index, digits), renderInput: (props, index) => renderInput(props, index, size), numInputs: +digits, inputType: 'number', onChange: onChange, value: value, ...restProps })] }), hint && _jsx(StyledHint, { level: 'sm', children: hint })] }));
};
