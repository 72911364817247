import { endOfMonth, isSameDay, startOfMonth, parse, isValid, format, format as dateFormat } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import enUS from 'rc-picker/lib/locale/en_US';
import { DATE_FORMAT, TIME_FORMAT } from '../types';
enUS.monthFormat = 'MMMM';
enUS.fieldMonthFormat = 'MMMM';
export function getLocale() {
    return enUS;
}
export function getPickerPanelWidth({ isRangePicker = false, showPreset = false, showTimePicker = false }) {
    const presetWidth = 0;
    const timePickerWidth = 151 + 24 + 24; // 151 is width and 24 is padding on left and right
    if (isRangePicker) {
        let calendarWidth = 665;
        if (showPreset)
            calendarWidth += presetWidth;
        if (showTimePicker)
            calendarWidth += timePickerWidth;
        return calendarWidth;
    }
    else {
        let calendarWidth = 328;
        if (showPreset)
            calendarWidth += presetWidth;
        if (showTimePicker)
            calendarWidth += timePickerWidth;
        return calendarWidth;
    }
}
export function isFirstDayOfMonth(date) {
    const startOfCurrentMonth = startOfMonth(date);
    return isSameDay(date, startOfCurrentMonth);
}
export function isLastDayOfMonth(date) {
    const startOfCurrentMonth = endOfMonth(date);
    return isSameDay(date, startOfCurrentMonth);
}
export function isDateValid(dateString, formatString = DATE_FORMAT.DD_MM_YY) {
    const parsedDate = parse(dateString, formatString, new Date());
    return isValid(parsedDate) && format(parsedDate, formatString) === dateString;
}
export function isTimeValid(timeString, formatString = TIME_FORMAT.HH_MM_SS) {
    const parsedTime = parse(timeString, formatString, new Date());
    return isValid(parsedTime) && format(parsedTime, formatString) === timeString;
}
export function getDefaultTime() {
    const defaultTime = new Date();
    defaultTime.setHours(0);
    defaultTime.setMinutes(0);
    defaultTime.setSeconds(0);
    defaultTime.setMilliseconds(0);
    return defaultTime;
}
export function checkPanelMode(mode, panelMode) {
    if (!panelMode)
        return;
    if (typeof panelMode === 'string') {
        if (typeof mode === 'string') {
            return panelMode === mode;
        }
        else if (Array.isArray(mode)) {
            return mode.includes(panelMode);
        }
    }
    else if (Array.isArray(panelMode)) {
        if (typeof mode === 'string') {
            return panelMode.includes(mode);
        }
        else if (Array.isArray(mode)) {
            return !!panelMode.find((pm) => mode.includes(pm));
        }
    }
}
export function addSeparatorToFormat(format, separator) {
    return format.replaceAll('/', separator);
}
function makeGenerateConfig() {
    const dateFnsFormat = dateFnsGenerateConfig.locale.format;
    dateFnsGenerateConfig.locale.format = function (locale, date, format) {
        const fmt = format.toLowerCase();
        if (/^(.*?(a|p))$/i.test(fmt)) {
            return dateFormat(date, fmt);
        }
        return dateFnsFormat(locale, date, format);
    };
    return dateFnsGenerateConfig;
}
export const generateConfig = makeGenerateConfig();
