import { tokens } from './tokens';
import { ButtonCategory, ButtonState, ButtonVariants } from './types';
// Utility function to get variant property
export const getVariantProperty = ({ category, variant }, property, state, defaultValue = '') => {
    const variantProperties = tokens.Button[category]?.[variant] ?? {};
    if (state) {
        return variantProperties[state][property] ?? variantProperties[property] ?? defaultValue;
    }
    return variantProperties[property] ?? defaultValue;
};
// Utility function to handle border styling
export const getBorder = ({ props, state, defaultValue = 'none', borderWidth, borderColor, borderStyle = 'solid' }) => {
    const borderColorValue = getVariantProperty(props, 'borderColor', state);
    const borderWidthValue = getVariantProperty(props, 'borderWidth', state);
    const finalBorderColor = borderColor || borderColorValue;
    const finalBorderWidth = borderWidth || borderWidthValue || '1px';
    if (finalBorderColor) {
        return `${finalBorderWidth} ${borderStyle} ${finalBorderColor}`;
    }
    return defaultValue;
};
// Utility function to handle background styling conditionally
export const getBackground = ({ props, state, defaultValue = 'transparent' }) => {
    const buttonState = props.disabled ? ButtonState.disabled : state;
    let backgroundColor;
    if (props.isGroupItem && props.variant === ButtonVariants.primary) {
        if (props.category === ButtonCategory.danger) {
            backgroundColor =
                state === ButtonState.focused
                    ? tokens.ButtonGroupItem.danger.primary.focused.backgroundColor
                    : tokens.ButtonGroupItem.danger.primary.backgroundColor;
        }
        else {
            backgroundColor =
                state === ButtonState.focused
                    ? tokens.ButtonGroupItem.default.primary.focused.backgroundColor
                    : tokens.ButtonGroupItem.default.primary.backgroundColor;
        }
    }
    else {
        backgroundColor = getVariantProperty(props, 'backgroundColor', buttonState, defaultValue);
    }
    if (props.variant === ButtonVariants.primary && !props.disabled) {
        return `background: ${backgroundColor};`;
    }
    return `background-color: ${backgroundColor};`;
};
