export var NodeType;
(function (NodeType) {
    NodeType["gem"] = "gem";
    NodeType["simpleGemNode"] = "simpleGemNode";
    NodeType["tile"] = "tile";
    NodeType["subFlow"] = "subFlow";
    NodeType["comment"] = "comment";
})(NodeType || (NodeType = {}));
export var LayoutAlgo;
(function (LayoutAlgo) {
    LayoutAlgo["elk"] = "Layer Based Layout";
    LayoutAlgo["dagre"] = "Simple DAG Layout";
})(LayoutAlgo || (LayoutAlgo = {}));
export var NodeStateTypes;
(function (NodeStateTypes) {
    NodeStateTypes["default"] = "default";
    NodeStateTypes["loading"] = "loading";
    NodeStateTypes["success"] = "success";
    NodeStateTypes["error"] = "error";
    NodeStateTypes["warning"] = "warning";
})(NodeStateTypes || (NodeStateTypes = {}));
