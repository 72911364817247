import { InkeepModalSearchAndChat, type InkeepModalSearchAndChatProps } from '@inkeep/cxkit-react';

import useInkeepSettings from './useInkeepSettings';

interface AiChatButtonProps {
  userAvatar?: string;
  trigger?: React.ReactElement;
  isOpen: boolean;
  onClose: () => unknown;
}

export function AiChatButton({ userAvatar, trigger, isOpen, onClose }: AiChatButtonProps) {
  const { baseSettings, aiChatSettings, searchSettings, modalSettings } = useInkeepSettings(userAvatar);

  const chatButtonProps: InkeepModalSearchAndChatProps = {
    baseSettings,
    aiChatSettings,
    searchSettings,
    modalSettings: {
      ...modalSettings,
      isOpen,
      onOpenChange: (val) => val || onClose()
    },
    defaultView: 'chat'
  };

  return (
    <>
      {trigger}
      <InkeepModalSearchAndChat {...chatButtonProps} />
    </>
  );
}
