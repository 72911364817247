import { jsx as _jsx } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { EditBIcon, LoadingDIcon } from '../Icons';
import { ICON_CLASS } from '../Icons/tokens';
import { getInterFontWeightCSS } from '../theme';
import { growInputCss } from './InlineInput';
import { Input } from './Input';
import { InputWrap, StyledHelpText, StyledInputContainer } from './styled';
import { tokens } from './tokens';
import { InputVariants } from './types';
const StyledGhostInput = styled(Input) `
  ${StyledInputContainer} {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid ${({ $variant }) => tokens.Input[$variant].borderColor};
    ${({ $underline, $variant }) => $underline &&
    $variant !== InputVariants.error &&
    `&:not(:hover) {
        border-bottom-color: transparent;
      }`}
    border-radius: ${tokens.GhostInput.borderRadius};
    box-shadow: none;
    padding: ${tokens.GhostInput.padding};
    padding-top: 0;
    ${getInterFontWeightCSS(tokens.GhostInput.fontWeight)};
    font-size: ${(props) => tokens.GhostInput.size[props.inputSize].fontSize};
    line-height: ${(props) => tokens.GhostInput.size[props.inputSize].lineHeight};
    height: ${(props) => tokens.GhostInput.size[props.inputSize].height};
    gap: ${(props) => tokens.GhostInput.size[props.inputSize].gap};
    padding-right: ${tokens.GhostInput.iconSize};
    .${ICON_CLASS} {
      display: none;
    }

    &:focus-within {
      outline: none;
      border-bottom: 2px solid ${({ $variant }) => tokens.Input[$variant].focus.borderColor};
    }

    &:hover,
    &:focus-within {
      padding-right: 0;
      .${ICON_CLASS} {
        display: block;
        font-size: ${(props) => tokens.GhostInput.size[props.inputSize].iconSize};
      }
    }

    ${({ $loading }) => $loading && `.${ICON_CLASS} { display: block; }`}
  }
  ${growInputCss}
  ${InputWrap} {
    &:not([data-value='']) {
      color: ${tokens.Input.color};
    }
  }
  ${StyledHelpText} {
    color: ${({ $variant }) => $variant === InputVariants.error && tokens.Input.error.color};
    padding: ${tokens.GhostInput.padding};
  }
`;
export const GhostInput = forwardRef(({ inputSize, value, onChange, defaultValue, loading, variant = InputVariants.primary, ...restProps }, ref) => {
    const [state, setState] = useControlledState({
        value,
        defaultValue: defaultValue,
        onChange: (value, triggerKind) => onChange?.(value, triggerKind)
    });
    return (_jsx(StyledGhostInput, { "$variant": variant, size: 1, inputSize: inputSize, "$underline": !!state, required: false, icon: loading ? (_jsx(LoadingDIcon, { spin: true, size: 'xs', type: 'extended', color: tokens.GhostInput.loading.iconColor })) : (_jsx(EditBIcon, { type: 'solid', color: tokens.GhostInput.iconColor })), iconPlacement: 'right', "$loading": loading, ref: ref, value: state, onChange: setState, ...restProps }));
});
