import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { castArray, flatten } from 'lodash-es';
import { Fragment, isValidElement } from 'react';
const convertNodeToOption = (node) => {
    const { key, props: { children, value, ...restProps } } = node;
    return { value: value !== undefined ? value : key, ...restProps };
};
const convertChildrenToData = (nodes) => {
    return flatten(castArray(nodes).filter(Boolean).map((node) => {
        const isArray = Array.isArray(node);
        if (!isArray && (!isValidElement(node) || !node.type)) {
            return null;
        }
        const hasChildren = isArray ||
            node.type === Fragment ||
            node.type.isSelectOptGroup;
        if (hasChildren) {
            return convertChildrenToData(isArray ? node : node?.props?.children);
        }
        return convertNodeToOption(node);
    })).filter(Boolean);
};
export const useGetSelectedOptions = ({ options, children }) => {
    return usePersistentCallback((value) => {
        const optionMap = new Map();
        const optionList = options || convertChildrenToData(children);
        optionList.forEach((option) => optionMap.set(option.value, option));
        if (Array.isArray(value)) {
            return value.map((v) => optionMap.get(v));
        }
        else {
            return optionMap.get(value);
        }
    });
};
