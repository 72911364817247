export var Variant;
(function (Variant) {
    Variant["text"] = "text";
    Variant["brand"] = "brand";
    Variant["gray"] = "gray";
})(Variant || (Variant = {}));
export const buttonVariant = {
    text: 'plain',
    brand: 'tertiary',
    gray: 'tertiaryGray'
};
