import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { emailInitials, nameInitials } from '@prophecy/utils/string';
import isEmail from 'validator/lib/isEmail';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { Root, Text, Image } from './styled';
export function Avatar({ text, icon, src, hint, tooltip, ...rest }) {
    let avatarUI = null;
    if (src) {
        avatarUI = _jsx(ImageAvatar, { src: src, ...rest });
    }
    else if (text) {
        avatarUI = _jsx(TextAvatar, { text: text, ...rest });
    }
    else if (icon) {
        avatarUI = _jsx(IconAvatar, { icon: icon, ...rest });
    }
    if (tooltip) {
        avatarUI = _jsx(_Fragment, { children: renderWithTooltip(_jsx("div", { children: avatarUI }), tooltip) });
    }
    if (hint) {
        avatarUI = (_jsx(LabelWithHint, { hint: hint, children: _jsx("div", { children: avatarUI }) }));
    }
    return avatarUI;
}
function TextAvatar({ size, text, ...rest }) {
    const initial = isEmail(text) ? emailInitials(text) : nameInitials(text);
    return (_jsx(Root, { size: size, ...rest, children: _jsx(Text, { size: size, children: initial }) }));
}
function IconAvatar({ size, icon, ...rest }) {
    return (_jsx(Root, { size: size, ...rest, children: _jsx("span", { children: icon }) }));
}
function ImageAvatar({ size, src, ...rest }) {
    return (_jsx(Root, { size: size, ...rest, children: _jsx(Image, { src: src }) }));
}
