import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { castArray, identity, isArray } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { useUser } from '../../context/user';
import { getOrchestrationRunsFabricToken } from '../../data/apis/api';
import { useRestQuery } from '../../data/util';
import { Fabric } from '../../redux/types';
import { FROZEN_EMPTY_ARRAY } from '../constants';
import { getFabricProviderInfo, isFabricExpired, isOrchestrationFabric } from './utils';

export function useUserFabrics(): Fabric[];
export function useUserFabrics<T>(fabricIds: T[], getter: (fabric: T) => string): Fabric[];
export function useUserFabrics<T>(fabricId: T, getter: (fabric: T) => string): Fabric;
export function useUserFabrics<T>(filterFabricIds?: T | T[], getter?: (fabric: T) => string) {
  const { user } = useUser();
  const fabrics = user?.fabrics || FROZEN_EMPTY_ARRAY;
  const persistantGetter = usePersistentCallback(getter || (() => undefined));
  const hasFilter = Boolean(filterFabricIds || getter);

  return useMemo(() => {
    if (!hasFilter) return fabrics;

    const fabricIdSet = new Set(castArray((filterFabricIds as T | T[]) || []).map(persistantGetter));
    const filteredFabrics = fabrics.filter((fabric) => fabricIdSet.has(fabric.id));
    return Array.isArray(filterFabricIds) ? filteredFabrics : filteredFabrics[0];
  }, [fabrics, filterFabricIds, persistantGetter, hasFilter]);
}

export type FabricWithProviderInfo = {
  fabric: Fabric;
  fabricProviderInfo: NonNullable<ReturnType<typeof getFabricProviderInfo>>;
  isExpired: boolean;
};

type FabricsWithProviderInfo<T extends Fabric | Fabric[] | undefined> = T extends Fabric
  ? FabricWithProviderInfo
  : T extends Fabric[]
    ? FabricWithProviderInfo[]
    : undefined;

export const useWithFabricProviderInfo = <T extends Fabric | Fabric[] | undefined>(fabrics: T) => {
  return useMemo(() => {
    const updatedFabrics = castArray(fabrics || []).map((fabric) => {
      const fabricProviderInfo = getFabricProviderInfo(fabric) as NonNullable<ReturnType<typeof getFabricProviderInfo>>;
      return {
        fabric,
        fabricProviderInfo,
        isExpired: isFabricExpired(fabric, fabricProviderInfo)
      };
    });

    return (isArray(fabrics) ? updatedFabrics : updatedFabrics[0]) as FabricsWithProviderInfo<T>;
  }, [fabrics]);
};

export function useSelectedFabric(fabricId?: string) {
  return useUserFabrics(fabricId, identity) as Fabric | undefined;
}

export function useOrchestrationFabrics() {
  const fabrics = useUserFabrics();
  const orchestrationFabrics = useMemo(() => fabrics.filter(isOrchestrationFabric), [fabrics]);
  const orchestrationFabricIds = orchestrationFabrics.map((fabric) => Number(fabric.id));

  const {
    data: jwtData,
    isInitialLoading: isLoading,
    refetch
  } = useRestQuery(
    ['orchestration-runs-fabric-token', orchestrationFabricIds],
    () => getOrchestrationRunsFabricToken(orchestrationFabricIds),
    {
      enabled: !!orchestrationFabricIds.length
    }
  );

  const refetchJwt = useCallback(async () => {
    if (orchestrationFabricIds.length) {
      return refetch();
    }
    return false;
  }, [refetch, orchestrationFabricIds]);

  const fabricsMap = useMemo(() => {
    return new Map(orchestrationFabrics.map((fabric) => [fabric.id, fabric]));
  }, [orchestrationFabrics]);

  return { fabrics: orchestrationFabrics, fabricsMap, jwt: jwtData?.data.jwt || '', isLoading, refetchJwt };
}
