import { theme } from '../theme';
export const tokens = {
    Display: {
        fontFamily: theme.fontFamily.text,
        fontWeight: theme.fontWeight.normal,
        letterSpacing: '0em',
        level: {
            '2xl': {
                fontSize: theme.display.xxl.fontSize,
                lineHeight: theme.display.xxl.lineHeight
            },
            xl: {
                fontSize: theme.display.xl.fontSize,
                lineHeight: theme.display.xl.lineHeight
            },
            lg: {
                fontSize: theme.display.lg.fontSize,
                lineHeight: theme.display.lg.lineHeight
            },
            md: {
                fontSize: theme.display.md.fontSize,
                lineHeight: theme.display.md.lineHeight
            },
            sm: {
                fontSize: theme.display.sm.fontSize,
                lineHeight: theme.display.sm.lineHeight
            },
            xs: {
                fontSize: theme.display.xs.fontSize,
                lineHeight: theme.display.xs.lineHeight
            }
        }
    },
    Text: {
        fontFamily: theme.fontFamily.text,
        fontWeight: theme.fontWeight.normal,
        letterSpacing: '0em',
        level: {
            xl: {
                fontSize: theme.text.xl.fontSize,
                lineHeight: theme.text.xl.lineHeight
            },
            lg: {
                fontSize: theme.text.lg.fontSize,
                lineHeight: theme.text.lg.lineHeight
            },
            md: {
                fontSize: theme.text.md.fontSize,
                lineHeight: theme.text.md.lineHeight
            },
            sm15: {
                fontSize: theme.fontSizes.x15,
                lineHeight: theme.lineHeight.x22
            },
            sm: {
                fontSize: theme.text.sm.fontSize,
                lineHeight: theme.text.sm.lineHeight
            },
            sm13: {
                fontSize: theme.fontSizes.x13,
                lineHeight: theme.lineHeight.x19
            },
            xs: {
                fontSize: theme.text.xs.fontSize,
                lineHeight: theme.text.xs.lineHeight
            },
            xxs: {
                fontSize: theme.text.xxs.fontSize,
                lineHeight: theme.text.xxs.lineHeight
            }
        }
    },
    Code: {
        fontFamily: theme.fontFamily.code,
        fontWeight: theme.fontWeight.normal,
        letterSpacing: '0em',
        level: {
            sm: {
                fontSize: theme.code.sm.fontSize,
                lineHeight: theme.code.sm.lineHeight
            },
            sm13: {
                fontSize: theme.fontSizes.x13,
                lineHeight: theme.lineHeight.x19
            },
            xs: {
                fontSize: theme.code.xs.fontSize,
                lineHeight: theme.code.xs.lineHeight
            }
        }
    },
    CodeBlock: {
        backgroundColor: theme.colors.gray100,
        borderColor: theme.colors.gray200,
        borderRadius: theme.radius.x2
    }
};
