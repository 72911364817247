import { useEffect } from 'react';

import { Entity } from '../../common/types/Entity';
import { EntityEvents } from '../../common/user-analytics';
import { useMixPanel } from '../mixpanel/context';

const EVENT_NAME = 'visibilitychange';

export const useTabFocusEvents = () => {
  const { track } = useMixPanel();
  useEffect(() => {
    const onVisibilityChange = () => {
      const isVisible = document.visibilityState === 'visible';
      track(isVisible ? EntityEvents[Entity.User].home.tabFocusIn : EntityEvents[Entity.User].home.tabFocusOut);
    };

    document.addEventListener(EVENT_NAME, onVisibilityChange);

    return () => {
      document.removeEventListener(EVENT_NAME, onVisibilityChange);
    };
  }, [track]);
};
