import { theme } from '../theme';
export const tokens = {
    TagsRoot: {
        borderRadius: theme.radius.x6,
        fontWeight: theme.fontWeight.medium,
        countBorderRadius: theme.radius.x4,
        circleFontSize: theme.fontSizes.x12,
        circleSize: theme.sizes.x20,
        iconColor: theme.colors.gray400,
        backgroundColor: theme.colors.white,
        numberContainer: {
            backgroundColor: theme.colors.gray100
        },
        gap: theme.spaces.x3,
        hover: {
            backgroundColor: theme.colors.gray50
        },
        focused: {
            boxShadow: theme.focusRing.shadow.skeumorphic
        },
        disabled: {
            backgroundColor: theme.colors.gray100
        },
        size: {
            s: {
                padding: `${theme.spaces.x3} ${theme.spaces.x8}`,
                countPadding: theme.spaces.x2,
                height: theme.sizes.x20,
                iconFontSize: theme.fontSizes.x10,
                fontSize: theme.fontSizes.x12,
                gap: theme.spaces.x4
            },
            m: {
                padding: `${theme.spaces.x2} 9px`,
                countPadding: theme.spaces.x6,
                height: theme.sizes.x24,
                iconFontSize: theme.fontSizes.x12,
                fontSize: theme.fontSizes.x14,
                gap: '5px'
            },
            l: {
                padding: `${theme.spaces.x4} ${theme.spaces.x10}`,
                countPadding: theme.spaces.x6,
                height: theme.sizes.x28,
                iconFontSize: theme.fontSizes.x14,
                fontSize: theme.fontSizes.x14,
                gap: theme.spaces.x6
            }
        }
    }
};
