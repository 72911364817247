import { useEffect } from 'react';

import { PortsAtomProps } from '../../components/Ports';
import { type PortSchemaProps } from '../../generic-components/Ports/PortSchema';
import { GemSpec } from '../../Parser/types';
import { findAtom } from '../graph/utils';
import { getGemSpecByKind } from '../selectors';
import { BaseProcess, BaseProcessMetadata, Connection, GenericGraph, GenericGraphProcessType } from '../types';

class UISpecPropsCacheManager {
  private cache: Map<string, unknown | null>;

  constructor() {
    this.cache = new Map<string, unknown | null>();
  }

  getAtomProps<T>(
    atomName: string,
    gemSpecs: GemSpec[],
    process: GenericGraphProcessType<
      GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata, unknown>, Connection>
    >,
    gemType: string
  ): T | null | undefined {
    function getAtomProps(
      specs: GemSpec[],
      process: GenericGraphProcessType<
        GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata, unknown>, Connection>
      >
    ) {
      const gemSpec = getGemSpecByKind(specs, process.component);
      let props: unknown | null = null;
      if (gemSpec?.uiSpec.contains) {
        const atom = findAtom(gemSpec.uiSpec.contains, (spec) => spec.kind === atomName);
        const atomProps = atom?.properties as unknown | undefined;
        props = atomProps;
      }
      return props;
    }
    const cacheKey = `${gemType}-${atomName}`;
    if (this.cache.has(cacheKey)) return this.cache.get(cacheKey) as T;
    else {
      let props: unknown | null = getAtomProps(gemSpecs, process);
      if (props) this.cache.set(cacheKey, props);
      return props as T;
    }
  }

  clear() {
    this.cache.clear();
  }
}

export const uiSpecAtomPropsCacheManager = new UISpecPropsCacheManager();

export function useUISpecProps() {
  const getAtomProps = uiSpecAtomPropsCacheManager.getAtomProps.bind(uiSpecAtomPropsCacheManager);

  useEffect(() => {
    uiSpecAtomPropsCacheManager.clear();
  }, []);

  return { getAtomProps };
}

export function canAddNewPorts(
  specs: GemSpec[],
  process: GenericGraphProcessType<
    GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata, unknown>, Connection>
  >,
  gemType: string
) {
  const portSchemaProps = uiSpecAtomPropsCacheManager.getAtomProps<PortSchemaProps>(
    'PortSchema',
    specs,
    process,
    gemType
  );
  const portsProps = uiSpecAtomPropsCacheManager.getAtomProps<PortsAtomProps>('Ports', specs, process, gemType);

  return portSchemaProps?.allowAddOrDelete || portsProps?.allowInputAddOrDelete;
}
