import { theme } from '../theme';
import { hexToRGBA } from '../theme/utils';
export const tokens = {
    Spinner: {
        Overlay: {
            backgroundColor: hexToRGBA(theme.colors.white, 0.2),
            zIndex: theme.zLayer.s
        },
        Tip: {
            fontSize: {
                sm: theme.text.sm.fontSize,
                lg: theme.text.lg.fontSize
            }
        },
        loaderSize: {
            s: theme.sizes.x20,
            m: theme.sizes.x24,
            l: theme.sizes.x28,
            xl: theme.sizes.x32
        },
        gap: theme.spaces.x8
    },
    OverlaySpinner: {
        zIndex: theme.zLayer.xxl
    }
};
