import { tokens as iconTokens } from '../../Icons/tokens';
import { theme } from '../../theme';
export const tokens = {
    BoxShadowDarkBox: theme.shadows.skeumorphic,
    BoxShadowLightBox: theme.shadows.skeumorphicXs,
    colorShade: {
        color: '600',
        backgroundType: {
            light: {
                color: '600',
                borderColor: '200',
                background: '50'
            },
            dark: {
                color: '0',
                borderColor: '200',
                background: '600'
            }
        }
    },
    Size: {
        // Plain
        xs: {
            iconSize: iconTokens.Icon.xs.size,
            iconStrokeWidth: iconTokens.Icon.xs.strokeWidth,
            containerSize: theme.sizes.x16,
            padding: '0',
            borderRadius: '0'
        },
        s: {
            iconSize: iconTokens.Icon.s.size,
            iconStrokeWidth: iconTokens.Icon.s.strokeWidth,
            containerSize: theme.sizes.x20,
            padding: '0',
            borderRadius: '0'
        },
        m: {
            iconSize: iconTokens.Icon.m.size,
            iconStrokeWidth: iconTokens.Icon.m.strokeWidth,
            containerSize: theme.sizes.x24,
            padding: '0',
            borderRadius: '0'
        },
        // Box
        l: {
            iconSize: iconTokens.Icon.s.size,
            iconStrokeWidth: iconTokens.Icon.s.strokeWidth,
            containerSize: theme.sizes.x32,
            padding: theme.sizes.x6,
            borderRadius: theme.sizes.x6
        },
        xl: {
            iconSize: iconTokens.Icon.m.size,
            iconStrokeWidth: iconTokens.Icon.m.strokeWidth,
            containerSize: theme.sizes.x40,
            padding: theme.sizes.x8,
            borderRadius: theme.sizes.x8
        },
        xxl: {
            iconSize: iconTokens.Icon.l.size,
            iconStrokeWidth: iconTokens.Icon.l.strokeWidth,
            containerSize: theme.sizes.x48,
            padding: theme.sizes.x10,
            borderRadius: theme.sizes.x10
        },
        xxxl: {
            iconSize: iconTokens.Icon.xl.size,
            iconStrokeWidth: iconTokens.Icon.xl.strokeWidth,
            containerSize: theme.sizes.x56,
            padding: theme.sizes.x12,
            borderRadius: theme.sizes.x12
        }
    }
};
