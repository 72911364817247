import { theme } from '../theme';
export const tokens = {
    SlidingPanel: {
        Overlay: {
            backgroundColor: theme.colors.gray900,
            zIndex: theme.zLayer.m
        },
        Content: {
            boxShadow: theme.shadows.xl,
            transition: theme.transition.m,
            backgroundColor: theme.colors.white,
            zIndex: theme.zLayer.m,
            paddingX: theme.spaces.x32,
            paddingY: theme.spaces.x32
        },
        CloseButton: {
            backgroundColor: theme.colors.white,
            hover: {
                backgroundColor: theme.colors.white
            }
        },
        Footer: {
            borderColor: theme.borderColor.secondary,
            backgroundColor: theme.backgroundColor.secondaryAlt,
            padding: theme.spaces.x16,
            height: `calc(${theme.spaces.x40} + 2 *${theme.spaces.x16})`
        },
        PanelCloseButton: {
            borderRadius: theme.radius.x8,
            zIndex: theme.zLayer.m,
            top: theme.spaces.x24
        },
        borderRadius: theme.radius.x32,
        size: {
            s: {
                width: '50%',
                minWidth: '700px'
            },
            m: {
                width: '75%',
                minWidth: '810px'
            },
            l: {
                width: '90%',
                minWidth: '950px'
            }
        }
    }
};
