/* eslint-disable import/no-internal-modules */
import { MetadataPageContainer, SIDE_NAVBAR_WIDTH, Spinner, theme } from '@prophecy/ui';
import React, { lazy, Suspense } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { ABOVE_ALL_CLS, SIDEBAR_ALLOWED_OVERLAY_CLS } from './common/constants';
import { withErrorBoundary } from './generic-components/ErrorBoundary';
import { PageContainer } from './generic-components/Layout';

const Container = styled.div<{ minBreakpoint?: string }>`
  width: 100%;
  min-width: calc(${({ minBreakpoint = theme.breakpoints.m }) => minBreakpoint} - ${SIDE_NAVBAR_WIDTH});
  background: ${theme.colors.white};
`;

const AppGlobalStyles = createGlobalStyle`
  .${SIDEBAR_ALLOWED_OVERLAY_CLS} {
    left: ${SIDE_NAVBAR_WIDTH};
    right: 0;
  }
  .${ABOVE_ALL_CLS} {
    z-index:${theme.zLayer.xxl};
  }
`;

export const PackageDetail = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Metadata/EntityDetail/Package/index'))
);
export const PackageHubListPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/PackageHub/index'))
);

export const IDEPage = withErrorBoundary(lazy(() => import(/* webpackPrefetch: true */ './pages/ide')));
export const IdeHome = withErrorBoundary(lazy(() => import(/* webpackPrefetch: true */ './pages/ide/IdeHome')));

export const SqlPage = withErrorBoundary(lazy(() => import(/* webpackPrefetch: true */ './pages/Sql')));

export const ComponentListPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/gem/old_playground/GemList/ComponentList'))
);
export const GemBuilderPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/gem/old_playground/GemBuilder'))
);

export const CreateEntityPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/CreateEntity/EntityCards'))
);

/* FABRIC START */
export const CreateFabricPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Fabric/CreateFabric'))
);
export const UpdateFabricPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Fabric/UpdateFabric'))
);
/* FABRIC END */

export const TranspilerListingPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Transpiler/Listing'))
);

export const AppListPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Sql/BusinessApps/AppList'))
);

export const TranspilerImportPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Transpiler/Import'))
);

export const TranspilerDetailPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Transpiler/ImportedInfo/PackageDetail'))
);

export const ProjectDetailPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Metadata/EntityDetail/ProjectDetail'))
);
export const UserDetailPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Metadata/EntityDetail/UserDetail'))
);
export const TeamDetailPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Metadata/EntityDetail/TeamDetail'))
);

export const SubgraphDetailPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Metadata/EntityDetail/SubgraphDetail'))
);

export const DatasetDetailPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Metadata/EntityDetail/DatasetDetail'))
);

export const JobDetailPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Metadata/EntityDetail/JobDetail'))
);

export const PipelineDetailPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Metadata/EntityDetail/PipelineDetail'))
);

export const LineagePage = withErrorBoundary(lazy(() => import(/* webpackPrefetch: true */ './pages/Lineage/Lineage')));

export const HomePage = withErrorBoundary(lazy(() => import(/* webpackPrefetch: true */ './pages/Metadata/Home')));

export const LineageSearchPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Lineage/Search/LineageSearch'))
);

export const UserSettings = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/UserSettings/Settings'))
);

export const UpgradePlanPage = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/UserSettings/UpgradePlan/UpgradePlan'))
);

export const DatabrickSignUp = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/auth/DatabrickSignUp'))
);

export const ResetPasswordForm = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/auth/ResetPassword'))
);
export const SignInForm = withErrorBoundary(lazy(() => import(/* webpackPrefetch: true */ './pages/auth/Signin')));
export const SignUpForm = withErrorBoundary(lazy(() => import(/* webpackPrefetch: true */ './pages/auth/signup')));
export const EmbeddedSignup = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/auth/signup/EmbeddedSignup'))
);

export const Maintenance = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Maintenance/Maintenance'))
);

export const ServiceDown = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Maintenance/ServiceDown'))
);
export const OAuthCallback = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Popups/OAuthCallback'))
);

export const OAuthSamlCallback = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/Popups/OAuthSamlCallback'))
);
export const LoginUsingToken = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/UserSettings/Admin/AuthProvider/LoginUsingToken'))
);

export const CreateProject = withErrorBoundary(
  lazy(() => import(/* webpackPrefetch: true */ './pages/CreateEntity/Project'))
);

export const PrivateMainSection = ({
  minBreakpoint,
  children,
  wrapInLayout
}: {
  minBreakpoint?: string;
  wrapInLayout?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Container minBreakpoint={minBreakpoint}>
      <Suspense
        fallback={
          <Spinner spinning={true}>
            {/** To maintain the spinner at consistent place */}
            <PageContainer />
          </Spinner>
        }>
        {wrapInLayout ? <MetadataPageContainer background='inherit' main={children}></MetadataPageContainer> : children}
      </Suspense>
      <AppGlobalStyles />
    </Container>
  );
};

export const PublicLayout = (props: { minBreakpoint?: string; children: React.ReactNode }) => {
  return (
    <>
      <Suspense
        fallback={
          <Spinner spinning={true}>
            {/** To maintain the spinner at consistent place */}
            <PageContainer />
          </Spinner>
        }>
        {props.children}
      </Suspense>
      <AppGlobalStyles />
    </>
  );
};
