import { jsx as _jsx } from "react/jsx-runtime";
import { LOGO_ICON_CLASS } from "./tokens";
import { ReactComponent as ApplicationGoogleAnalytics } from '../assets/images/FileType/Application/GoogleAnalytics.svg';
import { ReactComponent as ApplicationPowerBI } from '../assets/images/FileType/Application/PowerBI.svg';
import { ReactComponent as ApplicationSalesforce } from '../assets/images/FileType/Application/Salesforce.svg';
import { ReactComponent as ApplicationSlack } from '../assets/images/FileType/Application/Slack.svg';
import { ReactComponent as ApplicationSmartsheets } from '../assets/images/FileType/Application/Smartsheets.svg';
import { ReactComponent as ApplicationSMTP } from '../assets/images/FileType/Application/SMTP.svg';
import { ReactComponent as ApplicationTableau } from '../assets/images/FileType/Application/Tableau.svg';
import { ReactComponent as ApplicationWebPage } from '../assets/images/FileType/Application/WebPage.svg';
import { ReactComponent as CatalogGeneric } from '../assets/images/FileType/Catalog/Generic.svg';
import { ReactComponent as CatalogIceberg } from '../assets/images/FileType/Catalog/Iceberg.svg';
import { ReactComponent as FileArchiveDefaultRAR } from '../assets/images/FileType/File/Archive/Default/RAR.svg';
import { ReactComponent as FileArchiveDefaultZIP } from '../assets/images/FileType/File/Archive/Default/ZIP.svg';
import { ReactComponent as FileArchiveGrayRAR } from '../assets/images/FileType/File/Archive/Gray/RAR.svg';
import { ReactComponent as FileArchiveGrayZIP } from '../assets/images/FileType/File/Archive/Gray/ZIP.svg';
import { ReactComponent as FileArchiveSolidRAR } from '../assets/images/FileType/File/Archive/Solid/RAR.svg';
import { ReactComponent as FileArchiveSolidZIP } from '../assets/images/FileType/File/Archive/Solid/ZIP.svg';
import { ReactComponent as FileDevelopmentDefaultAvro } from '../assets/images/FileType/File/Development/Default/Avro.svg';
import { ReactComponent as FileDevelopmentDefaultBinary } from '../assets/images/FileType/File/Development/Default/Binary.svg';
import { ReactComponent as FileDevelopmentDefaultCSS } from '../assets/images/FileType/File/Development/Default/CSS.svg';
import { ReactComponent as FileDevelopmentDefaultDataGenerator } from '../assets/images/FileType/File/Development/Default/DataGenerator.svg';
import { ReactComponent as FileDevelopmentDefaultDelta } from '../assets/images/FileType/File/Development/Default/Delta.svg';
import { ReactComponent as FileDevelopmentDefaultFixed } from '../assets/images/FileType/File/Development/Default/Fixed.svg';
import { ReactComponent as FileDevelopmentDefaultFTP } from '../assets/images/FileType/File/Development/Default/FTP.svg';
import { ReactComponent as FileDevelopmentDefaultHTML } from '../assets/images/FileType/File/Development/Default/HTML.svg';
import { ReactComponent as FileDevelopmentDefaultHudi } from '../assets/images/FileType/File/Development/Default/Hudi.svg';
import { ReactComponent as FileDevelopmentDefaultJAVA } from '../assets/images/FileType/File/Development/Default/JAVA.svg';
import { ReactComponent as FileDevelopmentDefaultJS } from '../assets/images/FileType/File/Development/Default/JS.svg';
import { ReactComponent as FileDevelopmentDefaultJSON } from '../assets/images/FileType/File/Development/Default/JSON.svg';
import { ReactComponent as FileDevelopmentDefaultKafka } from '../assets/images/FileType/File/Development/Default/Kafka.svg';
import { ReactComponent as FileDevelopmentDefaultORC } from '../assets/images/FileType/File/Development/Default/ORC.svg';
import { ReactComponent as FileDevelopmentDefaultParquet } from '../assets/images/FileType/File/Development/Default/Parquet.svg';
import { ReactComponent as FileDevelopmentDefaultPython } from '../assets/images/FileType/File/Development/Default/Python.svg';
import { ReactComponent as FileDevelopmentDefaultSeed } from '../assets/images/FileType/File/Development/Default/Seed.svg';
import { ReactComponent as FileDevelopmentDefaultSFTP } from '../assets/images/FileType/File/Development/Default/SFTP.svg';
import { ReactComponent as FileDevelopmentDefaultSharepoint } from '../assets/images/FileType/File/Development/Default/Sharepoint.svg';
import { ReactComponent as FileDevelopmentDefaultSQL } from '../assets/images/FileType/File/Development/Default/SQL.svg';
import { ReactComponent as FileDevelopmentDefaultSynthetic } from '../assets/images/FileType/File/Development/Default/Synthetic.svg';
import { ReactComponent as FileDevelopmentDefaultXML } from '../assets/images/FileType/File/Development/Default/XML.svg';
import { ReactComponent as FileDevelopmentGrayAvro } from '../assets/images/FileType/File/Development/Gray/Avro.svg';
import { ReactComponent as FileDevelopmentGrayBinary } from '../assets/images/FileType/File/Development/Gray/Binary.svg';
import { ReactComponent as FileDevelopmentGrayCSS } from '../assets/images/FileType/File/Development/Gray/CSS.svg';
import { ReactComponent as FileDevelopmentGrayDataGenerator } from '../assets/images/FileType/File/Development/Gray/DataGenerator.svg';
import { ReactComponent as FileDevelopmentGrayDelta } from '../assets/images/FileType/File/Development/Gray/Delta.svg';
import { ReactComponent as FileDevelopmentGrayFixed } from '../assets/images/FileType/File/Development/Gray/Fixed.svg';
import { ReactComponent as FileDevelopmentGrayFTP } from '../assets/images/FileType/File/Development/Gray/FTP.svg';
import { ReactComponent as FileDevelopmentGrayHTML } from '../assets/images/FileType/File/Development/Gray/HTML.svg';
import { ReactComponent as FileDevelopmentGrayHudi } from '../assets/images/FileType/File/Development/Gray/Hudi.svg';
import { ReactComponent as FileDevelopmentGrayJAVA } from '../assets/images/FileType/File/Development/Gray/JAVA.svg';
import { ReactComponent as FileDevelopmentGrayJS } from '../assets/images/FileType/File/Development/Gray/JS.svg';
import { ReactComponent as FileDevelopmentGrayJSON } from '../assets/images/FileType/File/Development/Gray/JSON.svg';
import { ReactComponent as FileDevelopmentGrayKafka } from '../assets/images/FileType/File/Development/Gray/Kafka.svg';
import { ReactComponent as FileDevelopmentGrayORC } from '../assets/images/FileType/File/Development/Gray/ORC.svg';
import { ReactComponent as FileDevelopmentGrayParquet } from '../assets/images/FileType/File/Development/Gray/Parquet.svg';
import { ReactComponent as FileDevelopmentGrayPython } from '../assets/images/FileType/File/Development/Gray/Python.svg';
import { ReactComponent as FileDevelopmentGraySeed } from '../assets/images/FileType/File/Development/Gray/Seed.svg';
import { ReactComponent as FileDevelopmentGraySFTP } from '../assets/images/FileType/File/Development/Gray/SFTP.svg';
import { ReactComponent as FileDevelopmentGraySharepoint } from '../assets/images/FileType/File/Development/Gray/Sharepoint.svg';
import { ReactComponent as FileDevelopmentGraySQL } from '../assets/images/FileType/File/Development/Gray/SQL.svg';
import { ReactComponent as FileDevelopmentGraySynthetic } from '../assets/images/FileType/File/Development/Gray/Synthetic.svg';
import { ReactComponent as FileDevelopmentGrayXML } from '../assets/images/FileType/File/Development/Gray/XML.svg';
import { ReactComponent as FileDevelopmentIconAvro } from '../assets/images/FileType/File/Development/Icon/Avro.svg';
import { ReactComponent as FileDevelopmentIconAzure } from '../assets/images/FileType/File/Development/Icon/Azure.svg';
import { ReactComponent as FileDevelopmentIconAzureBlobStorage } from '../assets/images/FileType/File/Development/Icon/AzureBlobStorage.svg';
import { ReactComponent as FileDevelopmentIconCSS } from '../assets/images/FileType/File/Development/Icon/CSS.svg';
import { ReactComponent as FileDevelopmentIconDelta } from '../assets/images/FileType/File/Development/Icon/Delta.svg';
import { ReactComponent as FileDevelopmentIconGoogleCloud } from '../assets/images/FileType/File/Development/Icon/GoogleCloud.svg';
import { ReactComponent as FileDevelopmentIconGoogleDrive } from '../assets/images/FileType/File/Development/Icon/GoogleDrive.svg';
import { ReactComponent as FileDevelopmentIconHTML } from '../assets/images/FileType/File/Development/Icon/HTML.svg';
import { ReactComponent as FileDevelopmentIconHudi } from '../assets/images/FileType/File/Development/Icon/Hudi.svg';
import { ReactComponent as FileDevelopmentIconJAVA } from '../assets/images/FileType/File/Development/Icon/JAVA.svg';
import { ReactComponent as FileDevelopmentIconJS } from '../assets/images/FileType/File/Development/Icon/JS.svg';
import { ReactComponent as FileDevelopmentIconKafka } from '../assets/images/FileType/File/Development/Icon/Kafka.svg';
import { ReactComponent as FileDevelopmentIconORC } from '../assets/images/FileType/File/Development/Icon/ORC.svg';
import { ReactComponent as FileDevelopmentIconParquet } from '../assets/images/FileType/File/Development/Icon/Parquet.svg';
import { ReactComponent as FileDevelopmentIconPython } from '../assets/images/FileType/File/Development/Icon/Python.svg';
import { ReactComponent as FileDevelopmentIconS3 } from '../assets/images/FileType/File/Development/Icon/S3.svg';
import { ReactComponent as FileDevelopmentIconS3Alt } from '../assets/images/FileType/File/Development/Icon/S3Alt.svg';
import { ReactComponent as FileDevelopmentIconSeed } from '../assets/images/FileType/File/Development/Icon/Seed.svg';
import { ReactComponent as FileDevelopmentIconSharepoint } from '../assets/images/FileType/File/Development/Icon/Sharepoint.svg';
import { ReactComponent as FileDevelopmentIconSharesheet } from '../assets/images/FileType/File/Development/Icon/Sharesheet.svg';
import { ReactComponent as FileDevelopmentIconSmartSheet } from '../assets/images/FileType/File/Development/Icon/SmartSheet.svg';
import { ReactComponent as FileDevelopmentIconSynthetic } from '../assets/images/FileType/File/Development/Icon/Synthetic.svg';
import { ReactComponent as FileDevelopmentSolidAvro } from '../assets/images/FileType/File/Development/Solid/Avro.svg';
import { ReactComponent as FileDevelopmentSolidBinary } from '../assets/images/FileType/File/Development/Solid/Binary.svg';
import { ReactComponent as FileDevelopmentSolidCSS } from '../assets/images/FileType/File/Development/Solid/CSS.svg';
import { ReactComponent as FileDevelopmentSolidDataGenerator } from '../assets/images/FileType/File/Development/Solid/DataGenerator.svg';
import { ReactComponent as FileDevelopmentSolidDelta } from '../assets/images/FileType/File/Development/Solid/Delta.svg';
import { ReactComponent as FileDevelopmentSolidFixed } from '../assets/images/FileType/File/Development/Solid/Fixed.svg';
import { ReactComponent as FileDevelopmentSolidFTP } from '../assets/images/FileType/File/Development/Solid/FTP.svg';
import { ReactComponent as FileDevelopmentSolidHTML } from '../assets/images/FileType/File/Development/Solid/HTML.svg';
import { ReactComponent as FileDevelopmentSolidHudi } from '../assets/images/FileType/File/Development/Solid/Hudi.svg';
import { ReactComponent as FileDevelopmentSolidJAVA } from '../assets/images/FileType/File/Development/Solid/JAVA.svg';
import { ReactComponent as FileDevelopmentSolidJS } from '../assets/images/FileType/File/Development/Solid/JS.svg';
import { ReactComponent as FileDevelopmentSolidJSON } from '../assets/images/FileType/File/Development/Solid/JSON.svg';
import { ReactComponent as FileDevelopmentSolidKafka } from '../assets/images/FileType/File/Development/Solid/Kafka.svg';
import { ReactComponent as FileDevelopmentSolidORC } from '../assets/images/FileType/File/Development/Solid/ORC.svg';
import { ReactComponent as FileDevelopmentSolidParquet } from '../assets/images/FileType/File/Development/Solid/Parquet.svg';
import { ReactComponent as FileDevelopmentSolidPython } from '../assets/images/FileType/File/Development/Solid/Python.svg';
import { ReactComponent as FileDevelopmentSolidSeed } from '../assets/images/FileType/File/Development/Solid/Seed.svg';
import { ReactComponent as FileDevelopmentSolidSFTP } from '../assets/images/FileType/File/Development/Solid/SFTP.svg';
import { ReactComponent as FileDevelopmentSolidSharepoint } from '../assets/images/FileType/File/Development/Solid/Sharepoint.svg';
import { ReactComponent as FileDevelopmentSolidSQL } from '../assets/images/FileType/File/Development/Solid/SQL.svg';
import { ReactComponent as FileDevelopmentSolidSynthetic } from '../assets/images/FileType/File/Development/Solid/Synthetic.svg';
import { ReactComponent as FileDevelopmentSolidXML } from '../assets/images/FileType/File/Development/Solid/XML.svg';
import { ReactComponent as FileDocumentDefaultCSV } from '../assets/images/FileType/File/Document/Default/CSV.svg';
import { ReactComponent as FileDocumentDefaultDOC } from '../assets/images/FileType/File/Document/Default/DOC.svg';
import { ReactComponent as FileDocumentDefaultDOCX } from '../assets/images/FileType/File/Document/Default/DOCX.svg';
import { ReactComponent as FileDocumentDefaultPDF } from '../assets/images/FileType/File/Document/Default/PDF.svg';
import { ReactComponent as FileDocumentDefaultPPT } from '../assets/images/FileType/File/Document/Default/PPT.svg';
import { ReactComponent as FileDocumentDefaultPPTX } from '../assets/images/FileType/File/Document/Default/PPTX.svg';
import { ReactComponent as FileDocumentDefaultTXT } from '../assets/images/FileType/File/Document/Default/TXT.svg';
import { ReactComponent as FileDocumentDefaultXLS } from '../assets/images/FileType/File/Document/Default/XLS.svg';
import { ReactComponent as FileDocumentDefaultXLSX } from '../assets/images/FileType/File/Document/Default/XLSX.svg';
import { ReactComponent as FileDocumentGrayCSV } from '../assets/images/FileType/File/Document/Gray/CSV.svg';
import { ReactComponent as FileDocumentGrayDOC } from '../assets/images/FileType/File/Document/Gray/DOC.svg';
import { ReactComponent as FileDocumentGrayDOCX } from '../assets/images/FileType/File/Document/Gray/DOCX.svg';
import { ReactComponent as FileDocumentGrayPDF } from '../assets/images/FileType/File/Document/Gray/PDF.svg';
import { ReactComponent as FileDocumentGrayPPT } from '../assets/images/FileType/File/Document/Gray/PPT.svg';
import { ReactComponent as FileDocumentGrayPPTX } from '../assets/images/FileType/File/Document/Gray/PPTX.svg';
import { ReactComponent as FileDocumentGrayTXT } from '../assets/images/FileType/File/Document/Gray/TXT.svg';
import { ReactComponent as FileDocumentGrayXLS } from '../assets/images/FileType/File/Document/Gray/XLS.svg';
import { ReactComponent as FileDocumentGrayXLSX } from '../assets/images/FileType/File/Document/Gray/XLSX.svg';
import { ReactComponent as FileDocumentSolidCSV } from '../assets/images/FileType/File/Document/Solid/CSV.svg';
import { ReactComponent as FileDocumentSolidDOC } from '../assets/images/FileType/File/Document/Solid/DOC.svg';
import { ReactComponent as FileDocumentSolidDOCX } from '../assets/images/FileType/File/Document/Solid/DOCX.svg';
import { ReactComponent as FileDocumentSolidPDF } from '../assets/images/FileType/File/Document/Solid/PDF.svg';
import { ReactComponent as FileDocumentSolidPPT } from '../assets/images/FileType/File/Document/Solid/PPT.svg';
import { ReactComponent as FileDocumentSolidPPTX } from '../assets/images/FileType/File/Document/Solid/PPTX.svg';
import { ReactComponent as FileDocumentSolidTXT } from '../assets/images/FileType/File/Document/Solid/TXT.svg';
import { ReactComponent as FileDocumentSolidXLS } from '../assets/images/FileType/File/Document/Solid/XLS.svg';
import { ReactComponent as FileDocumentSolidXLSX } from '../assets/images/FileType/File/Document/Solid/XLSX.svg';
import { ReactComponent as FileGenericDefaultAudio } from '../assets/images/FileType/File/Generic/Default/Audio.svg';
import { ReactComponent as FileGenericDefaultCode } from '../assets/images/FileType/File/Generic/Default/Code.svg';
import { ReactComponent as FileGenericDefaultDocument } from '../assets/images/FileType/File/Generic/Default/Document.svg';
import { ReactComponent as FileGenericDefaultEmpty } from '../assets/images/FileType/File/Generic/Default/Empty.svg';
import { ReactComponent as FileGenericDefaultFolder } from '../assets/images/FileType/File/Generic/Default/Folder.svg';
import { ReactComponent as FileGenericDefaultImage } from '../assets/images/FileType/File/Generic/Default/Image.svg';
import { ReactComponent as FileGenericDefaultPDF } from '../assets/images/FileType/File/Generic/Default/PDF.svg';
import { ReactComponent as FileGenericDefaultSpreadsheet } from '../assets/images/FileType/File/Generic/Default/Spreadsheet.svg';
import { ReactComponent as FileGenericDefaultVideo01 } from '../assets/images/FileType/File/Generic/Default/Video01.svg';
import { ReactComponent as FileGenericDefaultVideo02 } from '../assets/images/FileType/File/Generic/Default/Video02.svg';
import { ReactComponent as FileGenericGrayAudio } from '../assets/images/FileType/File/Generic/Gray/Audio.svg';
import { ReactComponent as FileGenericGrayCode } from '../assets/images/FileType/File/Generic/Gray/Code.svg';
import { ReactComponent as FileGenericGrayDocument } from '../assets/images/FileType/File/Generic/Gray/Document.svg';
import { ReactComponent as FileGenericGrayEmpty } from '../assets/images/FileType/File/Generic/Gray/Empty.svg';
import { ReactComponent as FileGenericGrayFolder } from '../assets/images/FileType/File/Generic/Gray/Folder.svg';
import { ReactComponent as FileGenericGrayImage } from '../assets/images/FileType/File/Generic/Gray/Image.svg';
import { ReactComponent as FileGenericGrayPDF } from '../assets/images/FileType/File/Generic/Gray/PDF.svg';
import { ReactComponent as FileGenericGraySpreadsheet } from '../assets/images/FileType/File/Generic/Gray/Spreadsheet.svg';
import { ReactComponent as FileGenericGrayVideo01 } from '../assets/images/FileType/File/Generic/Gray/Video01.svg';
import { ReactComponent as FileGenericGrayVideo02 } from '../assets/images/FileType/File/Generic/Gray/Video02.svg';
import { ReactComponent as FileGenericSolidAudio } from '../assets/images/FileType/File/Generic/Solid/Audio.svg';
import { ReactComponent as FileGenericSolidCode } from '../assets/images/FileType/File/Generic/Solid/Code.svg';
import { ReactComponent as FileGenericSolidDocument } from '../assets/images/FileType/File/Generic/Solid/Document.svg';
import { ReactComponent as FileGenericSolidEmpty } from '../assets/images/FileType/File/Generic/Solid/Empty.svg';
import { ReactComponent as FileGenericSolidFolder } from '../assets/images/FileType/File/Generic/Solid/Folder.svg';
import { ReactComponent as FileGenericSolidImage } from '../assets/images/FileType/File/Generic/Solid/Image.svg';
import { ReactComponent as FileGenericSolidPDF } from '../assets/images/FileType/File/Generic/Solid/PDF.svg';
import { ReactComponent as FileGenericSolidSpreadsheet } from '../assets/images/FileType/File/Generic/Solid/Spreadsheet.svg';
import { ReactComponent as FileGenericSolidVideo01 } from '../assets/images/FileType/File/Generic/Solid/Video01.svg';
import { ReactComponent as FileGenericSolidVideo02 } from '../assets/images/FileType/File/Generic/Solid/Video02.svg';
import { ReactComponent as FileImageDefaultEPS } from '../assets/images/FileType/File/Image/Default/EPS.svg';
import { ReactComponent as FileImageDefaultGIF } from '../assets/images/FileType/File/Image/Default/GIF.svg';
import { ReactComponent as FileImageDefaultIMG } from '../assets/images/FileType/File/Image/Default/IMG.svg';
import { ReactComponent as FileImageDefaultJPEG } from '../assets/images/FileType/File/Image/Default/JPEG.svg';
import { ReactComponent as FileImageDefaultJPG } from '../assets/images/FileType/File/Image/Default/JPG.svg';
import { ReactComponent as FileImageDefaultPNG } from '../assets/images/FileType/File/Image/Default/PNG.svg';
import { ReactComponent as FileImageDefaultSVG } from '../assets/images/FileType/File/Image/Default/SVG.svg';
import { ReactComponent as FileImageDefaultTIFF } from '../assets/images/FileType/File/Image/Default/TIFF.svg';
import { ReactComponent as FileImageDefaultWebP } from '../assets/images/FileType/File/Image/Default/WebP.svg';
import { ReactComponent as FileImageGrayEPS } from '../assets/images/FileType/File/Image/Gray/EPS.svg';
import { ReactComponent as FileImageGrayGIF } from '../assets/images/FileType/File/Image/Gray/GIF.svg';
import { ReactComponent as FileImageGrayIMG } from '../assets/images/FileType/File/Image/Gray/IMG.svg';
import { ReactComponent as FileImageGrayJPEG } from '../assets/images/FileType/File/Image/Gray/JPEG.svg';
import { ReactComponent as FileImageGrayJPG } from '../assets/images/FileType/File/Image/Gray/JPG.svg';
import { ReactComponent as FileImageGrayPNG } from '../assets/images/FileType/File/Image/Gray/PNG.svg';
import { ReactComponent as FileImageGraySVG } from '../assets/images/FileType/File/Image/Gray/SVG.svg';
import { ReactComponent as FileImageGrayTIFF } from '../assets/images/FileType/File/Image/Gray/TIFF.svg';
import { ReactComponent as FileImageGrayWebP } from '../assets/images/FileType/File/Image/Gray/WebP.svg';
import { ReactComponent as FileImageSolidEPS } from '../assets/images/FileType/File/Image/Solid/EPS.svg';
import { ReactComponent as FileImageSolidGIF } from '../assets/images/FileType/File/Image/Solid/GIF.svg';
import { ReactComponent as FileImageSolidIMG } from '../assets/images/FileType/File/Image/Solid/IMG.svg';
import { ReactComponent as FileImageSolidJPEG } from '../assets/images/FileType/File/Image/Solid/JPEG.svg';
import { ReactComponent as FileImageSolidJPG } from '../assets/images/FileType/File/Image/Solid/JPG.svg';
import { ReactComponent as FileImageSolidPNG } from '../assets/images/FileType/File/Image/Solid/PNG.svg';
import { ReactComponent as FileImageSolidSVG } from '../assets/images/FileType/File/Image/Solid/SVG.svg';
import { ReactComponent as FileImageSolidTIFF } from '../assets/images/FileType/File/Image/Solid/TIFF.svg';
import { ReactComponent as FileImageSolidWebP } from '../assets/images/FileType/File/Image/Solid/WebP.svg';
import { ReactComponent as FolderBrandClosed } from '../assets/images/FileType/Folder/BrandClosed.svg';
import { ReactComponent as FolderBrandOpen } from '../assets/images/FileType/Folder/BrandOpen.svg';
import { ReactComponent as FolderGrayClosed } from '../assets/images/FileType/Folder/GrayClosed.svg';
import { ReactComponent as FolderGrayOpen } from '../assets/images/FileType/Folder/GrayOpen.svg';
import { ReactComponent as WarehouseBigQuery } from '../assets/images/FileType/Warehouse/BigQuery.svg';
import { ReactComponent as WarehouseCosmoDB } from '../assets/images/FileType/Warehouse/CosmoDB.svg';
import { ReactComponent as WarehouseDatabricks } from '../assets/images/FileType/Warehouse/Databricks.svg';
import { ReactComponent as WarehouseDatabricksVolumes } from '../assets/images/FileType/Warehouse/DatabricksVolumes.svg';
import { ReactComponent as WarehouseEmpty } from '../assets/images/FileType/Warehouse/Empty.svg';
import { ReactComponent as WarehouseJDBC } from '../assets/images/FileType/Warehouse/JDBC.svg';
import { ReactComponent as WarehouseMongoDB } from '../assets/images/FileType/Warehouse/MongoDB.svg';
import { ReactComponent as WarehouseMssql } from '../assets/images/FileType/Warehouse/Mssql.svg';
import { ReactComponent as WarehousePinecone } from '../assets/images/FileType/Warehouse/Pinecone.svg';
import { ReactComponent as WarehousePostgreSQL1 } from '../assets/images/FileType/Warehouse/PostgreSQL-1.svg';
import { ReactComponent as WarehousePostgreSQL } from '../assets/images/FileType/Warehouse/PostgreSQL.svg';
import { ReactComponent as WarehouseRedshift } from '../assets/images/FileType/Warehouse/Redshift.svg';
import { ReactComponent as WarehouseSnowflake } from '../assets/images/FileType/Warehouse/Snowflake.svg';
import { ReactComponent as WarehouseSplunk } from '../assets/images/FileType/Warehouse/Splunk.svg';
import { ReactComponent as WarehouseSynapse } from '../assets/images/FileType/Warehouse/Synapse.svg';
import { ReactComponent as WarehouseTrino } from '../assets/images/FileType/Warehouse/Trino.svg';
const withLogoClass = (WrappedComponent) => {
    return (props) => (_jsx(WrappedComponent, { ...props, className: `${LOGO_ICON_CLASS} ${props.className || ""}` }));
};
export const ApplicationGoogleAnalyticsIcon = withLogoClass(ApplicationGoogleAnalytics);
export const ApplicationPowerBIIcon = withLogoClass(ApplicationPowerBI);
export const ApplicationSalesforceIcon = withLogoClass(ApplicationSalesforce);
export const ApplicationSlackIcon = withLogoClass(ApplicationSlack);
export const ApplicationSmartsheetsIcon = withLogoClass(ApplicationSmartsheets);
export const ApplicationSMTPIcon = withLogoClass(ApplicationSMTP);
export const ApplicationTableauIcon = withLogoClass(ApplicationTableau);
export const ApplicationWebPageIcon = withLogoClass(ApplicationWebPage);
export const CatalogGenericIcon = withLogoClass(CatalogGeneric);
export const CatalogIcebergIcon = withLogoClass(CatalogIceberg);
export const FileArchiveDefaultRARIcon = withLogoClass(FileArchiveDefaultRAR);
export const FileArchiveDefaultZIPIcon = withLogoClass(FileArchiveDefaultZIP);
export const FileArchiveGrayRARIcon = withLogoClass(FileArchiveGrayRAR);
export const FileArchiveGrayZIPIcon = withLogoClass(FileArchiveGrayZIP);
export const FileArchiveSolidRARIcon = withLogoClass(FileArchiveSolidRAR);
export const FileArchiveSolidZIPIcon = withLogoClass(FileArchiveSolidZIP);
export const FileDevelopmentDefaultAvroIcon = withLogoClass(FileDevelopmentDefaultAvro);
export const FileDevelopmentDefaultBinaryIcon = withLogoClass(FileDevelopmentDefaultBinary);
export const FileDevelopmentDefaultCSSIcon = withLogoClass(FileDevelopmentDefaultCSS);
export const FileDevelopmentDefaultDataGeneratorIcon = withLogoClass(FileDevelopmentDefaultDataGenerator);
export const FileDevelopmentDefaultDeltaIcon = withLogoClass(FileDevelopmentDefaultDelta);
export const FileDevelopmentDefaultFixedIcon = withLogoClass(FileDevelopmentDefaultFixed);
export const FileDevelopmentDefaultFTPIcon = withLogoClass(FileDevelopmentDefaultFTP);
export const FileDevelopmentDefaultHTMLIcon = withLogoClass(FileDevelopmentDefaultHTML);
export const FileDevelopmentDefaultHudiIcon = withLogoClass(FileDevelopmentDefaultHudi);
export const FileDevelopmentDefaultJAVAIcon = withLogoClass(FileDevelopmentDefaultJAVA);
export const FileDevelopmentDefaultJSIcon = withLogoClass(FileDevelopmentDefaultJS);
export const FileDevelopmentDefaultJSONIcon = withLogoClass(FileDevelopmentDefaultJSON);
export const FileDevelopmentDefaultKafkaIcon = withLogoClass(FileDevelopmentDefaultKafka);
export const FileDevelopmentDefaultORCIcon = withLogoClass(FileDevelopmentDefaultORC);
export const FileDevelopmentDefaultParquetIcon = withLogoClass(FileDevelopmentDefaultParquet);
export const FileDevelopmentDefaultPythonIcon = withLogoClass(FileDevelopmentDefaultPython);
export const FileDevelopmentDefaultSeedIcon = withLogoClass(FileDevelopmentDefaultSeed);
export const FileDevelopmentDefaultSFTPIcon = withLogoClass(FileDevelopmentDefaultSFTP);
export const FileDevelopmentDefaultSharepointIcon = withLogoClass(FileDevelopmentDefaultSharepoint);
export const FileDevelopmentDefaultSQLIcon = withLogoClass(FileDevelopmentDefaultSQL);
export const FileDevelopmentDefaultSyntheticIcon = withLogoClass(FileDevelopmentDefaultSynthetic);
export const FileDevelopmentDefaultXMLIcon = withLogoClass(FileDevelopmentDefaultXML);
export const FileDevelopmentGrayAvroIcon = withLogoClass(FileDevelopmentGrayAvro);
export const FileDevelopmentGrayBinaryIcon = withLogoClass(FileDevelopmentGrayBinary);
export const FileDevelopmentGrayCSSIcon = withLogoClass(FileDevelopmentGrayCSS);
export const FileDevelopmentGrayDataGeneratorIcon = withLogoClass(FileDevelopmentGrayDataGenerator);
export const FileDevelopmentGrayDeltaIcon = withLogoClass(FileDevelopmentGrayDelta);
export const FileDevelopmentGrayFixedIcon = withLogoClass(FileDevelopmentGrayFixed);
export const FileDevelopmentGrayFTPIcon = withLogoClass(FileDevelopmentGrayFTP);
export const FileDevelopmentGrayHTMLIcon = withLogoClass(FileDevelopmentGrayHTML);
export const FileDevelopmentGrayHudiIcon = withLogoClass(FileDevelopmentGrayHudi);
export const FileDevelopmentGrayJAVAIcon = withLogoClass(FileDevelopmentGrayJAVA);
export const FileDevelopmentGrayJSIcon = withLogoClass(FileDevelopmentGrayJS);
export const FileDevelopmentGrayJSONIcon = withLogoClass(FileDevelopmentGrayJSON);
export const FileDevelopmentGrayKafkaIcon = withLogoClass(FileDevelopmentGrayKafka);
export const FileDevelopmentGrayORCIcon = withLogoClass(FileDevelopmentGrayORC);
export const FileDevelopmentGrayParquetIcon = withLogoClass(FileDevelopmentGrayParquet);
export const FileDevelopmentGrayPythonIcon = withLogoClass(FileDevelopmentGrayPython);
export const FileDevelopmentGraySeedIcon = withLogoClass(FileDevelopmentGraySeed);
export const FileDevelopmentGraySFTPIcon = withLogoClass(FileDevelopmentGraySFTP);
export const FileDevelopmentGraySharepointIcon = withLogoClass(FileDevelopmentGraySharepoint);
export const FileDevelopmentGraySQLIcon = withLogoClass(FileDevelopmentGraySQL);
export const FileDevelopmentGraySyntheticIcon = withLogoClass(FileDevelopmentGraySynthetic);
export const FileDevelopmentGrayXMLIcon = withLogoClass(FileDevelopmentGrayXML);
export const FileDevelopmentIconAvroIcon = withLogoClass(FileDevelopmentIconAvro);
export const FileDevelopmentIconAzureIcon = withLogoClass(FileDevelopmentIconAzure);
export const FileDevelopmentIconAzureBlobStorageIcon = withLogoClass(FileDevelopmentIconAzureBlobStorage);
export const FileDevelopmentIconCSSIcon = withLogoClass(FileDevelopmentIconCSS);
export const FileDevelopmentIconDeltaIcon = withLogoClass(FileDevelopmentIconDelta);
export const FileDevelopmentIconGoogleCloudIcon = withLogoClass(FileDevelopmentIconGoogleCloud);
export const FileDevelopmentIconGoogleDriveIcon = withLogoClass(FileDevelopmentIconGoogleDrive);
export const FileDevelopmentIconHTMLIcon = withLogoClass(FileDevelopmentIconHTML);
export const FileDevelopmentIconHudiIcon = withLogoClass(FileDevelopmentIconHudi);
export const FileDevelopmentIconJAVAIcon = withLogoClass(FileDevelopmentIconJAVA);
export const FileDevelopmentIconJSIcon = withLogoClass(FileDevelopmentIconJS);
export const FileDevelopmentIconKafkaIcon = withLogoClass(FileDevelopmentIconKafka);
export const FileDevelopmentIconORCIcon = withLogoClass(FileDevelopmentIconORC);
export const FileDevelopmentIconParquetIcon = withLogoClass(FileDevelopmentIconParquet);
export const FileDevelopmentIconPythonIcon = withLogoClass(FileDevelopmentIconPython);
export const FileDevelopmentIconS3Icon = withLogoClass(FileDevelopmentIconS3);
export const FileDevelopmentIconS3AltIcon = withLogoClass(FileDevelopmentIconS3Alt);
export const FileDevelopmentIconSeedIcon = withLogoClass(FileDevelopmentIconSeed);
export const FileDevelopmentIconSharepointIcon = withLogoClass(FileDevelopmentIconSharepoint);
export const FileDevelopmentIconSharesheetIcon = withLogoClass(FileDevelopmentIconSharesheet);
export const FileDevelopmentIconSmartSheetIcon = withLogoClass(FileDevelopmentIconSmartSheet);
export const FileDevelopmentIconSyntheticIcon = withLogoClass(FileDevelopmentIconSynthetic);
export const FileDevelopmentSolidAvroIcon = withLogoClass(FileDevelopmentSolidAvro);
export const FileDevelopmentSolidBinaryIcon = withLogoClass(FileDevelopmentSolidBinary);
export const FileDevelopmentSolidCSSIcon = withLogoClass(FileDevelopmentSolidCSS);
export const FileDevelopmentSolidDataGeneratorIcon = withLogoClass(FileDevelopmentSolidDataGenerator);
export const FileDevelopmentSolidDeltaIcon = withLogoClass(FileDevelopmentSolidDelta);
export const FileDevelopmentSolidFixedIcon = withLogoClass(FileDevelopmentSolidFixed);
export const FileDevelopmentSolidFTPIcon = withLogoClass(FileDevelopmentSolidFTP);
export const FileDevelopmentSolidHTMLIcon = withLogoClass(FileDevelopmentSolidHTML);
export const FileDevelopmentSolidHudiIcon = withLogoClass(FileDevelopmentSolidHudi);
export const FileDevelopmentSolidJAVAIcon = withLogoClass(FileDevelopmentSolidJAVA);
export const FileDevelopmentSolidJSIcon = withLogoClass(FileDevelopmentSolidJS);
export const FileDevelopmentSolidJSONIcon = withLogoClass(FileDevelopmentSolidJSON);
export const FileDevelopmentSolidKafkaIcon = withLogoClass(FileDevelopmentSolidKafka);
export const FileDevelopmentSolidORCIcon = withLogoClass(FileDevelopmentSolidORC);
export const FileDevelopmentSolidParquetIcon = withLogoClass(FileDevelopmentSolidParquet);
export const FileDevelopmentSolidPythonIcon = withLogoClass(FileDevelopmentSolidPython);
export const FileDevelopmentSolidSeedIcon = withLogoClass(FileDevelopmentSolidSeed);
export const FileDevelopmentSolidSFTPIcon = withLogoClass(FileDevelopmentSolidSFTP);
export const FileDevelopmentSolidSharepointIcon = withLogoClass(FileDevelopmentSolidSharepoint);
export const FileDevelopmentSolidSQLIcon = withLogoClass(FileDevelopmentSolidSQL);
export const FileDevelopmentSolidSyntheticIcon = withLogoClass(FileDevelopmentSolidSynthetic);
export const FileDevelopmentSolidXMLIcon = withLogoClass(FileDevelopmentSolidXML);
export const FileDocumentDefaultCSVIcon = withLogoClass(FileDocumentDefaultCSV);
export const FileDocumentDefaultDOCIcon = withLogoClass(FileDocumentDefaultDOC);
export const FileDocumentDefaultDOCXIcon = withLogoClass(FileDocumentDefaultDOCX);
export const FileDocumentDefaultPDFIcon = withLogoClass(FileDocumentDefaultPDF);
export const FileDocumentDefaultPPTIcon = withLogoClass(FileDocumentDefaultPPT);
export const FileDocumentDefaultPPTXIcon = withLogoClass(FileDocumentDefaultPPTX);
export const FileDocumentDefaultTXTIcon = withLogoClass(FileDocumentDefaultTXT);
export const FileDocumentDefaultXLSIcon = withLogoClass(FileDocumentDefaultXLS);
export const FileDocumentDefaultXLSXIcon = withLogoClass(FileDocumentDefaultXLSX);
export const FileDocumentGrayCSVIcon = withLogoClass(FileDocumentGrayCSV);
export const FileDocumentGrayDOCIcon = withLogoClass(FileDocumentGrayDOC);
export const FileDocumentGrayDOCXIcon = withLogoClass(FileDocumentGrayDOCX);
export const FileDocumentGrayPDFIcon = withLogoClass(FileDocumentGrayPDF);
export const FileDocumentGrayPPTIcon = withLogoClass(FileDocumentGrayPPT);
export const FileDocumentGrayPPTXIcon = withLogoClass(FileDocumentGrayPPTX);
export const FileDocumentGrayTXTIcon = withLogoClass(FileDocumentGrayTXT);
export const FileDocumentGrayXLSIcon = withLogoClass(FileDocumentGrayXLS);
export const FileDocumentGrayXLSXIcon = withLogoClass(FileDocumentGrayXLSX);
export const FileDocumentSolidCSVIcon = withLogoClass(FileDocumentSolidCSV);
export const FileDocumentSolidDOCIcon = withLogoClass(FileDocumentSolidDOC);
export const FileDocumentSolidDOCXIcon = withLogoClass(FileDocumentSolidDOCX);
export const FileDocumentSolidPDFIcon = withLogoClass(FileDocumentSolidPDF);
export const FileDocumentSolidPPTIcon = withLogoClass(FileDocumentSolidPPT);
export const FileDocumentSolidPPTXIcon = withLogoClass(FileDocumentSolidPPTX);
export const FileDocumentSolidTXTIcon = withLogoClass(FileDocumentSolidTXT);
export const FileDocumentSolidXLSIcon = withLogoClass(FileDocumentSolidXLS);
export const FileDocumentSolidXLSXIcon = withLogoClass(FileDocumentSolidXLSX);
export const FileGenericDefaultAudioIcon = withLogoClass(FileGenericDefaultAudio);
export const FileGenericDefaultCodeIcon = withLogoClass(FileGenericDefaultCode);
export const FileGenericDefaultDocumentIcon = withLogoClass(FileGenericDefaultDocument);
export const FileGenericDefaultEmptyIcon = withLogoClass(FileGenericDefaultEmpty);
export const FileGenericDefaultFolderIcon = withLogoClass(FileGenericDefaultFolder);
export const FileGenericDefaultImageIcon = withLogoClass(FileGenericDefaultImage);
export const FileGenericDefaultPDFIcon = withLogoClass(FileGenericDefaultPDF);
export const FileGenericDefaultSpreadsheetIcon = withLogoClass(FileGenericDefaultSpreadsheet);
export const FileGenericDefaultVideo01Icon = withLogoClass(FileGenericDefaultVideo01);
export const FileGenericDefaultVideo02Icon = withLogoClass(FileGenericDefaultVideo02);
export const FileGenericGrayAudioIcon = withLogoClass(FileGenericGrayAudio);
export const FileGenericGrayCodeIcon = withLogoClass(FileGenericGrayCode);
export const FileGenericGrayDocumentIcon = withLogoClass(FileGenericGrayDocument);
export const FileGenericGrayEmptyIcon = withLogoClass(FileGenericGrayEmpty);
export const FileGenericGrayFolderIcon = withLogoClass(FileGenericGrayFolder);
export const FileGenericGrayImageIcon = withLogoClass(FileGenericGrayImage);
export const FileGenericGrayPDFIcon = withLogoClass(FileGenericGrayPDF);
export const FileGenericGraySpreadsheetIcon = withLogoClass(FileGenericGraySpreadsheet);
export const FileGenericGrayVideo01Icon = withLogoClass(FileGenericGrayVideo01);
export const FileGenericGrayVideo02Icon = withLogoClass(FileGenericGrayVideo02);
export const FileGenericSolidAudioIcon = withLogoClass(FileGenericSolidAudio);
export const FileGenericSolidCodeIcon = withLogoClass(FileGenericSolidCode);
export const FileGenericSolidDocumentIcon = withLogoClass(FileGenericSolidDocument);
export const FileGenericSolidEmptyIcon = withLogoClass(FileGenericSolidEmpty);
export const FileGenericSolidFolderIcon = withLogoClass(FileGenericSolidFolder);
export const FileGenericSolidImageIcon = withLogoClass(FileGenericSolidImage);
export const FileGenericSolidPDFIcon = withLogoClass(FileGenericSolidPDF);
export const FileGenericSolidSpreadsheetIcon = withLogoClass(FileGenericSolidSpreadsheet);
export const FileGenericSolidVideo01Icon = withLogoClass(FileGenericSolidVideo01);
export const FileGenericSolidVideo02Icon = withLogoClass(FileGenericSolidVideo02);
export const FileImageDefaultEPSIcon = withLogoClass(FileImageDefaultEPS);
export const FileImageDefaultGIFIcon = withLogoClass(FileImageDefaultGIF);
export const FileImageDefaultIMGIcon = withLogoClass(FileImageDefaultIMG);
export const FileImageDefaultJPEGIcon = withLogoClass(FileImageDefaultJPEG);
export const FileImageDefaultJPGIcon = withLogoClass(FileImageDefaultJPG);
export const FileImageDefaultPNGIcon = withLogoClass(FileImageDefaultPNG);
export const FileImageDefaultSVGIcon = withLogoClass(FileImageDefaultSVG);
export const FileImageDefaultTIFFIcon = withLogoClass(FileImageDefaultTIFF);
export const FileImageDefaultWebPIcon = withLogoClass(FileImageDefaultWebP);
export const FileImageGrayEPSIcon = withLogoClass(FileImageGrayEPS);
export const FileImageGrayGIFIcon = withLogoClass(FileImageGrayGIF);
export const FileImageGrayIMGIcon = withLogoClass(FileImageGrayIMG);
export const FileImageGrayJPEGIcon = withLogoClass(FileImageGrayJPEG);
export const FileImageGrayJPGIcon = withLogoClass(FileImageGrayJPG);
export const FileImageGrayPNGIcon = withLogoClass(FileImageGrayPNG);
export const FileImageGraySVGIcon = withLogoClass(FileImageGraySVG);
export const FileImageGrayTIFFIcon = withLogoClass(FileImageGrayTIFF);
export const FileImageGrayWebPIcon = withLogoClass(FileImageGrayWebP);
export const FileImageSolidEPSIcon = withLogoClass(FileImageSolidEPS);
export const FileImageSolidGIFIcon = withLogoClass(FileImageSolidGIF);
export const FileImageSolidIMGIcon = withLogoClass(FileImageSolidIMG);
export const FileImageSolidJPEGIcon = withLogoClass(FileImageSolidJPEG);
export const FileImageSolidJPGIcon = withLogoClass(FileImageSolidJPG);
export const FileImageSolidPNGIcon = withLogoClass(FileImageSolidPNG);
export const FileImageSolidSVGIcon = withLogoClass(FileImageSolidSVG);
export const FileImageSolidTIFFIcon = withLogoClass(FileImageSolidTIFF);
export const FileImageSolidWebPIcon = withLogoClass(FileImageSolidWebP);
export const FolderBrandClosedIcon = withLogoClass(FolderBrandClosed);
export const FolderBrandOpenIcon = withLogoClass(FolderBrandOpen);
export const FolderGrayClosedIcon = withLogoClass(FolderGrayClosed);
export const FolderGrayOpenIcon = withLogoClass(FolderGrayOpen);
export const WarehouseBigQueryIcon = withLogoClass(WarehouseBigQuery);
export const WarehouseCosmoDBIcon = withLogoClass(WarehouseCosmoDB);
export const WarehouseDatabricksIcon = withLogoClass(WarehouseDatabricks);
export const WarehouseDatabricksVolumesIcon = withLogoClass(WarehouseDatabricksVolumes);
export const WarehouseEmptyIcon = withLogoClass(WarehouseEmpty);
export const WarehouseJDBCIcon = withLogoClass(WarehouseJDBC);
export const WarehouseMongoDBIcon = withLogoClass(WarehouseMongoDB);
export const WarehouseMssqlIcon = withLogoClass(WarehouseMssql);
export const WarehousePineconeIcon = withLogoClass(WarehousePinecone);
export const WarehousePostgreSQL1Icon = withLogoClass(WarehousePostgreSQL1);
export const WarehousePostgreSQLIcon = withLogoClass(WarehousePostgreSQL);
export const WarehouseRedshiftIcon = withLogoClass(WarehouseRedshift);
export const WarehouseSnowflakeIcon = withLogoClass(WarehouseSnowflake);
export const WarehouseSplunkIcon = withLogoClass(WarehouseSplunk);
export const WarehouseSynapseIcon = withLogoClass(WarehouseSynapse);
export const WarehouseTrinoIcon = withLogoClass(WarehouseTrino);
