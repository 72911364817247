import { jsx as _jsx } from "react/jsx-runtime";
import { StyledResize, StyledResizeIcon } from './styled';
import { tokens, RESIZE_HANDLE_CLASS } from './tokens';
export function Resizable({ direction, children, handleStyles, offset = parseInt(tokens.ResizeHandle.offset), ...props }) {
    const style = {
        [direction]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: tokens.ResizeHandle.zIndex,
            left: direction === 'left' && `-${offset}px`,
            top: direction === 'top' ? `-${offset}px` : (direction === 'left' || direction === 'right') && '0',
            right: direction === 'right' && `-${offset}px`,
            bottom: direction === 'bottom' && `-${offset}px`,
            fontSize: tokens.ResizeHandle.fontSize,
            ...handleStyles
        }
    };
    if (direction === 'top' || direction === 'bottom') {
        style[direction].height = '5px';
    }
    else {
        style[direction].width = '5px';
    }
    return (_jsx(StyledResize, { handleComponent: {
            [direction]: _jsx(StyledResizeIcon, { type: 'extended', direction: direction, style: { ...handleStyles } })
        }, handleStyles: style, handleClasses: { [direction]: RESIZE_HANDLE_CLASS }, enable: { [direction]: true }, boundsByDirection: true, ...props, children: children }));
}
