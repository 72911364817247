import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createPlatePlugin } from '@udecode/plate-common/react';
import { theme } from '../theme';
import { useMarkdownProps } from './context';
import { InsertImageButton } from './InsertImageButton';
import { LinkButton } from './LinkPlugin';
import { StyledFixedToolbar } from './styled';
import { TableActions } from './TableActions';
import { TextTypeSelect } from './TextTypeSelect';
import { BoldButton, UnderlineButton, ItalicButton, UnorderedListButton, OrderedListButton, CodeBlockButton } from './ToolbarComponents';
import { PluginType } from './types';
function FixedToolbar() {
    const { supportedPluginTypes } = useMarkdownProps();
    return (_jsxs(StyledFixedToolbar, { direction: 'horizontal', gap: theme.spaces.x4, alignY: 'center', children: [supportedPluginTypes.has(PluginType.TextType) && _jsx(TextTypeSelect, {}), supportedPluginTypes.has(PluginType.Bold) && _jsx(BoldButton, {}), supportedPluginTypes.has(PluginType.Italic) && _jsx(ItalicButton, {}), supportedPluginTypes.has(PluginType.Underline) && _jsx(UnderlineButton, {}), supportedPluginTypes.has(PluginType.Link) && _jsx(LinkButton, {}), supportedPluginTypes.has(PluginType.Code) && _jsx(CodeBlockButton, {}), supportedPluginTypes.has(PluginType.Image) && _jsx(InsertImageButton, {}), supportedPluginTypes.has(PluginType.List) && _jsx(UnorderedListButton, {}), supportedPluginTypes.has(PluginType.List) && _jsx(OrderedListButton, {}), supportedPluginTypes.has(PluginType.Table) && _jsx(TableActions, {})] }));
}
export const FixedToolbarPlugin = createPlatePlugin({
    key: 'fixed-toolbar',
    render: {
        beforeEditable: () => _jsx(FixedToolbar, {})
    }
});
