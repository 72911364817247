import { styled } from 'styled-components';
import { Stack } from '../../Layout';
import { getInterFontWeightCSS, theme } from '../../theme';
import { Text } from '../../Typography';
import { tokens } from './tokens';
export const StyledInput = styled.input `
  font-family: ${tokens.OTPInput.input.fontFamily};
  font-size: ${({ $size }) => tokens.OTPInput.size[$size].input.fontSize};
  line-height: ${({ $size }) => tokens.OTPInput.size[$size].input.lineHeight};
  ${({ $size }) => getInterFontWeightCSS(tokens.OTPInput.size[$size].input.fontWeight)};
  color: ${tokens.OTPInput.input.color};
  outline: none;
  height: ${({ $size }) => tokens.OTPInput.size[$size].input.height};
  min-width: ${({ $size }) => tokens.OTPInput.size[$size].input.minWidth};
  padding: ${({ $size }) => tokens.OTPInput.size[$size].input.padding};
  border: 1px solid ${tokens.OTPInput.state.default.borderColor};
  box-shadow: ${tokens.OTPInput.state.default.boxShadow};
  border-radius: ${({ $size }) => tokens.OTPInput.size[$size].input.borderRadius};
  background-color: ${tokens.OTPInput.input.backgroundColor};

  @media (max-width: ${theme.newBreakpoints.m}) {
    height: ${theme.sizes.x48};
    min-width: ${theme.sizes.x48};
    font-size: ${theme.fontSizes.x24};
  }

  &:focus {
    outline: none;

    &::placeholder {
      color: transparent;
    }
  }

  &:focus {
    box-shadow: ${tokens.OTPInput.state.focus.boxShadow};
    border: 2px solid ${tokens.OTPInput.state.focus.borderColor};
  }

  &:focus-visible {
    outline: none;
  }

  &:active,
  &:not([value='']) {
    border: 2px solid ${tokens.OTPInput.state.focus.borderColor};
  }

  &::placeholder {
    color: ${tokens.OTPInput.Separator.color};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
export const StyledLabel = styled(Text) `
  color: ${tokens.OTPInput.Label.color};
  font-size: ${tokens.OTPInput.Label.fontSize};
  line-height: ${tokens.OTPInput.Label.lineHeight};
  ${getInterFontWeightCSS(tokens.OTPInput.Label.fontWeight)};
`;
export const StyledHint = styled(Text) `
  color: ${tokens.OTPInput.Hint.color};
  font-size: ${tokens.OTPInput.Hint.fontSize};
  line-height: ${tokens.OTPInput.Hint.lineHeight};
  ${getInterFontWeightCSS(tokens.OTPInput.Hint.fontWeight)};
`;
export const VerificationCodeInputContainer = styled(Stack) `
  display: flex;
  flex-direction: column;
  gap: ${tokens.OTPInput.Container.gap};
`;
export const StyledInputContainer = styled(Stack) `
  display: flex;
  flex-direction: column;
  gap: ${tokens.OTPInput.Container.gap};
`;
export const StyledSeparator = styled.span `
  color: ${tokens.OTPInput.Separator.color};
  text-align: center;

  font-family: ${tokens.OTPInput.input.fontFamily};
  font-size: ${tokens.OTPInput.Separator.fontSize};
  ${getInterFontWeightCSS(tokens.OTPInput.Separator.fontWeight)};
  line-height: ${tokens.OTPInput.Separator.lineHeight};
`;
