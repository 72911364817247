import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FileGenericDefaultEmptyIcon } from '../Icons';
import { Stack } from '../Layout';
import { StyledEmptyWrap, StyledImageWrap, StyledEmptyDescription } from './styled';
import { tokens } from './tokens';
import { EmptySize } from './type';
// TODO: Default image need to be taken from the design
export function Empty({ image = _jsx(FileGenericDefaultEmptyIcon, {}), size = EmptySize.m, description = 'No Data', children, ...rest }) {
    return (_jsxs(StyledEmptyWrap, { alignY: 'center', ...rest, children: [_jsx(StyledImageWrap, { size: size, children: image }), children || description ? (_jsxs(Stack, { alignY: 'center', gap: tokens.Description.gap, children: [_jsx(StyledEmptyDescription, { elementType: 'p', level: 'sm', children: description }), children] })) : null] }));
}
