import { jsx as _jsx } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import { Stack } from '../Layout';
import { Checkbox } from './Checkbox';
import { tokens } from './tokens';
export function CheckboxGroup({ options, name, value, defaultValue, orientation = 'vertical', gap = tokens.CheckboxGroup.gap, onChange, ...restProps }) {
    const [checkedValues = [], setCheckedValues] = useControlledState({
        value,
        defaultValue,
        onChange
    });
    const checkedValuesMap = Object.fromEntries(checkedValues.map((value) => [value, true]));
    const _onChange = (value, checked) => {
        const newCheckedValues = checked
            ? [...checkedValues, value]
            : checkedValues.filter((checkedValue) => checkedValue !== value);
        setCheckedValues(newCheckedValues);
    };
    return (_jsx(Stack, { direction: orientation, gap: gap, ...restProps, children: options.map(({ key, label, value, disabled }, index) => (_jsx(Checkbox, { name: name, value: value, disabled: disabled, checked: Boolean(checkedValuesMap[value]), onChange: (checked) => _onChange(value, checked), children: label }, key ?? index))) }));
}
