import { Stack, theme, Text, Badges, BaseColor, Tooltip, Spinner, CRUMB_ATTR } from '@prophecy/ui';
import { DialogUtilConfig } from '@prophecy/ui/Dialog/types';
import { EXPRESSION_BOX_VALUE_ATTR } from '@prophecy/ui/ExpressionBox/base/constants';
import { ZapIcon } from '@prophecy/ui/Icons';
import { GemIconProps } from '@prophecy/ui/Icons/GemIcons';
import { TREE_NODE_CONTENT_WRAPPER_CLASS, TREE_NODE_CONTENT_WRAPPER_OPEN_CLASS } from '@prophecy/ui/Tree/tokens';
import { isEqual, noop } from 'lodash-es';
import { Action, Store } from 'redux';
import styled from 'styled-components';

import { SQL_PROVIDER } from '../../constants/sql';
import { UserFabricStatus, UserFabricStatusKnownStatus } from '../../context/types';
import { PropertiesDidChange, PropertiesDidSave } from '../../LSP/base/hook';
import { LSP } from '../../LSP/base/types';
import { useAutoPilot } from '../autoPilot';
import { getGemIcon } from '../categoryMap';
import { RUN_BUTTON_ATTR_NAME, RUN_BUTTON_ATTR_VALUE } from '../constants';
import { FabricProviderType } from '../Fabric/types';
import {
  BaseProcess,
  BaseProcessMetadata,
  BaseState,
  CommonActionTypes,
  GenericGraph,
  GenericGraphProcessType,
  GenericGraphProcesses,
  Connection as GraphConnection
} from '../types';
import { Entity } from '../types/Entity';
import { Private_Routes, getIDEUrl } from '../url';
import {
  CloseIconSelector,
  EB_EXIT_BTN_SELECTOR,
  ExpressionBuilderOverlaySelector,
  FXButtonSelector,
  Layers,
  TreeNodeColumnStyles
} from './constants';
import {
  delay,
  querySelector,
  renderMessage,
  Selector,
  waitForAttributeChange,
  waitForElementToRemoved,
  waitForEvent
} from './dom-util';
import { FlowToastProps, StepInstance } from './flow';
import { Processes } from './sql/constants';
import { OnboardingStatus } from './types';

const highlightColumnStyle = `background: ${theme.colors.secondary25};border: 1px solid ${theme.colors.secondary300};border-radius: 4px;`;
export const HighlightText = styled.span`
  ${highlightColumnStyle}
`;
const StyledBadges = styled(Badges)`
  height: ${theme.spaces.x24};
  span {
    gap: 0px;
    img {
      height: ${theme.spaces.x20};
      width: ${theme.spaces.x20};
    }
  }
`;
const badgeMap = {
  Source: {
    category: 'Source/Target',
    tone: BaseColor.secondary
  },
  Target: {
    category: 'Source/Target',
    tone: BaseColor.secondary
  },
  Model: {
    category: 'Custom',
    tone: BaseColor.pink
  },
  Join: {
    category: 'Join/Split',
    tone: BaseColor.purple
  },
  Reformat: {
    category: 'Transform',
    tone: BaseColor.fuchsia
  },
  Aggregate: {
    category: 'Transform',
    tone: BaseColor.fuchsia
  }
};
type GemNames = keyof typeof badgeMap;
export function GemBadges({
  name,
  label,
  icon
}: {
  icon?: React.FunctionComponent<GemIconProps>;
  name: GemNames;
  label?: string;
}) {
  const { category, tone } = badgeMap[name];
  const Icon = icon ? icon : getGemIcon(category);
  return (
    <StyledBadges size='s' icon={<Icon />} tone={tone}>
      {label || name}
    </StyledBadges>
  );
}

const SKIP_GEM_MESSAGE = (
  <Stack direction='horizontal' gap={theme.spaces.x4}>
    <ZapIcon type='default' />
    <span>Auto-configure this Gem</span>
  </Stack>
);
const SKIP_GEM_CONNECT = (
  <Stack direction='horizontal' gap={theme.spaces.x4}>
    <ZapIcon type='default' />
    <span>Auto-connect Gems</span>
  </Stack>
);
export function showToastForGem(step: StepInstance, toastProps: FlowToastProps, skipStepMessage?: React.ReactNode) {
  return step.operations.addToast({ ...toastProps, skipStepMessage: skipStepMessage ?? SKIP_GEM_MESSAGE });
}
function showToastForConnection(step: StepInstance, toastProps: FlowToastProps) {
  return step.operations.addToast({ ...toastProps, skipStepMessage: SKIP_GEM_CONNECT });
}

const sourcePortStyle = `border-left-color:${theme.colors.primary500};`;
function highLightSourcePort(element: Element) {
  const styleStr = element.getAttribute('style');
  if (!styleStr?.includes(sourcePortStyle)) {
    element.setAttribute('style', `${styleStr} ${sourcePortStyle}`);
  }
}
function resetSourcePortStyles(element: Element | null) {
  const styleStr = element?.getAttribute('style');
  if (styleStr?.includes(sourcePortStyle)) {
    element?.setAttribute('style', styleStr.replace(sourcePortStyle, ''));
  }
}
const targetPortStyle = `background:${theme.colors.primary500};`;

function highlightTargetPort(element: Element) {
  const styleStr = element.getAttribute('style');
  if (!styleStr?.includes(targetPortStyle)) {
    element.setAttribute('style', `${styleStr} ${targetPortStyle}`);
  }
}
function resetTargetPortStyles(element: Element | null) {
  const styleStr = element?.getAttribute('style');
  if (styleStr?.includes(targetPortStyle)) {
    element?.setAttribute('style', styleStr.replace(targetPortStyle, ''));
  }
}
function highlightConnectionPath(element: Element) {
  const connectionLineStyle = `stroke:${theme.colors.primary500};`;
  if (!element.getAttribute('stroke-dasharray')) {
    element.setAttribute('stroke-dasharray', '4');
    element.setAttribute('style', connectionLineStyle);
  }
}
function resetConnectionPath(element: Element | null) {
  element?.removeAttribute('style');
  element?.removeAttribute('stroke-dasharray');
}
const StraightConnectionStyles = {
  transform: `translate(0px, -24px)`
};
const edgeAnimationDuration = 750;
export async function waitForGemsToConnect<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(
  source: string,
  target: string,
  message: React.ReactNode,
  virtualGraph: G,
  step: StepInstance,
  indicatorStyles?: React.CSSProperties
) {
  const connection = getConnection(source, target, virtualGraph);
  const connectionLineSelector = `path[id="${connection?.id}"]`;
  const sourcePortSelector = `[data-handleid="${connection?.sourcePort}"][data-nodeid="${connection?.source}"]`;
  const targetPortSelector = `[data-handleid="${connection?.targetPort}"][data-nodeid="${connection?.target}"]`;
  const connectionSelector = `path[data-virtual="false"][data-source="${connection?.source}"][data-sourceHandleId="${connection?.sourcePort}"][data-target="${connection?.target}"][data-targetHandleId="${connection?.targetPort}"]`;
  // check if connection already exist
  const alreadyConnected = Boolean(querySelector(connectionSelector));
  if (!alreadyConnected) {
    await step.operations.waitForElement(connectionLineSelector);
    const cb1 = step.operations.syncElement(sourcePortSelector, highLightSourcePort, resetSourcePortStyles);
    const cb2 = step.operations.syncElement(targetPortSelector, highlightTargetPort, resetTargetPortStyles);
    const cb3 = step.operations.syncElement(connectionLineSelector, highlightConnectionPath, resetConnectionPath);
    const cb4 = animatePath(querySelector(connectionLineSelector) as SVGPathElement, edgeAnimationDuration);
    const removeArrow = await step.operations.showIndicator({
      selector: connectionLineSelector,
      waitForEvent: false,
      styles: {
        ...(indicatorStyles || StraightConnectionStyles)
      }
    });
    showToastForConnection(step, {
      content: message
    });
    step.operations.callbackContext.add(cb4);
    // wait for connection
    await step.operations.waitForElement(connectionSelector);
    cb1();
    cb2();
    cb3();
    cb4();
    removeArrow();
  }
}
const GEM_DIALOG_OPEN = 'GEM_DIALOG_OPEN';
export async function waitForGemDialogToOpen(processLabel: string, step: StepInstance) {
  await step.operations.waitForElement(`[data-test-id="Header Title"] input[value="${processLabel}"]`);
  return GEM_DIALOG_OPEN;
}
export async function waitForSourceDialogToOpen(processLabel: string, step: StepInstance) {
  await step.operations.waitForElement(getSourceDialogSelector(processLabel));
  return GEM_DIALOG_OPEN;
}
export function getSourceDialogSelector(processLabel: string): Selector {
  return [`[data-test-id="Header Title"]`, processLabel];
}
export function getPopoverRoot(elementInPopover: Selector) {
  const el = querySelector(elementInPopover);
  return el?.closest('[data-radix-popper-content-wrapper]') as Element;
}
export function getDialogRoot(elementInDialog: Selector) {
  const el = querySelector(elementInDialog);
  return el?.closest('[role="dialog"]')?.parentNode as Element;
}

export function getGemDialogRoot(processLabel: string) {
  return getDialogRoot(`[data-test-id="Header Title"] input[value="${processLabel}"]`);
}
export async function waitForGemToShow<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(processLabel: string, step: StepInstance, virtualGraph: G) {
  const process = getProcessByLabel(processLabel, virtualGraph);
  await step.operations.waitForElement(getGemSelector(process.id));
}

export async function waitForDialogToClose(
  step: StepInstance,
  dialogRoot: Element,
  onClose?: () => void,
  delayInterval?: number
) {
  await step.operations.asyncTask(() => waitForElementToRemoved(dialogRoot));
  onClose?.();
  // allow other promise to settle first, bcoz dialog can also get closed by hitting save button
  await delay(delayInterval ?? 500);
}
export async function waitForPopoverToClose(step: StepInstance, popoverRoot: Element) {
  const result = await step.operations.asyncTask(() => waitForElementToRemoved(popoverRoot));
  // allow other promise to settle first, bcoz dialog can also get closed by hitting save button
  await delay(500);
  return result as ReturnType<typeof waitForElementToRemoved>;
}
export async function showArrowOnGem<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>({
  processLabel,
  message,
  virtualGraph,
  step,
  indicatorStyles,
  skipStepMessage
}: {
  processLabel: string;
  message: React.ReactNode;
  virtualGraph: G;
  step: StepInstance;
  indicatorStyles?: React.CSSProperties;
  skipStepMessage?: React.ReactNode;
}) {
  const process = getProcessByLabel(processLabel, virtualGraph);
  const gemNodeSelector = `.react-flow__node[data-id="${process.id}"]`;

  showToastForGem(
    step,
    {
      content: renderMessage(<>{message}</>)
    },
    skipStepMessage
  );
  return step.operations.showIndicator({
    selector: gemNodeSelector,
    waitForEvent: false,
    styles: {
      ...(indicatorStyles || {})
    }
  });
}

export async function addProcessAndSuggestion<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(
  graph: G,
  newProcess: GenericGraphProcessType<G>,
  virtualGraph: G,
  processesPath: string,
  propertiesDidSave: PropertiesDidSave,
  propertiesDidChange: PropertiesDidChange,
  setSuggestion: ReturnType<typeof useAutoPilot>['setSuggestion']
) {
  const processes = graph.processes || {};
  const connections = graph.connections || [];
  const alreadyExist = Boolean(processes[newProcess.id]);
  if (!alreadyExist) {
    propertiesDidChange({
      property: `${processesPath}.${newProcess.id}`,
      value: newProcess
    });
    propertiesDidSave();
    const newProcesses = { ...processes, [newProcess.id]: newProcess };
    const suggestionGraph = getSuggestionGraph(virtualGraph, newProcesses, connections);
    setSuggestion({
      graph: suggestionGraph
    });
  }
}

export function getProcessByLabel<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(label: string, graph: G) {
  return Object.values(graph.processes).find(
    (process) => process.metadata.label === label
  ) as GenericGraphProcessType<G>;
}
function getConnection<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(source: string, target: string, graph: G) {
  const sourceProcess = getProcessByLabel(source, graph);
  const targetProcess = getProcessByLabel(target, graph);
  return graph.connections.find(
    (connection) => connection.source === sourceProcess.id && connection.target === targetProcess.id
  );
}
export function getSuggestionGraph<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(virtualGraph: G, existingProcesses: G['processes'], existingConnections: G['connections']) {
  const processes: GenericGraphProcesses<
    unknown,
    BaseProcessMetadata,
    BaseProcess<BaseProcessMetadata>,
    GraphConnection
  > = {};

  Object.values(virtualGraph.processes).forEach((process) => {
    if (!existingProcesses[process.id]) {
      processes[process.id] = process;
    }
  });
  const connections = virtualGraph.connections.filter((conn) => {
    const index = existingConnections.findIndex((connection) => {
      return (
        connection.source === conn.source &&
        connection.target === conn.target &&
        connection.sourcePort === conn.sourcePort &&
        connection.targetPort === conn.targetPort
      );
    });
    return index === -1;
  });
  return {
    processes: processes,
    connections: connections
  };
}

function getGemSelector(processId: string) {
  return `.react-flow__node[data-id="${processId}"]`;
}
export function getRadioButtonSelector(value: string, checked: boolean): Selector {
  if (checked) {
    return [`button[role="radio"][data-state="checked"]`, value];
  } else {
    return [`button[role="radio"][data-state="unchecked"]`, value];
  }
}
export function getTableCellSelector(row: number, cell: number) {
  return `.ui-table-tbody .ui-table-row[data-row-index="${row - 1}"] .ui-table-cell:nth-child(${cell})`;
}
export function getTargetColumnSelector(value?: string) {
  if (value) {
    return `input[placeholder="target_column"][value="${value}"]`;
  } else {
    return `input[placeholder="target_column"]`;
  }
}
export function getTargetColumnSelectorButton(value: string): Selector {
  return [`button[type="button"]`, value];
}
export function getExpressionColumnSelector(value?: string): Selector {
  if (value) {
    return `div[${EXPRESSION_BOX_VALUE_ATTR}="${value.replaceAll('"', '\\"')}"]`;
  }
  return `div[${EXPRESSION_BOX_VALUE_ATTR}]`;
}
export function getEditorSelector(value: string): Selector {
  return `div[data-component="Editor"][${EXPRESSION_BOX_VALUE_ATTR}="${value.replaceAll(/"/g, '\\"')}"]`;
}

export function getTabSelector(text: string, active?: boolean): Selector {
  if (active) {
    return ['[type="button"][role="tab"][aria-selected="true"]', text];
  }
  return [`[type="button"][role="tab"]`, text];
}

export function getSwitchSelector(text: string, active?: boolean): Selector {
  if (active) {
    return ['[type="button"][role="radio"][aria-checked="true"]', text];
  }
  return ['[type="button"][role="radio"]', text];
}

export function getTreeNodeSelector(text: string, open: boolean): Selector {
  if (open) {
    return `.${TREE_NODE_CONTENT_WRAPPER_CLASS}.${TREE_NODE_CONTENT_WRAPPER_OPEN_CLASS}[data-title="${text}"]`;
  } else {
    return `.${TREE_NODE_CONTENT_WRAPPER_CLASS}[data-title="${text}"]`;
  }
}
const HIGHLIGHT_CLS = 'ob-highlighted';
function resetHighlightStyle(element: Element | null) {
  const styleStr = element?.getAttribute('style');
  if (styleStr?.includes(highlightColumnStyle)) {
    element?.classList.remove(HIGHLIGHT_CLS);
    element?.setAttribute('style', styleStr.replace(highlightColumnStyle, ''));
  }
}
function addHighlightStyle(element: Element) {
  const styleStr = element.getAttribute('style') || '';
  if (!styleStr?.includes(highlightColumnStyle)) {
    element.classList.add(HIGHLIGHT_CLS);
    element.setAttribute('style', `${styleStr} ${highlightColumnStyle}`);
  }
}

export async function highlightColumnInPortSchema(
  step: StepInstance,
  portType: string,
  columnNames: readonly string[]
) {
  const result = columnNames.map((columnName) => {
    return step.operations.syncElement(
      `[data-port-type="${portType}"] ${getTreeNodeSelector(columnName, false)}`,
      addHighlightStyle,
      resetHighlightStyle
    );
  });
  // let it reflect it dom
  await delay(100);
  return result;
}
export async function showArrowAroundHighlightedColumn(step: StepInstance) {
  const selector = `.${HIGHLIGHT_CLS}`;
  const el = querySelector(selector);
  if (el) {
    return step.operations.showIndicator({
      selector: `.${HIGHLIGHT_CLS}`,
      waitForEvent: false,
      styles: {
        ...TreeNodeColumnStyles,
        zIndex: Layers.dialog
      }
    });
  } else {
    return noop;
  }
}

const StyledText = styled(Text)`
  align-self: center;
`;
const ExpressionContainer = styled(Stack)`
  border: 1px solid ${theme.colors.warning300};
  background: ${theme.colors.warning25};
  border-radius: ${theme.radius.xs};
  padding: 0 ${theme.spaces.x2};
`;
export function highlightExpression(message: string, onClick: () => void) {
  return (
    <Tooltip title='Click to auto-fill' placement='top'>
      <ExpressionContainer>
        <StyledText level='sm' tone={theme.colors.warning500} onClick={onClick}>
          {message}
        </StyledText>
      </ExpressionContainer>
    </Tooltip>
  );
}
export function highlightTextMessage(message: string) {
  return (
    <StyledText level='sm' tone={theme.colors.warning500}>
      {message}
    </StyledText>
  );
}

export function autoConnectGem<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>({
  connections,
  source,
  target,
  virtualGraph,
  connectionsPath,
  propertiesDidChange,
  propertiesDidSave
}: {
  connections: GraphConnection[];
  source: string;
  target: string;
  virtualGraph: G;
  connectionsPath: string;
  propertiesDidChange: PropertiesDidChange;
  propertiesDidSave: PropertiesDidSave;
}) {
  const connection = getConnection(source, target, virtualGraph);
  propertiesDidChange({
    property: connectionsPath,
    value: [...connections, connection]
  });
  propertiesDidSave();
}

export const TaskFinishValue = true as const;

export const StyledButtonContainer = styled(Stack)`
  flex-direction: column;
  button {
    order: unset;
  }
`;

export function getDialogPropsForCreateProject(comingFromFabric: boolean, skippedPipeline: boolean, copilot: boolean) {
  let okButton = 'Create Project',
    closeButton = 'Skip. I’ll do it later',
    title,
    subTitle;
  if (comingFromFabric) {
    title = 'Your fabric is ready!';
    subTitle = `You're ready to create a project to manage your pipelines.`;
  } else if (skippedPipeline) {
    title = `You have skipped the onboarding pipeline.`;
    subTitle = null;
  } else if (copilot) {
    title = 'Your pipeline is up and running!';
    subTitle = 'I want AI to build a pipeline for me';
    okButton = 'Try Copilot';
    closeButton = 'Skip. I’ll create Project';
  } else {
    title = 'Your first pipeline is up and running!';
    subTitle =
      'We have just built a pipeline without writing any code. You are ready to build a pipeline on your own data.';
  }
  const dialogProps: DialogUtilConfig = {
    iconPlacement: 'center',
    title,
    subTitle,
    okButton: { children: okButton },
    closeButton: { children: closeButton },
    ButtonsContainer: (props) => {
      return <StyledButtonContainer gap={theme.spaces.x12}>{props.children}</StyledButtonContainer>;
    }
  };
  return dialogProps;
}
export function getDialogPropsForCreateFabric(skippedPipeline: boolean, copilot: boolean) {
  let okButton = 'Create Data Fabric',
    closeButton = 'Skip. I’ll do it later',
    title,
    subTitle;
  if (skippedPipeline) {
    title = `You have skipped the onboarding pipeline.`;
    subTitle = null;
  } else if (copilot) {
    title = `Your first pipeline is up and running!`;
    subTitle = `I want AI to build a pipeline for me`;
    okButton = 'Try Copilot';
    closeButton = 'Skip. I’ll create Fabric';
  } else {
    title = 'Your first pipeline is up and running!';
    subTitle =
      'We have just built a pipeline without writing any code. You are ready to create a Data Fabric to run a pipeline';
  }
  const dialogProps: DialogUtilConfig = {
    iconPlacement: 'center',
    title: title,
    subTitle: subTitle,
    okButton: { children: okButton },
    closeButton: { children: closeButton },
    ButtonsContainer: (props) => {
      return <StyledButtonContainer gap={theme.spaces.x12}>{props.children}</StyledButtonContainer>;
    }
  };
  return dialogProps;
}

export type SparkExpressionType = {
  target: string;
  expression: {
    format: string;
    expression: string;
  };
  description: string;
  _row_id: string;
};
export type SqlExpressionType = {
  alias: string;
  expression: {
    expression: string;
  };
};
function getRowNumberForExpression<E extends SparkExpressionType | SqlExpressionType>(
  expressions: E[],
  targetColumn: string,
  expression: string,
  targetColumnName: keyof Omit<E, 'expression'>
) {
  let pre: E[] = [],
    post: E[] = [];

  let indexFound = expressions.length,
    isTargetColumnFilled = false,
    isExpressionFilled = false,
    rowNumber = expressions.length + 1;
  for (let index = 0; index < expressions.length; index++) {
    const item = expressions[index];
    const value = item[targetColumnName] as unknown as string;
    if (value === targetColumn) {
      isTargetColumnFilled = true;
      if (item.expression.expression === expression) {
        isExpressionFilled = true;
      }
      rowNumber = index + 1;
      indexFound = index;
      break;
    }
  }
  pre = expressions.slice(0, indexFound);
  post = expressions.slice(indexFound + 1);
  return { rowNumber, pre, post, isTargetColumnFilled, isExpressionFilled };
}
export function getRowNumberForSqlExpression(
  expressions: SqlExpressionType[],
  targetColumn: string,
  expression: string
) {
  return getRowNumberForExpression(expressions, targetColumn, expression, 'alias');
}
export function getRowNumberForSparkExpression(
  expressions: SparkExpressionType[],
  targetColumn: string,
  expression: string
) {
  return getRowNumberForExpression(expressions, targetColumn, expression, 'target');
}

export async function matchExpressionValue(
  step: StepInstance,
  expressionSelector: Selector,
  valuesToCompare: string[]
) {
  const compare = () => {
    const element = querySelector(expressionSelector);
    const value = element?.getAttribute(EXPRESSION_BOX_VALUE_ATTR) || '';
    const withoutSpaces = removeSpace(value);
    return valuesToCompare.includes(withoutSpaces);
  };

  await step.operations.waitForElement(expressionSelector);
  await step.operations.asyncTask(() => {
    const element = querySelector(expressionSelector);
    if (element && compare()) {
      return Promise.resolve(true);
    }
    return waitForAttributeChange(compare);
  });
}
export const CODE_VIEW_ACTIVE = 'CODE_VIEW_ACTIVE';
export async function waitForCodeTab(step: StepInstance) {
  await step.operations.waitForElement(getSwitchSelector('Code', true));
  return CODE_VIEW_ACTIVE;
}
export async function waitForVisualTab(step: StepInstance) {
  step.operations.addToast({
    content: renderMessage(
      <>
        👉 <b>Click</b> on <b>Visual</b> to continue
      </>
    )
  });
  let removeArrow = await step.operations.showIndicator({
    selector: getSwitchSelector('Visual'),
    waitForEvent: false,
    styles: {
      zIndex: Layers.canvas
    }
  });
  await step.operations.waitForElement(getSwitchSelector('Visual', true));
  removeArrow();
}
export const OUTPUT_PORT_ACTIVE = 'OUTPUT_PORT_ACTIVE';
export async function waitForOutputPort(step: StepInstance) {
  await step.operations.waitForElement(getTabSelector('Output', true));
  return OUTPUT_PORT_ACTIVE;
}
export async function waitForInputPort(step: StepInstance) {
  step.operations.addToast({
    content: renderMessage(
      <>
        👉 <b>Click</b> on <b>Input</b> to continue
      </>
    )
  });
  let removeArrow = await step.operations.showIndicator({
    selector: getTabSelector('Input'),
    waitForEvent: false,
    styles: {
      zIndex: Layers.dialog
    }
  });
  await step.operations.waitForElement(getTabSelector('Input', true));
  removeArrow();
}
export function removeSpace(value: string = '') {
  return value.replace(/\s/g, '').trim();
}
const MAIN_RUN_BTN_SELECTOR = `button[${RUN_BUTTON_ATTR_NAME}="${RUN_BUTTON_ATTR_VALUE.Pipeline}"]`;
const GEM_DIALOG_RUN_BTN_SELECTOR = `button[${RUN_BUTTON_ATTR_NAME}="${RUN_BUTTON_ATTR_VALUE.GemDialog}"]`;
const ARROW_XY_RUN_DIALOG = {
  zIndex: Layers.dialog
};
const ARROW_XY_RUN_MAIN = {
  zIndex: Layers.canvas
};

export async function run(
  step: StepInstance,
  inGemDialog: boolean,
  message1: React.ReactNode,
  message2: React.ReactNode
) {
  const selector = inGemDialog ? GEM_DIALOG_RUN_BTN_SELECTOR : MAIN_RUN_BTN_SELECTOR;
  // click run button
  step.operations.addToast({
    content: message1
  });
  let removeArrow = await step.operations.showIndicator({
    selector: selector,
    waitForEvent: false,
    styles: inGemDialog ? ARROW_XY_RUN_DIALOG : ARROW_XY_RUN_MAIN
  });
  const loadingIconSelector = `${selector} [data-icon-name="LoadingD"]`;
  // use may click other play btn in gem to trigger interim, wait for loading icon to visible
  await step.operations.waitForElement(loadingIconSelector);
  removeArrow();
  // wait for interims to show
  step.operations.addToast({
    content: message2
  });
  const loadingIconEl = querySelector(loadingIconSelector) as Element;
  await step.operations.asyncTask(() => waitForElementToRemoved(loadingIconEl));
}

export const PARTIALLY_CONFIGURED_GEM_MESSAGE = (
  <Stack height='28px' direction='horizontal' alignY='center' gap={theme.spaces.x4}>
    <Spinner />
    <span>The Gem is partially configured, auto-configuring the Gem.</span>
  </Stack>
);
export function getSparkOnboardingFlow(
  userFabricStatus: UserFabricStatus,
  onboardingStatus: OnboardingStatus,
  onboardingPipelineId: string
) {
  if (onboardingStatus === OnboardingStatus.READY_TO_BEGIN) {
    const url = getIDEUrl({
      uid: onboardingPipelineId as string,
      entity: Entity.Pipeline
    });
    return { url, flow: OnboardingStatus.ONBOARDING_PIPELINE };
  } else if (onboardingStatus === OnboardingStatus.ONBOARDING_PIPELINE) {
    if (userFabricStatus === UserFabricStatusKnownStatus.NoFabric) {
      // no fabric , go to fabric creation flow
      const url = Private_Routes.Create_Entity.fabric.new.getUrl(undefined, {
        fabricProviderType: FabricProviderType.Spark,
        onboarding: 'true'
      });
      return { url, flow: OnboardingStatus.NEW_FABRIC };
    } else if (userFabricStatus === UserFabricStatusKnownStatus.AlreadyHaveFabric) {
      // have working fabric, go to create project flow
      const url = Private_Routes.Create_Entity.project.getUrl(undefined, {
        onboarding: 'true'
      });
      return { url, flow: OnboardingStatus.NEW_PROJECT };
    } else {
      //invited user flow, create a new fabric
      const url = Private_Routes.Create_Entity.fabric.new.getUrl(undefined, {
        fabricProviderType: FabricProviderType.Spark,
        onboarding: 'true'
      });
      return { url, flow: OnboardingStatus.NEW_FABRIC };
    }
  } else if (
    (onboardingStatus === OnboardingStatus.NEW_FABRIC || onboardingStatus === OnboardingStatus.UPDATE_FABRIC) &&
    userFabricStatus === UserFabricStatusKnownStatus.AlreadyHaveFabric
  ) {
    const url = Private_Routes.Create_Entity.project.getUrl(undefined, {
      onboarding: 'true'
    });
    return { url, flow: OnboardingStatus.NEW_PROJECT };
  } else if (
    onboardingStatus === OnboardingStatus.NEW_PROJECT &&
    userFabricStatus === UserFabricStatusKnownStatus.AlreadyHaveFabric
  ) {
    const url = Private_Routes.Create_Entity.project.getUrl(undefined, {
      onboarding: 'true'
    });
    return { url, flow: OnboardingStatus.NEW_PROJECT };
  } else if (
    onboardingStatus === OnboardingStatus.NEW_PIPELINE &&
    userFabricStatus === UserFabricStatusKnownStatus.AlreadyHaveFabric
  ) {
    const url = Private_Routes.Create_Entity.project.getUrl(undefined);
    return { url, flow: OnboardingStatus.NEW_PROJECT };
  } else {
    const url = Private_Routes.Create_Entity.fabric.new.getUrl(undefined);
    return { url, flow: OnboardingStatus.NEW_FABRIC };
  }
}
export function getSqlOnboardingFlow(
  userFabricStatus: UserFabricStatus,
  onboardingStatus: OnboardingStatus,
  onboardingProjectId: string
) {
  if (onboardingStatus === OnboardingStatus.READY_TO_BEGIN) {
    const url = Private_Routes.SQL_IDE.getUrl(
      {
        uid: onboardingProjectId as string
      },
      { entity: Entity.Model, name: Processes.CustomerNation.label }
    );
    return { url, flow: OnboardingStatus.ONBOARDING_PIPELINE };
  } else if (onboardingStatus === OnboardingStatus.ONBOARDING_PIPELINE) {
    if (userFabricStatus === UserFabricStatusKnownStatus.NoFabric) {
      // no fabric , go to fabric creation flow
      const url = Private_Routes.Create_Entity.fabric.new.getUrl(undefined, {
        fabricProviderType: FabricProviderType.SQL,
        onboarding: 'true'
      });
      return { url, flow: OnboardingStatus.NEW_FABRIC };
    } else if (userFabricStatus === UserFabricStatusKnownStatus.AlreadyHaveFabric) {
      // have working fabric, go to create project flow
      const url = Private_Routes.Create_Entity.project.getUrl(undefined, {
        providerType: SQL_PROVIDER.DataBricks,
        onboarding: 'true'
      });
      return { url, flow: OnboardingStatus.NEW_PROJECT };
    } else {
      //invited user flow, create a new fabric
      const url = Private_Routes.Create_Entity.fabric.new.getUrl(undefined, {
        fabricProviderType: FabricProviderType.SQL,
        onboarding: 'true'
      });
      return { url, flow: OnboardingStatus.NEW_FABRIC };
    }
  } else if (
    (onboardingStatus === OnboardingStatus.NEW_FABRIC || onboardingStatus === OnboardingStatus.UPDATE_FABRIC) &&
    userFabricStatus === UserFabricStatusKnownStatus.AlreadyHaveFabric
  ) {
    const url = Private_Routes.Create_Entity.project.getUrl(undefined, {
      providerType: SQL_PROVIDER.DataBricks,
      onboarding: 'true'
    });
    return { url, flow: OnboardingStatus.NEW_PROJECT };
  } else if (
    onboardingStatus === OnboardingStatus.NEW_PROJECT &&
    userFabricStatus === UserFabricStatusKnownStatus.AlreadyHaveFabric
  ) {
    const url = Private_Routes.Create_Entity.project.getUrl(undefined, {
      providerType: SQL_PROVIDER.DataBricks,
      onboarding: 'true'
    });
    return { url, flow: OnboardingStatus.NEW_PROJECT };
  } else {
    const url = Private_Routes.Create_Entity.fabric.new.getUrl(undefined);
    return { url, flow: OnboardingStatus.NEW_FABRIC };
  }
}

export async function resetDialogState<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  S extends Store<BaseState<G>, Action<any>>
>(store: S, propertiesDidReset: () => Promise<LSP.CallbackReturnType>) {
  const { $graph, currentGraph, currentComponentId } = store.getState();
  if (currentComponentId) {
    store.dispatch({
      type: CommonActionTypes.dialogClose
    });

    if (!isEqual($graph, currentGraph)) {
      await propertiesDidReset();
    }
  }
}
export const ExpressionBuilderOpen = 'ExpressionBuilderOpen';
export const ExpressionBuilderClose = 'ExpressionBuilderClose';
export async function waitForEBToOpen(step: StepInstance) {
  await step.operations.waitForElement(ExpressionBuilderOverlaySelector);
  return ExpressionBuilderOpen;
}
export async function waitForEBToClose() {
  const exitButtonClick = waitForEvent([querySelector(EB_EXIT_BTN_SELECTOR) as Element], 'click');
  const firstBreadcrumbButtonClick = waitForEvent(
    [querySelector(`button[${CRUMB_ATTR.Index}="0"]`) as Element],
    'click'
  );
  await Promise.race([exitButtonClick, firstBreadcrumbButtonClick]);
  return ExpressionBuilderClose;
}

export async function waitForUserToCloseEB(step: StepInstance, message?: React.ReactNode) {
  const exitButtonClick = waitForEvent([querySelector(EB_EXIT_BTN_SELECTOR) as Element], 'click');
  const firstBreadcrumbButtonClick = waitForEvent(
    [querySelector(`button[${CRUMB_ATTR.Index}="0"]`) as Element],
    'click'
  );
  await step.operations.showIndicator({
    selector: EB_EXIT_BTN_SELECTOR,
    waitForEvent: false,
    styles: {
      zIndex: Layers.dialog
    }
  });
  step.operations.addToast({
    content: message
      ? message
      : renderMessage(
          <>
            👉 <b>Click</b> on <b>Exit</b> to continue
          </>
        )
  });
  await Promise.race([exitButtonClick, firstBreadcrumbButtonClick]);
  return ExpressionBuilderClose;
}
export const CopilotSectionCloseValue = 'CopilotSectionCloseValue';
export async function waitForCopilotSectionToClose(step: StepInstance) {
  await Promise.race([waitForEvent(CloseIconSelector, 'click', 100), waitForEvent(FXButtonSelector, 'click', 100)]);
  return CopilotSectionCloseValue;
}
function animatePath(path: SVGPathElement, duration: number) {
  const totalLength = path.getTotalLength();
  const keyframes = new KeyframeEffect(
    path,
    [
      {
        visibility: 'hidden',
        strokeDasharray: `0 ${totalLength}`
      },
      {
        visibility: 'visible',
        strokeDasharray: `${totalLength} 0`
      }
    ],
    { duration: duration, iterations: Infinity }
  );

  const animationObj = new Animation(keyframes, document.timeline);
  animationObj.play();
  return () => {
    animationObj.cancel();
  };
}
