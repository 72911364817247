import { theme } from '../theme';
export const tokens = {
    Button: {
        transition: theme.transition.s,
        fontWeight: theme.fontWeight.semiBold,
        plain: {
            backgroundColor: theme.colors.transparent,
            color: 'inherit',
            border: 'none',
            hover: {
                backgroundColor: theme.colors.transparent
            }
        },
        default: {
            primary: {
                backgroundColor: theme.effects.primaryButtonBG.default,
                borderColor: theme.colors.transparent,
                borderWidth: theme.spaces.x2,
                color: theme.colors.white,
                boxShadow: theme.shadows.skeumorphicXs,
                hover: {
                    boxShadow: 'none',
                    backgroundColor: theme.effects.primaryButtonBG.defaultHover,
                    borderColor: theme.colors.transparent,
                    borderWidth: theme.spaces.x2
                },
                focused: {
                    boxShadow: theme.focusRing.shadow.xs,
                    borderColor: theme.colors.transparent,
                    borderWidth: theme.spaces.x2
                },
                disabled: {
                    color: theme.colors.gray400,
                    borderColor: theme.colors.gray200,
                    backgroundColor: theme.colors.gray100
                }
            },
            secondary: {
                borderColor: theme.colors.brand300,
                backgroundColor: theme.colors.white,
                color: theme.colors.brand700,
                boxShadow: theme.shadows.skeumorphic,
                hover: {
                    backgroundColor: theme.colors.brand100,
                    borderColor: theme.colors.brand300
                },
                focused: {
                    backgroundColor: theme.colors.white,
                    borderColor: theme.colors.brand300,
                    boxShadow: theme.focusRing.shadow.skeumorphic
                },
                disabled: {
                    color: theme.colors.gray400,
                    borderColor: theme.colors.gray200
                }
            },
            secondaryGray: {
                color: theme.colors.gray700,
                borderColor: theme.colors.gray300,
                backgroundColor: theme.colors.white,
                boxShadow: theme.shadows.skeumorphic,
                hover: {
                    backgroundColor: theme.colors.gray50,
                    color: theme.colors.gray800,
                    borderColor: theme.colors.gray300
                },
                focused: {
                    boxShadow: theme.focusRing.shadow.skeumorphic,
                    borderColor: theme.colors.gray300
                },
                disabled: {
                    color: theme.colors.gray400,
                    borderColor: theme.colors.gray200
                }
            },
            tertiary: {
                backgroundColor: 'transparent',
                color: theme.colors.brand600,
                boxShadow: 'none',
                hover: {
                    backgroundColor: theme.colors.brand50,
                    color: theme.colors.brand700
                },
                focused: {
                    borderColor: theme.colors.brand100,
                    boxShadow: theme.focusRing.shadow.xs
                },
                disabled: {
                    color: theme.colors.gray400
                }
            },
            tertiaryGray: {
                backgroundColor: 'transparent',
                color: theme.colors.gray700,
                boxShadow: 'none',
                hover: {
                    backgroundColor: theme.colors.gray50,
                    color: theme.colors.gray800
                },
                focused: {
                    boxShadow: theme.focusRing.shadow.xs
                },
                disabled: {
                    color: theme.colors.gray300
                }
            },
            link: {
                backgroundColor: theme.colors.transparent,
                color: theme.colors.brand700,
                padding: 0,
                boxShadow: 'none',
                hover: {
                    color: theme.colors.brand800
                },
                focused: {
                    borderColor: theme.colors.transparent,
                    boxShadow: theme.focusRing.shadow.xs
                },
                disabled: {
                    color: theme.colors.gray400
                }
            },
            linkGray: {
                backgroundColor: theme.colors.transparent,
                color: theme.colors.gray600,
                padding: 0,
                boxShadow: 'none',
                hover: {
                    color: theme.colors.gray700
                },
                focused: {
                    borderColor: theme.colors.transparent,
                    boxShadow: theme.focusRing.shadow.xs
                },
                disabled: {
                    color: theme.colors.gray400
                }
            }
        },
        danger: {
            primary: {
                backgroundColor: theme.effects.primaryButtonBG.error,
                borderColor: theme.colors.transparent,
                borderWidth: theme.spaces.x2,
                color: theme.colors.white,
                boxShadow: theme.shadows.skeumorphicXs,
                hover: {
                    boxShadow: 'none',
                    backgroundColor: theme.effects.primaryButtonBG.errorHover,
                    borderColor: theme.colors.transparent,
                    borderWidth: theme.spaces.x2
                },
                focused: {
                    boxShadow: theme.focusRing.error.shadow.xs,
                    borderColor: theme.colors.transparent,
                    borderWidth: theme.spaces.x2
                },
                disabled: {
                    color: theme.colors.gray400,
                    borderColor: theme.colors.gray200,
                    backgroundColor: theme.colors.gray100
                }
            },
            secondary: {
                borderColor: theme.colors.error300,
                backgroundColor: theme.colors.white,
                color: theme.colors.error700,
                hover: {
                    backgroundColor: theme.colors.error100,
                    borderColor: theme.colors.error300
                },
                focused: {
                    backgroundColor: theme.colors.error50,
                    borderColor: theme.colors.error300,
                    boxShadow: theme.focusRing.error.shadow.xs
                },
                disabled: {
                    color: theme.colors.gray400,
                    borderColor: theme.colors.gray200
                }
            },
            tertiary: {
                backgroundColor: theme.colors.transparent,
                color: theme.colors.error700,
                boxShadow: 'none',
                hover: {
                    backgroundColor: theme.colors.error50,
                    color: theme.colors.error800
                },
                focused: {
                    boxShadow: theme.focusRing.error.shadow.xs
                },
                disabled: {
                    color: theme.colors.gray400
                }
            },
            link: {
                backgroundColor: theme.colors.transparent,
                color: theme.colors.error700,
                padding: 0,
                boxShadow: 'none',
                hover: {
                    color: theme.colors.error800
                },
                focused: {
                    borderColor: theme.colors.transparent,
                    boxShadow: theme.focusRing.error.shadow.xs
                },
                disabled: {
                    color: theme.colors.gray400
                }
            }
        },
        size: {
            xs: {
                fontSize: theme.text.xs.fontSize,
                lineHeight: theme.text.xs.lineHeight,
                height: theme.sizes.x28,
                padding: `5px ${theme.spaces.x8}`,
                borderRadius: theme.radius.x6,
                iconSize: theme.sizes.x12,
                logoIconSize: theme.sizes.x20,
                gap: theme.spaces.x2
            },
            s: {
                fontSize: theme.text.sm.fontSize,
                lineHeight: theme.text.sm.lineHeight,
                height: theme.sizes.x32,
                padding: `${theme.spaces.x4} ${theme.spaces.x12}`,
                borderRadius: theme.radius.x6,
                iconSize: theme.sizes.x16,
                logoIconSize: theme.sizes.x20,
                gap: theme.spaces.x4
            },
            m: {
                fontSize: theme.text.sm.fontSize,
                lineHeight: theme.text.sm.lineHeight,
                height: theme.sizes.x40,
                padding: `${theme.spaces.x10} ${theme.spaces.x14}`,
                borderRadius: theme.radius.x8,
                iconSize: theme.sizes.x20,
                logoIconSize: theme.sizes.x24,
                gap: theme.spaces.x4
            },
            l: {
                fontSize: theme.text.md.fontSize,
                lineHeight: theme.text.md.lineHeight,
                height: theme.sizes.x44,
                padding: `${theme.spaces.x10} ${theme.spaces.x16}`,
                borderRadius: theme.radius.x8,
                iconSize: theme.sizes.x24,
                logoIconSize: theme.sizes.x24,
                gap: theme.spaces.x6
            },
            xl: {
                fontSize: theme.text.md.fontSize,
                lineHeight: theme.text.md.lineHeight,
                height: theme.sizes.x48,
                padding: `${theme.spaces.x12} 18px`,
                borderRadius: theme.radius.x8,
                iconSize: theme.sizes.x28,
                logoIconSize: theme.sizes.x28,
                gap: theme.spaces.x6
            },
            xxl: {
                fontSize: theme.text.lg.fontSize,
                lineHeight: theme.text.lg.lineHeight,
                height: theme.sizes.x56,
                padding: `${theme.spaces.x14} 22px`,
                borderRadius: theme.radius.x10,
                iconSize: theme.sizes.x32,
                logoIconSize: theme.sizes.x32,
                gap: theme.spaces.x10
            }
        }
    },
    ButtonGroupItem: {
        color: theme.colors.gray700,
        borderColor: theme.colors.gray300,
        default: {
            primary: {
                backgroundColor: theme.colors.brand600,
                boxShadow: 'none',
                hover: {
                    backgroundColor: theme.colors.brand700
                },
                focused: {
                    backgroundColor: theme.colors.brand700,
                    boxShadow: 'none'
                },
                disabled: {
                    color: theme.colors.white,
                    borderColor: theme.colors.gray200,
                    backgroundColor: theme.colors.brand200
                }
            }
        },
        danger: {
            primary: {
                backgroundColor: theme.colors.error600,
                boxShadow: 'none',
                hover: {
                    backgroundColor: theme.colors.error700
                },
                focused: {
                    backgroundColor: theme.colors.error700,
                    boxShadow: 'none'
                },
                disabled: {
                    color: theme.colors.white,
                    borderColor: theme.colors.gray200,
                    backgroundColor: theme.colors.error200
                }
            }
        },
        secondaryGray: {
            boxShadow: 'none',
            focused: {
                backgroundColor: theme.colors.gray50
            },
            disabled: {
                color: theme.colors.gray700
            }
        }
    }
};
