import { deserializeMd } from '@udecode/plate-markdown';
import { visitParents } from 'unist-util-visit-parents';
function processNodesToSplit(nodesToSplit) {
    for (const [node, { parent, highlightRanges }] of nodesToSplit) {
        const nodeStart = node.position.start.offset;
        const nodeEnd = node.position.end.offset;
        const nodeText = node.value;
        // Adjust highlight ranges to be relative to the node's text
        const adjustedRanges = highlightRanges
            .map((range) => ({
            from: Math.max(range.from, nodeStart) - nodeStart,
            to: Math.min(range.to, nodeEnd) - nodeStart,
            commentId: range.commentId
        }))
            .filter((range) => range.from < range.to); // Exclude non-overlapping ranges
        // Determine all split points
        const splitPoints = new Set([0, nodeText.length]);
        adjustedRanges.forEach((range) => {
            splitPoints.add(range.from);
            splitPoints.add(range.to);
        });
        // Sort split points
        const sortedSplitPoints = Array.from(splitPoints).sort((a, b) => a - b);
        // Create new subnodes
        const newNodes = [];
        for (let i = 0; i < sortedSplitPoints.length - 1; i++) {
            const startOffset = sortedSplitPoints[i];
            const endOffset = sortedSplitPoints[i + 1];
            const segmentText = nodeText.slice(startOffset, endOffset);
            // Find overlapping highlight ranges
            const segmentRanges = adjustedRanges.filter((range) => range.from < endOffset && range.to > startOffset);
            // Build additional properties
            const additionalProps = {};
            if (segmentRanges.length > 0) {
                additionalProps.comment = true;
                segmentRanges.forEach((range) => {
                    additionalProps[`comment_${range.commentId}`] = true;
                });
            }
            // Create new MdastNode with additional properties
            const newNode = {
                position: {
                    start: { ...node.position.start, offset: nodeStart + startOffset },
                    end: { ...node.position.end, offset: nodeStart + endOffset }
                },
                type: 'text',
                value: segmentText,
                ...additionalProps
            };
            newNodes.push(newNode);
        }
        // Replace the original node with the new subnodes in the parent's children
        const index = parent.children.indexOf(node);
        parent.children.splice(index, 1, ...newNodes);
    }
}
export const remarkCommentPlugin = (options) => {
    const { comments } = options;
    return (tree) => {
        // Pre-process comments to compute offsets and initialize nodesInRange
        const commentsWithOffsets = comments.map((comment) => {
            const fromOffset = comment.range.fromOffset;
            const toOffset = comment.range.toOffset;
            return {
                ...comment,
                fromOffset,
                toOffset,
                nodesInRange: []
            };
        });
        const nodesToSplit = new Map();
        const getNodeMetadata = (node, parent) => {
            if (!nodesToSplit.get(node)) {
                nodesToSplit.set(node, {
                    node,
                    parent,
                    highlightRanges: []
                });
            }
            return nodesToSplit.get(node);
        };
        visitParents(tree, (node, ancestors) => {
            if (node.type !== 'text' || !node.position)
                return;
            const nodeStartOffset = node.position.start.offset;
            const nodeEndOffset = node.position.end.offset;
            const parent = ancestors[ancestors.length - 1];
            commentsWithOffsets.forEach((comment) => {
                const { id, fromOffset, toOffset } = comment;
                // if full node is within the comment range, mark the node as a comment
                if (nodeStartOffset >= fromOffset && nodeEndOffset <= toOffset) {
                    const _node = node;
                    _node.comment = true;
                    _node[`comment_${id}`] = true;
                    // if the comment is within the node range, highlight the middle part of the text
                }
                else if (fromOffset > nodeStartOffset && toOffset < nodeEndOffset) {
                    const { highlightRanges } = getNodeMetadata(node, parent);
                    highlightRanges.push({
                        from: fromOffset,
                        to: toOffset,
                        commentId: id
                    });
                    // if the comment overlaps with the text node on start, highlight that section
                }
                else if (toOffset >= nodeStartOffset && toOffset < nodeEndOffset) {
                    const { highlightRanges } = getNodeMetadata(node, parent);
                    highlightRanges.push({
                        from: nodeStartOffset,
                        to: toOffset,
                        commentId: id
                    });
                    // if the comment overlaps with the text node on end, highlight that section
                }
                else if (fromOffset >= nodeStartOffset && fromOffset < nodeEndOffset) {
                    const { highlightRanges } = getNodeMetadata(node, parent);
                    highlightRanges.push({
                        from: fromOffset,
                        to: nodeEndOffset,
                        commentId: id
                    });
                }
            });
        });
        // Split nodes that have highlights
        processNodesToSplit(nodesToSplit);
        return tree;
    };
};
export function deserializeMdWithComments(editor, value, comments) {
    if (!comments?.length)
        return deserializeMd(editor, value);
    return deserializeMd(editor, value, {
        //@ts-ignore
        processor: (tree) => {
            return tree.use(remarkCommentPlugin, {
                comments,
                text: value || ''
            });
        }
    });
}
