import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { clone, isNil } from 'lodash-es';
import { isValidElement } from 'react';
import styled from 'styled-components';
import { InterimAIcon, InterimBIcon } from '../Icons';
import { StyledMark } from './styled';
import { AUTO_POSITION, GRID_SIZE, NODE_DIMENSION, REACT_FLOW_CLASS, TILE_NODE_HEIGHT, TILE_NODE_WIDTH } from './tokens';
export function isElementInReactFlow(element) {
    return !!element.closest(`.${REACT_FLOW_CLASS}`);
}
export function isGraphPresent() {
    return Boolean(document.querySelector(`.${REACT_FLOW_CLASS}`));
}
export function getTileSize(noOfSourceHandles = 0, noOfTargetHandles = 0) {
    return { height: TILE_NODE_HEIGHT, width: TILE_NODE_WIDTH };
}
export function getNodeSize(noOfSourceHandles = 0, noOfTargetHandles = 0) {
    const maxPorts = Math.max(noOfSourceHandles, noOfTargetHandles);
    let size = NODE_DIMENSION;
    let divider = 3.5;
    if (maxPorts >= 4) {
        size = (size * maxPorts) / divider;
    }
    return { width: size, height: size };
}
export function stripNodeProperties(node) {
    const { data, ...restNode } = node;
    // strip all react elements and functions
    const cloneableData = Object.fromEntries(Object.entries(data).filter(([key, value]) => {
        return !(isValidElement(value) || typeof value === 'function');
    }));
    const _node = clone(restNode);
    const _data = clone(cloneableData);
    _node.data = _data;
    return _node;
}
export function defaultFilterNodes(node) {
    return true;
}
export function snapPosition(position) {
    return {
        x: position.x ? GRID_SIZE * Math.round(position.x / GRID_SIZE) : position.x,
        y: position.y ? GRID_SIZE * Math.round(position.y / GRID_SIZE) : position.y
    };
}
export function checkRectangleOverlap(rect1, rect2) {
    const left1 = rect1.x;
    const right1 = rect1.x + rect1.width;
    const top1 = rect1.y;
    const bottom1 = rect1.y + rect1.height;
    const left2 = rect2.x;
    const right2 = rect2.x + rect2.width;
    const top2 = rect2.y;
    const bottom2 = rect2.y + rect2.height;
    return left1 < right2 && right1 > left2 && top1 < bottom2 && bottom1 > top2;
}
export function isNodeNotPositioned(node) {
    const { x, y } = node.position;
    return x > AUTO_POSITION || y > AUTO_POSITION || isNil(x) || isNil(y);
}
/**
 * Note the getRectOfNodes util from react-flow, doesn't give rect based on absolute position,
 * until its explicitly set.
 */
export function getNodeRect(node, nodeInternals) {
    const nodeInternal = nodeInternals.get(node.id);
    const position = nodeInternals.get(node.id)?.positionAbsolute || node.position;
    const width = nodeInternal?.width || node.width || NODE_DIMENSION;
    const height = nodeInternal?.height || node.height || NODE_DIMENSION;
    return {
        ...position,
        width,
        height
    };
}
export function getTextWithHighlight(text = '', searchQuery = '', style) {
    if (!searchQuery || searchQuery.trim().length === 0)
        return text;
    const matchIndex = text.toLowerCase().indexOf(searchQuery.toLowerCase());
    if (matchIndex !== -1) {
        return (_jsxs(_Fragment, { children: [text.substring(0, matchIndex), _jsx(StyledMark, { style: style, children: text.substring(matchIndex, matchIndex + searchQuery.length) }), text.substring(matchIndex + searchQuery.length)] }));
    }
    else {
        return text;
    }
}
export const StyledInterimStaleIcon = styled(InterimBIcon) `
  filter: grayscale(1);
`;
export function getInterimIcon({ isStale, isLazyLoadInterim }) {
    if (isLazyLoadInterim)
        return _jsx(InterimBIcon, {});
    if (isStale)
        return _jsx(StyledInterimStaleIcon, {});
    return _jsx(InterimAIcon, {});
}
