import { jsx as _jsx } from "react/jsx-runtime";
import { isAbsoluteURL, LinkTarget } from '@prophecy/utils/url';
import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import remarkGfm from 'remark-gfm';
const linkTarget = ({ properties }) => {
    return isAbsoluteURL(properties.href) ? LinkTarget._blank : LinkTarget._self;
};
export default function Markdown({ children: markdownContent, className, remarkPlugins, ...restProps }) {
    const remarkPluginsList = remarkPlugins || [];
    return (_jsx(ReactMarkdown, { ...restProps, className: `ui-typography ${className || ''}`, rehypePlugins: [[rehypeExternalLinks, { target: linkTarget, rel: ['nofollow', 'noopener'] }]], remarkPlugins: [remarkGfm, ...remarkPluginsList], children: markdownContent?.replace(/\n/g, '  \n') }));
}
