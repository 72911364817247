import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { findNode } from '@udecode/plate-common';
import { useFormInputProps, useEditorPlugin } from '@udecode/plate-common/react';
import { flip, offset } from '@udecode/plate-floating';
import { encodeUrlIfNeeded } from '@udecode/plate-link';
import { useLinkToolbarButton, useLinkToolbarButtonState, LinkPlugin, useFloatingLinkEdit, useFloatingLinkEditState, useFloatingLinkInsert, useFloatingLinkInsertState } from '@udecode/plate-link/react';
import { useEffect, useRef } from 'react';
import { Button } from '../Button';
import { MarkdownLinkIcon, MarkdownUnlinkIcon, LinkExternalAIcon } from '../Icons';
import { Stack } from '../Layout';
import { Separator } from '../Separator';
import { theme } from '../theme';
import { StyledLinkInput, StyledFloatingToolbar, StyledButton } from './styled';
const floatingOptions = {
    middleware: [
        offset(12),
        flip({
            fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
            padding: 12
        })
    ],
    placement: 'bottom'
};
export function LinkButton() {
    const state = useLinkToolbarButtonState();
    const { props } = useLinkToolbarButton(state);
    return _jsx(StyledButton, { variant: 'tertiaryGray', size: 'm', ...props, icon: _jsx(MarkdownLinkIcon, { type: 'extended' }) });
}
function LinkInput() {
    const insertState = useFloatingLinkInsertState({
        floatingOptions
    });
    const { hidden, props: insertProps, ref: insertRef } = useFloatingLinkInsert(insertState);
    const editState = useFloatingLinkEditState({
        floatingOptions
    });
    const { editButtonProps, props: editProps, ref: editRef, unlinkButtonProps } = useFloatingLinkEdit(editState);
    const inputProps = useFormInputProps({
        preventDefaultOnEnterKeydown: true
    });
    const { getOptions, useOption, setOption } = useEditorPlugin(LinkPlugin);
    const inputRef = useRef(null);
    const updated = useOption('updated');
    useEffect(() => {
        if (inputRef.current && updated) {
            setTimeout(() => {
                const input = inputRef.current;
                if (!input)
                    return;
                const url = getOptions().url;
                input.focus();
                input.value = url ? decodeURI(url) : '';
            }, 0);
        }
    }, [getOptions, updated]);
    const onInputChange = (value) => {
        setOption('url', encodeUrlIfNeeded(value));
    };
    if (hidden)
        return null;
    const input = (_jsx(Stack, { ...inputProps, height: '100%', align: 'center', children: _jsx(StyledLinkInput, { icon: _jsx(MarkdownLinkIcon, { type: 'extended' }), defaultValue: getOptions().url, ref: inputRef, autoFocus: true, onChange: onInputChange, iconPlacement: 'left', placeholder: 'Link URL' }) }));
    const linkNode = findNode(editState.editor, {
        match: { type: editState.editor.getType(LinkPlugin) }
    })?.[0];
    const editContent = editState.isEditing ? (input) : (_jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x4, alignY: 'center', children: [_jsx(Button, { variant: 'tertiaryGray', size: 's', ...editButtonProps, children: "Edit link" }), _jsx(Separator, { orientation: 'vertical' }), _jsx(Button, { variant: 'tertiaryGray', size: 's', icon: _jsx(LinkExternalAIcon, { type: 'default' }), elementType: 'anchor', "aria-label": 'Open link in a new tab', target: '_blank', href: linkNode?.url, onClick: (e) => {
                    e.stopPropagation();
                } }), _jsx(Separator, { orientation: 'vertical' }), _jsx(Button, { variant: 'tertiaryGray', size: 's', icon: _jsx(MarkdownUnlinkIcon, { type: 'extended' }), ...unlinkButtonProps })] }));
    return (_jsxs(_Fragment, { children: [_jsx(StyledFloatingToolbar, { ref: insertRef, ...insertProps, align: 'center', children: input }), _jsx(StyledFloatingToolbar, { ref: editRef, ...editProps, align: 'center', children: editContent })] }));
}
export const CustomLinkPlugin = LinkPlugin.extend({
    render: {
        afterEditable: () => _jsx(LinkInput, {})
    }
});
