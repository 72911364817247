export var Size;
(function (Size) {
    Size["xs"] = "xs";
    Size["s"] = "s";
    Size["m"] = "m";
    Size["l"] = "l";
})(Size || (Size = {}));
export var Variant;
(function (Variant) {
    Variant["pillLight"] = "pillLight";
    Variant["pillOutline"] = "pillOutline";
    Variant["pillDark"] = "pillDark";
    Variant["badgeColor"] = "badgeColor";
    Variant["badgeModern"] = "badgeModern";
})(Variant || (Variant = {}));
//? BadgeGroup _________________________________________________________
export var BadgePlacement;
(function (BadgePlacement) {
    BadgePlacement["left"] = "left";
    BadgePlacement["right"] = "right";
})(BadgePlacement || (BadgePlacement = {}));
export var BadgeGroupSize;
(function (BadgeGroupSize) {
    BadgeGroupSize["m"] = "m";
    BadgeGroupSize["l"] = "l";
})(BadgeGroupSize || (BadgeGroupSize = {}));
