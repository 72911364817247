import { theme } from '../theme/index';
export const tokens = {
    RadioGroup: {
        gap: theme.spaces.x8,
        padding: theme.spaces.x16,
        borderRadius: theme.radius.x12
    },
    RadioItem: {
        color: theme.colors.gray600,
        iconColor: theme.colors.brand600,
        subTextColor: theme.colors.gray600,
        fontWeight: theme.fontWeight.medium,
        focus: {
            boxShadow: theme.focusRing.color
        },
        normal: {
            backgroundColor: 'transparent',
            borderColor: theme.colors.gray200,
            borderWidth: '1px', // one off size for radio button small size
            Icon: {
                borderColor: theme.colors.gray300,
                backgroundColor: theme.colors.white
            }
        },
        disabled: {
            backgroundColor: theme.colors.gray50,
            cardIconBackground: theme.colors.gray100,
            color: theme.colors.gray700,
            Icon: {
                borderColor: theme.colors.gray300,
                backgroundColor: theme.colors.gray100
            }
        },
        selected: {
            borderWidth: theme.spaces.x2,
            borderColor: theme.colors.brand500,
            backgroundColor: theme.colors.white,
            cardIconBackground: theme.colors.gray50,
            Icon: {
                borderColor: theme.colors.gray200,
                backgroundColor: theme.colors.white
            }
        },
        s: {
            size: theme.sizes.x16,
            fontSize: theme.fontSizes.x14,
            radioIconSize: theme.fontSizes.x16,
            padding: '5px' // one off size for radio button small size
        },
        m: {
            size: theme.sizes.x20,
            fontSize: theme.fontSizes.x16,
            radioIconSize: theme.fontSizes.x20,
            padding: theme.spaces.x6
        },
        borderRadius: '50%',
        marginRight: theme.spaces.x2,
        Caption: {
            color: theme.colors.gray600,
            fontSize: theme.fontSizes.x14,
            lineHeight: theme.lineHeight.x20
        }
    },
    RadioButtonGroupItem: {
        Title: {
            gap: theme.spaces.x12
        }
    }
};
