import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { useState, useRef } from 'react';
import styled from 'styled-components';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { Tooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
export const StyledContent = styled.div `
  display: initial;
`;
const ContentWrapper = styled.div `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${StyledContent} {
    /** 
     Browser can add ellipsis when the character barely touch the container.
     This padding adds an offset to handle such scenario  */
    padding-right: 0.5ch;
  }
`;
export const overlayStyle = {
    maxWidth: '500px',
    maxHeight: '300px',
    overflow: 'auto',
    padding: theme.spaces.x12,
    wordBreak: 'break-all'
};
const ChildrenWithHint = ({ hint, content }) => {
    if (!hint) {
        return _jsx(_Fragment, { children: content });
    }
    return (_jsxs(Stack, { width: '100%', direction: 'horizontal', alignY: 'center', gap: theme.spaces.x8, children: [content, _jsx(LabelWithHint, { hint: hint, children: null })] }));
};
export function Ellipsis({ tooltip, hint, tooltipProps, children, containerRef, onTooltipChange, alwaysShowTooltip, ...rest }) {
    const [showTooltip, toggleTooltip] = useState(false);
    const ref = useRef(null);
    const currentRef = containerRef || ref;
    const handleTruncate = usePersistentCallback((wrapper) => {
        let hasEllipsis = false;
        if (wrapper) {
            const { width } = wrapper.getBoundingClientRect();
            const { width: childWidth } = wrapper.children[0].getBoundingClientRect();
            if (width < childWidth) {
                hasEllipsis = true;
            }
        }
        return hasEllipsis;
    });
    const handleTooltipTitleChange = usePersistentCallback((e) => {
        if (handleTruncate(ref.current)) {
            toggleTooltip(true);
            onTooltipChange?.(true);
        }
        else if (showTooltip) {
            toggleTooltip(false);
            onTooltipChange?.(false);
        }
    });
    if (!tooltip)
        return (_jsx(ChildrenWithHint, { hint: hint, content: _jsx(ContentWrapper, { ...rest, ref: currentRef, children: children }) }));
    const tooltipTitle = tooltipProps?.title || children;
    const tooltipUI = (_jsx(Tooltip, { ...tooltipProps, title: showTooltip || alwaysShowTooltip ? tooltipTitle : undefined, children: _jsx(ContentWrapper, { ...rest, ref: currentRef, onMouseEnter: handleTooltipTitleChange, children: _jsx(StyledContent, { children: children }) }) }));
    return _jsx(ChildrenWithHint, { hint: hint, content: tooltipUI });
}
