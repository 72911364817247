import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, isValidElement } from 'react';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { Tooltip } from '../Tooltip';
import { useHint } from './Hint';
import { BaseText } from './styled';
export const Text = forwardRef(({ level, hint, tooltip, tone, weight, elementType, children, ...rest }, ref) => {
    const _hint = useHint({ hint, level, tone });
    let childrenUI = children;
    if (_hint) {
        childrenUI = (_jsxs(Stack, { gap: theme.spaces.x4, direction: 'horizontal', alignY: 'center', children: [children, _jsx(_Fragment, { children: _hint })] }));
    }
    if (tooltip) {
        const tooltipProps = isValidElement(tooltip) || typeof tooltip === 'string'
            ? { title: tooltip, placement: 'top' }
            : tooltip;
        childrenUI = (_jsx(Tooltip, { ...tooltipProps, children: _jsx("span", { children: childrenUI }) }));
    }
    return (_jsx(BaseText, { ...rest, tone: tone, weight: weight, as: elementType, level: level, ref: ref, children: childrenUI }));
});
