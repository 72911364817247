import { theme } from '../theme/index';
export const tokens = {
    SwitchRoot: {
        padding: theme.spaces.x2,
        borderRadius: theme.radius.x24,
        boxShadow: theme.focusRing.shadow.xs,
        state: {
            normal: {
                backgroundColor: theme.colors.gray300,
                hover: {
                    backgroundColor: theme.colors.gray300
                }
            },
            selected: {
                backgroundColor: theme.colors.brand500,
                hover: {
                    backgroundColor: theme.colors.brand600
                },
                focus: {
                    backgroundColor: theme.colors.brand500
                }
            },
            disabled: {
                backgroundColor: theme.colors.gray200
            }
        }
    },
    Label: {
        weight: theme.fontWeight.medium,
        color: theme.colors.gray700,
        helpTextColor: theme.colors.gray600,
        disabled: {
            color: theme.colors.gray500
        }
    },
    size: {
        sm: {
            height: theme.sizes.x20,
            width: theme.sizes.x36,
            SwitchThumb: {
                size: theme.sizes.x16
            }
        },
        md: {
            height: theme.sizes.x24,
            width: theme.sizes.x44,
            SwitchThumb: {
                size: theme.sizes.x20
            }
        }
    },
    SwitchThumb: {
        backgroundColor: theme.colors.white,
        disabled: {
            backgroundColor: theme.colors.gray50
        }
    },
    ToggleSwitch: {
        color: theme.colors.gray500,
        containerPadding: theme.spaces.x2,
        padding: theme.spaces.x10,
        borderRadius: theme.radius.x6,
        gap: theme.spaces.x6,
        fontWeight: theme.fontWeight.medium,
        activeState: {
            borderColor: theme.colors.purple300,
            boxShadow: theme.shadows.sm,
            backgroundColor: theme.colors.white,
            color: theme.colors.purple700
        },
        variant: {
            default: {
                backgroundColor: theme.colors.gray50
            },
            gray: {
                backgroundColor: theme.colors.gray100
            }
        },
        hoverState: {
            color: theme.colors.gray700,
            activeState: {
                color: theme.colors.purple700
            }
        },
        disabledState: {
            borderColor: theme.colors.gray200,
            color: theme.colors.gray300
        },
        iconSize: theme.fontSizes.x16,
        size: {
            sm: {
                height: theme.sizes.x28,
                fontSize: theme.fontSizes.x13
            },
            md: {
                height: theme.sizes.x32,
                iconSize: theme.fontSizes.x18,
                fontSize: theme.fontSizes.x14
            }
        }
    }
};
